import React from 'react'
import Video from '../../../assets/video/ezgif.com-gif-maker.gif'

export default function TaskmoApp() {
    return (
        <>
            <div style={{ paddingTop: "0px" }} class="app-video font-montserrat">
                <div class="container">
                    <div style={{ padding: "0px" }} class="block-style-twelve">
                        <div class="row">
                            <div data-aos="zoom-in-up" class="col-lg-6 order-lg-first">
                                <div style={{ margin: "80px auto 0px" }} class="cl-white text-wrapper">
                                    <h3 class="font-montserrat fw-600 cl-white mt-10 fs-50" style={{ lineHeight: "1em", padding: "0px 0px 0px" }}>India’s Largest Gig Discovery Platform</h3>
                                    <p class="fw-500 mt-20" style={{ fontSize: "18px" }}>
                                        Taskmo is India's largest gig discovery platform that lets businesses find gig workers and get the task done fast!
                                    </p>
                                    <div class="block-style-twentyFive flexbox">
                                        <div class="fw-500">
                                            <ul>
                                                <li>
                                                    100% reliable and trusted gig workers
                                                </li>
                                                <li>
                                                    Easy scaling, visibility, and growth
                                                </li>
                                                <li>
                                                    Faster deliverables
                                                </li>
                                                <li>
                                                    Instant Access to the largest pool of talent
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a target="_blank" class="home-page-more-services-action" id="home-page-more-services-action" href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" >
                                        <img style={{ marginLeft: "-18px", height: "90px" }} src="assets/img/google_playstore.png" alt="Play store" />
                                    </a>
                                </div>
                            </div>
                            <div style={{ padding: "0px 0px 0px" }} class="col-lg-6 col-md-8 ml-auto order-lg-last" >
                                <img src={Video} alt="India’s Best Job Search App Video" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}