import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function MarchantOnboarding() {

    const navRef1 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ marginTop: "20px" }}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Warehouse Services In India
                </title>
                <meta name="description" content="Taskmo provides best Warehousing Service in India, we provide one stop solution for all your warehousing and logistics needs. Click here..." />
                <meta name="keywords" content="warehouse service, warehousing, warehouse logistics, on demand warehousing, warehousing companies, warehousing near me, warehouse executive jobs" />
                <link rel="canonical" href="https://taskmo.com/services/warehouse-service" />
            </Helmet>

            <div class="pt-100 fancy-text-block-seven mt-60 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Warehouse Services
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Warehouse Services" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        Warehousing is a necessity for many organizations that are involved in the production, trade of imports and exports or even transportation of merchandise. A warehouse or storage facility will give you better power over your stock and guarantee that clients will accept their items on the schedule, which eventually increases your profits and benefits. However, many businesses may lack the human resources that are necessary to ensure that the warehouse is optimised accordingly.<br /><br />
                                        Taskmo is your single solution for your warehousing and logistics needs. We offer complete solutions for all your warehousing needs right from storage to package and shipping. We ensure all our warehouse executives are trained to pick the right material and send it in for shipment based on the customer needs and market conditions. Taskmo will help in leveraging your warehouse services and help to streamline your supply chain.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Warehouse Service" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="pricing-section-two">
                <div class="fancy-hero-four" data-aos="fade-up" data-aos-duration="1200">
                    <div class="title-style-two text-center md-mb-50" >
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div style={{ border: "none" }} data-aos="fade-up" data-aos-duration="1200" class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/merchant/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Instant Access to Expert Warehouse Specialists  </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/merchant/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Pre -Vetted and Screened On-Ground Taskers </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/merchant/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Customised Warehouse Services </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/merchant/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A broad range of industry bound onboarding services</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OurPartners />

            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">1000000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Taskers</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">300</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Cities</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial />

            <div class="faq-classic pt-10 ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thought? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the different types of Warehouse Services offered by Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="show collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                The different types of Warehouse Services are pickers and packers, loaders and unloaders, warehouse officers, product samplers, retail surveyors and many more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
