export const localRoute = {
  home: '/',
  blog: '/blogs',
  blogDetails1: '/blog',
  contact: '/contact-us',
  career: '/careers',
  careerDetails: '/career-details',
  about: '/about-us',
  jobsCampaign: '/jobs-campaign',
  login: '/login',
  signup: '/signup',
  appLanding: '/job-search-app',
  d2c: '/d2c_services',
  campus: '/campus-ambassador',
  services: '/services',
  restartCareer: '/restart-careers',
  IDCardPage: '/id-card',

  news: '/news',
  newsletter: '/newsletter',
  mediaKit: '/mediaKit',

  merchantOnboarding: '/services/merchant-onboarding',
  backgroundVerification: '/services/background-verification',
  teleSales: '/services/telemarketing',
  healthCare: '/services/healthcare',
  brandActivation: '/services/btl-activation-services',
  lastMileDelivery: '/services/last-mile-delivery',
  customerAcquisition: '/services/customer-acquisition',
  stockAudits: '/services/stock-audit',
  geoTagging: '/services/geotagging',
  pickingPacking: '/services/picker-packer',
  surveyCollection: '/services/survey-data-collection',
  productSampling: '/services/product-sampling',
  influencerMarketing: '/services/influencer-marketing',
  customerSupport: '/services/customer-support-services',
  warehouseService: '/services/warehouse-service',
  contentModeration: '/services/content-moderation',
  privacyPolicy: '/privacy-policy',
  privacyPolicyApp: '/privacy-policy-app',
  termsConditions: '/terms-conditions',
  termsConditionsApp:'/terms-conditions-app',
  casestudyAmazon: '/case-study/amazon-seller-central',
  casestudyZomato: '/case-study/zomato-restaurant-partner',
  casestudyRapido: '/case-study/rapido-bike-taxi-partner-onboarding',
  casestudyPhonepay: '/case-study/phonepe-penny-drop-transactions',
  financialSolutions: "/services/financial-products-and-services",

  caseStudy: '/case-studies',
  caseStudyDetails: '/casestudy',

  autoOnboarding: '/services/auto-driver-onboarding',
  cabOnboarding: '/services/cab-driver-onboarding',
  bikeOnboarding: '/services/bike-driver-onboarding',
  teacherOnboarding: '/services/best-teacher-onboarding-services',
  doctorOnboarding: '/services/best-doctor-onboarding-services',
  deliveryOnboarding: '/services/best-delivery-executive-onboarding-services',
  vendorOnboarding: '/services/best-vendor-onboarding-services',
  shopOnboarding: '/services/best-shop-onboarding-services',

  PPC: '/b2b-services',
  // bharatPetroleum: '/bharat-petroleum',

  thankYou: '/thank-you',
  notFound: '*',
  notFound500: '*',
}
