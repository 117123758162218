export const styles = (theme) => ({
    headerCardStart: {
        width: "100%",
        background: "#4E287C",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "60px",
        paddingLeft: "98px",
        flexWrap: "wrap",
        "@media (max-width: 767px)": {
            padding: "0px",
            textAlign: "center",
            // flexDirection: "column-reverse"
        },
    },
    headerText: {
        width: "55%",
        marginTop: "100px",
        "@media (max-width: 767px)": {
            width: "90%",
            margin: "auto",
            marginTop: "50px",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            width: "90%",
            margin: "auto",
            marginTop: "50px",
        },

    },
    headerImage: {
        width: "45%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        "@media (max-width: 767px)": {
            width: "90%",
            margin: "auto",
            marginTop: "20px"
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            width: "90%",
            margin: "auto",
            marginTop: "20px"
        },
    },
    connectCardText: {
        fontSize: "40px",
        "@media (max-width: 767px)": {
            fontSize: "30px",
            lineHeight: "40px",

        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            fontSize: "30px",
            lineHeight: "40px",
        },
    },
    connectCard: {
        display: "flex",
        gap: "80px",
        flexWrap: "wrap",
        "@media (max-width: 767px)": {
            gap: "20px",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            gap: "20px",
        },
    },
    topText: {
        "@media (max-width: 767px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        },
        "@media (min-width: 768px) and (max-width: 1200px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        },
    },
    connectCardImg: {
        "@media (max-width: 767px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        "@media (min-width: 768px) and (max-width: 1200px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
    },
    flipKartCard: {
        display: "flex",
        gap: "20px",
        alignItems: "center",
        "@media (max-width: 767px)": {
            flexWrap: "wrap"
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            flexWrap: "wrap"
        },
    },

    client: {
        fontFamily: "Montserrat",
        color: "#3C3C3C",
        fontSize: "50px",
        "@media (max-width: 767px)": {
            fontSize: "40px",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            fontSize: "40px",
        },
    },

    successStoriesNumbers: {
        marginTop: "20px",
        "@media (max-width: 767px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
        },
    },

    successStoriesText: {
        marginTop: "20px",
        textAlign: "left",
        "@media (max-width: 767px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            textAlign: "center",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            textAlign: "center",
        },
    },
    successStoriesImageDiv: {
        width: "100%",
        maxWidth: "350px",
        "@media (max-width: 767px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "auto"
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "auto"
        },
    }

})