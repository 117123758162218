import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AppLandingStyle";
import AppLanding from "../../components/appLanding/index";
const AppLandingPage = React.memo((props) => {
  return (
    <>
      <AppLanding />
    </>
  );
});

export default withRouter(AppLandingPage);
