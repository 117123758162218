import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Campus(props) {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    // console.log("subscriber_city_name",state.subscriber_city_name)
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const navRef4 = React.useRef(null);
    const navRef5 = React.useRef(null);
    const navRef6 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    const onToggleClick4 = (e) => {
        navRef4.current.classList.toggle("show");
    };

    const onToggleClick5 = (e) => {
        navRef5.current.classList.toggle("show");
    };

    const onToggleClick6 = (e) => {
        navRef6.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Auto Driver Onboarding Services || Taskmo
                </title>
                <meta name="description" content="Taskmo - Provide best auto driver onboarding services with Customisable Workflow, Document Creator, and Customer Support" />
                <meta name="keywords" content="auto onboarding, driver onboarding, onboarding services, merchant onboarding services, onboard, online onboarding, auto onboarding" />
                <link rel="canonical" href="https://taskmo.com/services/merchant-onboarding/auto-driver-onboarding-services" />
            </Helmet>



            <div class="font-montserrat container mt-20">
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9 title-style-eight">
                                <h1 class="font-montserrat fs-50 fw-600 hero-heading">Auto Driver Onboarding Service</h1>
                                <p class="font-montserrat fs-20 fw-500">We help ride aggregators onboard auto drivers for auto-sharing mobile applications and ensure their business runs efficiently and seamlessly. </p>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 mb-20 md-mt-30">Apply Now</a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <img src="../../../../assets/services/merchant/autoOnboarding/Auto-onboarding-Taskmo.jpg" alt="Auto onboarding Program" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Benefits of Auto-onboarding services</h2>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}><img width="50" src="../../../assets/services/merchant/auto1.png" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Customisable Workflow
                                </h4>
                                <p>Complete control over the onboarding process.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}><img width="40" src="../../../assets/services/merchant/auto2.png" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Document Creator
                                </h4>
                                <p>Customise any document to add extra information.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}><img width="50" src="../../../assets/services/merchant/auto3.png" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Customer Support
                                </h4>
                                <p>Multilingual agents provide reliable support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">5</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">10</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">200000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen mt-180 md-mt-100" id="effect">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Why Taskmo?</h2>
                                <p class="font-montserrat">Why is Taskmo the best auto-onboarding service provider?</p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFCE22' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/autoOnboarding/task-management.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Task Management</h4>
                                        <p>Create and assign tasks to create unique experiences every time</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#8F6BF6' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/autoOnboarding/workflow.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Automate Workflow</h4>
                                        <p>Standardise taskers who are trained as per your requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF5C5C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="70" src="../../../../assets/services/merchant/autoOnboarding/live-dashboard.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Integrated Dashboards</h4>
                                        <p>Automatically track the tasks states and progress of taskers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#63EAA9' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/autoOnboarding/live-monitoring.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Real-time monitoring</h4>
                                        <p>API integrated analytical dashboard to track tasker progress.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 md-mt-30">Apply Now</a>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What does the process look like?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <h4>
                                                The process of onboarding auto drivers generally includes:
                                            </h4>
                                            <li>Conducting Background checks</li>
                                            <li>Training auto drivers</li>
                                            <li>Proper Documentation</li>
                                            <li>Operational readiness</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                How are the taskers trained?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <h4>
                                                Taskmo takes reasonable steps to ensure their taskers understand all the business requirements. The training include:
                                            </h4>
                                            <li>Safety Training</li>
                                            <li>Operational Training</li>
                                            <li>On-field Training</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Does Taskmo keep records?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo ensures to record all the outlined activities of the onboarded person and make sure that they undergo all relevant onboarding processes prior to onboarding.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick4} class="btn btn-link font-montserrat">
                                                What is the verification process Taskmo follows?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef4} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <h4>
                                                When it comes to auto-drivers, Taskmo makes sure to check:
                                            </h4>
                                            <li>Criminal check</li>
                                            <li>History Check</li>
                                            <li>Driver Reference</li>
                                            <li>Training as required</li>
                                            <li>Assessment to verify training</li>
                                            <li>Action Plan</li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
