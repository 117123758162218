import React from 'react'
import { styles } from "./PosMarketingStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import demo from "../../../../assets/images/customer.png";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"

const useStyles = makeStyles(styles);

const PosMarketing = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best POS Marketing Service | QR Code Installation || Taskmo
                </title>
                <meta name="description" content="Taskmo - POS Marketing Service & QR Code Installation Service help in getting more sales. we offer QR Installation, Collateral deployment, In-store promotions" />

                <meta name="keywords" content="best pos marketing service, qr code installation service, Point of sale marketing, In-store marketing, Retail marketing, Retail branding, In-store promotions, Retail POS marketing, POS advertising, Collateral fulfillment services, Collateral printing and delivery, qr scanner install" />

                <link rel="canonical" href="https://www.taskmo.com/services/pos-marketing-services" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is POS marketing?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "POS marketing, or point-of-sale marketing, refers to strategies and techniques that retailers use to promote products and services to customers at the point where they are making a purchase. This can include POC collaterals like in-store displays, danglers, stickers and other marketing materials that are strategically placed near the checkout area or other high-traffic areas within the store. The goal of POS marketing is to capture customers' attention and encourage impulse purchases, ultimately increasing sales and revenue for the business."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the different types of POS marketing that can be done?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "There are several types of point-of-sale (POS) marketing collaterals that retailers can use to promote their products and services, including in-store displays, QR stickers, danglers, stickers and other marketing materials. By leveraging these and other POS marketing strategies, retailers can create a more engaging and effective shopping experience for customers, ultimately driving sales and revenue for their business."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the benefits of POS marketing for my brand?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's point-of-sale (POS) marketing services benefit your brand with increased sales, improved brand awareness, enhanced customer experience, greater customer engagement, competitive advantage, and increased customer loyalty."
                            }
                        }]
                    })}
                </script>

            </Helmet>

            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    POS Marketing Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    According to industry reports,  approx 75% of purchase decisions are made at the point of sale. Hence, implementing successful POS marketing tactics to uplift selling is necessary. Taskmo's effective POS marketing service can boost your business's sales and cross-selling opportunities, strengthen your brand, make your customers feel welcome, and enhance your store's aesthetic appeal. With endless marketing opportunities, POS marketing materials are a fantastic addition to your retail firm as they provide a terrific method to engage customers and boost sales by being actionable, fast, and error-free.
                                </p>
                            </div>


                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/36777aeb221b87f8fdfa1683006824004_pos%20marketing.png" alt='' style={{ width: "70%", margin: "auto", maxHeight: "400px" }} />
                        </div>
                    </div>

                    {/* header card end */}

                    {/* pos services start */}

                    {/* card 1 desktop view start */}
                    <div className={classes.serviceCardDesktopView} data-aos="fade-up">

                        <div style={{
                            width: "90%",
                            borderRadius: "20px",
                            margin: "auto",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            minHeight: "200px",
                            position: "relative",
                            marginTop: "100px",
                            background: "#e4ffee",
                        }}  >

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "40px", paddingRight: "20px" }} >

                                <div style={{ width: "40%", position: "absolute", top: "-50px", marginLeft: "60px" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5a2fbe9ad4c58aa440db1682573357811_POS%20Soundbox%20%281%29.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                                <div style={{ visibility: "hidden", marginLeft: "60px" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5a2fbe9ad4c58aa440db1682573357811_POS%20Soundbox%20%281%29.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                                <div style={{ width: "60%" }} >
                                    <h2 style={{ color: "#62B680", fontFamily: "Montserrat" }} >POS Machine/Sound Box Installation</h2>
                                    <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                        Taskmo's POS Machine & Soundbox installation services help to increase higher payment volumes and subscription revenues while strengthening merchants' and consumers' digital confidence.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* card 1 desktop view end */}

                    {/* card 1 mobile view start */}

                    <div className={classes.serviceCardMobileView} data-aos="fade-up">
                        <div className={classes.serviceCardMobileViewCard1}>
                            <div style={{ textAlign: "center" }} >
                                <h2 style={{ color: "#62B680", fontFamily: "Montserrat" }} >POS Machine/Sound Box Installation</h2>
                                <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                    Taskmo's POS Machine & Soundbox installation services help to increase higher payment volumes and subscription revenues while strengthening merchants' and consumers' digital confidence.
                                </p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5a2fbe9ad4c58aa440db1682573357811_POS%20Soundbox%20%281%29.svg" alt="" style={{ width: "80%", margin: "auto", maxHeight: "200px" }} />
                            </div>

                        </div>
                    </div>


                    {/* card 1 mobile view end */}

                    {/* card 2 desktop view start */}
                    <div className={classes.serviceCardDesktopView} data-aos="fade-up">

                        <div style={{
                            width: "90%",
                            borderRadius: "20px",
                            margin: "auto",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            minHeight: "200px",
                            position: "relative",
                            marginTop: "100px",
                            background: "#ffefd0",
                        }}  >

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "40px", paddingLeft: "50px" }} >

                                <div style={{ width: "60%" }} >
                                    <h2 style={{ color: "#E2A630", fontFamily: "Montserrat" }} >QR Code Installation</h2>
                                    <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                        With Taskmo's QR code installation service, your marketing efforts will be enhanced and you'll be able to connect offline and online platforms to reach, engage, and convert a larger target audience.
                                    </p>
                                </div>

                                <div style={{ visibility: "hidden" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/c59a5c3b3fbb2361e7b61682575545774_qrr.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                                <div style={{ position: "absolute", top: "-40px", right: "80px" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/c59a5c3b3fbb2361e7b61682575545774_qrr.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* card 2 desktop view end */}

                    {/* card 2 mobile view start */}

                    <div className={classes.serviceCardMobileView} data-aos="fade-up">
                        <div className={classes.serviceCardMobileViewCard2} >

                            <div style={{ textAlign: "center" }} >
                                <h2 style={{ color: "#E2A630", fontFamily: "Montserrat" }} >QR Code Installation</h2>
                                <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                    With Taskmo's QR code installation service, your marketing efforts will be enhanced and you'll be able to connect offline and online platforms to reach, engage, and convert a larger target audience.
                                </p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", marginLeft: "20px" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/c59a5c3b3fbb2361e7b61682575545774_qrr.svg" alt="" style={{ width: "80%", margin: "auto", maxHeight: "200px" }} />
                            </div>

                        </div>
                    </div>

                    {/* card 2 mobile view end */}


                    {/* card 3 desktop view start */}
                    <div className={classes.serviceCardDesktopView} data-aos="fade-up">

                        <div style={{
                            width: "90%",
                            borderRadius: "20px",
                            margin: "auto",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            minHeight: "200px",
                            position: "relative",
                            marginTop: "100px",
                            background: "#ffe6ed",
                        }}  >

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "40px", paddingRight: "20px" }} >

                                <div style={{ width: "40%", position: "absolute", top: "-50px", marginLeft: "60px" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2a3ed797e2f47a7f6ed81682576380186_collateral%20deployment.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                                <div style={{ visibility: "hidden", marginLeft: "60px" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2a3ed797e2f47a7f6ed81682576380186_collateral%20deployment.svg" alt="" style={{ maxWidth: "450px" }} />
                                </div>

                                <div style={{ width: "60%" }} >
                                    <h2 style={{ color: "#EC5D86", fontFamily: "Montserrat" }} >Collateral Deployment</h2>
                                    <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                        Taskmo's effective collateral deployment solution provides a cost-effective option, ideal for communicating information, promoting sales opportunities and grabbing shopper's attention, in a unique format.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* card 3 desktop view end */}

                    {/* card 3 mobile view start */}

                    <div className={classes.serviceCardMobileView} data-aos="fade-up">
                        <div className={classes.serviceCardMobileViewCard3} >

                            <div style={{ textAlign: "center" }} >
                                <h2 style={{ color: "#EC5D86", fontFamily: "Montserrat" }} >Collateral Deployment</h2>
                                <p style={{ lineHeight: "23.44px", color: "#949398FF" }} >
                                    Taskmo's effective collateral deployment solution provides a cost-effective option, ideal for communicating information, promoting sales opportunities and grabbing shopper's attention, in a unique format.
                                </p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2a3ed797e2f47a7f6ed81682576380186_collateral%20deployment.svg" alt="" style={{ width: "80%", margin: "auto", maxHeight: "200px" }} />
                            </div>

                        </div>
                    </div>

                    {/* card 3 mobile view end */}


                    {/* pos services end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale POS Marketing Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5d779cccaefa1fe9f0261682491863486_1.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Strengthen
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        brand value
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a7938769e871542081ff1682491913434_2.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Utilize successful strategies
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        to increase sales
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2662aeeb21463e0aead51682491938077_3.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Utilize unique marketing
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        techniques to highlight promotions
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/004609a1548e7656ff711682491954971_4.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Enable increased
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        conversions
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f94ebeec73fc68a278931682492189211_one.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Increase Brand Awareness
                                                    </h4>

                                                    <p>
                                                        We ensure to use the Point of Sale materials to highlight your brand to improve brand recognition and avoid having your product get lost on the shelf amid rivals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a071b045c3e91f505a4e1682492228455_two.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Highlight POS Materials
                                                    </h4>
                                                    <p>
                                                        We attract the attention of the shoppers and thereby increase sales through optimized positioning of the POS materials.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/ab0f2c6c74e9dcc2f8ab1682492250604_three.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Account Creation
                                                    </h4>
                                                    <p>
                                                        We also assist the retailers/merchants with account creation to shift from manual and paper-based transactions to online transactions through payment platforms for QR sticker installations.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/26171cceec40aa7ba1ea1682492283921_four.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Test Transactions
                                                    </h4>
                                                    <p>
                                                        We also assist the retailers/merchants with initial test transactions through the QR code process for seamless operations without any bottleneck.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "220px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        3Mn+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Successful Store Visits
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        8000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        20+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Happy Brands
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        96%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Productivity Delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* use cases start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#FFE7C2", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/797803b3865b6962a5c11682492366236_amazon%20pay.png" alt="" style={{ width: "100%", marginTop: "30px" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >5x</p>
                                                </div>

                                                <div className={classes.successStoriesText} style={{ textAlign: "left" }} >
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        QR codes installations in the span of 4-6 months leading to increased returns
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#E9D6FF", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/286d6746a60bf4383ff81682492429520_phonepe_logo_icon.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >10k+</p>
                                                </div>

                                                <div className={classes.successStoriesText} style={{ textAlign: "left" }}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        QR Installation across 100+ pin codes to eliminate paper-based operations
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#FFDDC1", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "198px" }} data-aos="zoom-in-up">
                                        <CardContent >
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} style={{ marginTop: "30px" }} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d048c0b44052acfbd3281682492480270_cashe.png" alt="" style={{ width: "90%", margin: "auto", marginTop: "-10px" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >70K+</p>
                                                </div>

                                                <div className={classes.successStoriesText} style={{ textAlign: "left" }}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        QR installations over 100+ pin codes across India to increase operations.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#FFD4CD", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "190px", marginTop: "5px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8198887ed7cbfd99cbde1682492526096_payswiff%20logo.png" alt="" style={{ width: "100%", marginTop: "25px" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-10px" }} >94%</p>
                                                </div>

                                                <div className={classes.successStoriesText} style={{ textAlign: "left" }}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Productivity delivered across 50+ pin codes in India to scale their operations quickly and efficiently.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    {/* use cases end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is POS marketing?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            POS marketing, or point-of-sale marketing, refers to strategies and techniques that retailers use to promote products and services to customers at the point where they are making a purchase. This can include POC collaterals like in-store displays, danglers, stickers and other marketing materials that are strategically placed near the checkout area or other high-traffic areas within the store. The goal of POS marketing is to capture customers' attention and encourage impulse purchases, ultimately increasing sales and revenue for the business.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the different types of POS marketing that can be done?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                There are several types of point-of-sale (POS) marketing collaterals that retailers can use to promote their products and services, including in-store displays, QR stickers, danglers, stickers and other marketing materials. By leveraging these and other POS marketing strategies, retailers can create a more engaging and effective shopping experience for customers, ultimately driving sales and revenue for their business
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the benefits of POS marketing for my brand?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Taskmo's point-of-sale (POS) marketing services benefit your brand with increased sales, improved brand awareness, enhanced customer experience, greater customer engagement, competitive advantage, and increased customer loyalty.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default PosMarketing