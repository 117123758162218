import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "row-reverse"
    },
    formContainer: {
        width: "60%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            margin: "auto",
            textAlign: "center"
        },
    },
    formMain: {
        minHeight: '80vh',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            minHeight: '40vh',
        },
    },
    logoContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "95%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        },
    },
}));

const BusinessPartnerSignIn = () => {
    const classes = useStyles();
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [otpClicked, setOtpClicked] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (otp.length != "") {
            history.push("/bpp")
        }
    };

    const handleGetOtpClick = () => {
        if (phoneNumber.length < 10) {
            setPhoneNumberError(true);
            return;
        }
        setOtpClicked(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ fontFamily: "Montserrat" }}>

            <div className={classes.logoContainer}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f3ef0a0c9fc2812f05a51684760425590_taskmo-logo.png"
                    alt=""
                    onClick={() => history.push('/business-partner-program')}
                    style={{ maxWidth: "153px", height: "auto", cursor: "pointer" }} />
            </div>

            <Grid container spacing={2} className={classes.root} >

                <Grid item xs={12} sm={6} className={classes.formMain} >
                    <div className={classes.formContainer} >
                        <div style={{ color: "#603890", fontSize: "24px", fontWeight: 700, lineHeight: "30px" }} >
                            SIGN IN TO YOUR ACCOUNT
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={{ marginTop: "20px" }}>
                                <TextField
                                    id="outlined-basic"
                                    type="number"
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
                                    }}
                                    error={phoneNumberError && phoneNumber.length < 10}
                                    helperText={phoneNumberError && phoneNumber.length < 10 ? "Minimum 10 numbers required" : ""}
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    disabled={otpClicked ? true : false}
                                />
                            </div>

                            {!otpClicked ? (
                                <div style={{ marginTop: "20px" }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleGetOtpClick}
                                        style={{ background: "#603890", color: "#fff", fontWeight: 700, fontSize: "16px" }}
                                    >
                                        GET OTP
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <div style={{ marginTop: "20px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            type="number"
                                            label="OTP"
                                            variant="outlined"
                                            fullWidth
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            required={submitted} // Only set required when the form is submitted
                                            error={submitted && otp === ""}
                                            helperText={submitted && otp === "" ? "This field is mandatory" : ""}
                                        />
                                    </div>

                                    <div style={{ marginTop: "20px" }}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            style={{ background: "#603890", color: "#fff", fontWeight: 700, fontSize: "16px" }}
                                            onClick={handleSubmit}
                                        >
                                            SUBMIT
                                        </Button>
                                    </div>
                                </>
                            )}
                        </form>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} style={{
                    minHeight: '100vh',
                    background: `url(${"https://taskmotech.s3.amazonaws.com/493951ab5df4456489721684733788692_Group%208096.png"})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: "flex", justifyContent: "center", alignItems: "center"
                }}>



                    <div style={{ width: "95%", margin: "auto" }} >

                        <div style={{ fontSize: "20px", fontWeight: 500, color: "#fff", textAlign: "center", lineHeight: "25px", marginTop: "20px", display: "flex", justifyContent: "center" }}>
                            <div>
                                <p>
                                    Refer a Friend,
                                    Earn Rewards, and Build
                                    Your Business Network
                                    with Taskmo’s Business
                                    Partner Program
                                </p>

                            </div>
                        </div>

                        <div style={{ fontSize: "36px", fontWeight: 600, color: "#fff", textAlign: "center", lineHeight: "40px", marginTop: "20px" }} >
                            Don't Have an Account?
                        </div>

                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }} >
                            <Button
                                variant="contained"
                                type='submit'
                                style={{
                                    background: "#fff",
                                    color: "#603890",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    minWidth: "100px",
                                    minHeight: "40px",
                                    borderRadius: "20px",
                                }}
                                onClick={() => history.push('/business-partner-sign-up')}
                            >
                                SIGN UP
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default BusinessPartnerSignIn