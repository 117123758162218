import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row-reverse",
        marginTop: "10px"
    },
    formContainer: {
        width: "60%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            margin: "auto",
            textAlign: "center"
        },
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    previewContainer: {
        marginTop: theme.spacing(2),
        border: '1px solid #ccc',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#f8f8f8',
        maxWidth: '400px',
    },
    previewImage: {
        objectFit: 'cover', // Adjust the image aspect ratio
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        overflow: "hidden",
        [theme.breakpoints.down('sm')]: {
            width: "200px",
            height: "200px",
        },
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    uploadIcon: {
        fontSize: '40px',
        marginBottom: theme.spacing(1),
    },
    uploadText: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    logoContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "95%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        },
    },
    formMain: {
        minHeight: '80vh',
    }
}));

const BusinessPartnerSignUp = () => {

    const classes = useStyles();

    const history = useHistory();

    const initialValues = { name: "", email: "", phoneNumber: "", referral: "" };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [city, setCity] = useState(null);

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setSelectedImage(e.target.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const handlePermission = (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        };

        const handleError = (error) => {
            console.log("formValues error", error.message);
        };

        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'granted') {
                        navigator.geolocation.getCurrentPosition(handlePermission, handleError);
                    } else if (permissionStatus.state === 'prompt') {
                        navigator.geolocation.getCurrentPosition(handlePermission, handleError);
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handlePermission, handleError);
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        if (latitude && longitude) {
            fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            )
                .then(response => response.json())
                .then(data => {
                    if (data.address && data.address.city) {
                        setCity(data.address.city);
                    }
                })
                .catch(error => {
                    console.log(error.message);
                });
        }
    }, [latitude, longitude]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = (values) => {

        const errors = {};

        if (values.phoneNumber.length < 10) {
            errors.phoneNumber = "Phone Number must contain 10 numbers";
        }

        return errors;
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        setFormErrors(validate(formValues))

        if (Object.keys(validate(formValues)).length === 0) {
            // we can make an api call here
            // console.log("formValues", formValues, city, latitude, longitude, selectedImage);

            const payload = {
                ...formValues,
                city,
                latitude,
                longitude,
                imageURL: selectedImage
            }

            console.log("formValues", payload);

            history.push('/business-partner-otp')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ fontFamily: "Montserrat" }} >

            <div className={classes.logoContainer}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f3ef0a0c9fc2812f05a51684760425590_taskmo-logo.png"
                    alt=""
                    onClick={() => history.push('/business-partner-program')}
                    style={{ maxWidth: "153px", height: "auto", cursor: "pointer" }} />
            </div>

            <Grid container spacing={2} className={classes.root} >

                <Grid item xs={12} sm={6} className={classes.formMain}  >
                    <div className={classes.formContainer} >
                        <div style={{ color: "#603890", fontSize: "24px", fontWeight: 700, textAlign: "center" }} >
                            Let’s get you started
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }} >
                            <Button
                                style={{ color: "#606060FF" }}
                                onClick={() => history.push('/business-partner-program')}
                            >
                                <ArrowBackIosIcon /> Back
                            </Button>
                        </div>
                        <div>
                            <div className={classes.main}>
                                <div className={classes.uploadContainer}>
                                    {selectedImage ? (
                                        <div>
                                            <div className={classes.previewImage}>
                                                <img src={selectedImage} alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <Avatar alt="" src="" style={{ width: "100px", height: "100px" }} />
                                        </div>
                                    )}
                                </div>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="image-upload"
                                    type="file"
                                    onChange={handleImageUpload}
                                />

                                <label htmlFor="image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        style={{ background: "#603890", color: "#fff", marginTop: "10px" }}
                                    >
                                        Select Image
                                    </Button>
                                </label>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={{ marginTop: "30px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Full Name"
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    value={formValues?.name}
                                    onChange={handleChange}
                                    required />
                            </div>

                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    type='email'
                                    label="Email address"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={formValues?.email}
                                    onChange={handleChange}
                                    required />
                            </div>

                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    type='number'
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                    name="phoneNumber"
                                    value={formValues?.phoneNumber}
                                    onChange={handleChange}
                                />
                                <div>
                                    <p style={{ color: 'red', fontSize: "10px" }} >{formErrors?.phoneNumber}</p>
                                </div>
                            </div>

                            <div style={{ marginTop: "10px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Referal Code"
                                    variant="outlined"
                                    name="referral"
                                    value={formValues?.referral}
                                    onChange={handleChange}
                                    fullWidth />
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type='submit'
                                    style={{ background: "#603890", color: "#fff", fontWeight: 700, fontSize: "16px" }}
                                >
                                    GET OTP
                                </Button>
                            </div>

                        </form>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} style={{
                    minHeight: '90vh',
                    background: `url(${"https://taskmotech.s3.amazonaws.com/493951ab5df4456489721684733788692_Group%208096.png"})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    <div style={{ width: "95%", margin: "auto" }} >
                        <div style={{ fontSize: "40px", fontWeight: 600, color: "#fff", textAlign: "center", lineHeight: "40px" }} >
                            Already have an account
                        </div>

                        <div style={{ fontSize: "20px", fontWeight: 500, color: "#fff", textAlign: "center", lineHeight: "25px", marginTop: "20px" }}>
                            <p>To keep connected with us please</p>
                            <p>login with your personal info</p>
                        </div>

                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }} >
                            <Button
                                variant="contained"
                                type='submit'
                                style={{
                                    background: "#fff",
                                    color: "#603890",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    minWidth: "100px",
                                    minHeight: "40px",
                                    borderRadius: "20px",
                                }}
                                onClick={() => history.push('/business-partner-sign-in')}
                            >
                                SIGN IN
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default BusinessPartnerSignUp