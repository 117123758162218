import React, { useState } from 'react';
import { styles } from "./ModalChatStyles";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import Find_Taskers from '../../components/Find_Taskers';
import Find_Jobs from '../../components/Find_Jobs';



export default function ModalChat() {
  const [open, setOpen] = React.useState(false);
  const [showTasker, setShowTaskers] = useState(false)
  const [showJobs, setShowJobs] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenTasker = () => {
    handleClose()
    setShowTaskers(true)
  }

  const handleCloseTasker = () => {
    setShowTaskers(false)
  }

  const handleOpenJobs = () => {
    handleClose()
    setShowJobs(true)
  }

  const handleCloseJobs = () => {
    setShowJobs(false)
  }

  return (
    <div>
      <Fab onClick={handleOpen} style={{ zIndex: "100", margin: "0px", top: "auto", right: "20px", bottom: "20px", left: "auto", position: "fixed", backgroundColor: "#6A3996", color: "white", borderRadius: '50%' }} aria-label="add" >
        <MessageIcon />
      </Fab>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ zIndex: "100000" }}
      >
        <Fade in={open}>
          <Box class="chatButtonResponsive" >
            {/* <div>
              <div class="col-lg-12 d-flex" data-aos="fade-up" data-aos-duration="1200">
                <div class="block-style-twentyFour " style={{ background: "#F7EEFF", marginTop: "auto", padding: "15px" }}>
                  <div class="d-flex align-items-start ">
                    <img width="115" src="../../../assets/home/Group 8.png" alt="" />
                    <div class=" d-flex align-items-center justify-content-center" style={{ marginTop: "auto" }}>
                    </div>
                    <div class="text" style={{ marginTop: "3%" }}>
                      <h4 class="font-montserrat chatButtonResponsiveFont" style={{ fontWeight: "600", color: "#7946A9" }}>Earn Money</h4>
                      <button onClick={handleOpenJobs} style={{ fontWeight: "500", borderRadius: "30px", fontSize: "16px" }} type="submit" class="font-montserrat mt-10 template-btn">Find Job <i class="flaticon-right-arrow"></i></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="font-montserrat col-lg-12 d-flex" style={{ marginTop: "20px" }} data-aos="fade-up" data-aos-duration="1700">
                <div class="block-style-twentyFour" style={{ background: "#F7EEFF", marginTop: "auto", padding: "15px" }}>
                  <div class="d-flex align-items-start">
                    <img width="115" src="../../../assets/home/Group 9.png" alt="" />
                    <div class="d-flex align-items-center justify-content-center" style={{ marginTop: "auto" }}>
                    </div>
                    <div class="text" style={{ marginTop: "3%" }}>
                      <h4 class="font-montserrat chatButtonResponsiveFont" style={{ fontWeight: "600", color: "#7946A9" }}>Scale Business</h4>
                      <button onClick={handleOpenTasker} style={{ fontWeight: "500", borderRadius: "30px", fontSize: "16px" }} type="submit" class="font-montserrat mt-10 template-btn">Find Tasker <i class="flaticon-right-arrow"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div style={{ border: "2px solid white", background: "white", margin: "10px", borderRadius: "10px" }} data-aos="fade-up">
              <div class="col-lg-12 d-flex" data-aos-duration="1200" style={{ margin: "20px 0px" }}>
                <div class="block-style-twentyFour " style={{ marginTop: "auto", padding: "15px", boxShadow: "none" }}>
                  <div class="d-flex align-items-start ">
                    <img width="115" src="../../../assets/home/Group 8.png" alt="" />
                    <div class=" d-flex align-items-center justify-content-center" style={{ marginTop: "auto" }}>
                    </div>
                    <div class="text" style={{ marginTop: "3%", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
                      <h4 class="font-montserrat chatButtonResponsiveFont" style={{ fontWeight: "600", color: "#7946A9" }}>To Earn Money ?</h4>
                      <button onClick={handleOpenJobs} style={{ fontWeight: "500", borderRadius: "30px", fontSize: "16px" }} type="submit" class="font-montserrat mt-10 template-btn">Find Job <i class="flaticon-right-arrow"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ width: "80%", margin: "auto" }} />
              <div class="font-montserrat col-lg-12 d-flex" style={{ margin: "20px 0px" }} data-aos-duration="1700">
                <div class="block-style-twentyFour" style={{ marginTop: "auto", padding: "15px", boxShadow: "none" }}>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }} >
                    <img width="115" src="../../../assets/home/Group 9.png" alt="" />
                    <div class="d-flex align-items-center justify-content-center" style={{ marginTop: "auto" }}>
                    </div>

                    <div class="text" style={{ marginTop: "3%", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
                      <h4 class="font-montserrat chatButtonResponsiveFont" style={{ fontWeight: "600", color: "#7946A9" }}>To Scale Business ?</h4>
                      <button onClick={handleOpenTasker} style={{ fontWeight: "500", borderRadius: "30px", fontSize: "16px" }} type="submit" class="font-montserrat mt-10 template-btn">Post Requirement <i class="flaticon-right-arrow"></i></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Find_Taskers
        open={showTasker}
        onClose={handleCloseTasker}
        subscriber_type="Chat-Button-Hire-Tasker"

      />
      <Find_Jobs
        open={showJobs}
        onClose={handleCloseJobs}
      />
    </div>

  );
}
