import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Find_Taskers from "../Find_Taskers";
import { localRoute } from "../../routes/localRoutes";
import { useEffect } from "react";
import Button from "@material-ui/core/Button";
import SpamAlert from "pages/spamAlert/SpamAlert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  desktopSpamAlert: {
    width: "100%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileSpamAlert: {
    width: "100%",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();

  const [openPop, setOpenPop] = useState(false);
  const navRef = React.useRef(null);
  const newsRef = React.useRef(null);

  const history = useHistory();

  const handleBackHome = () => {
    navRef.current.classList.toggle("show");
    history.push(localRoute.home);
    window.scrollTo(0, 0);
  };
  const handleBackHome1 = () => {
    history.push(localRoute.home);
    window.scrollTo(0, 0);
  };
  const handleBackServices = () => {
    history.push(localRoute.services);
    navRef.current.classList.toggle("show");
  };
  const handleBackBlog = () => {
    history.push(localRoute.blog);
    navRef.current.classList.toggle("show");
  };

  const handleBackD2C = () => {
    history.push(localRoute.d2c);
    navRef.current.classList.toggle("show");
  };

  const handleBackNews = () => {
    history.push(localRoute.news);
    navRef.current.classList.toggle("show");
    newsRef.current.classList.toggle("show");
  };

  const handleMediaKit = () => {
    history.push(localRoute.mediaKit);
    navRef.current.classList.toggle("show");
    newsRef.current.classList.toggle("show");
  };

  const handleBackNewsletter = () => {
    history.push(localRoute.newsletter);
    navRef.current.classList.toggle("show");
    newsRef.current.classList.toggle("show");
  };

  const handleBackContact = () => {
    history.push(localRoute.contact);
    navRef.current.classList.toggle("show");
  };

  const handlebPP = () => {
    history.push("/business-partner-program");
    navRef.current.classList.toggle("show");
  };

  const handleBackFindJobs = () => {
    history.push(localRoute.appLanding);
    navRef.current.classList.toggle("show");
  };

  const handleBackAbout = () => {
    console.log("ABOUT");
    navRef.current.classList.toggle("show");
    history.push(localRoute.about);
  };
  const currentRoute = useHistory().location.pathname.toLowerCase();

  const handleBackLogin = () => {
    history.push(localRoute.login);
  };

  console.log("currentRoute", currentRoute);

  const onToggleClick = (e) => {
    navRef.current.classList.toggle("show");
  };

  const onToggleNews = (e) => {
    newsRef.current.classList.toggle("show");
  };

  const handleOpen = () => {
    setOpenPop(true);
    navRef.current.classList.toggle("show");
  };

  const handleClose = () => {
    setOpenPop(false);
  };

  const handleGoLetsTalk = () => {
    if (window.innerWidth <= 700) {
      window.location.replace("/b2b-services#lets-talk-mobile");
    } else {
      window.location.replace("/b2b-services#lets-talk");
    }
  };

  return (
    <>
      {/* <div className={classes.mobileSpamAlert}>
        <SpamAlert />
      </div> */}

      <div class="theme-main-menu sticky-menu" style={{ zIndex: 99 }}>
        {/* <div className={classes.desktopSpamAlert}>
          <SpamAlert />
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div class="logo">
            <a
              style={{ cursor: "pointer", marginBottom: "5px" }}
              onClick={handleBackHome1}
            >
              <img
                class="taskmo-logo"
                src="../../../../assets/img/taskmo-final-logo.svg"
                alt="taskmo-logo"
              />
            </a>
          </div>

          <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
            <div class="container nav-container">
              <button
                class="navbar-toggler navbar-toggler-right"
                onClick={onToggleClick}
                type="button"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i class="flaticon-menu-of-three-lines"></i>
              </button>

              <div class="navbar-collapse collapse" ref={navRef}>
                {currentRoute == "/b2b-services" ||
                currentRoute == "/b2b-services/" ? (
                  <div class="font-montserrat d-lg-flex justify-content-between align-items-center">
                    <ul class="fw-500 font-montserrat navbar-nav">
                      <li class=" nav-item position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackHome}
                          class="nav-link"
                        >
                          <span
                            className={currentRoute == "/" ? "tabactive" : ""}
                          ></span>
                        </a>
                      </li>
                    </ul>
                    {/* <ul class="right-widget user-login-button d-flex align-items-center justify-content-center">
                                        <li>
                                            <span>
                                                <a class="signUp-action d-flex align-items-center m-auto fw-500 fs-18 font-montserrat" style={{ border: "none", cursor: "pointer", borderRadius: "10px" }} onClick={handleGoLetsTalk} >Lets Talk</a>
                                            </span>
                                        </li>
                                    </ul> */}
                  </div>
                ) : (
                  <div class="font-montserrat d-lg-flex justify-content-between align-items-center">
                    <ul class="fw-500 font-montserrat navbar-nav">
                      <li class=" nav-item position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackHome}
                          class="nav-link"
                        >
                          <span
                            className={currentRoute == "/" ? "tabactive" : ""}
                          >
                            Home
                          </span>
                        </a>
                      </li>
                      <li class="nav-item position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackAbout}
                          class="nav-link"
                        >
                          <span
                            className={
                              currentRoute == "/about-us" ? "tabactive" : ""
                            }
                          >
                            About
                          </span>
                        </a>
                      </li>
                      <li class="nav-item position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackServices}
                          class="nav-link"
                        >
                          <span
                            className={
                              currentRoute == "/services" ? "tabactive" : ""
                            }
                          >
                            Offerings
                          </span>
                        </a>
                      </li>
                      <li class="nav-item position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackBlog}
                          class="nav-link"
                        >
                          <span
                            className={
                              currentRoute == "/blogs" ? "tabactive" : ""
                            }
                          >
                            Blog
                          </span>
                        </a>
                      </li>

                      {/* <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" style={{ color: "#000" }} onClick={onToggleNews}>Media</a>
                                                <ul class="dropdown-menu" ref={newsRef}>
                                                    <li>
                                                        <a style={{ cursor: "pointer" }} onClick={handleBackNews} class="dropdown-item"><span className={currentRoute == "/news" ? "tabactive" : ""}>News</span></a>
                                                    </li>
                                                    <li>
                                                        <a style={{ cursor: "pointer" }} onClick={handleBackNewsletter} class="dropdown-item"><span className={currentRoute == "/newsletter" ? "tabactive" : ""}>Newsletter</span></a>
                                                    </li>
                                                    <li>
                                                        <a style={{ cursor: "pointer" }} onClick={handleMediaKit} class="dropdown-item"><span className={currentRoute == "/mediaKit" ? "tabactive" : ""}>Media Kit</span></a>
                                                    </li>
                                                </ul>
                                            </li> */}

                      <li class="nav-item  position-static active">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleBackContact}
                          class="nav-link"
                        >
                          <span
                            className={
                              currentRoute == "/contact-us" ? "tabactive" : ""
                            }
                          >
                            Contact
                          </span>
                        </a>
                      </li>

                      {/* <li class="nav-item  position-static active">
                                                <a style={{ cursor: "pointer" }} onClick={handlebPP} class="nav-link"><span className={currentRoute == "/business-partner-program" ? "tabactive" : ""}>Business Partner</span></a>
                                            </li> */}

                      <li class="nav-item  position-static active">
                        <a
                          style={{
                            color: "black",
                            cursor: "pointer",
                            fontWeight: "500",
                          }}
                          onClick={handleBackFindJobs}
                          class="nav-link"
                        >
                          <span
                            className={
                              currentRoute == "/job-search-app"
                                ? "tabactive"
                                : ""
                            }
                          >
                            I'm a Job Seeker
                          </span>
                        </a>
                      </li>

                      <li
                        class="nav-item  position-static active"
                        style={{ marginTop: "25px" }}
                      >
                        <span>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={handleOpen}
                            style={{
                              color: "#673695",
                              fontWeight: 600,
                              fontFamily: "montserrat",
                              fontSize: "16px",
                              borderRadius: "10px",
                              textTransform: "none",
                              border: "1.5px solid #673695",
                            }}
                          >
                            I'm an Employer
                          </Button>
                          <Find_Taskers
                            open={openPop}
                            onClose={handleClose}
                            subscriber_type="Header Button"
                          />
                        </span>
                      </li>

                      {/* <li
                        class="nav-item  position-static active"
                        style={{ marginTop: "25px" }}
                      >
                        <a href="https://client.taskmo.co/" target="_blank">
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              background: "#673695",
                              color: "#fff",
                              fontWeight: 600,
                              fontFamily: "montserrat",
                              fontSize: "16px",
                              borderRadius: "10px",
                              textTransform: "none",
                              marginLeft: "15px",
                              marginBottom: "10px",
                            }}
                          >
                            Employer Login
                          </Button>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
