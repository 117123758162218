import React, { useEffect, useState, useContext, } from "react";
import { getAllNews } from "../../../context/actions/pressRelease/getAllNews";

import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function News(props) {
	const { newsState, newsDispatch } = useContext(GlobalContext);
	const [filterNewsDetails, setFilterNewsDetails] = React.useState([]);
	const [tabFilter, setTabFilter] = React.useState('active');
	const history = useHistory()

	useEffect(() => {
		window.scrollTo(0, 0)
		AOS.init({
			duration: 2000
		});
	}, [])

	useEffect(() => {
		let status = tabFilter
		getAllNews({ type: "news", status, page: "all" })(newsDispatch)
	}, [])

	useEffect(() => {
		let status = newsState.getAllNews.status
		console.log('newsstatus', status);
		if (status === true) {
			setFilterNewsDetails(newsState.getAllNews.data.res)
		}
		// SERVER ERROR RESPONSE
		if (status === 500) {
			console.log("Server Error:", newsState.getAllNews)
		}
		// SERVER VALIDATION ERROR RESPONSE  
		if (status === 401) {
			console.log("Validation Error:", newsState.getAllNews)
		}
	}, [newsState])

	console.log("filterNewsDetails", filterNewsDetails);




	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					News, Events & Information About Gig Economy || Taskmo
				</title>
				<meta name="description" content="Get breaking news, events, and updated information on Taskmo Media. Read press releases, newsletters, and more..." />
				<meta name="keywords" content="Social Gig Platform, Gig Economy, Gig Workers, Gig News,Gig Jobs, Gig topics, Gig updates, Gig Newsletter" />
				<link rel="canonical" href="https://taskmo.com/news" />
			</Helmet>

			<div class="blog-v3" style={{ marginTop: "30px" }} >
				<div style={{ cursor: "pointer" }} class="container">
					<img id="img1" src="../../../assets/news/newsdesktop.jpeg" style={{ borderRadius: "10px" }} class="illustration" />
					<img id="img2" src="../../../assets/news/newsmobile.jpg" style={{ borderRadius: "10px", margin: "auto" }} class=" illustration" />
				</div>
				<div style={{ marginTop: "30px" }} class="container">
					<div class="font-montserrat feature-blog-one blog-page-bg">
						<div class="container">
							<div class="row">
								{filterNewsDetails.map(item =>
									<div class="col-lg-4 col-md-6">
										<div class="post-meta">
											<img src={item.news_image} alt="Image" class="image-meta" />
											<h3 style={{ margin: "10px 0px" }}>
												<a target="_blank" href={item.news_link} style={{ cursor: "pointer" }} class="font-montserrat title fw-600">
													{item.news_title}
												</a>
											</h3>
											<p style={{ lineHeight: "1.5em", color: "gray" }}>
												{item.news_description}
											</p>
											<a href={item.news_link} target="_blank" style={{ cursor: "pointer" }} class="read-more d-flex justify-content-between align-items-center">
												<span>Read More</span>
												<i class="flaticon-right-arrow"></i>
											</a>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

