    import Find_Taskers_Common from 'components/Find_Taskers_Common';
    import React, { useContext } from 'react';
    import { useEffect, useState } from 'react';

    export default function Consultation() {

        const navRef1 = React.useRef(null);
        const navRef2 = React.useRef(null);
        const navRef3 = React.useRef(null);

        const onToggleClick1 = (e) => {
            navRef1.current.classList.toggle("show");
        }

        const onToggleClick2 = (e) => {
            navRef2.current.classList.toggle("show");
        };

        const onToggleClick3 = (e) => {
            navRef3.current.classList.toggle("show");
        };

        useEffect(() => {
            window.scrollTo(0, 0)
        }, [])
        return (
            <section id="hire-taskers" class="consultation-section section-polygon-bg section-gap bg-color-primary">

                <div class="font-montserrat container">
                    <div class="row justify-content-center">
                        <div class="col-xl-10">
                            <div class="row align-items-center justify-content-center">
                                <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                    <div class="consultation-text text-white pr-xl-5">
                                        <div class="section-heading heading-white mb-30">
                                            <h2 class="font-montserrat fs-50 fw-600 cl-white">Leverage Your Business With Us!</h2>
                                        </div>
                                        <p class="fs-18">
                                            At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                        <div class="counter-items row">
                                            <div class="col-lg-6">
                                                <div class="counter-item counter-white mt-40">
                                                    <div class="counter-wrap">
                                                        <span class="counter">92</span>

                                                        <span class="suffix">%</span>
                                                    </div>
                                                    <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="counter-item counter-white mt-40">
                                                    <div class="counter-wrap">
                                                        <span class="counter">2000000</span>
                                                        <span class="suffix">+</span>
                                                    </div>
                                                    <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                    <div class="consultation-form-area pl-xl-3 content-mt-md-50">

                                        <Find_Taskers_Common subscriber_type="Home Page" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
