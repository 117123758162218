import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import BikeOnboarding from "../../../../components/services/merchant/onboardingServices/bikeOnboarding/index";

const BikeOnboardingPage = React.memo((props) => {
  return (
    <>
      <BikeOnboarding />
    </>
  );
});

export default withRouter(BikeOnboardingPage);
