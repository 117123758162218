import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validation';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_city_name: '',
  area_of_interest: ''
};

const names = [
  'Brand Promoter',
  'Data Moderator',
  'Delivery Partner',
  'Event Promoters',
  'Field Activation',
  'Field Auditor',
  'Field Marketing',
  'Field Sales Executive',
  'Instore staff',
  'Part Time Jobs',
  'Picker and Packers',
  'Tele Caller',
  'Telesales',
  'Warehouse Incharge',
];


const cities = [
  'Agra',
  'Ahmedabad',
  'Aligarh',
  'Bangalore',
  'Bhopal',
  'Chandigarh',
  'Chennai',
  'Delhi',
  'Goa',
  'Gorakhpur',
  'Gurgaon',
  'Guwahati',
  'Hyderabad',
  'Indore',
  'Jodhpur',
  'Kolhapur',
  'Kolkata',
  'Kota',
  'Ludhiana',
  'Mumbai',
  'Nagpur',
  'Nashik',
  'Noida',
  'Pune',
  'Surat',
  'Thane',
  'Vadodara'
];


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );



const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function Chat_Button(props) {
  const { classes } = props;

  console.log(props)
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);

  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([])
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);


  const handleChangeNumberLeadInput = (event) => {
    console.log('event', event.target.value)
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));
    if (
      state.subscriber_name !== '' &&
      state.subscriber_email !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.subscriber_city_name !== '' &&
      state.area_of_interest !== ''
    ) {
      const postData = {
        subscriber_name: state.subscriber_name,
        subscriber_email: state.subscriber_email,
        subscriber_phone: state.subscriber_phone,
        city: state.subscriber_city_name,
        area_of_interest: state.area_of_interest,
        // area_of_interest: areaOfInterest.toString(),
        subscriber_type: 'Chat-Button-Find-Jobs',
        visitor_type: 'job_seeker'
      };

      createSubscriber(postData)(subscriberDispatch)
      console.log('Create Subscriber Detail', postData)
      setState(init);
      setOpenNotification(true);
      // window.location.href = 'https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US';
    }
  }


  useEffect(() => {
    console.log('subscriberState', subscriberState)
    let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
    if (status == true) {
      console.log("store", store);
      subscriberState.createSubscriber = {
        loading: false,
        error: null,
        data: null,
      }
    }
  }, [subscriberState])

  const handleClose = () => {
    setState(init);
    setErrors(!validation(state));
    props.onClose()
  }


  return (
    <div >
      {/* <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white", cursor: "pointer" }} onClick={handleOpen} class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a> */}
      {/* <Fab onClick={handleOpen} style={{zIndex:"100", margin:"0px",top:"auto",right:"20px",bottom:"20px",left:"auto",position:"fixed", backgroundColor:"#6A3996",color:"white"}}  aria-label="add" >
        <MessageIcon />
      </Fab> */}

      <Modal
        open={props.open}
        onClose={()=>handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <div style={{ height: 540, overflowY: 'auto', display: 'inline-grid', padding: "0px", position: "absolute" }} class="form-left col-lg-4 col-md-8 wow fadeInUp">
            <Snackbar open={openNotification} onClose={handleCloseNotification} autoHideDuration={2500} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert severity="success" sx={{ width: '100%' }}>
                Form Successfully Submitted!
              </Alert>
            </Snackbar>
            <div style={{ margin: "0px" }} class="consultation-form-area pl-xl-3 content-mt-md-50">
              <div class="consultation-form">
                <div style={{ display: "flex" }}>
                  <h4 class="pb-10 font-montserrat fs-20 fw-600">Get in touch with our experts!</h4>
                  <CloseIcon style={{ cursor: "pointer", marginLeft: "10%" }} onClick={()=>handleClose()} />
                </div>
                <form action="#">
                  <div class="font-montserrat input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Full Name*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                    </Grid>
                    <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Email*" inputProps={{
                        style: {
                          height: "20px",
                        },
                      }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                    </Grid>
                    {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Phone*" inputProps={{
                        style: {
                          height: "20px",
                        },
                      }} type="text" variant="outlined" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                    </Grid>
                    {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                  </div>

                  <div class="input-field">
                    <FormControl fullWidth>
                      <InputLabel id="multiple-city">City*</InputLabel>
                      <Select
                        labelId="multiple-city"
                        id="demo-multiple-checkbox"
                        label="city"
                        value={state.subscriber_city_name}
                        name="subscriber_city_name"
                        // value={multipleCities}
                        // style={{}}
                        onChange={handleChange}
                        style={{ background: "#f0f4ff", outline: 'none !important' }}
                        input={<OutlinedInput classes={classes} label="city" />}
                        // renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {cities.map((name) => (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.subscriber_city_name && <p style={{ color: 'red' }}>{errors.subscriber_city_name}</p>}
                  </div>

                  <div class="input-field">
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Area of Interest*</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        label="city"
                        value={state.area_of_interest}
                        name="area_of_interest"
                        // value={multipleCities}
                        // style={{}}
                        // multiple
                        onChange={handleChange}
                        style={{ background: "#f0f4ff", outline: 'none !important' }}
                        input={<OutlinedInput classes={classes} label="Area of Interest" />}
                        // renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.area_of_interest && <p style={{ color: 'red' }}>{errors.area_of_interest}</p>}
                  </div>

                  <div class="input-field">
                    <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}