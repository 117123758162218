import { GET_JOB, GET_JOB_ERR, GET_JOBS, GET_JOBS_ERR, GET_CAREER_CATEGORIES, GET_CAREER_CATEGORIES_ERR } from '../../actionTypes/careerType'

export default (state, action) => {
  switch (action.type) {
    case GET_JOB:
      return {
        ...state,
        getJob: action.payload, ...state.data,
      }

    case GET_JOB_ERR:
      return {
        ...state,
        getJob: { getdata: action.payload }
      }

    case GET_JOBS:
      return {
        ...state,
        getJobs: action.payload, ...state.data,
      }

    case GET_JOBS_ERR:
      return {
        ...state,
        getJobs: { getdata: action.payload }
      }

    case GET_CAREER_CATEGORIES:
      return {
        ...state,
        getJobCategories: action.payload, ...state.data,
      }

    case GET_CAREER_CATEGORIES_ERR:
      return {
        ...state,
        getJobCategories: { getdata: action.payload }
      }

    default:
      return state;
  }
}