import React, { useEffect, useState, useContext, } from "react";
import { getBlogs } from "../../../context/actions/blogAction/getBlogs";

import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';


export default function LatestBlogs() {

    const { blogState, blogDispatch } = useContext(GlobalContext);
    const [blogDetail, setBlogDetail] = useState({})
    const [filterBlogDetails, setFilterBlogDetails] = React.useState([]);
    const [tabFilter, setTabFilter] = React.useState('active');

    const history = useHistory()


    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])

    useEffect(() => {
        let filter = tabFilter
        getBlogs({ filter, page: "all" })(blogDispatch)
    }, [])

    useEffect(() => {
        let status = blogState.getBlogs.status
        if (status === true) {
            setFilterBlogDetails(blogState.getBlogs.data.res)
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", blogState.getBlogs)
        }
        // SERVER VALIDATION ERROR RESPONSE  
        if (status === 401) {
            console.log("Validation Error:", blogState.getBlogs)
        }
    }, [blogState])

    console.log("filterBlogDetails", filterBlogDetails);

    const OpenBlog = (item) => {
        // props.history.push(`${localRoute.blogDetails1}/${item.blog_slug}`,{data:item})
        history.push(`${localRoute.blogDetails1}/${item.blog_slug}`)
    }

    return (
        <div>
            <div class="font-montserrat feature-blog-one blog-page-bg">
                <div class="section-heading text-center mb-50">
                    <h2 class="font-montserrat fs-50 fw-600">Latest Blog</h2>
                </div>
                
                <div class="container">
                    <div class="row">
                        {filterBlogDetails.slice(0, 3).map(item =>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="post-meta">
                                    <img src={item.blog_image} alt="Image" class="image-meta" />
                                    <div class="font-montserrat tag">{item.category_name}</div>
                                    <h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.blog_slug} class="font-montserrat title fw-600">
                                            {item.blog_title}
                                        </a>
                                    </h3>
                                    <a style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.blog_slug} class="read-more d-flex justify-content-between align-items-center">
                                        <span>Read More</span>
                                        <i class="flaticon-right-arrow"></i>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}