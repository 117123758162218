import { useEffect } from 'react'
import React from 'react'
import Preloader from '../../../components/preloader/index'
import StartArea from '../startArea/index'
import OurPartners from '../ourPartners/index'
import Counter from '../counter/index'
import Services from '../services/index'
import WorkingProgress from '../workingProgress/index'
import Feature from '../feature/index'
import PopularCaseStudy from '../popularCaseStudy/index'
import Testimonial from '../testimonial/index'
import Media from '../media/index'
import TaskmoApp from '../taskmoApp/index'
import Consultation from '../consultation/index'
import GrowWithUs from '../growWithUs/index'
import LatestBlogs from '../latestBlogs/LatestBlogs'
import Technology from '../technology/Technology'
import { Helmet } from 'react-helmet'
import NewServices from 'pages/services/newServices/NewServices'
import { useState } from 'react'


export default function Home() {

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Task Fulfillment Platform | Gig Marketplace|| Taskmo
        </title>
        <meta name="description" content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters." />
        <meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
        <link rel="canonical" href="https://taskmo.com/" />
      </Helmet>

      <StartArea />
      <OurPartners />
      <Counter />
      <div class="mt-80 md-mt-80" id="product">
        <Services />
        {/* <NewServices /> */}
      </div>
      <WorkingProgress />
      <Feature />
      <Technology />
      <TaskmoApp />
      <PopularCaseStudy />
      <Testimonial />
      <Consultation />
      <Media />
      <LatestBlogs />
      <GrowWithUs />
    </div>
  )
}

