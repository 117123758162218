import React, { useRef, useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Grid, TextField } from '@mui/material';
import { Button, Fab } from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';
import { GlobalContext } from '../../context/GlobalState';
import { createImageUrl } from "../../context/actions/subscriberAction/createImageUrl";
import PersonIcon from '@mui/icons-material/Person';
import html2canvas from "html2canvas";
import * as jsPDF from 'jspdf';
import validation from '../../components/validation';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import "./IDCardStyle";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_campany_name: '',
  subscriber_message: '',
  file_url: '',
  sign_url: '',
  subsriber_contact: ''

};


const bloodgroup = [
  'A+',
  'A-',
  'B+',
  'B-',
  'AB+',
  'AB-',
  'O+',
  'O-',
]

const IDCardPage = React.memo((props) => {
  const { classes } = props;
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [imageUrl, setImageUrl] = React.useState('');
  const [signUrl, setSignUrl] = React.useState('');
  const [uploadResponse, setUploadResponse] = useState(false);
  const [errors, setErrors] = useState({});

  console.log("ssss", state)
  const handleCapture = ({ target }) => {
    console.log('upload Files')
    const { name, value } = target
    if (value != '') {
      const fileReader = new FileReader();
      const name1 = target.accept.includes('pdf');

      fileReader.readAsDataURL(target.files[0]);

      let formData = new FormData();
      formData.append('file', target.files[0]);
      console.log('file_url', target.files)
      setImageUrl(target.files[0].name)
      setUploadResponse(false)
      createImageUrl(formData)(subscriberDispatch)
    }
  };


  const handleCaptureSign = ({ target }) => {
    console.log('upload Files')
    const { name, value } = target
    if (value != '') {
      const fileReader = new FileReader();
      const name1 = target.accept.includes('pdf');

      fileReader.readAsDataURL(target.files[0]);

      let formData = new FormData();
      formData.append('file', target.files[0]);
      console.log('sign_url', target.files)
      setSignUrl(target.files[0].name)

      setUploadResponse(true)
      createImageUrl(formData)(subscriberDispatch)
      console.log("formDatasign", formData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };



  const handleDownload = e => {
    e.preventDefault()
    setErrors(validation(state));
    if (
      state.subscriber_name !== '' &&
      state.subscriber_worklocation !== '' &&
      state.subscriber_bloodgroup !== '' &&
      state.subscriber_dob !== '' &&
      state.subscriber_doj !== '' &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.file_url !== '' &&
      state.sign_url !== ''

    ) {
      const postData = {
        subscriber_name: state.subscriber_name,
        subscriber_worklocation: state.subscriber_worklocation,
        subscriber_bloodgroup: state.subscriber_bloodgroup,
        subscriber_dob: state.subscriber_dob,
        subscriber_doj: state.subscriber_doj,
        subscriber_phone: state.subscriber_phone,
        file_url: state.file_url,
        sign_url: state.sign_url,
      };
      var printContents = document.getElementById("divToPrint").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;


    }
  }




  useEffect(() => {
    console.log('subscriberStatess', subscriberState)
    let status = subscriberState.createSubscriber && subscriberState.createImageUrl.data && subscriberState.createImageUrl.data.status
    if (status == true) {

      if (uploadResponse == true) {
        setState(
          {
            ...state,
            sign_url: subscriberState.createImageUrl.data.data.url
          });
        setUploadResponse(false)
      }
      else {
        setState(
          {
            ...state,
            file_url: subscriberState.createImageUrl.data.data.url
          });
      }
    }
  }, [subscriberState])


  // const handleDownload = () => {    
  //   var printContents = document.getElementById("divToPrint").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  const inputRef = useRef(null);
  // const handleDownload = () => {
  //   html2canvas(inputRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     pdf.save("download.pdf");
  //   });
  // };



  return (
    <>

      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-8 wow fadeInUp">
          <div class="consultation-form-area pl-xl-3 content-mt-md-50">
            <div class="consultation-form">
              <div style={{ display: "flex" }}>
                <h4 class="pb-10 font-montserrat fs-20 fw-600">Fill details to create ID Card</h4>
              </div>
              <form action="#">
                <div class="font-montserrat input-field" >
                  <Grid xs={12} >
                    <TextField fullWidth label="Full Name"
                      required
                      inputProps={{
                        style: {
                          height: "10px"
                        },
                      }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                    {errors.subscriber_name && <p style={{ color: 'red' }}>{errors.subscriber_name}</p>}
                  </Grid>
                </div>
                <div class="input-field">
                  <Grid xs={12} >
                    <TextField fullWidth label="Enter Work Location "
                      inputProps={{
                        style: {
                          height: "10px",
                        },
                      }} variant="outlined" type="text" name="subscriber_worklocation" value={state.subscriber_worklocation} onChange={handleChange} />
                    {errors.subscriber_worklocation && <p style={{ color: 'red' }}>{errors.subscriber_worklocation}</p>}

                  </Grid>
                </div>
                <div class="input-field">

                  <div class="input-field">
                    <FormControl fullWidth>
                      <InputLabel id="multiple-city">Blood Group</InputLabel>
                      <Select
                        labelId="multiple-city"
                        id="demo-multiple-checkbox"
                        label="city"
                        value={state.subscriber_bloodgroup}
                        name="subscriber_bloodgroup"
                        // value={multipleCities}
                        // style={{}}
                        onChange={handleChange}
                        style={{ background: "#f0f4ff", outline: 'none !important' }}
                        input={<OutlinedInput classes={classes} label="city" />}
                        // renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {bloodgroup.map((name) => (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.subscriber_bloodgroup && <p style={{ color: 'red' }}>{errors.subscriber_bloodgroup}</p>}
                  </div>


                  {/* <Grid xs={12} >
                    <TextField fullWidth label="Blood Group"
                      inputProps={{
                        style: {
                          height: "10px",
                        },
                      }} variant="outlined" type="text" name="subscriber_bloodgroup" value={state.subscriber_bloodgroup} onChange={handleChange} />
                    {errors.subscriber_bloodgroup && <p style={{ color: 'red' }}>{errors.subscriber_bloodgroup}</p>}
                  </Grid> */}
                </div>

                <div class="input-field">
                  <Grid xs={12} >
                    <TextField fullWidth label="Date of Birth"
                      inputProps={{
                        style: {
                          height: "10px",
                        },
                      }} variant="outlined" focused type="date" name="subscriber_dob" value={state.subscriber_dob} onChange={handleChange} />
                    {errors.subscriber_dob && <p style={{ color: 'red' }}>{errors.subscriber_dob}</p>}

                  </Grid>
                </div>
                <div class="input-field">
                  <Grid xs={12} >
                    <TextField fullWidth label="Date of Joining"
                      inputProps={{
                        style: {
                          height: "10px",
                        },
                      }} variant="outlined" type="date" focused name="subscriber_doj" value={state.subscriber_doj} onChange={handleChange} />
                    {errors.subscriber_doj && <p style={{ color: 'red' }}>{errors.subscriber_doj}</p>}

                  </Grid>
                </div>
                <div class="input-field">
                  <Grid xs={12} >
                    <TextField fullWidth label="Contact"
                      inputProps={{
                        style: {
                          height: "10px",
                        },
                      }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChange} />
                    {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}

                  </Grid>
                </div>


                <div class="input-field">
                  <Grid xs={12} >
                    <p>Profile Image</p>
                    <input
                      accept="image/*"
                      id="icon-button-photo"
                      onChange={handleCapture}
                      type="file"
                      name='profile'
                    // error={errors.profile}
                    />
                    {errors.file_url && <p style={{ color: 'red' }}>{errors.file_url}</p>}

                  </Grid>
                </div>


                <div class="input-field">
                  <Grid xs={12} >
                    <p>Signature Image</p>
                    <input
                      accept="image/*"
                      id="icon-button-photo1"
                      onChange={handleCaptureSign}
                      type="file"
                      name='profile1'
                    // error={errors.profile}
                    />
                    {errors.sign_url && <p style={{ color: 'red' }}>{errors.sign_url}</p>}

                  </Grid>
                </div>

                {/* <div class="input-field">
                  <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* <div class="col-lg-8 col-md-8 wow" id="divToPrint" ref={inputRef}> */}

        <div class="id-card-flex" style={{ padding: "20px" }} id="divToPrint" >
          <div class="inside-card" style={{ width: "211px", height: "336px", marginLeft: "30px" }}>
            <img src="assets/byju-id-card/Frame3.png" width="350px" />
            <img src="assets/byju-id-card/Group.png" width="130px" style={{ marginLeft: "30px", marginTop: "-300px" }} />
            <div >

              {state.file_url == "" ?
                <div style={{ textAlign: 'center' }}>
                  <span >
                    <img src="https://taskmotech.s3.amazonaws.com/cc0038104419e0a6a4411649939204441_images%20%282%29.jpeg" width="100px" height="100px" style={{ margin: "10px auto", border: "2px solid #f0f0f0" }} />
                  </span>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                  <span >
                    <img src={state.file_url} width="100px" height="100px" style={{ margin: "10px auto", border: "2px solid #f0f0f0" }} />
                  </span>
                </div>

              }
            </div>
            <h1 style={{ fontSize: "20px", color: "#94328C", textAlign: "center" }}>{state.subscriber_name}</h1>
            <p style={{ color: "#4F4F4F", textAlign: "center" }}>{state.subscriber_worklocation}</p>
            <p style={{ color: "#4F4F4F", textAlign: "center" }}>{state.subscriber_bloodgroup}</p>
          </div>
          <br />

          <div class="inside-card" style={{ width: "211px", height: "336px", marginLeft: "30px" }}>
            <img src="assets/byju-id-card/Frame4.png" width="350px" />
            <div style={{ background: "#F2F2F2", width: "90%", margin: "-300px auto -20px", height: "100px" }}>
              <p style={{ fontSize: "10px", color: "#4F4F4F" }}>DOB:- {state.subscriber_dob}</p>
              <p style={{ fontSize: "10px", color: "#4F4F4F" }}>DOJ:- {state.subscriber_doj}</p>
              <p style={{ fontSize: "10px", color: "#4F4F4F" }}>Contact:- {state.subscriber_phone}</p>
            </div>
            <div style={{ width: "90%", border: "2px solid red", margin: "auto", lineHeight: "1.3", fontSize: "12px" }}>
              <li style={{ fontSize: "8px" }}>ID Card should carried at all time while on duty and should be provided on demand</li>
              <li style={{ fontSize: "8px" }}>If ID card is lost, the same should be intimated to the Admin department immediately. </li>
              <li style={{ fontSize: "8px" }}>Please surrender this card to admin.</li>
              <li style={{ fontSize: "8px" }}>Department on cessation of service or transfer out of location</li>
            </div>

            <div >

              {state.sign_url == "" ?
                <div style={{ textAlign: 'center' }}>
                  <span >
                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e2e64bd65d1f222e326a1649940396542_istockphoto-1214683854-612x612%20%281%29.jpeg" width="100px" height="60px" style={{ margin: "10px auto", border: "2px solid #f0f0f0" }} />
                  </span>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                  <span >
                    <img src={state.sign_url} width="100px" height="60px" style={{ margin: "10px auto", border: "2px solid #f0f0f0" }} />
                  </span>
                </div>

              }
            </div>

            <h5 style={{ color: "#4F4F4F", textAlign: "center", fontSize: "10px", marginTop: "-12px" }}>Candidate Signature</h5>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div style={{ margin: "auto", padding: "20px" }}>
        <button style={{ color: "white" }} class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine home-page-employer-action" onClick={handleDownload}>Download</button>
      </div>
    </>
  );
});

export default withRouter(IDCardPage);
