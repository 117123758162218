export default {
  getJob: {
    loading: false,
    error: null,
    data: [],
  },

  getJobs: {
    loading: false,
    error: null,
    data: [],
  },

  getJobCategories: {
    loading: false,
    error: null,
    data: [],
  },

};
