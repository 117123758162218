import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import TermsConditionsApp from "components/termsConditions/termsConditionsApp";

const TermsConditionsAppPage = React.memo((props) => {
  return (
    <>
      <TermsConditionsApp />
    </>
  );
});

export default withRouter(TermsConditionsAppPage);
