import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./BlogStyle";
import CaseStudy from '../../../components/caseStudy/caseStudy'

const CaseStudyPage = React.memo((props) => {
  return (
    <>
      <CaseStudy />
    </>
  );
});

export default withRouter(CaseStudyPage);
