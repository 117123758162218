import { GET_ALLNEWS, GET_ALLNEWS_ERR, GET_NEWS, GET_NEWS_ERR, CREATE_NEWS, CREATE_NEWS_ERR } from '../../actionTypes/newsType'

export default (state, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        getNews: action.payload, ...state.data,
      }

    case GET_NEWS_ERR:
      return {
        ...state,
        getNews: { getdata: action.payload }
      }

    case GET_ALLNEWS:
      return {
        ...state,
        getAllNews: action.payload, ...state.data,
      }

    case GET_ALLNEWS_ERR:
      return {
        ...state,
        getAllNews: { getdata: action.payload }
      }

    case CREATE_NEWS:
      return {
        ...state,
        createNews: action.payload, ...state.data,
      }

    case CREATE_NEWS_ERR:
      return {
        ...state,
        createNews: { getdata: action.payload }
      }


    default:
      return state;
  }
}