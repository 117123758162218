import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CampusStyle";
import Campus from "../../components/campus/index";
const CampusPage = React.memo((props) => {
  return (
    <>
      <Campus />
    </>
  );
});

export default withRouter(CampusPage);
