import React from "react";
import { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Find_Taskers_Common from "components/Find_Taskers_Common";
import Button from "@material-ui/core/Button";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Best Gig Service Provider | Gig Companies | Manpower Services ||
            Taskmo
          </title>
          <meta
            name="description"
            content="Taskmo - Best Gig Service provider, Gig Marketplace, Gig Companies, employement, customer service, contract staffing services. To know more click here...."
          />
          <meta
            name="keywords"
            content="Gig service provider, gig company, manpower services, Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered"
          />
          <link rel="canonical" href="https://taskmo.com/contact-us" />
        </Helmet>

        <div
          class="fancy-hero-four bg-doc space-fix"
          style={{ paddingTop: "30px" }}
        >
          <div class="bg-wrapper">
            <div class="container">
              <div class="row" data-aos="fade-up">
                <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
                  {/* <h6>Contact us</h6> */}
                  <h2 class="fw-600">Contact Us!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="font-montserrat contact-style-two">
          <div class="container">
            <div class="contact-info-wrapper">
              <div class="row justify-content-center">
                {/* 
                                <div data-aos="fade-up" class="col-lg-4 col-sm-6 d-lg-flex">
                                    <div class="address-info" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>

                                        <div class="title">Join Our WhatsApp Group</div>

                                        <div style={{ fontSize: "20px", color: "#000", position: "relative", display: "inline-block", padding: "17px 0 22px" }} >
                                            Join Our Official Community
                                        </div>

                                        <div style={{ display: "flex", gap: "20px", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }} >
                                            <div style={{ maxWidth: "max-content" }} >
                                                <a href='https://chat.whatsapp.com/L88VJShJfei8jybjKrc3IL' target="_blank" >
                                                    <Button
                                                        variant="contained"
                                                        size='large'
                                                        startIcon={<WhatsAppIcon />}
                                                        style={{ background: "#45C354", color: "#FFFFFF", fontWeight: 600 }}
                                                    >
                                                        Join Now
                                                    </Button>
                                                </a>
                                            </div>

                                            <div style={{ maxWidth: "max-content" }}>
                                                <a href='https://t.me/taskmo_money_earning_application' target="_blank" >
                                                    <Button
                                                        variant="contained"
                                                        size='large'
                                                        startIcon={<TelegramIcon />}
                                                        style={{ background: "#25A4E1", color: "#FFFFFF", fontWeight: 600 }}
                                                    >
                                                        Join Now
                                                    </Button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}

                {/* <div data-aos="fade-up" class="col-lg-4 col-sm-6 d-lg-flex" >
                                    <div class="address-info" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }} >
                                        <div class="icon d-flex align-items-end"><img src="assets/contact/location.svg" alt="" /></div>
                                        <div class="title">Location</div>
                                        <p style={{ fontSize: "16px", fontWeight: "500" }} class=" font-rubik"><a target="_blank" href="https://g.page/Taskmo?share">#33, Mohan chambers, 1st Main Rd, 3rd Phase, J. P. Nagar, Bengaluru, Karnataka 560078</a></p>
                                    </div>
                                </div> */}

                <div
                  data-aos="fade-up"
                  data-aos-duration="2500"
                  class="col-lg-4 col-sm-6 d-lg-flex"
                >
                  <div
                    class="address-info"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
                  >
                    <div class="icon d-flex align-items-end">
                      <img src="assets/contact/socialmedia.svg" alt="" />
                    </div>
                    <div class="title">Social Media</div>
                    <p
                      style={{ fontSize: "16px", fontWeight: "500" }}
                      class="font-rubik"
                    >
                      Find us on social media
                    </p>
                    <ul class="d-flex justify-content-center">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/taskmoofficial"
                        >
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://twitter.com/Taskmo_official"
                        >
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/taskmo/"
                        >
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/company/taskmo-feedmypockets/"
                        >
                          <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UCTJT_pHzljO9tAUBw3fNI6Q"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  data-aos="fade-up"
                  data-aos-duration="2200"
                  class="col-lg-4 col-sm-6 d-lg-flex"
                >
                  <div
                    class="address-info"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
                  >
                    <div class="icon d-flex align-items-end">
                      <img src="assets/contact/mail.svg" alt="" />
                    </div>
                    <div class="title">Contact</div>
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "black",
                        }}
                        class="font-rubik"
                      >
                        For Businesses:-{" "}
                      </span>
                      <span style={{ fontSize: "16px" }} class="font-rubik">
                        <a href="mailto:business@taskmo.com">
                          {" "}
                          business@taskmo.com
                        </a>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "black",
                        }}
                        class="font-rubik"
                      >
                        For Careers:-{" "}
                      </span>
                      <span style={{ fontSize: "16px" }} class="font-rubik">
                        <a href="mailto:hr@taskmo.com"> hr@taskmo.com</a>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "black",
                        }}
                        class="font-rubik"
                      >
                        For Queries:-{" "}
                      </span>
                      <span style={{ fontSize: "16px" }} class="font-rubik">
                        <a href="mailto:support@taskmo.com">
                          {" "}
                          support@taskmo.com
                        </a>
                      </span>
                    </div>

                    {/* <p style={{ fontSize: "18px" }} class="font-rubik"><a href="tel:+91 7406480001" data-rel="external" class="mobile-num">+91 7406480001</a></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="font-montserrat container mt-80 md-mt-50">
          <div class="row justify-content-center">
            <div class="col-xl-10">
              <div class="row align-items-center justify-content-center">
                <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                  <div class="consultation-text pr-xl-5">
                    <div class="section-heading heading-white mb-30">
                      <h2 class="font-montserrat fs-50 fw-600">
                        Leverage Your Business With Us!
                      </h2>
                    </div>
                    <p class="fs-18">
                      At Taskmo, we work with businesses and enterprises of all
                      sizes by offering services across multiple industries. We
                      help you scale up your business efficiently through our
                      distributed gig workers across the country.
                    </p>
                    <div class="counter-items row">
                      <div class="col-lg-6">
                        <div class="counter-item mt-40">
                          <div class="counter-wrap">
                            <span class="counter">92</span>

                            <span class="suffix">%</span>
                          </div>
                          <h6
                            style={{ fontSize: "18px" }}
                            class="title font-montserrat"
                          >
                            Customer Satisfaction
                          </h6>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="counter-item mt-40">
                          <div class="counter-wrap">
                            <span class="counter">2000000</span>
                            <span class="suffix">+</span>
                          </div>
                          <h6
                            style={{ fontSize: "18px" }}
                            class="title font-montserrat"
                          >
                            Tasks Completed
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in-up"
                  class="col-lg-6 col-md-8 wow fadeInUp"
                >
                  <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                    <Find_Taskers_Common subscriber_type="Contact" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
