import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
      <div
        style={{
          padding: "10px",
          border: "1px solid gainsboro",
          borderRadius: "5px",
          marginTop: "30px",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Privacy Policy| Gig Onboarding Services || Gig Marketplace
          </title>
          <meta
            name="description"
            content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters."
          />
          <meta
            name="keywords"
            content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered"
          />
          <link rel="canonical" href="https://taskmo.com/privacy-policy" />
        </Helmet>

        <p style={{ textAlign: "center" }}>
          <strong>Privacy Policy</strong>
        </p>
        <br />
        <p>
          <strong>Introduction </strong>
        </p>
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <span style={{ fontWeight: "400" }}>
              <strong>Stellarslog Technovation Pvt. Ltd.</strong>
              ("Stellarslog," "we," "us," or "our") respects the privacy of our
              users ("you" or "your"). This Privacy Policy describes how we
              collect, use, disclose, and secure your personal information when
              you use the <strong>Taskmo App (the "App").</strong>
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Information We Collect:</strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              We collect several types of information to provide and improve the
              App's services. Personal Information:
            </span>
            <ul style={{ marginLeft: "20px", paddingTop: "10px" }}>
              <li>
                <strong style={{ fontWeight: "600" }}>
                  Registration Information:{" "}
                </strong>
                When you create an account, we may collect information such as
                your phone number, email address (optional), name (optional),
                and any other information you choose to provide.
              </li>
              <li>
                <strong style={{ fontWeight: "600" }}>
                  KYC Verification:{" "}
                </strong>
                For security purposes and compliance with Indian regulations, we
                collect your Aadhaar number, a photo of your Aadhaar card, and a
                selfie using the app's camera.
              </li>
              <li>
                <strong style={{ fontWeight: "600" }}>
                  {" "}
                  Device Information:{" "}
                </strong>
                We may collect information about your device, such as your
                device type, operating system version, unique device identifier,
                and IP address.
              </li>
              <li>
                <strong style={{ fontWeight: "600" }}>
                  Location Information:{" "}
                </strong>
                We may collect your location information with your consent to
                enable certain features of the App, such as finding nearby
                tasks.
              </li>
              <li>
                <strong style={{ fontWeight: "600" }}>
                  Usage Information:{" "}
                </strong>{" "}
                We may collect information about how you use the App, such as
                the tasks you apply for, the projects you complete, and your
                interaction with the App's features.
              </li>
            </ul>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Non-Personal Information:</strong>
            <span style={{ fontWeight: "400" }}></span>
            <br />
            <span style={{ fontWeight: "400" }}>
              We may also collect non-personal information that does not
              directly identify you. This may include information such as your
              demographic data, browsing history on the App, and preferences.
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>How We Use Your Information: </strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              We use the information we collect for several purposes, including:
            </span>
            <ul
              className=""
              style={{ listStyleType: "disc", marginLeft: "35px" }}
            >
              <li>To provide and improve the App's services</li>
              <li>To process your account registration and KYC verification</li>
              <li>To connect you with relevant tasks and projects</li>
              <li>To facilitate communication between you and businesses</li>
              <li>
                {" "}
                To send you important information about the App, such as
                updates, security alerts, and support messages
              </li>
              <li>To personalize your experience with the App</li>
              <li>To analyze how you use the App to improve our services</li>
              <li>To comply with legal and regulatory requirements</li>
            </ul>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Sharing Your Information: </strong>
            <span style={{ fontWeight: "400" }}>
              We may share your information with third-party service providers
              who help us operate the App, such as payment processors, data
              storage providers, and communication platforms. These service
              providers are contractually obligated to keep your information
              confidential and secure.
            </span>
            <span style={{ fontWeight: "400" }}>
              We may also share your information with businesses that offer
              tasks and projects through the App to facilitate your work with
              them. We will only share the information necessary for you to
              complete the tasks or projects.
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Your Choices: </strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              You have several choices regarding your information:
            </span>
            <ul
              className=""
              style={{ listStyleType: "disc", marginLeft: "35px" }}
            >
              <li>
                {" "}
                You can access and update your personal information through the
                App's settings.
              </li>
              <li>
                You can withdraw your consent for location tracking at any time
                through your device settings.
              </li>
              <li>
                You can delete your account by contacting us (see Contact Us
                section below).
              </li>
            </ul>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Data Security: </strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              Stellarslog takes steps to protect your information from
              unauthorized access, disclosure, alteration, or destruction. We
              use industry-standard security measures, including encryption in
              transit and at rest. However, no internet transmission or
              electronic storage method is completely secure. We cannot
              guarantee the absolute security of your information.
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Changes to this Privacy Policy </strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on the
              App. You are advised to review this Privacy Policy periodically
              for any changes.
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Children's Privacy </strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              The App is not intended for children under the age of 18. We do
              not knowingly collect personal information from children under 18.
              If you are a parent or guardian and you are aware that your child
              has used the App or provided us with personal information, please
              contact us.
            </span>
          </li>
        </ol>
        <br />
        <ol>
          <li style={{ fontWeight: "400" }} aria-level="1">
            <strong>Contact Us</strong>
            <br />
            <span style={{ fontWeight: "400" }}>
              If you have any questions about this Privacy Policy, please
              contact us at Email:{" "}
              <a
                style={{ fontWeight: "500", color: "#281f5d" }}
                href="mailto:support@taskmo.com"
              >
                support@taskmo.com
              </a>
            </span>
          </li>
        </ol>
      </div>
    );
}
