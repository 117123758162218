import React from 'react'
import { styles } from "./ServicesStyle";
import { useHistory } from 'react-router-dom'
import { localRoute } from "../../../routes/localRoutes";
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import retailImg from "../../../assets/images/retailandmerchant.png";
import retail1 from "../../../assets/images/retail1.svg";
import retail2 from "../../../assets/images/retail2.svg";
import qrInstallation from "../../../assets/images/qrInstallation.svg";
import survey from "../../../assets/images/survey.svg";
import teleImg from "../../../assets/images/tele.png";
import teleImg1 from "../../../assets/images/tele1.svg";
import teleImg2 from "../../../assets/images/tele2.svg";
import teleImg3 from "../../../assets/images/tele3.svg";
import dataImg from "../../../assets/images/data-content.png";
import data1 from "../../../assets/images/data1.svg";
import data2 from "../../../assets/images/data2.svg";
import data3 from "../../../assets/images/data3.svg";
import data4 from "../../../assets/images/data4.svg";
import customer from "../../../assets/images/customer-acquistion.png";
import customer1 from "../../../assets/images/customer1.svg";
import customer2 from "../../../assets/images/customer2.svg";
import finance from "../../../assets/images/finacial-solutions.png";
import finance1 from "../../../assets/images/finance1.svg";
import finance2 from "../../../assets/images/finance2.svg";
import finance3 from "../../../assets/images/finance3.svg";
import finance4 from "../../../assets/images/finance4.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./sliderServices.css";

const useStyles = makeStyles(styles);

const Services = () => {
    const history = useHistory()
    const classes = useStyles();

    const handleFinancialServices = () => {
        history.push(localRoute.financialSolutions)
    };

    const [retailClick, setRetailClick] = useState(true);
    const [teleSalesClick, setTelesalesClick] = useState(false);
    const [dataContentClick, setDataContentClick] = useState(false);
    const [customerPartnerClick, setCustomerPartnerClick] = useState(false);
    const [finacialClick, setFinancialClick] = useState(false);

    const handleRetailClick = () => {
        setRetailClick(true)
        setTelesalesClick(false)
        setDataContentClick(false)
        setCustomerPartnerClick(false)
        setFinancialClick(false)
    }

    const handleTeleClick = () => {
        setRetailClick(false)
        setTelesalesClick(true)
        setDataContentClick(false)
        setCustomerPartnerClick(false)
        setFinancialClick(false)
    }

    const handleDataClick = () => {
        setRetailClick(false)
        setTelesalesClick(false)
        setDataContentClick(true)
        setCustomerPartnerClick(false)
        setFinancialClick(false)
    }

    const handleCustomerClick = () => {
        setRetailClick(false)
        setTelesalesClick(false)
        setDataContentClick(false)
        setCustomerPartnerClick(true)
        setFinancialClick(false)
    }

    const handleFinancialClick = () => {
        setRetailClick(false)
        setTelesalesClick(false)
        setDataContentClick(false)
        setCustomerPartnerClick(false)
        setFinancialClick(true)
    }

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        swipeToSlide: true,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div>
            <div class="inner-container">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-10 col-md-9 m-auto">
                            <div class="text-center">
                                <h2 class="fs-50 fw-600 font-montserrat">Our Offerings</h2>
                                <p style={{ textAlign: "center" }} class="font-montserrat fs-20 fw-500">India’s Leading Gig Economy Service Provider</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.dskBtnView}  >
                    <div>
                        <Button variant="contained"
                            style={{ background: retailClick ? "#603890" : "#F6F0FE", color: retailClick ? "#fff" : "#8B76A6", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px", minWidth: "220px" }}
                            onClick={handleRetailClick} >
                            RETAIL & MERCHANT OPS
                        </Button>
                    </div>
                    <div>
                        <Button variant="contained"
                            style={{ background: teleSalesClick ? "#603890" : "#F6F0FE", color: teleSalesClick ? "#fff" : "#8B76A6", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px", minWidth: "220px" }}
                            onClick={handleTeleClick} >Tele-sales OPS</Button>
                    </div>


                    <div>
                        <Button variant="contained"
                            style={{ background: dataContentClick ? "#603890" : "#F6F0FE", color: dataContentClick ? "#fff" : "#8B76A6", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px", minWidth: "220px" }}
                            onClick={handleDataClick} >DATA & CONTENT OPS</Button>
                    </div>

                    <div>
                        <Button variant="contained"
                            style={{ background: customerPartnerClick ? "#603890" : "#F6F0FE", color: customerPartnerClick ? "#fff" : "#8B76A6", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px", minWidth: "220px" }}
                            onClick={handleCustomerClick} >CUSTOMER ACQUISITION</Button>
                    </div>

                    <div>
                        <Button variant="contained"
                            style={{ background: finacialClick ? "#603890" : "#F6F0FE", color: finacialClick ? "#fff" : "#8B76A6", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px", minWidth: "220px" }}
                            onClick={handleFinancialClick} >FINANCIAL SOLUTION</Button>
                    </div>

                </div>


                <div className={classes.dskView}>
                    {retailClick &&
                        <Card style={{ background: "#F6F0FE" }} >
                            <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                <div className={classes.offeringContainer} data-aos="fade-right" data-aos-duration="500" >
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        <img src={retailImg} alt="/" style={{ maxHeight: "550px" }} />
                                    </div>
                                </div>
                                <div className={classes.offeringContainer} data-aos="fade-left" data-aos-duration="500" >
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages} >
                                                <img src={retail1} alt="/"
                                                    onClick={() => history.push(localRoute.merchantOnboarding)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div
                                                className={classes.offeringImages}
                                                style={{ marginTop: "-15px" }} >
                                                <img src={retail2} alt="/"
                                                    onClick={() => history.push(localRoute.merchantOnboarding)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages}>
                                                <img src={qrInstallation} alt="/"
                                                    onClick={() => history.push(localRoute.merchantOnboarding)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div className={classes.offeringImages}>
                                                <img src={survey} alt="/"
                                                    onClick={() => history.push(localRoute.merchantOnboarding)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    }

                    {teleSalesClick &&
                        <Card style={{ background: "#F6F0FE" }} >
                            <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                <div className={classes.offeringContainer} data-aos="fade-right" data-aos-duration="500" >
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        <img src={teleImg} alt="/" style={{ maxHeight: "550px" }} />
                                    </div>
                                </div>
                                <div className={classes.offeringContainer} data-aos="fade-left" data-aos-duration="500" >
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages} >
                                                <img src={teleImg1} alt="/"
                                                    onClick={() => history.push(localRoute.teleSales)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div
                                                className={classes.offeringImages}
                                                style={{ marginTop: "-15px" }} >
                                                <img src={teleImg2} alt="/"
                                                    onClick={() => history.push(localRoute.customerSupport)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages}>
                                                <img src={teleImg3} alt="/"
                                                    onClick={() => history.push(localRoute.teleSales)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            {/* <div className={classes.offeringImages}>
                                                <img src={survey} alt="/" style={{ width: "100%", height: "auto" }} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    }

                    {dataContentClick &&
                        <Card style={{ background: "#F6F0FE" }} >
                            <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                <div className={classes.offeringContainer} data-aos="fade-right" data-aos-duration="500" >
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        <img src={dataImg} alt="/" style={{ maxHeight: "550px" }} />
                                    </div>
                                </div>
                                <div className={classes.offeringContainer} data-aos="fade-left" data-aos-duration="500" >
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages} >
                                                <img src={data1} alt="/"
                                                    onClick={() => history.push(localRoute.contentModeration)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div
                                                className={classes.offeringImages}
                                                style={{ marginTop: "-15px" }} >
                                                <img src={data2} alt="/"
                                                    onClick={() => history.push(localRoute.contentModeration)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages}>
                                                <img src={data3} alt="/"
                                                    onClick={() => history.push(localRoute.contentModeration)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div className={classes.offeringImages}>
                                                <img src={data4} alt="/"
                                                    onClick={() => history.push(localRoute.contentModeration)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    }

                    {customerPartnerClick &&
                        <Card style={{ background: "#F6F0FE" }} >
                            <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                <div className={classes.offeringContainer} data-aos="fade-right" data-aos-duration="500" >
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        <img src={customer} alt="/" style={{ maxHeight: "550px" }} />
                                    </div>
                                </div>
                                <div className={classes.offeringContainer} data-aos="fade-left" data-aos-duration="500" >
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages} >
                                                <img src={customer1} alt="/"
                                                    onClick={() => history.push(localRoute.customerAcquisition)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            {/* <div
                                                className={classes.offeringImages}
                                                style={{ marginTop: "-10px" }} >
                                                <img src={customer2} alt="/"
                                                    onClick={() => history.push(localRoute.customerAcquisition)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages}>
                                                <img src={data3} alt="/" style={{ width: "100%", height: "auto" }} />
                                            </div>
                                            <div className={classes.offeringImages}>
                                                <img src={data4} alt="/" style={{ width: "100%", height: "auto" }} />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    }

                    {finacialClick &&
                        <Card style={{ background: "#F6F0FE" }} >
                            <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                <div className={classes.offeringContainer} data-aos="fade-right" data-aos-duration="500" >
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        <img src={finance} alt="/" style={{ maxHeight: "550px" }} />
                                    </div>
                                </div>
                                <div className={classes.offeringContainer} data-aos="fade-left" data-aos-duration="500" >
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages} >
                                                <img src={finance1} alt="/"
                                                    onClick={() => history.push(localRoute.financialSolutions)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div
                                                className={classes.offeringImages}
                                                style={{ marginTop: "-10px" }} >
                                                <img src={finance2} alt="/"
                                                    onClick={() => history.push(localRoute.financialSolutions)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >
                                            <div className={classes.offeringImages}>
                                                <img src={finance3} alt="/"
                                                    onClick={() => history.push(localRoute.financialSolutions)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                            <div className={classes.offeringImages}>
                                                <img src={finance4} alt="/"
                                                    onClick={() => history.push(localRoute.financialSolutions)}
                                                    style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    }
                </div>

                <div className={classes.mbBtnView}>
                    <div class="col-xl-10 col-lg-9 m-auto" style={{ width: "98%", margin: "auto" }} >
                        <Slider {...settings}>
                            <div class="item">
                                <div style={{ width: "90%", margin: "auto" }} >
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                                    >
                                        RETAIL & MERCHANT OPS
                                    </Button>
                                </div>
                                <Card style={{ background: "#F6F0FE" }}>
                                    <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                        <div className={classes.offeringContainer} >
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <img src={retailImg} alt="/" style={{ maxHeight: "550px" }} />
                                            </div>
                                        </div>
                                        <div className={classes.offeringContainer} >
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages} >
                                                        <img src={retail1} alt="/"
                                                            onClick={() => history.push(localRoute.merchantOnboarding)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div
                                                        className={classes.offeringImages}
                                                        style={{ marginTop: "-15px" }} >
                                                        <img src={retail2} alt="/"
                                                            onClick={() => history.push(localRoute.merchantOnboarding)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages}>
                                                        <img src={qrInstallation} alt="/"
                                                            onClick={() => history.push(localRoute.merchantOnboarding)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div className={classes.offeringImages}>
                                                        <img src={survey} alt="/"
                                                            onClick={() => history.push(localRoute.merchantOnboarding)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="item">
                                <div style={{ width: "90%", margin: "auto" }} >
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                                    >
                                        Tele-sales OPS
                                    </Button>
                                </div>
                                <Card style={{ background: "#F6F0FE" }} >
                                    <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                        <div className={classes.offeringContainer} >
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <img src={teleImg} alt="/" style={{ maxHeight: "550px" }} />
                                            </div>
                                        </div>
                                        <div className={classes.offeringContainer} >
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages} >
                                                        <img src={teleImg1} alt="/"
                                                            onClick={() => history.push(localRoute.teleSales)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div
                                                        className={classes.offeringImages}
                                                        style={{ marginTop: "-15px" }} >
                                                        <img src={teleImg2} alt="/"
                                                            onClick={() => history.push(localRoute.customerSupport)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages}>
                                                        <img src={teleImg3} alt="/"
                                                            onClick={() => history.push(localRoute.teleSales)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="item">
                                <div style={{ width: "90%", margin: "auto" }} >
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                                    >
                                        DATA & CONTENT OPS
                                    </Button>
                                </div>
                                <Card style={{ background: "#F6F0FE" }} >
                                    <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                        <div className={classes.offeringContainer} >
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <img src={dataImg} alt="/" style={{ maxHeight: "550px" }} />
                                            </div>
                                        </div>
                                        <div className={classes.offeringContainer} >
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages} >
                                                        <img src={data1} alt="/"
                                                            onClick={() => history.push(localRoute.contentModeration)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div
                                                        className={classes.offeringImages}
                                                        style={{ marginTop: "-15px" }} >
                                                        <img src={data2} alt="/"
                                                            onClick={() => history.push(localRoute.contentModeration)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages}>
                                                        <img src={data3} alt="/"
                                                            onClick={() => history.push(localRoute.contentModeration)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div className={classes.offeringImages}>
                                                        <img src={data4} alt="/"
                                                            onClick={() => history.push(localRoute.contentModeration)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="item">
                                <div style={{ width: "90%", margin: "auto" }} >
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                                    >
                                        CUSTOMER ACQUISITION
                                    </Button>
                                </div>
                                <Card style={{ background: "#F6F0FE" }} >
                                    <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                        <div className={classes.offeringContainer} >
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <img src={customer} alt="/" style={{ maxHeight: "550px" }} />
                                            </div>
                                        </div>
                                        <div className={classes.offeringContainer} >
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages} >
                                                        <img src={customer1} alt="/"
                                                            onClick={() => history.push(localRoute.customerAcquisition)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="item">
                                <div style={{ width: "90%", margin: "auto" }} >
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                                    >
                                        FINANCIAL SOLUTION
                                    </Button>
                                </div>
                                <Card style={{ background: "#F6F0FE" }} >
                                    <CardContent style={{ display: "flex", alignItems: "center", flexWrap: "wrap", paddingBottom: "16px", marginTop: "10px" }} >
                                        <div className={classes.offeringContainer} >
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <img src={finance} alt="/" style={{ maxHeight: "550px" }} />
                                            </div>
                                        </div>
                                        <div className={classes.offeringContainer} >
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages} >
                                                        <img src={finance1} alt="/"
                                                            onClick={() => history.push(localRoute.financialSolutions)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div
                                                        className={classes.offeringImages}
                                                        style={{ marginTop: "-10px" }} >
                                                        <img src={finance2} alt="/"
                                                            onClick={() => history.push(localRoute.financialSolutions)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                                    <div className={classes.offeringImages}>
                                                        <img src={finance3} alt="/"
                                                            onClick={() => history.push(localRoute.financialSolutions)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                    <div className={classes.offeringImages}>
                                                        <img src={finance4} alt="/"
                                                            onClick={() => history.push(localRoute.financialSolutions)}
                                                            style={{ width: "100%", height: "auto", cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Slider>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Services