
import { CREATE_IMAGE_URL,CREATE_IMAGE_URL_ERR} from '../../actionTypes/subscriberType'
import { createImageUrlApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'


export const createImageUrl = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.post(`${createImageUrlApi}`, payload, await accessToken())
         console.log("result", results)
        let data = results && results.data
        dispatch({
            type: CREATE_IMAGE_URL,
            payload: data || [],
        });
    } catch (error) {
        if ([403].includes(error && error.response && error.response.status)) {
            localStorage.clear();
        }
        dispatch({
            type: CREATE_IMAGE_URL_ERR,
            payload: error.response
        });
    }
}
