import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sliderServices1.css";
export default function Testimonial() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3500,
        autoplaySpeed: 4000,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div style={{ width: "90%", margin: "100px auto 120px" }}>
            <div class="container">
                <div class="title-style-five text-center mb-70 md-mb-50">
                    <div class="row">
                        <div class="col-lg-7 col-md-9 m-auto">
                            <h2 class="centerAlign font-montserrat fw-600"> Check what our clients say about us. </h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-10 col-lg-9 m-auto">
                        <img src="../../images/icon/65.svg" alt="icon" class="m-auto" />
                        <div class="clientSliderThree">
                            <Slider {...settings}>
                                <div class="item">
                                    <p class="font-montserrat">“Taskmo (Formerly Known as FeedMyPockets) has supported us immensely by providing us with temporary gig workers who have helped us achieve all our targets and milestones within a short period of time.”</p>
                                    <h6 class="name font-montserrat">Jnanesh</h6>
                                    <span class="desig font-montserrat">Program Manager, Amazon</span>
                                </div>
                                <div class="item">
                                    <p class="font-montserrat">"Taskmo took away all our work of going through resumes, calling and hiring temp staff at an affordable price. They sourced the right candidate for the job. Very happy with their diligent and hard work"</p>
                                    <h6 class="name">Sneha</h6>
                                    <span class="desig">Founder, Fig-O-Honey</span>
                                </div>
                                <div class="item">
                                    <p class="font-montserrat">"Feed My Pockets has always supported us in getting temporary staff or promoters to reach our targets and milestones."</p>
                                    <h6 class="name">Vikram</h6>
                                    <span class="desig">Marketing Manager, Uber Eats</span>
                                </div>
                                <div class="item">
                                    <p class="font-montserrat">"We use Taskmo service in many of our daily operations. Well maintained team, experience and execution."</p>
                                    <h6 class="name">Surya</h6>
                                    <span class="desig">Senior Marketing Executive,  Zoom Car</span>
                                </div>
                                <div class="item">
                                    <p class="font-montserrat">"Conducting survey and sampling drive has been easier after our engagement with Taskmo. They enabled new age technology in short term assignment"</p>
                                    <h6 class="name">Anubhav</h6>
                                    <span class="desig">Operations Manager, ITC</span>
                                </div>
                                <div class="item">
                                    <p class="font-montserrat">"Taskmo is very balanced team, we have been using the service in various ways and roles. One stop solution to hire temp staffs"</p>
                                    <h6 class="name">Reetin</h6>
                                    <span class="desig">Assistant Marketing Manager, Vogo</span>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
