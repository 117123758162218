import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

export default function About() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    // speed: 1000,
    autoplaySpeed: 1500,

    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Gig Marketplace | Gig Companies | Gig Services || Taskmo
        </title>
        <meta
          name="description"
          content="Taskmo - Best Gig Service provider, Gig Marketplace, Gig Companies, employement, customer service, contract staffing services. To know more click here...."
        />
        <meta
          name="keywords"
          content="Gig Services, Gig Marketplace, Gig companies, employement, customer services, contract staffing, contract staffing companies in india, staffing agency, manpower supply, manpower consultency, manpower agencies"
        />
        <link rel="canonical" href="https://taskmo.com/about-us" />
      </Helmet>
      <div class="font-montserrat fancy-text-block-nine">
        <div class="container">
          <div class="title-style-two text-center mb-35 md-mb-10">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <p></p>
                <h1 class="font-montserrat fw-600" data-aos="fade-up">
                  Our Story
                </h1>
                <div class="col-12 order-first">
                  {/* <div class="portfolio-thumb" data-aos="fade-up">
                                        <img style={{ rotate: "25%" }} src="assets/about/groupimg.jpg" alt="taskmo group image" />
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <p
              class="fs-22 text-meta"
              style={{ textAlign: "left" }}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              Taskmo is a tech-driven gig discovery platform that helps large
              businesses, corporations, personal brands, and even start-ups by
              employing gig workers to perform tasks. Our sole mission is to
              make jobs accessible to everyone at the global level.
            </p>
            <p
              class="fs-22 text-meta"
              style={{ textAlign: "left" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              Taskmo is addressing one of the world’s largest gig economy
              markets with a vision of making job accessibility a fundamental
              right for the Indian workforce. We have successfully completed
              2Mn+ tasks with the help of 75K+ gig workers in the year 2021. Our
              clients range from start-ups to Fortune 500, a few of them are
              Amazon, Flipkart, Jio, Tata Group, Paytm, PhonePe, BharatPe,
              Zepto, etc. We are headquartered in Bengaluru, Karnataka. Whereas
              we are present across the length and breadth of the country with
              offices located in Chennai, Mumbai, Hyderabad, Delhi, Pune,
              Kolkata, and Ahmedabad.{" "}
            </p>
            <p
              class="fs-22 text-meta"
              style={{ textAlign: "left" }}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              We are a tech-driven company whose business model, innovation
              strategies, and growth are technology-centric. We make use of our
              technology for better customer service to gain a competitive
              advantage, and evolve with the marketplace. With the advancement
              of AI technology, we give them more mobility, freedom, and
              creativity to innovate instead of clinging to traditional methods
              and processes. Also, our updated technology helps us to get the
              most potential taskers according to the task requirement.{" "}
            </p>
          </div>
        </div>
      </div>
      <div class="fancy-feature-sixteen mt-150 md-mt-100" id="feature">
        <div class="container">
          <div class="block-style-eighteen">
            <div class="row align-items-center">
              <div class="col-lg-7" data-aos="fade-up" data-aos-duration="1200">
                <div class="d-flex align-items-center justify-content-center">
                  <img
                    width="500"
                    src="assets/about/20.png"
                    alt="Image"
                    class="animate-float-bob-y"
                  />
                </div>
              </div>

              <div class="col-lg-5" data-aos="fade-up" data-aos-duration="1200">
                <div class="font-montserrat text-wrapper">
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ width: "40px", marginRight: "10px" }}
                      src="assets/about/vision.svg"
                      alt="taskmo vision"
                    />
                    <h3 class="font-montserrat fw-600 mt-10">Vision</h3>
                  </div>
                  <p style={{ paddingTop: "10px " }}>
                    To digitally revolutionize gig jobs for the next billion
                    users.
                  </p>
                  <br />
                  <div style={{ display: "flex" }}>
                    <img
                      style={{ width: "40px", marginRight: "10px" }}
                      src="assets/about/mission.svg"
                      alt="taskmo mission"
                    />
                    <h3 class="font-montserrat fw-600 mt-10">Mission</h3>
                  </div>
                  <p style={{ paddingTop: "10px" }}>
                    To empower gig workers globally by facilitating ease of
                    access to gig jobs and revolutionize the gig economy
                    globally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="font-montserrat team-section-two mt-100 md-mt-70">
        <div class="container">
          <div class=" align-items-center justify-content-between mb-80">
            <div style={{ textAlign: "center" }} class="title-style-two">
              <h2 class="font-montserrat fs-50 fw-600">Our Leadership Team</h2>
            </div>
          </div>
          {/* <div class=" align-items-center justify-content-between mb-10">
            <div style={{ textAlign: "center" }}>
              <h2 class="font-montserrat fs-30 fw-600">Our Founders</h2>
            </div>
          </div>
          <div style={{ margin: "auto" }} class="row">
            <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Prashant Janadri.png"
                  alt="Prashant Janadri"
                />
                <div class="name">Prashant Janadri</div>
                <div class="position">Founder</div>
              </div>
            </div>
            <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Naveen Ramchandra.png"
                  alt="Naveen Ramchandra"
                />
                <div class="name">Naveen Ramchandra</div>
                <div class="position">Founder</div>
              </div>
            </div>
          </div> */}
          <div class=" align-items-center justify-content-between mt-50 mb-10">
            <div style={{ textAlign: "center" }}>
              <h2 class="font-montserrat fs-30 fw-600">Board of Directors</h2>
            </div>
          </div>
          <div style={{ width: "100%", margin: "auto" }} class="row">
            {/* <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Lohit-Bhatia.png"
                  alt="Lohit Bhatia"
                />
                <div class="name">Lohit Bhatia</div>
                <div class="position">President, Workforce Management</div>
              </div>
            </div> */}
            {/* <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img src="assets/founders image/arjun.png" alt="Arjun" />
                <div class="name">Arjun Ramaraju</div>
                <div class="position">CEO, Conneqt Business Solutions</div>
              </div>
            </div> */}
            <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img src="assets/founders image/Guruprasad-Srinivasan.png" alt="Guruprasad Srinivasan" />
                <div class="name">Guruprasad Srinivasan</div>
                <div class="position">Director</div>
              </div>
            </div>
            <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Vijay-Sivaram.png"
                  alt="Vijay Sivaram"
                  style={{ width: "342px", height: "342px" }}
                />
                <div class="name">Vijay Sivaram</div>
                <div class="position">Director</div>
              </div>
            </div>

            {/* <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img src="assets/founders image/Madhu-latest.png" alt="MadhuDamodaran" />
                <div class="name">Madhu Damodaran </div>
                <div class="position">Director</div>
              </div>
            </div> */}
          </div>

          {/* <div class=" align-items-center justify-content-between mt-50 mb-10">
            <div style={{ textAlign: "center" }}>
              <h2 class="font-montserrat fs-30 fw-600">Taskmo Mentors</h2>
            </div>
          </div> */}
          {/* <div style={{ width: "100%", margin: "auto" }} class="row"> */}
            {/* <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Guruprasad-Srinivasan.png"
                  alt="Guruprasad Srinivasan"
                />
                <div class="name">Guruprasad Srinivasan</div>
                <div class="position">Executive Director and Group CEO</div>
              </div>
            </div>
            <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Vijay-Sivaram.png"
                  alt="Vijay Sivaram"
                  style={{ width: "342px", height: "342px", marginLeft:"60px" }}
                />
                <div class="name">Vijay Sivaram</div>
                <div class="position">
                  CEO, Quess IT Staffing and Quess Search & Recruitment
                </div>
              </div>
            </div> */}
            {/* <div style={{ margin: "auto" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                  <div class="team-member" onclick="location.href='team-single.html';">
                      <img src="assets/founders image/Rupal Sinha.png" alt="Rupal Sinha" />
                      <div class="name">Rupal Sinha</div>
                      <div class="position">Taskmo Mentor</div>
                  </div>
              </div> */}
            {/* <div
              style={{ margin: "auto" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div
                class="team-member"
                onclick="location.href='team-single.html';"
              >
                <img
                  src="assets/founders image/Jolly-Jose.png"
                  alt="Jolly Jose"
                />
                <div class="name">Jolly Jose</div>
                <div class="position">Growth Mentor</div>
              </div>
            </div> */}
            {/* <div style={{ margin: "auto" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="team-member" onclick="location.href='team-single.html';">
                                <img src="assets/founders image/Nitin Gupta.png" alt="Nitin Gupta" />
                                <div class="name">Nitin Gupta</div>
                                <div class="position">Taskmo Mentor</div>
                            </div>
                        </div> */}
          {/* </div> */}
        </div>
      </div>
      <div class="fancy-portfolio-two mt-60 md-mb-40 lg-container">
        <div class="container">
          <div class=" pb-10 md-pb-20">
            {/* <div class=" align-items-center justify-content-between mb-80">
              <div style={{ textAlign: "center" }} class="title-style-two">
                <h2 class="font-montserrat fs-50 fw-600"> Life At Taskmo</h2>
              </div>
            </div> */}

            {/* <Slider {...settings}>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame1.png"
                      alt="Life At Taskmo-1"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame3.png"
                      alt="Life At Taskmo-2"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame8.png"
                      alt="Life At Taskm-3"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame4.png"
                      alt="Life At Taskmo-4"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame5.png"
                      alt="Life At Taskmo-5"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame6.png"
                      alt="Life At Taskmo-6"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame7.png"
                      alt="Life At Taskmo-7"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame2.png"
                      alt="Life At Taskmo-8"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame9.png"
                      alt="Life At Taskmo-9"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame10.png"
                      alt="Life At Taskmo-10"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame11.png"
                      alt="Life At Taskmo-11"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
              <div class="mixitUp-container gutter-space-one ">
                <div style={{ marginLeft: "30px", padding: "10px" }}>
                  <div class="portfolio-block-two position-relative">
                    <img
                      src="assets/about/Frame12.png"
                      alt="Life At Taskmo-12"
                      class=" w-100 h-100 tran4s img-meta"
                    />
                  </div>
                </div>
              </div>
            </Slider> */}
          </div>
        </div>
      </div>
    </>
  );
}
