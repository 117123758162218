import React from 'react'
import { styles } from "./PartnerOnboardingStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import { localRoute } from 'routes/localRoutes';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"


const useStyles = makeStyles(styles);

const PartnerOnboarding = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Partner Onboarding Services India || Taskmo
                </title>
                <meta name="description" content="Taskmo - Best Partner Onboarding Services India, guides you through the entire process from identifying potential partners to negotiation & closing deals" />

                <meta name="keywords" content="partner onboarding service, partner onboarding process, channel partner onboarding, b2b partner onboarding, partner onboarding best practices, onboarding new partners, doctor onboarding, teacher onboarding, driver onboarding, farmer onboarding, cab onboarding, bike onboarding" />

                <link rel="canonical" href="https://www.taskmo.com/services/partner-onboarding-services" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is a partner onboarding service?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo offers partner onboarding services to aid in the smooth integration of new partners into the business. This enables service providers to be accepted as partners, allowing your firm to acquire services for payment invoices. With the help of a seamless onboarding procedure, Taskmo's onboarding service ensures that partners have access to the tools and resources they need and are well-informed about the company's goods, services, and working methods."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the different partners you can onboard to your business?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The types of partners businesses can onboard through Taskmo may vary depending on the specific needs of the project or task. However, some common examples of partners businesses may onboard through Taskmo include auto, cab and drivers, teachers, doctors, delivery executives, vendors, and shops."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the steps followed in a partner onboarding service?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's proven partner onboarding strategy and experienced team efficiently cater to various business needs by seamlessly onboarding new partners. The seasoned partner onboarding team helps partners through benefits, registration, verification,  actionable tools, dedicated serviceability, and commission structure for partners."
                            }
                        }]
                    })}
                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    Partner Onboarding Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Partners are the lifeblood of your business; however, partner shortages combined with emerging competitors can make it increasingly difficult for them to choose you as a business partner. Additionally, your partners are frequently bombarded with possibilities from other businesses. As a result, Taskmo has developed the finest solution for your onboarding issues, which helps you stand out from your rivals by making new partners choose you over them.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Our well-defined onboarding process provides you to recover lost sales opportunities with enhanced partner loyalty and a diminished churn rate. Taskmo's seasoned team is committed to delivering frictionless and highly cutting-edge services that instil in your partner a sense of trust and collaboration, motivating them to get working for mutual success.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/6b1b728cb100e685698b1683013970966_Partner%20onboarding%20%281%29.png" alt='' style={{ width: "80%", margin: "auto" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Partner Onboarding Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a5a252e998daf78297b21682416894936_002.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Impactful onboarding process
                                    </p>
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        for reduced turnover rates
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/65426ed588103a62bf881682416939971_001.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Optimised customer support
                                    </p>
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        to retain high-performing partners
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/50b3cd457695e328b0161682416974158_004.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Customised onboarding for faster
                                    </p>
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        time-to-value and increased revenue
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a362a2340ba5a69077721682417002720_003.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Intuitive technology to
                                    </p>
                                    <p style={{ fontSize: "14px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        tackle onboarding challenges
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    <div class="fancy-feature-twentyOne mt-10 md-mt-80" id="template">
                        <div class="container">
                            <div class="font-montserrat title-style-eight  text-center md-mb-10" data-aos="fade-up" data-aos-duration="1200">
                                <h2 class="font-montserrat fs-50">Onboarding Partner</h2>
                                <p style={{ padding: "0px" }} >In addition to merchant onboarding, Taskmo also offers partner onboarding services. Our major partner onboarding services include </p>
                            </div>
                        </div>
                        <div class="lg-container">
                            <div class="container">
                                <div class="row justify-content-center">


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" style={{ display: "flex", justifyContent: "center" }} >
                                        <Link to={localRoute.autoOnboarding}>
                                            <div class="block-style-twentyOne" >
                                                <img src="../../../assets/services/merchant/Onboading/auto.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white "> Auto-Drivers Onboarding  </span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.cabOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/cab.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white "> Cab Drivers Onboarding </span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.bikeOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/bike onboarding.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white ">Bike Drivers Onboarding </span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.teacherOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/teacher.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white ">Teachers Onboarding</span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.doctorOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/doctor.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white ">Doctors Onboarding </span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>



                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.deliveryOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/delivery.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white "> Delivery Executives Onboarding</span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.vendorOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/vendor.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white "> Vendors Onboarding</span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>


                                    <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" style={{ display: "flex", justifyContent: "center" }}>
                                        <Link to={localRoute.shopOnboarding}>
                                            <div class="block-style-twentyOne">
                                                <img src="../../../assets/services/merchant/Onboading/shop.png" alt="" class="w-100" />
                                                <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                                    <span class="cl-white ">Shop Onboarding</span>
                                                </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8c9eb54a0134c23999511682417304238_card1.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Knowledge Enhancement For Partners
                                                    </h4>

                                                    <p>
                                                        We educate partners to become real experts in your services by explaining expectations, resolving uncertainties, addressing queries and encouraging the partnership opportunity and its advantages to become top performers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2e1e7ecc3338642a393d1682417344739_card2.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Mitigating Risks
                                                    </h4>
                                                    <p>
                                                        With our robust B2B partner onboarding process, data governance, compliance, and validation procedures ensure your quality requirements are met.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/18858b33689009ce2e381682417381556_card3.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Partner Activation
                                                    </h4>
                                                    <p>
                                                        End-to-end onboarding support enables the partners to get onboarded successfully following due diligence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/19fa1c745bf25225f9c81683110190955_headphones%20%281%29.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Optimised Customer Support
                                                    </h4>
                                                    <p>
                                                        Effective onboarding combined with optimised support nurtures a lasting relationship with your firm resulting in reduced partner churns.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "300px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        2mn+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Partners Successfully Onboarded
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "300px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        10,000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Covered Across India
                                    </p>
                                </div>

                                <div className={classes.whyTaskmoCards}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        50+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Companies Catered
                                    </p>
                                </div>

                                <div className={classes.whyTaskmoCards}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        95%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Productivity Delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* use cases start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#FFF0BE", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/9aec8daf5c977ed82d6a1682417706704_rapido-logo.png" alt="" style={{ width: "80%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, whiteSpace: "nowrap" }} >1 lakh+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Partners successfully onboarded across 200+ pin codes to scale business
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#EDD7FF", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/bd0c6d863f9f16dea74a1682417805845_Zepto.svg" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-20px" }} >89%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Productivity achieved by delivering over 3000+ orders resulting in maximum user acquisition drive with remarkable revenue benchmarks
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: "auto" }} >
                                    <Card style={{ background: "#FFFFA8", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "198px" }} data-aos="zoom-in-up">
                                        <CardContent >
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} style={{ marginTop: "30px" }} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8a720a03bf84a1e6f04a1682417873295_Ola-Cabs-Symbol.png" alt="" style={{ width: "90%", margin: "auto" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >92%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Productivity delivered by onboarding partners across 50+ cities to enable convenience for hundreds of millions of consumers
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    {/* use cases end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is a partner onboarding service?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Taskmo offers partner onboarding services to aid in the smooth integration of new partners into the business. This enables service providers to be accepted as partners, allowing your firm to acquire services for payment invoices. With the help of a seamless onboarding procedure, Taskmo's onboarding service ensures that partners have access to the tools and resources they need and are well-informed about the company's goods, services, and working methods.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the different partners you can onboard to your business?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                The types of partners businesses can onboard through Taskmo may vary depending on the specific needs of the project or task. However, some common examples of partners businesses may onboard through Taskmo include auto, cab and drivers, teachers, doctors, delivery executives, vendors, and shops.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the steps followed in a partner onboarding service?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Taskmo's proven partner onboarding strategy and experienced team efficiently cater to various business needs by seamlessly onboarding new partners. The seasoned partner onboarding team helps partners through benefits, registration, verification,  actionable tools, dedicated serviceability, and commission structure for partners.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default PartnerOnboarding