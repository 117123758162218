import { GET_ALLCASESTUDY, GET_ALLCASESTUDY_ERR, GET_CASESTUDY, GET_CASESTUDY_CATEGORIES, GET_CASESTUDY_CATEGORIES_ERR, GET_CASESTUDY_ERR } from '../../actionTypes/casestudyType'

export default (state, action) => {
  switch (action.type) {
    case GET_CASESTUDY:
      return {
        ...state,
        getCasestudy: action.payload, ...state.data,
      }

    case GET_CASESTUDY_ERR:
      return {
        ...state,
        getCasestudy: { getdata: action.payload }
      }

    case GET_ALLCASESTUDY:
      return {
        ...state,
        getAllCasestudy: action.payload, ...state.data,
      }

    case GET_ALLCASESTUDY_ERR:
      return {
        ...state,
        getAllCasestudy: { getdata: action.payload }
      }

    case GET_CASESTUDY_CATEGORIES:
      return {
        ...state,
        getCasestudyCategories: action.payload, ...state.data,
      }

    case GET_CASESTUDY_CATEGORIES_ERR:
      return {
        ...state,
        getCasestudyCategories: { getdata: action.payload }
      }

    default:
      return state;
  }
}