import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import validation from '../../validationBusiness';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { GlobalContext } from '../../../context/GlobalState';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from "react-helmet";

import { createSubscriber } from '../../../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    subscriber_campany_name: '',
    subscriber_message: ''
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );



export default function LastMileDelivery() {
    const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
    const [state, setState] = useState(init);
    const [number, setNumber] = useState([])
    const [store, setStore] = useState([]);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChangeNumberLeadInput = (event) => {
        console.log('event', event.target.value)
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        console.log('onlyNums', onlyNums)
        if (onlyNums.length <= 10) {
            setNumber(onlyNums)
            setState({
                ...state,
                [event.target.name]: onlyNums,
            });
        }
    }

    const handleClick = e => {
        e.preventDefault()
        setErrors(validation(state));
        if (
            state.subscriber_name !== '' &&
            state.subscriber_email !== '' &&
            !state.subscriber_email.match("gmail") &&
            validateEmail(state.subscriber_email) &&
            state.subscriber_phone !== '' &&
            state.subscriber_phone.length === 10 &&
            state.subscriber_campany_name !== '' &&
            state.subscriber_message !== ''
        ) {
            const postData = {
                subscriber_name: state.subscriber_name,
                subscriber_email: state.subscriber_email,
                subscriber_phone: state.subscriber_phone,
                subscriber_campany_name: state.subscriber_campany_name,
                subscriber_message: state.subscriber_message,
                subscriber_type: 'Last Mile Delivery',
                visitor_type: 'hire_tasker'
            };

            createSubscriber(postData)(subscriberDispatch)
            console.log('Create Subscriber Detail', postData)
            setState(init);
            setOpen(true);
        }
    }


    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {

            console.log("store", store);
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])


    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Last Mile Delivery | Logistics Service Provider India
                </title>
                <meta name="description" content="At Taskmo, the only goal for all our last-mile delivery services in India is to deliver the packages in a quick, easy and affordable manner." />
                <meta name="keywords" content="last mile delivery, logistics services, last mile operations, Transportation, supply chain" />
                <link rel="canonical" href="https://taskmo.com/services/last-mile-delivery" />
            </Helmet>

            <div class="pt-100 fancy-text-block-seven mt-60 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Last Mile Delivery
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Last Mile Delivery" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        In today’s urban life, every business focuses on creating good customer experiences by facilitating faster delivery of products and services. Last mile delivery services have become the need of the hour and Taskmo has stepped into providing last mile delivery services in cities across the country. Last mile delivery is also known as last mile logistics. It is the process in which goods are moved from the place of production to the place of final delivery. At Taskmo, the only goal for all our last-mile delivery and logistics services is to deliver the packages in a quick, easy and affordable manner.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="consultation-form">
                                    <h4 style={{ lineHeight: "1.3em" }} class="pb-20 font-montserrat fs-25 fw-600">Get in Touch With Our Experts!</h4>
                                    <form action="#">
                                        <div class="font-montserrat input-field">
                                            <Grid xs={12} >
                                                <TextField fullWidth label="Full Name*"
                                                    inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                                            </Grid>
                                            {errors.subscriber_name && <p style={{ color: 'red' }}>{errors.subscriber_name}</p>}
                                        </div>
                                        <div class="input-field">
                                            <Grid xs={12} >
                                            <TextField fullWidth label="Official Email*"
                                                    inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                                            </Grid>
                                            {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                                        </div>
                                        <div class="input-field">
                                            <Grid xs={12} >
                                                <TextField fullWidth label="Phone*"
                                                    inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                                            </Grid>
                                            {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}

                                        </div>
                                        <div class="input-field">
                                            <Grid xs={12} >
                                                <TextField fullWidth label="Company Name*"
                                                    inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="text" name="subscriber_campany_name" value={state.subscriber_campany_name} onChange={handleChange} />
                                            </Grid>
                                            {errors.subscriber_campany_name && <p style={{ color: 'red' }}>{errors.subscriber_campany_name}</p>}
                                        </div>
                                        <div class="input-field">
                                            <Grid xs={12} >
                                                <TextField fullWidth label="Describe your requirement*"
                                                    inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="text" name="subscriber_message" value={state.subscriber_message} onChange={handleChange} />
                                            </Grid>
                                            {errors.subscriber_message && <p style={{ color: 'red' }}>{errors.subscriber_message}</p>}
                                        </div>
                                        <div class="input-field">
                                            <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/Lastmile delivery/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Experienced and Trusted Taskers
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/Lastmile delivery/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A wide range of delivery services.
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/Lastmile delivery/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Real Time Tracking and Monitoring
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/Lastmile delivery/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    KPI-Driven Reports and Analysis
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="feedback" class="pricing-section-two mt-60 mb-60">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>

                                                <div class="bill-cycle"> Cities</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">75000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Taskers</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <OurPartners />


            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial />

            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the last mile delivery services offered at Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo offers a wide range of last mile delivery services which include the following:
                                                <li>Standard Delivery</li>
                                                <li>Same Day Delivery </li>
                                                <li>Scheduled Delivery and so much more. </li>
                                                Get in touch with our experts and find out more about last mile delivery services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Does Taskmo provide last mile delivery services all over India?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! Taskmo has access to over 1000+ pin codes and served over 500+ clients across the country. Taskmo has been continuously adding more cities and more pincodes to our existing widespread network.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Does Taskmo also work on short-term delivery projects?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Absolutely! At Taskmo, we ensure that all our giggers perform the given task for the specified timeframe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


