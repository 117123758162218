import React, { useEffect, useState, useContext, } from "react";
import { getBlog } from '../../../context/actions/blogAction/getBlog'
import { GlobalContext } from '../../../context/GlobalState'
import parse from 'html-react-parser'
import './blogDetail.css'
import { useParams, useHistory } from 'react-router-dom';
import { localRoute } from '../../../routes/localRoutes';
import { getBlogs } from "../../../context/actions/blogAction/getBlogs";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Helmet } from "react-helmet";

export default function BlogDetails1() {
    const { blogState, blogDispatch } = useContext(GlobalContext);
    const [blogDetail, setBlogDetail] = useState({})
    const [filterBlogDetails, setFilterBlogDetails] = React.useState([]);
    const [tabFilter, setTabFilter] = React.useState('active');

    const history = useHistory()
    const { blog_id } = useParams()


    let pageTitlePath = history.location && history.location.pathname

    let newpath = "https://taskmo.com" + pageTitlePath

    console.log("newpath", newpath)



    useEffect(() => {
        window.scrollTo(0, 0)
        getBlog(blog_id)(blogDispatch)
    }, [blog_id])

    useEffect(() => {
        let status = blogState && blogState.getBlog.status
        console.log('blogCategoriesStatezz', blogState)
        if (status == true) {
            setBlogDetail(blogState.getBlog.data)
        }
    }, [blogState])


    useEffect(() => {
        let filter = tabFilter
        getBlogs({ filter, page: "all" })(blogDispatch)
    }, [])

    useEffect(() => {
        let status = blogState.getBlogs.status
        if (status === true) {
            setFilterBlogDetails(blogState.getBlogs.data.res)
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", blogState.getBlogs)
        }
        // SERVER VALIDATION ERROR RESPONSE  
        if (status === 401) {
            console.log("Validation Error:", blogState.getBlogs)
        }
    }, [blogState])

    console.log("filterBlogDetails", filterBlogDetails);


    const OpenBlog = (item) => {
        history.push(`${localRoute.blogDetails1}/${item.blog_slug}`)
    }

    console.log('blogDetail', blogDetail)
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {blogDetail.blog_title && parse(blogDetail.blog_title)}
                </title>
                <meta name="description" content={blogDetail.meta_description} />
                <meta name="keywords" content={blogDetail.blog_meta_tag} />
                <link rel="canonical" href={newpath} />
            </Helmet>
            <div
                class="font-montserrat page-title-area container"
                style={{ display: "flex", justifyContent: "space-between", padding: "0px 0px 0px", marginTop: "0px" }}
            >
                <ul style={{ width: "245px", marginTop: "0px" }} class="breadcrumb-nav">
                    <li style={{ paddingRight: "10px" }}><a style={{ cursor: "pointer" }} onClick={() => history.push(`${localRoute.blog}`)}>Blog</a></li>
                    <li><ChevronRightIcon /> Blog Details</li>
                </ul>
                <ul class="breadcrumb-nav" style={{ marginTop: "0px" }} >
                    <li style={{ cursor: "pointer" }} onClick={() => history.push(`${localRoute.blog}`)}><KeyboardBackspaceIcon />Back</li>
                </ul>
            </div>
            <hr style={{ width: "80%", margin: "10px auto" }} />
            {/* <!--====== Page Title Area End ======--> */}

            {/* <!--====== Blog Area Started ======--> */}
            <section style={{ paddingTop: "30px" }} class="blog-area section-gap">
                <div class="font-montserrat container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="single-blog-post blog-details-content pr-xl-5">
                                <div class="post-thumbnail">
                                    <img src={blogDetail.blog_image} alt={blogDetail.blog_title} />
                                </div>
                                <br />
                                <div class="post-content">
                                    <h1 class="post-title">
                                        {blogDetail.blog_title && parse(blogDetail.blog_title)}
                                        <a class="font-montserrat fw-600">
                                        </a>
                                    </h1>
                                    <div style={{ listStyleType: "initial" }}>
                                        {blogDetail.blog_description && parse(blogDetail.blog_description)}
                                    </div>
                                    <blockquote class="font-montserrat fw-600">
                                        <p>Shoot an email to us at <span><u><a href="mailto:business@taskmo.com">business@taskmo.com</a></u></span> and book a 30-min call with our experts today! </p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-sidebar-one">
                                <div class="sidebar-recent-news mb-85">
                                    <h4 class="sidebar-title font-montserrat fw-600">Recent Blogs</h4>
                                    <ul>
                                        <li>
                                            {filterBlogDetails.slice(0, 5).map(item =>
                                                <a style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.blog_slug}>
                                                    <img src={item.blog_image} alt={item.blog_title} class="image-meta" />
                                                    <span class="font-montserrat title" style={{ display: "flex" }}>
                                                        {item.blog_title}
                                                    </span>
                                                </a>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Blog Area End ======--> */}
        </div>
    )
}
