import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function ProductSampling() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Product Sampling Services in India
                </title>
                <meta name="description" content="We offer best product sampling services for all firms in and across India. We conduct physical & online sample surverys which helps to increase brand awareness." />
                <meta name="keywords" content="product sampling, free sampling, sample tester, sampling, samplers" />
                <link rel="canonical" href="https://taskmo.com/services/product-sampling" />
            </Helmet>

            <div class="fancy-text-block-seven mt-20 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Product Sampling
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Product Sampling" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        At Taskmo, we believe that product sampling plays an important role in driving a customer's purchase decision. Product sampling is an activity conducted solely to increase brand awareness and drive sales. Over the last three years, Taskmo has helped popular brands to reach over a million customers. <br /><br />
                                        Taskmo has worked with products across all categories and reached customers across all age groups in the country. We frame a creative product sampling process and can help you sample almost everything. At Taskmo, we understand your brand and customers and help to manage an effective campaign that will help to provide you the desired results. The team at Taskmo, will work seamlessly with samplers who will be provided with ample training to understand the brief and successfully execute the given campaign.
                                    </p>
                                    <br />

                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Product Sampling" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/Product sampling/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Diverse Sampling Techniques
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" s src="../../../assets/services/Product sampling/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Unique and Customised Sampling Processes
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/Product sampling/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Efficient and Experienced Product Promoters
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/Product sampling/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Industry Bound Sampling Services
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                <div class="container">
                    <div class="text-center">
                        <h2 class=" font-montserrat fs-50 fw-600">Our Services</h2>
                    </div>
                </div>
                <div class="bg-wrapper mt-100 md-mt-80">
                    <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center"><img src="../../../images/shape/200.svg" alt="" /></a>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="35" src="../../../images/icon/124.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                Direct Sampling
                                            </h4>
                                            <p>
                                                Under this service, our samplers make direct contact with the target group and help reach out your product to them.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4F86" }}><img width="35" src="../../../images/icon/125.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                Indirect Sampling
                                            </h4>
                                            <p>
                                                Under this service, we ensure that your product reaches the target group without any physical interaction involved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="35" src="../../../images/icon/126.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                Event Based Product Sampling
                                            </h4>
                                            <p>
                                                With event-based sampling, you can reach to your target audience and even gain potential customers and increase brand awareness.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="35" src="../../../images/icon/127.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                In-Mail Sampling Services
                                            </h4>
                                            <p>
                                                Under this service, we help you to reach distant audiences through mails and ensure that they garner conversions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "orchid" }}><img width="35" src="../../../images/icon/128.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                Segmented/Targeted Sampling
                                            </h4>
                                            <p>
                                                With this service, we reach out to specific segments in your audience category through multiple innovative techniques.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "mediumpurple" }}><img width="35" src="../../../images/icon/129.svg" alt="" /></div>
                                        <div class="text">
                                            <h4>
                                                Custom Product Sampling
                                            </h4>
                                            <p>
                                                Under this service, we completely customise your product sampling method and ensure your objectives are fulfilled.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <OurPartners />



            <div id="feedback" class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">10</span>
                                                    <span>+</span>
                                                </div>

                                                <div class="bill-cycle">Industries </div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">150</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Product Sampling Campaigns   </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Testimonial />


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                Does Taskmo work with all brands?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! Taskmo is highly inclusive we work with brands across all industries and sizes and help you to reach your target audience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Will Taskmo help my firm run campaigns on digital platforms?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! Taskmo will assist you in running successful  sampling campaigns on every digital channel at an additional cost.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


