import React from 'react'
export default function Login() {
    return (
        <>
            <div class="user-data-page clearfix d-lg-flex">
                <div class="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
                    <h3 class="font-rubik">Want your best managment <br />software? <a href="sign-up.html">sign up</a></h3>
                    <div class="illustration-holder">
                        <img src="images/assets/ils_08.svg" alt="" class="illustration" />
                        <img src="images/assets/ils_08.1.svg" alt="" class="shapes shape-one" />
                        <img src="images/assets/ils_08.2.svg" alt="" class="shapes shape-two" />
                    </div>
                </div>

                <div class="form-wrapper">
                    <div class="d-flex justify-content-between">
                        <div class="logo"><a href="index.html"><img src="images/logo/deski_01.svg" alt="" /></a></div>
                        <a href="index.html" class="font-rubik go-back-button">Go to home</a>
                    </div>
                    <form action="#" class="user-data-form mt-80 md-mt-40">
                        <h2>Hi buddy, welcome <br /> Back!</h2>
                        <p class="header-info pt-30 pb-50">Still don't have an account? <a href="sign-up.html">Sign up</a></p>

                        <div class="row">
                            <div class="col-12">
                                <div class="input-group-meta mb-80 sm-mb-70">
                                    <label>Email</label>
                                    <input type="email" placeholder="bolcovfed@ce.edu" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="input-group-meta mb-15">
                                    <label>Password</label>
                                    <input type="password" placeholder="Enter Password" class="pass_log_id" />
                                    <span class="placeholder_icon"><span class="passVicon"><img src="images/icon/view.svg" alt="" /></span></span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="agreement-checkbox d-flex justify-content-between align-items-center">
                                    <div>
                                        <input type="checkbox" id="remember" />
                                        <label for="remember">Keep me logged in</label>
                                    </div>
                                    <a href="#">Forget Password?</a>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="theme-btn-one mt-50 mb-50">Login</button>
                            </div>
                            <div class="col-12">
                                <p class="text-center font-rubik copyright-text">© Copyright 2021 deski</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}