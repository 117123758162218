import React from 'react'
import { styles } from "./SellerOnboardingStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"


const useStyles = makeStyles(styles);

const SellerOnboarding = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best E commerce Seller Onboarding Service India || Taskmo
                </title>
                <meta name="description" content="Taskmo - Best E Commerce Seller Onboarding Service India helps in onboarding new sellers for your platform. We help in registering, cataloging & support." />

                <meta name="keywords" content="seller onboarding, e commerce, seller onboarding solution, flipkart seller onboarding, seller onboarding service, meesho seller onboarding, refine seller onboarding, seller onboarding service provider, Onboard new sellers, E-commerce seller onboarding, supplier onboarding, onboarding service india" />

                <link rel="canonical" href="https://taskmo.com/services/seller-onboarding-service" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is seller onboarding?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Seller onboarding is the process of onboarding a seller or merchant onto a particular platform or marketplace, most commonly an online or e-commerce platform. This procedure often consists of multiple phases, such as registering as a seller, validating personal and financial information, creating a seller account, listing items, and learning how to utilise the platform's tools and services."
                            }
                        }, {
                            "@type": "Question",
                            "name": "How can businesses get benefitted from seller onboarding?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Businesses can benefit from seller onboarding in several ways: Helps businesses improve their total revenues by expanding and diversifying their product offerings, bringing in new consumers, expanding their product variety, and bringing more suppliers to their online shop or marketplace. In an effective seller onboarding process, businesses ensure that sellers are carefully vetted and verified before selling on the platform, reducing the risk of fraud and counterfeit products and improving the quality of products and services. When new sellers onboard a platform, they are often mentored on using the platform's tools and services efficiently, optimising product listings, and handling orders, payments, and customer care. A positive onboarding process may encourage relationships with sellers, resulting in greater loyalty and long- term collaborations, thereby increasing seller retention rates and reducing churn rates."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the steps involved in seller onboarding?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The procedures for onboarding a seller may differ based on the particular e-commerce platform or marketplace, but they often include the following: Taskmo experts help the sellers to register on the platform with basic information such as a name, an email address, and a phone number. Following registration, seasoned professionals verify registered sellers through government- issued identification, bank account information, and tax ID. After authentication, the expert creates the seller account on the platform by building a profile, mentoring them in deciding how they want to be paid and how they want their packages delivered and customising other account settings. Next, the onboarding specialists assist in listing the seller's products on the platform with product information, pricing, images, and other relevant details. An effective seller onboarding process offers new sellers the necessary help and direction to ensure a great experience and long- term success on the platform"
                            }
                        }, {
                            "@type": "Question",
                            "name": "How to onboard new sellers for my business?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's proven seller onboarding strategy and experienced team efficiently cater to e-commerce business needs by seamlessly onboarding new sellers on the online portals. The seasoned seller-onboarding team helps sellers through registration, verification,  cataloguing, packaging, return policies and dedicated serviceability and commission structure for sellers."
                            }
                        }]
                    })}

                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} > E-commerce Seller Onboarding Services</h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Seller churn has the potential to weaken any e-commerce marketplace's market presence, resulting in revenue loss and poor corporate reputation. Hence, successful seller onboarding is essential for e-commerce success. The best-selling onboarding services from Taskmo improve the seller experience by guaranteeing that the seller continues to operate, develop, and expand business for the e-commerce companies, thereby reducing seller churn rate.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Taskmo's successful method of onboarding merchants creates long-term connections with sellers by providing account management services, lifecycle seller support, timely, quality help, and speedy resolution. Our team is dedicated to providing effective, well-organized and highly advanced services that enable your sellers to save time, optimise operations, and expand their businesses.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d9b18557f32c3356310d1682682238540_seller%20onboarding%20%282%29.png" alt='' style={{ width: "100%" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Seller Onboarding Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src={storeFront} alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Dedicated support for
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        optimised seller satisfaction
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src={ethics} alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Personalised solutions
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        to meet business needs
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src={humanResources} alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Streamlined  process
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        saving time & money
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/7dc3ec29dedd378d326b1683023519255_headphones.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        In-depth expertise in
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        onboarding sellers
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText} >
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                    <img src={introducingSeller} alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Introducing Sellers To Online Platforms
                                                    </h4>

                                                    <p> We educate sellers on the advantages of the platform or marketplace, competitor analysis, expenses associated with applicable fees, shifting sales tax regulations, etc., as well as logistical choices and fulfilment for seller success.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4F86" }}>
                                                    <img src={sellerRegistration} alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Seller Registration & Onboarding
                                                    </h4>
                                                    <p>
                                                        We onboard sellers by creating seller accounts utilising the given information after due diligence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                    <img src={elearning} alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>User-friendly & Appealing Cataloguing</h4>
                                                    <p>
                                                        We create product pages that follow the marketplaces' norms by assembling information about the items, uploading high-quality images, and writing brief, informative, and convincing content.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                    <img src={customerSatisfaction} alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Superior Seller Satisfaction
                                                    </h4>
                                                    <p>
                                                        We assure sellers' success on e-commerce platforms and marketplaces by providing ongoing healthy seller support during their association with the platform, increasing their satisfaction and thus boosting revenue.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "220px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        600+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Verified Sellers Onboarded
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        5000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        20+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Platforms Catered
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        96%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        productivity delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* Success Stories start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#fffae6", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.amazonaws.com/67bbcbce26813e6ef8051682060579394_Flipkart-taskmo.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >6000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Sellers onboarded across 6000+ pin codes for Flipkart, thereby increasing seller reach.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#ffe9f4", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/315e8a12fc80dcc76f8e1682061200888_634bd1950007a1468123fe05_logo%20meesho%20.webp" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >15000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Sellers successfully onboarded across 250+ pin codes resulting in increased brand awareness and app downloads.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#fff1db", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "198px" }} data-aos="zoom-in-up">
                                        <CardContent >
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} style={{ marginTop: "30px" }} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5ed2cd328635df32acd11682322510335_Amazon_logo.svg" alt="" style={{ width: "90%", margin: "auto" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >75000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Sellers onboarded across 12+ cities to increase seller reach.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#e7f2fd", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "190px", marginTop: "5px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d809804b4e39d52e0ff91682061578039_jiomart-taskmo.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-10px" }} >60,000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Sellers successfully onboarded across 8+ cities to increase overall sales.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    {/* Success Stories end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is seller onboarding?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Seller onboarding is the process of onboarding a seller or merchant onto a particular platform or marketplace, most commonly an online or e-commerce platform. This procedure often consists of multiple phases, such as registering as a seller, validating personal and financial information, creating a seller account, listing items, and learning how to utilise the platform's tools and services.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                How can businesses get benefitted from seller onboarding?</h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Businesses can benefit from seller onboarding in several ways:
                                            </p>

                                            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Helps businesses improve their total revenues by expanding and diversifying their product offerings, bringing in new consumers, expanding their product variety, and bringing more suppliers to their online shop or marketplace.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        In an effective seller onboarding process, businesses ensure that sellers are carefully vetted and verified before selling on the platform, reducing the risk of fraud and counterfeit products and improving the quality of products and services.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        When new sellers onboard a platform, they are often mentored on using the platform's tools and services efficiently, optimising product listings, and handling orders, payments, and customer care.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        A positive onboarding process may encourage relationships with sellers, resulting in greater loyalty and long-term collaborations, thereby increasing seller retention rates and reducing churn rates.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the steps involved in seller onboarding?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                The procedures for onboarding a seller may differ based on the particular e-commerce platform or marketplace, but they often include the following:
                                            </p>

                                            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Taskmo experts help the sellers to register on the platform with basic information such as a name, an email address, and a phone number.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Following registration, seasoned professionals verify registered sellers through government-issued identification, bank account information, and tax ID.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        After authentication, the expert creates the seller account on the platform by building a profile, mentoring them in deciding how they want to be paid and how they want their packages delivered and customising other account settings.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Next, the onboarding specialists assist in listing the seller's products on the platform with product information, pricing, images, and other relevant details.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px" }} >
                                                <p>
                                                    An effective seller onboarding process offers new sellers the necessary help and direction to ensure a great experience and long-term success on the platform.
                                                </p>
                                            </div>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >04</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                How to onboard new sellers for my business?
                                            </h2>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Taskmo's proven seller onboarding strategy and experienced team efficiently cater to e-commerce business needs by seamlessly onboarding new sellers on the online portals. The seasoned seller-onboarding team helps sellers through registration, verification,  cataloguing, packaging, return policies and dedicated serviceability and commission structure for sellers.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default SellerOnboarding