import { GET_CASESTUDY, GET_CASESTUDY_ERR } from '../../actionTypes/casestudyType'
import { getCasestudyApi} from 'api/Api';
import axios from "axios";
export const getCasestudy = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getCasestudyApi}/${payload}`)
        console.log("GET CASESTUDY", results)
        let data = results && results.data
        dispatch({
            type: GET_CASESTUDY,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_CASESTUDY_ERR,
            payload: error && error.response
        });
    }
}
