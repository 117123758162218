import React from 'react'
import { styles } from "./SellingBusinessLoansStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"

const useStyles = makeStyles(styles);

const SellingBusinessLoans = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Selling Business Loans | Direct Selling Agents in India || Taskmo
                </title>
                <meta name="description" content="Taskmo: Selling business loans & direct selling agents help in finding the right buyers, closing loan deals, business loan services, credit limit assessment." />

                <meta name="keywords" content="selling business loans, Business loan services, Business Loan Agent, Business Loan Gig Agent, direct selling agents, DSA, top direct selling agency, direct sales agent" />

                <link rel="canonical" href="https://taskmo.com/services/selling-business-loans" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "How to sell my business loans to customers?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Selling business loans to customers requires a strategic approach to connect with potential customers and convince them of the benefits of your loans. Hence Taskmo's business loans services effectively sell your business loans by identifying your target audience, understanding your customer’s needs, highlighting the benefits of your loans, provide excellent customer service regarding customer inquiries and concerns to expand your reach and attract new customers."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What is selling business loan services?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Selling business loan services involves offering financing options to Small and medium-sized enterprises or SMEs that need capital to start or expand their operations. Business loan services can be offered by banks, credit unions, online lenders, and other financial institutions. Taskmo's process of selling business loan services typically involves identifying potential customers, understanding their financial needs and goals, and presenting loan options that align with those needs and goals."
                            }
                        }]
                    })}
                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    Selling Business Loans Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px", fontFamily: "Montserrat"
                                    }}
                                >
                                    The high cost of borrowing combined with increasing competition in the lending market necessitates lenders to be agile in their practices. But, modern MSMEs borrowers are growing more digitally aware and have higher expectations for credit facilities. Hence, Taskmo's more customer-friendly method of providing business loan services along with technical integrations offers every financial lending organisation a competitive advantage.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px", fontFamily: "Montserrat"
                                    }}
                                >
                                    Taskmo provides MSMEs with a range of loan solutions, including loans for working capital, machinery, growth, and other small company finance options. Our team of professionals consisting of direct selling agents specialises in assisting company owners by offering them the finest loans per their demands to help them navigate the often complex areas of small business financing.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5579de2a1fd29099ece61682683704712_Business%20Loan%20%281%29%201.png" alt='' style={{ width: "100%" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Selling Business Loan Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/93e4eaf94986e1bf77891682510821064_1.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        In-depth expertise in
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        financing options
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/7e777b167994fe762b1f1682510849239_2.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        Adds value to all the clients
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        by offering superior support
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e6eab7fd6bdc4b9dd3871682510872809_3.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        Provides a detailed analysis
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        of the target borrowers
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/30dc8b429c513589235d1682510898276_4.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        Quick and streamlined process
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", fontFamily: "Montserrat" }} >
                                        for quick disbursals of loans
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} style={{ fontFamily: "Montserrat" }} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/3520262d5a69b2f3a0521682512239836_one.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Defining Target Borrowers
                                                    </h4>

                                                    <p style={{ fontFamily: "Montserrat" }} >To get things moving more quickly, we start by identifying your target market, the people who are most likely to apply for a loan right away.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/afbe4f9ff45e7081bd981682512404596_twi.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Educate Merchants
                                                    </h4>
                                                    <p style={{ fontFamily: "Montserrat" }} >
                                                        We educate the borrower on the need for the loans combined with loan advantages as well as respond to the queries and doubts thereby creating a roadmap for success.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/7e9a1f908d1b621a05301682512471154_three.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Credit Limit Assessment
                                                    </h4>
                                                    <p style={{ fontFamily: "Montserrat" }} >
                                                        We also assist in easy credit limit assessment for a quick and hassle-free process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/fde2cb7b607893a6ed931682512528721_four.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Quick KYC Completion
                                                    </h4>
                                                    <p style={{ fontFamily: "Montserrat" }} >
                                                        We enable merchants with updated KYC documents for a quick and easy loan disbursal process to prevent financial fraud and money laundering.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        25k+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        Business Loans Approved
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        4500+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        10+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        Happy Brands Catered
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        96%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        Productivity Delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* Success Stories start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#ffeee0", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/3f8bc739b6fca3d264011682573952933_cashe-logo-1-1.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >2000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400, fontFamily: "Montserrat" }} >
                                                        Loans disbursed successfully across 10+ cities in India
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#d1f0ff", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/32e9acead8f689b07dac1682574057987_image.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >1000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400, fontFamily: "Montserrat" }} >
                                                        Loans disbursed successfully across 15+ cities in India
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                        </div>
                    </div>

                    {/* Success Stories end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p style={{ fontFamily: "Montserrat" }} class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141", fontFamily: "Montserrat" }} >
                                                01
                                            </p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                How to sell my business loans to customers?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p style={{ fontFamily: "Montserrat" }} >
                                            Selling business loans to customers requires a strategic approach to connect with potential customers and convince them of the benefits of your loans. Hence Taskmo's business loans services effectively sell your business loans by identifying your target audience, understanding your customer’s needs, highlighting the benefits of your loans, provide excellent customer service regarding customer inquiries and concerns to expand your reach and attract new customers.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141", fontFamily: "Montserrat" }} >
                                                02
                                            </p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is selling business loan services?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p style={{ fontFamily: "Montserrat" }} >
                                                Selling business loan services involves offering financing options to Small and medium-sized enterprises or SMEs that need capital to start or expand their operations. Business loan services can be offered by banks, credit unions, online lenders, and other financial institutions. Taskmo's process of selling business loan services typically involves identifying potential customers, understanding their financial needs and goals, and presenting loan options that align with those needs and goals.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default SellingBusinessLoans