import React, { createContext, useReducer } from 'react';
import SubscriberReducer from './reducers/subscriberReducer';
import SubscriberInitialState from "./intialstates/subscriberState";
import BlogReducer from './reducers/blogReducer';
import BlogInitialState from "./intialstates/blogState";
import careerReducer from './reducers/careerReducer';
import careerInitialState from "./intialstates/careerState";
import newsReducer from './reducers/newsReducer';
import newsInitialState from './intialstates/newsState';
import casestudyInitialState from './intialstates/casestudyState';
import casestudyReducer from './reducers/casestudyReducer';
// Initial State
const initialState = {
  users: [],
}
// Create Context
export const GlobalContext = createContext(initialState);
// Provider Component
export const GlobalProvider = ({ children }) => {
  const [subscriberState, subscriberDispatch] = useReducer(SubscriberReducer, SubscriberInitialState);
  const [blogState, blogDispatch] = useReducer(BlogReducer, BlogInitialState);
  const [jobState, jobDispatch] = useReducer(careerReducer, careerInitialState);
  const [newsState, newsDispatch] = useReducer(newsReducer, newsInitialState);
  const [casestudyState,casestudyDispatch] = useReducer(casestudyReducer,casestudyInitialState)

  let provides = {
    subscriberState,
    subscriberDispatch,
    blogState,
    blogDispatch,
    jobState,
    jobDispatch,
    newsState,
    newsDispatch,
    casestudyState,
    casestudyDispatch,
  }

  return (
    <GlobalContext.Provider value={provides}>
      {children}
    </GlobalContext.Provider>
  )
}