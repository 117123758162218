import React from 'react'
import { styles } from "./SellingSubscriptionsStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"

const useStyles = makeStyles(styles);

const SellingSubscriptions = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo: Best platform to sell subscription | Best financial advisor
                </title>
                <meta name="description" content="Taskmo: best platform to sell subscription & best financial advisor help in selling your debit & credit cards, selling subscriptions, insurance products, bnpl" />

                <meta name="keywords" content="best financial advisor india, financial consultant, financial advisory services, best financial advisors near me, financial advisor companies, financial advisory firms, Best platform to sell subscriptio, subscription management, Subscription sales, Subscription marketing, selling subscriptions, subscription selling platform, Sell subscriptions, sell your products or services" />

                <link rel="canonical" href="https://www.taskmo.com/services/sell-subscription" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What are the steps taken in successfully selling my business service to the customers?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's effective selling business service such as subscriptions and debit/ credit cards to customers requires a strategic approach that involves several key steps including identifying your target audience, defining your value proposition, creating a sales pitch by explaining the benefits, forging a connection with the customer, provide demonstrations if necessary and address objections or concerns to close the deal."
                            }
                        }, {
                            "@type": "Question",
                            "name": "How Taskmo can help you with selling services to customers?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's team of experts with years of in-depth expertise in these services provided value to the customer by offering a quick and hassle-free service. The professional enables the customers to make informed decisions by highlighting the benefits and advantages of the service as well as with proper responses to their concerns and queries"
                            }
                        }]
                    })}
                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    Selling Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    <b>Subscription Selling Service:</b> The art of selling subscription services has evolved significantly in the recent past with increased awareness of customers about the modern subscription economy. As competition in the subscription commerce tightens, Taskmo's subscription-selling service offers convenience to your customers while growing your recurring revenue. Our team of experts specialises in conducting successful subscription-selling services by showcasing value at every stage of the selling process.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    <b>Selling Debit/Credit Cards:</b> The credit/debit card selling service economy is facing intense competition with the emergence of many payment options and shifting consumer preferences. Hence, Taskmo's financial agents with years of in-depth expertise in financial products provided value to the customer by offering a quick and hassle-free service. The professional enables the customers to make informed decisions by highlighting the benefits and advantages of the service as well as with proper responses to their concerns and queries.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/3bea23dc5ec974d2620d1682601041273_selling%20subscription%201%20%281%29.png" alt='' style={{ width: "100%" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Your Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/394c988e19d9c69ab0ef1682513808140_1.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Expand
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        customer base
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8d03a29c63cb2dcc34af1682513846818_2.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Provide hassle-free
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        experience to customers
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f1a0a0f2277791c6f65a1682513865317_3.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Deliver
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        convincing pitch
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/9372b27e3e5fcc2169891682513890490_4.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Drive
                                    </p>
                                    <p style={{ fontSize: "18px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        recurring revenue
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}


                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8a481f702791e7293ee41682513929577_one.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Identify Target Audience
                                                    </h4>

                                                    <p> Identify the traits of the clients who would most likely profit from your services. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/16856d0da445b05c27d11682513946010_two.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Define Value Proposition
                                                    </h4>
                                                    <p>
                                                        Explain how your service offers customers a unique value, along with benefits and outcomes.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/b755855afe8d9e7bd0a31682513968835_three.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Deliver Effective Sales Pitch
                                                    </h4>
                                                    <p>
                                                        Ensures our sales pitch communicates your value proposition clearly and resonates with the target audience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5f2e389d951b2a638a351682513995732_four.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Address Concerns
                                                    </h4>
                                                    <p>
                                                        Prepare to address objections and concerns that potential customers may have about your service to support its effectiveness.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        5000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Subscription Sold Successfully
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        10k+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Happy Card Buyers
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        5000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "250px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                    minHeight: "190px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        15+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Happy Brands
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* Success Stories start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#ffe4e8", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/fdb6f1aebf2c565dab8b1682514219130_vyaapar.svg" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >10000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Subscribers onboarded successfully across pan India to increase recurring revenue
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#fff2c6", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f9388f9888a8fff99efa1683109455506_Layer%200.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >1000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Subscribers onboarded successfully pan India for business growth
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#e1f3ff", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "198px" }} data-aos="zoom-in-up">
                                        <CardContent >
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} style={{ marginTop: "30px" }} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a2f39d5171870e6be7911682514283599_image%20%281%29.png" alt="" style={{ width: "90%", margin: "auto" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >100+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Successful cards disbursement across 5000+ pin codes to increase brand awareness
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#ebfffc", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "190px", marginTop: "5px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e3611fd39a88491a6fbc1682514427067_image%20%282%29.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-10px" }} > 5000+ </p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Successful cards disbursement with account creation across 5000+ pin codes for increased revenue
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                        </div>
                    </div>

                    {/* Success Stories end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the steps taken in successfully selling my business service to the customers?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Taskmo's effective selling business service such as subscriptions and debit/ credit cards to customers requires a strategic approach that involves several key steps including identifying your target audience, defining your value proposition, creating a sales pitch by explaining the benefits, forging a connection with the customer, provide demonstrations if necessary and address objections or concerns to close the deal.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                How Taskmo can help you with selling services to customers?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Taskmo's team of experts with years of in-depth expertise in these services provided value to the customer by offering a quick and hassle-free service. The professional enables the customers to make informed decisions by highlighting the benefits and advantages of the service as well as with proper responses to their concerns and queries.
                                            </p>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default SellingSubscriptions