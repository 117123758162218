import React from 'react'
import bannerBg from "../../../assets/images/banner-bg.png";
import mainImg from "../../../assets/images/mainImg.png";
import mainImgOld from "../../../assets/images/mainImgOld.png";
import Button from '@material-ui/core/Button';

export default function StartArea() {

    const handleBackClass = () => {
        window.location.replace("/#hire-taskers");
    }
    return (
        <div style={{ width: "100%", backgroundImage: `url(${bannerBg})` }} >
            {/* <div style={{ width: "100%" }} > */}
            <div class="container" >
                <div class="row" style={{ marginBottom: "0px", paddingTop: "0px" }}>
                    <div class="col-xl-11 col-lg-11 col-md-10 m-auto" style={{ textAlign: "center" }} >

                        {/* <h1 class="hero-heading mt-50 font-montserrat fw-600" style={{ color: "#6A3996", fontSize: "26px" }} >
                            India’s Largest Distributed Network of Skilled Gig Workers Community</h1> */}

                        <h1 class="hero-heading mt-50 font-montserrat fw-600" style={{ color: "#fff" }} >
                            India’s Largest Distributed Network of Gig Workers Community</h1>
                    </div>
                    <div style={{ textAlign: "center" }} class="col-xl-10 col-lg-9 m-auto">
                        {/* <p class="mt-20 mb-40 font-montserrat fs-18 fw-500" style={{ color: "#636363" }} >
                            We offer digital and hyperlocal solutions for financial products, e-commerce, q-commerce, food tech, grocery, mobility, rental, pharmacy, retail, and more through our network of gig-workers.</p> */}

                        <p class="mt-20 mb-40 font-montserrat fs-18 fw-500" style={{ color: "#fff" }} >We offer digital and hyperlocal solutions for financial products, e-commerce, q-commerce, food tech, grocery, mobility, rental, pharmacy, retail, and more through our network of gig-workers.</p>

                        {/* <Button
                            size='large'
                            variant="contained"
                            onClick={handleBackClass}
                            style={{
                                background: "#6A3996",
                                color: "#fff",
                                fontWeight: 600,
                                fontFamily: "montserrat",
                                fontSize: "16px",
                                borderRadius: "10px",
                                // textTransform: "none",
                            }}
                        >
                            I'm an Employer
                        </Button> */}

                        <Button
                            size='large'
                            variant="contained"
                            onClick={handleBackClass}
                            style={{
                                background: "#fff",
                                color: "#6A3996",
                                fontWeight: 600,
                                fontFamily: "montserrat",
                                fontSize: "16px",
                                borderRadius: "10px",
                                textTransform: "none",
                            }}
                        >
                            I'm an Employer
                        </Button>

                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }} >
                    {/* <img src={mainImg} alt="Taskmo Hero Banner" class="illustration" style={{ marginTop: "40px" }} /> */}

                    <img src={mainImgOld} alt="Taskmo Hero Banner" class="illustration" style={{ marginTop: "40px" }} />

                </div>
            </div>
        </div>
    )
}

