import { GET_JOB,GET_JOB_ERR } from '../../actionTypes/careerType'
import { getJobApi} from 'api/Api';
import axios from "axios";
export const getJob = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getJobApi}/${payload}`)
        console.log("GET JOB", results)
        let data = results && results.data
        dispatch({
            type: GET_JOB,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_JOB_ERR,
            payload: error && error.response
        });
    }
}
