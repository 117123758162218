import { CREATE_NEWS, CREATE_NEWS_ERR } from '../../actionTypes/newsType';
import { createNewsApi} from 'api/Api';
import axios from "axios";
// import { accessToken } from 'api/Token'
export const createNews = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.post(`${createNewsApi}`,payload)

        // let results = await axios.post(`${createNewsApi}`,payload, await accessToken())
        //console.log("CREATE NEWS", results)
        let data = results && results.data
        dispatch({
            type: CREATE_NEWS,
            payload: data || [],
        });
    } catch (error) {
       
        dispatch({
            type: CREATE_NEWS_ERR,
            payload: error && error.response
        });
    }
}

