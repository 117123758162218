import { GET_ALLCASESTUDY, GET_ALLCASESTUDY_ERR } from '../../actionTypes/casestudyType'
import { getAllCasestudyApi } from 'api/Api';
import axios from "axios";
export const getAllCasestudy = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getAllCasestudyApi}/${payload.filter}/${payload.page}`)
        console.log("GET BLOGSz", results)
        let data = results && results.data
        dispatch({
            type: GET_ALLCASESTUDY,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_ALLCASESTUDY_ERR,
            payload: error && error.response
        });
    }
}

