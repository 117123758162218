import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Campus(props) {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    // console.log("subscriber_city_name",state.subscriber_city_name)
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const navRef4 = React.useRef(null);
    const navRef5 = React.useRef(null);
    const navRef6 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    const onToggleClick4 = (e) => {
        navRef4.current.classList.toggle("show");
    };

    const onToggleClick5 = (e) => {
        navRef5.current.classList.toggle("show");
    };

    const onToggleClick6 = (e) => {
        navRef6.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Shop Onboarding Services | Retail Onboarding || Taskmo
                </title>
                <meta name="description" content="Taskmo - Provides Best Shop Onboarding, Retailer Onboarding Services with 8* faster onboarding, Click here to read more..." />
                <meta name="keywords" content="Merchant Onboarding, Shop Onboarding, Onboarding Process, Retailer Onboarding, Vendor Onboarding, Onboarding details" />
                <link rel="canonical" href="https://taskmo.com/services/merchant-onboarding/best-shop-onboarding-services" />
            </Helmet>


            <div class="font-montserrat container mt-20">
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9 title-style-eight">
                                <h1 class="font-montserrat fs-50 fw-600 hero-heading">Shop Onboarding Service</h1>
                                <p class="font-montserrat fs-20 fw-500">Taskmo offers the best shop onboarding process to improve productivity and boost retention.</p>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 mb-20 md-mt-30">Apply Now</a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <img src="../../../assets/img/shop.jpg" alt="Shop Onboarding Service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Benefits of Shop Onboarding services</h2>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}><img width="50" src="../../../assets/campus/connect.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Solid Workflow
                                </h4>
                                <p>Suppliers are relevant to the request of the onboarding.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}><img width="50" src="../../../assets/campus/communicate.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Streamline Onboarding
                                </h4>
                                <p>
                                    Taskmo's vendor onboarding solution facilitates communications with suppliers.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}><img width="50" src="../../../assets/campus/create.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Reduce Risk
                                </h4>
                                <p>
                                    Automatic risk upfront assistance to assure quality control and conflict safety.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">8</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen mt-180 md-mt-100" id="effect">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Why Taskmo?</h2>
                                <p class="font-montserrat">Why is Taskmo the best Shop-onboarding service provider?</p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFCE22' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/shopOnboarding/proficiency.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Accelerate proficiency</h4>
                                        <p>Onboard shops with 44% less operational cost and decreased in-store reps.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#8F6BF6' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/shopOnboarding/track-and-monitor.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Track and Monitor</h4>
                                        <p>Increase your ROI by measuring in-store onboarding through live dashboards.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF5C5C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/shopOnboarding/pincode.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">5000+ Pin Code Presence</h4>
                                        <p>Access to shop requirements over 5000+ Pincode.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#63EAA9' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/shopOnboarding/live-dashboard.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">KPI driven dashboards</h4>
                                        <p>AI curated dashboard to get optimal onboarding sense.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 md-mt-30">Apply Now</a>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the three phases of shop onboarding?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>Typically the three faces of shop onboarding includes: administration, orientation, and implementation.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                What is Taskmo’s key to successful onboarding programs?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo three key strategies to s successful onboarding program include: people, milestones, and tasks.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                What brand Taskmo has collaborated with?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo has collaborated with more than 350+ brands and completed 100000+ successful onboarding.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick4} class="btn btn-link font-montserrat">
                                                What happens after onboarding?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef4} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Phase two of onboarding includes training. The taskers make sure to train the shop owners as per the business needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
