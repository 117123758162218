import React from 'react'
import { styles } from "./OrderTakingManagementStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"

const useStyles = makeStyles(styles);

const OrderTakingManagement = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Outsource Retail Order Taking Service | Order Management || Taskmo
                </title>
                <meta name="description" content="Taskmo - Outsource Retail Order Taking Service & Order Management we handle it from order taking, processing orders & managing inventory for FMCG companies" />

                <meta name="keywords" content="Outsource retail order taking, Retail order taking, fmcg order management, Online retail order taking, In-store order taking, Retail order management services" />

                <link rel="canonical" href="https://taskmo.com/services/outsource-order-taking-service" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is retail order taking?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Retail order-taking management refers to the process of managing the various aspects of the retail order-taking process to ensure efficient and effective operations. This includes managing order placement, order processing, and order fulfilment along with inventory management to provide customers with a seamless and positive experience. Taskmo's effective retail order-taking management enables retailers to improve customer satisfaction, increase sales, and streamline operations to provide excellent customer service throughout the order-taking process."
                            }
                        }, {
                            "@type": "Question",
                            "name": "Why is retail order taking important for businesses?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Retail order-taking is an essential part of a business's operations, and it can have a significant impact on the success and profitability of a business. There are several reasons why retailers need to take retail orders including customer satisfaction, increased sales, operational efficiency, and competitive advantage."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the benefits of using a Taskmo order-taking management service?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo's order-taking management service can provide businesses with a range of benefits, including scalability, cost-effectiveness, high-quality service, advanced technology, and real-time reporting. By outsourcing order-taking management to Taskmo, businesses can focus on their core operations and provide their customers with an excellent experience."
                            }
                        }]
                    })}
                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    Order-Taking Management Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    A retail business encounters challenges with an expanded customer base resulting from sales growth, the presence of multiple outlets along with the addition of a variety of products to the inventory. Taskmo offers the best in the business retail order-taking management services to assist you to eliminate hurdles as your retail store expands by streamlining the entire process.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Retail order-taking is a crucial aspect of retail operations and has a big influence on the business' performance and profitability. Hence, outsourcing retail order-taking services to Taskmo's team of seasoned professionals gives the brands the flexibility to scale, optimise costs, and provide high-quality service with cutting-edge technology and real-time data.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e518fe660e782799d9fd1682683487079_ORDER%20TAKING%201.png" alt='' style={{ width: "85%" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Order-Taking Management Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/6c2638dc24526d51de151682427853508_01.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Remove barriers to your
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        expanding retail business
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e52d937c4780360fd21f1682427897710_02.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Accurate fulfilment
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        for customer satisfaction
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/41d9266b595114adfbbb1682427917167_03.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Streamlines the tedious
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        order-taking workflow
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f5fd011cbbb27d9eef811682427943070_04.svg" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Minimise errors to enhance
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        and expedite fulfilment
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}


                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2980f64e716274f5a4dc1682428094374_one.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Inventory Control
                                                    </h4>

                                                    <p>
                                                        Enable the merchants to control their inventory levels by reordering when goods are running low thereby ensuring successful order fulfilment at less cost.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/6ecfe203a571fc3b88041682428138115_two.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Optimized Logistics
                                                    </h4>
                                                    <p>
                                                        Provide real-time updates on each shipment's progress to provide an optimized logistics process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/45772dbc31c4c0321bc11682428164804_three.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Leveraging Advanced Technology
                                                    </h4>
                                                    <p>
                                                        Customized end-to-end order-taking management for your specific business needs
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/8ab3139d7a99175d87411682428184709_four.svg" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Scaling Services
                                                    </h4>
                                                    <p>
                                                        Provides increased order control with optimized order management, and cuts order lead times in half enabling scalable expansion
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "220px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                    minHeight: "200px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        100MN+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Orders In GMV
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        9000
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px"
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        15+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Happy Brands
                                    </p>
                                </div>

                                <div style={{
                                    border: "5px solid #BA73FA",
                                    borderRadius: "30px",
                                    boxSizing: "border-box",
                                    padding: "35px",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    margin: "auto",
                                    marginBottom: "30px",
                                }}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        96%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Productivity Delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* use cases start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#DAE8F6", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "190px", marginTop: "5px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d809804b4e39d52e0ff91682061578039_jiomart-taskmo.png" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-10px" }} >60%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Loyal merchants for successful order-taking management across 250 pincodes
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#FFD6EB", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "190px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/315e8a12fc80dcc76f8e1682061200888_634bd1950007a1468123fe05_logo%20meesho%20.webp" alt="" style={{ width: "100%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >40%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Increase in order-taking management across 250 pincodes
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>


                            </Grid>
                        </div>
                    </div>

                    {/* use cases end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is retail order taking?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Retail order-taking management refers to the process of managing the various aspects of the retail order-taking process to ensure efficient and effective operations. This includes managing order placement, order processing, and order fulfilment along with inventory management to provide customers with a seamless and positive experience. Taskmo's effective retail order-taking management enables retailers to improve customer satisfaction, increase sales, and streamline operations to provide excellent customer service throughout the order-taking process.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                Why is retail order taking important for businesses?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Retail order-taking is an essential part of a business's operations, and it can have a significant impact on the success and profitability of a business. There are several reasons why retailers need to take retail orders including customer satisfaction, increased sales, operational efficiency, and competitive advantage.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the benefits of using a Taskmo order-taking management service?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                Taskmo's order-taking management service can provide businesses with a range of benefits, including scalability, cost-effectiveness, high-quality service, advanced technology, and real-time reporting. By outsourcing order-taking management to Taskmo, businesses can focus on their core operations and provide their customers with an excellent experience.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default OrderTakingManagement