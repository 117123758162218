import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validation';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { createImageUrl } from "../context/actions/subscriberAction/createImageUrl";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { Grid, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  file_url: '',
  subscriber_message: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const file_url = ""

const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function Career_Form(props) {
  console.log("propss",props);
  console.log("props.jobsDetail", props.jobsDetail)
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
  const [imageUrl, setImageUrl] = React.useState('');
  const [number, setNumber] = useState([])
  const [state, setState] = useState(init);
  const [uploadResponse, setUploadResponse] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);

  const handleClose = () => {
    setState(init);
    setImageUrl("")
    setOpen(false)
    setErrors(!validation(state));
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };



  const handleChangeNumberLeadInput = (event) => {
    console.log('event', event.target.value)
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));

    if (
      state.subscriber_name !== '' &&
      state.subscriber_email !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.file_url !== ''
    ) {
      const postData = {
        subscriber_name: state.subscriber_name,
        subscriber_email: state.subscriber_email,
        subscriber_phone: state.subscriber_phone,
        file_url: state.file_url,
        // file_url: imageUrl,
        subscriber_type: props.subscriber_type,
        visitor_type: 'job_seeker'
      };

      createSubscriber(postData)(subscriberDispatch)
      console.log('Create Subscriber Detail', postData)

      setState(init);
      setImageUrl("")
      setUploadResponse(false)
      setOpenNotification(true);
    }
  }

  useEffect(() => {
    console.log('subscriberState', subscriberState)
    let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
    if (status == true) {
      subscriberState.createSubscriber = {
        loading: false,
        error: null,
        data: null,
      }
    }
  }, [subscriberState])


  const handleCapture = ({ target }) => {
    console.log('upload Files')
    const { name, value } = target
    if (value != '') {
      const fileReader = new FileReader();
      const name1 = target.accept.includes('pdf');

      fileReader.readAsDataURL(target.files[0]);

      let formData = new FormData();
      formData.append('file', target.files[0]);
      console.log('file', target.files)
      setImageUrl(target.files[0].name)
      setUploadResponse(false)
      createImageUrl(formData)(subscriberDispatch)
    }
  };


  useEffect(() => {
    console.log('subscriberStatess', subscriberState)
    let status = subscriberState.createSubscriber && subscriberState.createImageUrl.data && subscriberState.createImageUrl.data.status
    if (status == true) {
      setState(
        {
          ...state,
          file_url: subscriberState.createImageUrl.data.data.url
        });
      // setImageUrl(subscriberState.createImageUrl.data.data.url)
      setUploadResponse(true)
      console.log("setState1", state.file_url)
    }
  }, [subscriberState])

  // useEffect(() => {
  //   console.log('subscriberState', subscriberState)
  //   let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
  //   if (status == true) {
  //     setImageUrl(subscriberState.createImageUrl.data.data.url)

  //     console.log("store", store);
  //     subscriberState.createSubscriber = {
  //       loading: false,
  //       error: null,
  //       data: null,
  //     }
  //   }
  // }, [subscriberState])



  return (
    <div >
      <a onClick={handleOpen} style={{ borderRadius: "10px", color: "black", cursor: "pointer" }} class="mt-20 theme-btn-four">Apply Now</a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <div style={{ height: 540, overflowY: 'auto', display: 'inline-grid', padding: "0px", position: "absolute" }} class="form-left col-lg-4 col-md-8 wow fadeInUp">
            <Snackbar open={openNotification} onClose={handleCloseNotification} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert severity="success" sx={{ width: '100%' }}>
                Form Successfully Submitted!
              </Alert>
            </Snackbar>
            <div style={{ margin: "0px" }} class="consultation-form-area pl-xl-3 content-mt-md-50">
              <div class="consultation-form">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h4 class="pb-10 font-montserrat fs-20 fw-600">Apply for a position!</h4>
                  <CloseIcon style={{ cursor: "pointer", floatDirection: "left" }} onClick={handleClose} />
                </div>
                <form action="#">
                  <div class="font-montserrat input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Full Name*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                    </Grid>
                    <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Email*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                    </Grid>                  </div>
                  {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Phone*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                      {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                    </Grid>
                  </div>
 


                  <div >
                    <span>
                      <label htmlFor="icon-button-photo" class="mt-10">
                        Upload Resume* (Only PDF and Doc File)
                        <input
                          accept="application/pdf,application/msword"
                          type="file"
                          id="icon-button-photo"
                          // value={state.file_url}
                          name="file_url"
                          onChange={handleCapture}
                          style={{ display: 'none', border: "1px solid #b8b8b8", height: "50px", padding: "9px 15px" }}
                          class="inputfile font-montserrat fw-300"
                        />
                          <Button color="primary" component="span" size="small" variant="contained" color="primary" style={{ backgroundColor: '#7946A9',marginRight:"5px" }}><UploadIcon />Upload Resume</Button>
                        {uploadResponse == true ? <>
                          <span style={{ color: "gray" }}>{imageUrl}</span></> : <></>}
                          </label>
                          {imageUrl?<p style={{ color: "green" }}>File Uploaded Successfully</p>:""}
                        {errors.file_url && <p style={{ color: 'red' }}>{errors.file_url}</p>}
                    </span>
                  </div>
                  <button style={{ height: "50px" }} style={{ fontWeight: "600" }} onClick={handleClick} type="submit" class="mt-20 template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}