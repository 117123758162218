import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./D2CStyle";
import D2C from "../../components/d2c/index";
const D2CPage = React.memo((props) => {
  return (
    <>
      <D2C />
    </>
  );
});

export default withRouter(D2CPage);
