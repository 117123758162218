import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from "react-helmet";
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function CustomerSupport() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Customer Support Services in India
                </title>
                <meta name="description" content="At Taskmo, we provide best customer support services at utmost importance, we understand your brief, secure new clients and retain existing ones. Click here.." />
                <meta name="keywords" content="customer service, customer care, customer service representative, customer support, customer relationship, customer care service" />
                <link rel="canonical" href="https://taskmo.com/services/customer-support-services" />
            </Helmet>

            <div class="fancy-text-block-seven md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Customer Support
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Customer Support" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        The only way to maintain clients is to provide good customer service. An expanding number of firms and organisations place an emphasis on good customer service. At Taskmo, our customer support services are of the utmost importance. We understand that securing new clients and retaining existing ones are essential for the success of any business venture. With expanding business activities, you can hardly find time to have a dedicated customer support service provider like Taskmo which will help you save time, effort and money. <br /><br />
                                        Taskmo is highly committed towards providing continued quality customer support services. Taskmo aims to provide excellent customer support service and improve client satisfaction while providing the desired results.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Customer Support" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/Customer support/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Instant Access to Experienced Professionals
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/Customer support/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Effective and Efficient Inbound and Outbound Services
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/Customer support/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A unique and distinctive processes
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/Customer support/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A broad range of industry bound customer support services.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <OurPartners />


            <div id="feedback" class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">75000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Taskers </div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Cities </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Testimonial />


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the various customer support services Taskmo offers?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                At Taskmo, we offer a wide range of customer support services, which include help-desk services, live chat support, telephonic support, email support and so many more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Do you offer services in regional languages?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! At Taskmo we offer services across various vernacular languages.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


