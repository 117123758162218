import React from 'react'

export default function WorkingProgress() {
    return (
        <div>
            <div style={{ background: "white" }} class="font-montserrat lg-container mt-100 pt-50 md-mt-60" id="product">
                <div class="container">
                    <div class="text-center mb-50 md-mb-20">
                        <div class="row">
                            <div class="col-xl-10 col-lg-11 m-auto">
                                <h2>
                                    <span class="font-montserrat fw-600 fs-50">How It Works</span>
                                    <p style={{ textAlign: "center", color: "gray" }} class="font-montserrat fs-20 fw-500">Simple, Efficient and Quick work process</p>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="fancy-feature-twelve mt-30 md-mt-50" id="about">
                        <div class="bg-wrapper">
                            <div class="container">
                                <div class="row justify-content-center md-pt-20">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="card block-style-seventeen mt-40">
                                            <div class=" icon d-flex align-items-center justify-content-center">
                                                <img width="40" src="assets/home/Submit Your Requirements.svg" class="display-none" alt="Submit Your Requirements" />
                                                <img width="40" src="assets/home/Submit Your Requirements white.svg" class="img-top" alt="Submit Your Requirements" />
                                            </div>
                                            <div class="static-text">
                                                <h3 class="font-montserrat fw-600">Submit Your Requirements</h3>
                                                <p>Select from our wide range of gig services and choose the services that suit your necessity. &nbsp; </p>
                                            </div>
                                            <div class="hover-text">Select from our wide range of gig services and choose the services that suit your necessity.</div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6">
                                        <div class="card block-style-seventeen mt-40">
                                            <div class=" icon d-flex align-items-center justify-content-center">
                                                <img width="40" src="assets/home/Curate Workflow.svg" class="display-none" alt="Curate Workflow" />
                                                <img width="40" src="assets/home/Curate Workflow white.svg" class="img-top" alt="Curate Workflow" />
                                            </div>
                                            <div class="static-text">
                                                <h3 class="font-montserrat">Curate Workflow</h3>
                                                <p>Once you have sent us your needs, we curate a team of best fit trained gig workers who are all set to perform the given task at hand. </p>
                                            </div>
                                            <div class="hover-text">Once you have sent us your needs, we curate a team of best fit trained gig workers who are all set to perform the given task at hand. </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6">
                                        <div class="card block-style-seventeen mt-40">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <img width="40" src="assets/home/Track the Task.svg" class="display-none" alt="Track the Task" />
                                                <img width="40" src="assets/home/Track the Task white.svg" class="img-top" alt="Track the Task" />
                                            </div>
                                            <div class="static-text">
                                                <h3 class="font-montserrat">Track the Task</h3>
                                                <p>Access, train and track the progress of your task in real-time through our AI-Based (Artificial Intelligence) dashboards.</p>
                                            </div>
                                            <div class="hover-text">Access, train and track the progress of your task in real-time through our AI-based customized dashboards.</div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6">
                                        <div class="card block-style-seventeen mt-40">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <img width="40" src="assets/home/Pay for the Outcome.svg" class="display-none" alt="Pay for the Outcome" />
                                                <img width="40" src="assets/home/Pay for the Outcome white.svg" class="img-top" alt="Pay for the Outcome" />
                                            </div>
                                            <div class="static-text">
                                                <h3 class="font-montserrat">Pay for the Outcome</h3>
                                                <p>We help you track the efficiency of the task completed and ensure that you pay only for the outcome.</p>
                                            </div>
                                            <div class="hover-text">We help you track the efficiency of the task completed and ensure that you pay only for the outcome.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
