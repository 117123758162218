export default {
  getBlog: {
    loading: false,
    error: null,
    data: [],
  },

  getBlogs: {
    loading: false,
    error: null,
    data: [],
  },

  getBlogCategories: {
    loading: false,
    error: null,
    data: [],
  },


};
