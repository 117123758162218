import React from 'react'

import Group1 from '../../assets/images/1.png'
import Group2 from '../../assets/images/2.png'
import Group3 from '../../assets/images/3.png'

export default function Counter() {
    return (


        <div class="pricing-section-two">
            <div class="row no-gutters">
                <div style={{ lineHeight: "1", backgroundImage: `url(${Group1})`, backgroundPosition: "center" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                    <div style={{ padding: "180px 0 180px" }} class="counter-item  pr-table-wrapper">
                        <div style={{ color: "white" }} class="font-montserrat fs-50 fw-600 price">
                            <span class="counter">75000</span>
                            <span>+</span>
                        </div>
                        <div style={{ color: "white" }} class="bill-cycle"> Gig Worker Community </div>
                    </div>
                </div>

                <div style={{ lineHeight: "1", backgroundImage: `url(${Group2})`, backgroundPosition: "center" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                    <div style={{ padding: "180px 0 180px" }} class="counter-item  pr-table-wrapper">
                        <div style={{ color: "white" }} class="font-montserrat fs-50 fw-600 price">
                            <span class="counter">10000</span>
                            <span>+</span>
                        </div>
                        <div style={{ color: "white" }} class="bill-cycle">Pincodes Presence</div>
                    </div>
                </div>

                <div style={{ lineHeight: "1", backgroundImage: `url(${Group3})`, backgroundPosition: "center" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                    <div style={{ padding: "180px 0 180px" }} class="counter-item pr-table-wrapper">
                        <div style={{ color: "white" }} class="font-montserrat fs-50 fw-600 price">
                            <span class="counter">2000000</span>
                            <span>+</span>
                        </div>
                        <div style={{ color: "white" }} class="bill-cycle"> Tasks Completed</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
