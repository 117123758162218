import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./NewsStyle";
import News from "../../../components/pressRelease/news/index";
const NewsPage = React.memo((props) => {
  return (
    <>
      <News />
    </>
  );
});
export default withRouter(NewsPage);
