import React, { useEffect, useState, useContext, } from "react";
import './CaseStudyDetail.css'
import { useParams, useHistory } from 'react-router-dom';
import { localRoute } from '../../../routes/localRoutes';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import parse from 'html-react-parser'
import { Helmet } from "react-helmet";
import { GlobalContext } from '../../../context/GlobalState'

import { getCasestudy } from '../../../context/actions/casestudyAction/getCaseStudy'
import { getAllCasestudy } from "../../../context/actions/casestudyAction/getAllCaseStudy";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function CaseStudyDetails() {
    const { casestudyState, casestudyDispatch } = useContext(GlobalContext);

    const [caseStudyDetail, setCasestudyDetail] = useState({})
    const [filterCasestudyDetails, setFilterCasestudyDetails] = React.useState([]);
    const [tabFilter, setTabFilter] = React.useState('active');
    const history = useHistory()
    const { casestudy_id } = useParams()
    let pageTitlePath = history.location && history.location.pathname
    let newpath = "https://taskmo.com" + pageTitlePath
    console.log("newpath", newpath)

    useEffect(() => {
        window.scrollTo(0, 0)
        getCasestudy(casestudy_id)(casestudyDispatch)
    }, [casestudy_id])

    useEffect(() => {
        let status = casestudyState && casestudyState.getCasestudy.status
        console.log('CasestudyCategoriesStatezz', casestudyState)
        if (status == true) {
            setCasestudyDetail(casestudyState.getCasestudy.data)
        }
    }, [casestudyState])


    useEffect(() => {
        let filter = tabFilter
        getAllCasestudy({ filter, page: "all" })(casestudyDispatch)
    }, [])

    useEffect(() => {
        let status = casestudyState.getAllCasestudy.status
        if (status === true) {
            setFilterCasestudyDetails(casestudyState.getAllCasestudy.data.res)
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", casestudyState.getAllCasestudy)
        }
        // SERVER VALIDATION ERROR RESPONSE  
        if (status === 401) {
            console.log("Validation Error:", casestudyState.getAllCasestudy)
        }
    }, [casestudyState])

    console.log("filterCasestudyDetails", filterCasestudyDetails);


    const openCaseStudy = (item) => {
        history.push(`${localRoute.caseStudyDetails}/${item.case_study_slug}`)
    }
    const handleContact = () => {
        history.push(`${localRoute.contact}`)
    }
    console.log('caseStudyDetail', caseStudyDetail)
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {caseStudyDetail.case_study_title && parse(caseStudyDetail.case_study_title)}
                </title>
                <meta name="description" content={caseStudyDetail.meta_description} />
                <meta name="keywords" content={caseStudyDetail.case_study_tag} />
                <link rel="canonical" href={newpath} />
            </Helmet>

            <section style={{ marginTop: "30px" }} >
                <div style={{ paddingBottom: "120px" }} class="font-montserrat container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="single-blog-post blog-details-content pr-xl-5">
                                <div class="post-thumbnail">
                                    <img src={caseStudyDetail.case_study_image} alt={caseStudyDetail.case_study_title} />
                                </div>
                                <br />
                                <div class="post-content">
                                    <h1 class="post-title">
                                        {caseStudyDetail.case_study_title && parse(caseStudyDetail.case_study_title)}
                                        <a class="font-montserrat fw-600">
                                        </a>
                                    </h1>
                                    <div style={{ listStyleType: "initial" }}>
                                        {caseStudyDetail.case_study_description && parse(caseStudyDetail.case_study_description)}
                                    </div>
                                    <blockquote class="font-montserrat fw-600">
                                        <p>Shoot an email to us at <span><u><a href="mailto:business@taskmo.com">business@taskmo.com</a></u></span> and book a 30-min call with our experts today! </p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-last">
                            <div class="portfolio-info-box" style={{ margin: "0px" }}>
                                <h2 class="font-montserrat fs-40 fw-600">Generate Leads 5x Faster</h2>
                                <button onClick={handleContact} class="fw-600" style={{ border: "1px solid #7D8EC6", color: "#262159", fontSize: "18px", borderRadius: "5px", padding: "5px 10px", width: "-webkit-fill-available", marginTop: "10px" }}>
                                    Get started
                                </button>
                                <div style={{ textAlign: "center" }}>
                                    <a href="https://twitter.com/Taskmo_official" target="_blank" style={{ cursor: "pointer" }}><TwitterIcon color="primary" /></a>
                                    <a href="https://www.linkedin.com/company/taskmo-feedmypockets/" target="_blank" style={{ cursor: "pointer" }}><LinkedInIcon color="primary" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-30">

                        {filterCasestudyDetails.slice(0, 3).map((item, index) => {
                            return (

                                <div class="col-lg-4 col-md-6">
                                    <div class="post-meta" style={{ marginBottom: "20px", padding: "20px", background: "#fff", boxShadow: "0 10px 40px rgb(0 0 0 / 8%)", minHeight: "355px" }}>
                                        <div style={{ cursor: "pointer" }} onClick={() => openCaseStudy(item)} key={item.case_study_slug} class="post-meta">
                                            <img src={item.case_study_image} alt={item.case_study_title} />
                                            <div class="post">
                                                <h2>
                                                    {item.case_study_title}
                                                </h2>

                                                <p class="read-more">
                                                    <span>Continue Reading &nbsp;</span>
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>


            </section>
        </div>
    )
}
