export const styles = (theme) => ({

    main: {
        width: "100%",
        background: "#FC263B",
        color: "#fff",
        padding: "5px",

        "@media (max-width: 767px)": {
            paddingLeft: "15px",
            paddingRight: "15px",
            marginBottom: "10px",
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            paddingLeft: "15px",
            paddingRight: "15px",
            marginBottom: "10px",
        },
    },

    container: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        "@media (max-width: 767px)": {
            gap: "10px",
            marginBottom: "10px",
            marginTop: "10px"
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            gap: "10px",
            marginBottom: "10px",
            marginTop: "10px"
        },
    }

})