import { GET_BLOGS, GET_BLOGS_ERR, GET_BLOG, GET_BLOG_ERR, GET_BLOGS_CATEGORIES, GET_BLOGS_CATEGORIES_ERR } from '../../actionTypes/blogType'

export default (state, action) => {
  switch (action.type) {
    case GET_BLOG:
      return {
        ...state,
        getBlog: action.payload, ...state.data,
      }

    case GET_BLOG_ERR:
      return {
        ...state,
        getBlog: { getdata: action.payload }
      }

    case GET_BLOGS:
      return {
        ...state,
        getBlogs: action.payload, ...state.data,
      }

    case GET_BLOGS_ERR:
      return {
        ...state,
        getBlogs: { getdata: action.payload }
      }

    case GET_BLOGS_CATEGORIES:
      return {
        ...state,
        getBlogCategories: action.payload, ...state.data,
      }

    case GET_BLOGS_CATEGORIES_ERR:
      return {
        ...state,
        getBlogCategories: { getdata: action.payload }
      }

    default:
      return state;
  }
}