import React from "react";
import { useHistory } from "react-router-dom";
import { localRoute } from "../../routes/localRoutes";

export default function Footer() {
  const history = useHistory();

  const handleBackTaskam = () => {
    history.push("/TASKAM");
  };

  const handleBackprivacypolicy = () => {
    history.push(localRoute.privacyPolicy);
  };
  const handleBacktermsconditions = () => {
    history.push(localRoute.termsConditions);
  };
  const handleBackHome = () => {
    history.push(localRoute.home);
  };
  const handleBackServices = () => {
    history.push(localRoute.services);
  };
  const handleBackBlog = () => {
    history.push(localRoute.blog);
  };
  const handleBackContact = () => {
    history.push(localRoute.contact);
  };
  const handleBackAbout = () => {
    history.push(localRoute.about);
  };
  const handleBackCareer = () => {
    history.push(localRoute.career);
  };

  const handleBackMerchant = () => {
    history.push(localRoute.merchantOnboarding);
  };

  const handleBackBackground = () => {
    history.push(localRoute.backgroundVerification);
  };
  const handleBackTele = () => {
    history.push(localRoute.teleSales);
  };
  const handleBackLastMile = () => {
    history.push(localRoute.lastMileDelivery);
  };
  const handleBackCustomerAcquisition = () => {
    history.push(localRoute.customerAcquisition);
  };
  const handleBackStockAudits = () => {
    history.push(localRoute.stockAudits);
  };
  const handleBackGeoTagging = () => {
    history.push(localRoute.geoTagging);
  };
  const handleBackPickingPacking = () => {
    history.push(localRoute.pickingPacking);
  };
  const handleBackSurveyCollection = () => {
    history.push(localRoute.surveyCollection);
  };
  const handleBackProductSampling = () => {
    history.push(localRoute.productSampling);
  };
  const handleBackCustomerSupport = () => {
    history.push(localRoute.customerSupport);
  };
  const handleBackInfluencerMarketing = () => {
    history.push(localRoute.influencerMarketing);
  };
  const handleBackWarehouseService = () => {
    history.push(localRoute.warehouseService);
  };
  const handleBackContentModeration = () => {
    history.push(localRoute.contentModeration);
  };
  const handleBackBrandActivation = () => {
    history.push(localRoute.brandActivation);
  };

  const handleBackCampus = () => {
    history.push(localRoute.campus);
  };

  const handleBackD2C = () => {
    history.push(localRoute.d2c);
  };

  const handleBackNews = () => {
    history.push(localRoute.news);
  };

  const handleBackNewsletter = () => {
    history.push(localRoute.newsletter);
  };

  const handleBackCaseStudy = () => {
    history.push(localRoute.caseStudy);
  };

  return (
    <>
      <footer class="theme-footer-two pt-50 md-pt-40">
        <hr />
        <div class="top-footer mt-20 pb-10">
          <div class="container">
            <div class="row justify-content-between">
              <div class="font-montserrat fw-500 col-lg-2 col-md-3 col-sm-6 footer-list">
                <h5 class="footer-title font-montserrat fw-600">Quick links</h5>
                <ul>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackAbout}>
                      About us
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={handleBackServices}
                    >
                      Our Offerings
                    </a>
                  </li>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackBlog}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackCareer}>
                      Career
                    </a>
                  </li>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackD2C}>
                      D2C
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={handleBackCaseStudy}
                    >
                      Case Studies
                    </a>
                  </li>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackNews}>
                      News
                    </a>
                  </li>
                  {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackNewsletter}>Newsletter</a></li> */}
                  {/* <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackCampus}>
                      Taskmo CAP
                    </a>
                  </li> */}
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={handleBackContact}
                    >
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={handleBacktermsconditions}
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={handleBackprivacypolicy}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a style={{ cursor: "pointer" }} onClick={handleBackTaskam}>
                      Taskmo
                    </a>
                  </li>
                </ul>
              </div>

              <div class="font-montserrat fw-500 col-lg-7 col-md-3 col-sm-6 footer-list">
                <h5
                  class="footer-service-name footer-title font-montserrat fw-600 margin-right"
                  style={{ textAlign: "left" }}
                >
                  Our Offerings
                </h5>
                <div class="footer-services">
                  <ul>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackMerchant}
                      >
                        Merchant / Partner Onboarding
                      </a>
                    </li>
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackBackground}>Background Verification</a></li> */}
                    <li>
                      <a style={{ cursor: "pointer" }} onClick={handleBackTele}>
                        Tele Support
                      </a>
                    </li>
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackLastMile}>Last Mile Delivery</a></li> */}
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackCustomerAcquisition}
                      >
                        Customer Acquisition
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackStockAudits}
                      >
                        Stock Audits
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackGeoTagging}
                      >
                        Geo Tagging
                      </a>
                    </li>
                  </ul>
                  <ul>
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackPickingPacking}>Picker And Packers</a></li> */}
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackBrandActivation}>BTL Activation</a></li> */}
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackSurveyCollection}>Survey Collection</a></li> */}
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackProductSampling}
                      >
                        Product Sampling
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackInfluencerMarketing}
                      >
                        Influencer Marketing
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackCustomerSupport}
                      >
                        Customer Support
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleBackContentModeration}
                      >
                        Content Moderation
                      </a>
                    </li>
                    {/* <li><a style={{ cursor: "pointer" }} onClick={handleBackWarehouseService}>Warehouse Services</a></li> */}
                  </ul>
                </div>
              </div>

              <div class="font-montserrat fw-500 col-lg-3 col-md-3 col-sm-6 address-list">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={handleBackHome}
                  class="logo"
                >
                  <img
                    style={{ width: "12em" }}
                    src="../../../../assets/img/taskmo-final-logo.svg"
                    alt="taskmo-logo"
                  />
                </a>
                <ul class="mt-10 info">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/dir/12.9390587,77.6278113/quess+koramangala/@12.939013,77.6251931,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae152d34e6eff5:0xe0e1ef2dd0539231!2m2!1d77.6277422!2d12.9389879?entry=ttu"
                    >
                      #10, Star Office, Venkatta Reddy Layout, Off 80ft road,
                      6th Block, Koramangala, <br></br>Bengaluru - 560095,{" "}
                      <br></br>Karnataka, India
                    </a>
                  </li>
                  <hr style={{ marginTop: "5px" }} />
                  <li>
                    <p
                      style={{
                        marginBottom: "0px",
                        lineHeight: "1",
                        fontWeight: "600",
                        color: "#281f5d",
                      }}
                    >
                      For Businesses
                    </p>
                  </li>
                  <li>
                    <a
                      style={{ marginBottom: "0px", lineHeight: "1" }}
                      href="mailto:business@taskmo.com"
                    >
                      business@taskmo.com
                    </a>
                  </li>
                  <hr style={{ marginTop: "5px" }} />
                  <li>
                    <p
                      style={{
                        marginBottom: "0px",
                        lineHeight: "1",
                        fontWeight: "600",
                        color: "#281f5d",
                        marginTop: "5px",
                      }}
                    >
                      For Careers
                    </p>
                  </li>
                  <li>
                    <a
                      style={{ marginBottom: "0px", lineHeight: "1" }}
                      href="mailto:hr@taskmo.com"
                    >
                      hr@taskmo.com
                    </a>
                  </li>
                  <hr style={{ marginTop: "5px" }} />
                  <li>
                    <p
                      style={{
                        marginBottom: "0px",
                        lineHeight: "1",
                        fontWeight: "600",
                        color: "#281f5d",
                        marginTop: "5px",
                      }}
                    >
                      For Queries
                    </p>
                  </li>
                  <li>
                    <a
                      style={{ marginBottom: "0px", lineHeight: "1" }}
                      href="mailto:support@taskmo.com"
                    >
                      support@taskmo.com
                    </a>
                  </li>
                  <hr style={{ marginTop: "5px" }} />
                  {/* <li><a href="tel:+917406480001" data-rel="external" class="mobile-num">+91 7406480001</a></li> */}
                </ul>
                <ul class="social-icon d-flex">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/taskmoofficial"
                    >
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/taskmo/">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/Taskmo_official"
                    >
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/taskmo-feedmypockets/"
                    >
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://bit.ly/Taskmo_YT">
                      <i class="fa fa-youtube"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://t.me/taskmo_money_earning_application"
                    >
                      <i class="fa fa-telegram"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://chat.whatsapp.com/L88VJShJfei8jybjKrc3IL"
                    >
                      <i class="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="bottom-footer-content">
            <div class="d-flex align-items-center justify-content-center">
              <p
                style={{ borderTop: "1.5px solid gray" }}
                class="font-montserrat pt-10 fw-500 "
              >
                ©2023 Quess Corp Limited. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
