import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./LoginStyle";
import Login from "../../components/login/index";
const LoginPage = React.memo((props) => {
  return (
    <>
      <Login />
    </>
  );
});

export default withRouter(LoginPage);
