export const styles = (theme) => ({
    info: {
        display: "flex",
        justifyContent: "center",
        gap: "100px",
        marginTop: "50px",
        // "@media (min-width: 1024px) and  (max-width: 1099px)": {
        //     flexDirection: "row",
        // },
        // "@media (min-width: 1100px) and  (max-width: 1200px)": {
        //     flexDirection: "row",
        // },
        // "@media (min-width: 1201px) and  (max-width: 1300px)": {
        //     flexDirection: "row",
        // },
        "@media (max-width: 900px)": {
            flexDirection: "column",
            gap: "50px",
        },
    },
    contact: {
        "@media (max-width: 900px)": {
            margin: "auto",
        },
        "@media (max-width: 600px)": {
            margin: "auto",
            textAlign: "center"
        },
    },

    btn: {
        marginTop: "24px",
        width: "345px",
        "@media (max-width: 600px)": {
            width: "260px",
        },
    }
})