import React from 'react'
import RouterComponent from './routes/index';

const App = () => {
  return (
    <div>
      <RouterComponent />
    </div>
  )
}

export default App