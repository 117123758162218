import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { styles } from "./PpcStyle";
// import OurPartners from '../../home/ourPartners/index';
import Testimonial from '../home/testimonial';
import OurPartners from '../home/ourPartners';
import { Helmet } from "react-helmet";
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import { useHistory } from 'react-router-dom'
import { localRoute } from '../../routes/localRoutes';
import SliderServices from './sliderServices';
import NewServices from 'pages/services/newServices/NewServices';
import SpamAlert from 'pages/spamAlert/SpamAlert';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CustomerSupport() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const classes = useStyles();

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const history = useHistory()

    const handleCaseStudyAmazon = () => {
        history.push(localRoute.casestudyAmazon)
    }
    const handleCaseStudyRapido = () => {
        history.push(localRoute.casestudyRapido)
    }
    const handleCaseStudyPhonepay = () => {
        history.push(localRoute.casestudyPhonepay)
    }
    const handleCaseStudyZomato = () => {
        history.push(localRoute.casestudyZomato)
    }

    const handleGoLetsTalk = () => {
        if (window.innerWidth <= 700) {
            window.location.replace("/b2b-services#lets-talk-mobile");
        } else {
            window.location.replace("/b2b-services#lets-talk");
        }
    }
    const handleBackHome1 = () => {
        history.push("/")
        window.scrollTo(0, 0)
    };
    return (
        <div style={{ marginTop: "20px" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Task Fulfillment Platform | Gig Marketplace|| Taskmo
                </title>
                <meta name="description" content="TASKMO is a tech driven on demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters" />
                <meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services" />
                <link rel="canonical" href="https://taskmo/b2b-services" />
            </Helmet>

            <div style={{ position: "sticky", top: 0, zIndex: "99" }} >
                <div style={{ width: "100%" }} >
                    <SpamAlert />
                </div>
                <div className={classes.headerLogo} >
                    <a style={{ cursor: "pointer", marginBottom: "5px" }} onClick={handleBackHome1}>
                        <img class="taskmo-logo" src="../../../../assets/img/logo.png" alt="taskmo-logo" style={{ marginTop: "10px" }} />
                    </a>
                </div>

            </div>

            <div>
                <section id='lets-talk' class="consultation-section section-polygon-bg section-gap bg-color-primary">

                    <div class="font-montserrat container">
                        <div class="row justify-content-center">
                            <div class="col-xl-12">
                                <div class="row align-items-center justify-content-center">
                                    <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                        <div class="consultation-text text-white pr-xl-5">
                                            <div class="section-heading heading-white mb-30">
                                                <h2 class="font-montserrat fs-30 fw-600 cl-white" style={{ marginBottom: "0px" }} >India’s Largest Distributed Network of Gig Workers Community</h2>
                                            </div>
                                            <div style={{ marginTop: "-15px", marginBottom: "15px" }} >
                                                <h4 class="font-montserrat fs-20 fw-600 cl-white" > Discover, Acquire, and Manage to Scale Your Business needs with Taskmo B2B Gig Platform.</h4>
                                            </div>
                                            <p class="fs-18">
                                                We offer digital and hyperlocal solutions for financial products, e-commerce, q-commerce, food tech, grocery, mobility, rental, pharmacy, retail, and more through our network of gig-workers.
                                            </p>
                                            <div class="counter-items row">
                                                <div class="col-lg-6">
                                                    <div class="counter-item counter-white mt-40">
                                                        <div class="counter-wrap">
                                                            <span>250+</span>
                                                        </div>
                                                        <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Trusted by Brands</h6>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="counter-item counter-white mt-40">
                                                        <div class="counter-wrap">
                                                            <span>3,000,000+</span>
                                                        </div>
                                                        <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="counter-item counter-white mt-40">
                                                        <div class="counter-wrap">
                                                            <span>10,000+</span>
                                                        </div>
                                                        <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Pincodes Presence</h6>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="counter-item counter-white mt-40">
                                                        <div class="counter-wrap">
                                                            <span>75,000+</span>

                                                        </div>
                                                        <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Gig workers community</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div id='lets-talk-mobile' data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                        <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                            <Find_Taskers_Common subscriber_type="pay-per-click-ads" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <div style={{ marginTop: "50px" }} >
                <NewServices />
            </div>

            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10 md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/Customer support/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Instant Access to Experienced Professionals
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/Customer support/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Effective and Efficient Inbound and Outbound Services
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/Customer support/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A unique and distinctive processes
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/Customer support/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A broad range of industry bound customer support services.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <OurPartners />

            {/* <SliderServices /> */}

            <div style={{ backgroundColor: "white", padding: '30px 15px 30px' }} class="font-montserrat fancy-feature-eighteen" id="feature">
                <div style={{ backgroundColor: "#F6ECFF" }} class="tab-content" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                    <div class="tab-pane active" id="ft1">
                        <div class="row no-gutters align-items-center">
                            <div class="col-md-6">
                                <div class="img-holder">
                                    <img src="../../../assets/home/KPI Analysis.png" alt="KPI Analysis" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-wrapper ">
                                    <h4 class="font-montserrat cl-black">How It Works</h4>

                                    <li style={{ color: 'black', fontSize: "20px" }}>Submit Your Requirements</li>
                                    <li style={{ color: 'black', fontSize: "20px" }}>Curate workflow </li>
                                    <li style={{ color: 'black', fontSize: "20px" }}>Track the task</li>
                                    <li style={{ color: 'black', fontSize: "20px" }}>Pay for the Outcome</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="fancy-feature-nineteen" id="effect">
                <div class="container">
                    <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                        Case Studies
                    </h2>
                    <div class="font-montserrat row">
                        <div onClick={handleCaseStudyPhonepay} style={{ cursor: "pointer" }} class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#6714A5' }}>
                                <div class="d-flex align-items-start">
                                    <img width="120" src="../../../assets/ppc/3.png" alt="Phonepe" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Merchant Onboarding</h4>
                                        <p>
                                            We streamlined the merchant onboarding process & assisted penny drop transactions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={handleCaseStudyAmazon} style={{ cursor: "pointer" }} class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF9800' }}>
                                <div class="d-flex align-items-start">
                                    <img width="120" src="../../../assets/ppc/1.png" alt="amazon logo" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Customer Acquisition</h4>
                                        <p>
                                            We simplified the process by segmenting it into tasks and onboard merchants.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={handleCaseStudyRapido} style={{ cursor: "pointer" }} class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFC901' }}>
                                <div class="d-flex align-items-start">
                                    <img width="120" src="../../../assets/ppc/4.png" alt="Rapido" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Partner Onboarding</h4>
                                        <p>
                                            Taskmo's taskers educated auto drivers about the platform and onboarded them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={handleCaseStudyZomato} style={{ cursor: "pointer" }} class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#F4173C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="120" src="../../../assets/ppc/2.png" alt="Zomato Logo" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">User Acquisition</h4>
                                        <p>
                                            Taskmo's executives collected data from 25000+ restaurants across the India.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Testimonial />

            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a onClick={handleGoLetsTalk} style={{ borderRadius: "10px", color: "black", backgroundColor: "white", cursor: 'pointer' }} class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


