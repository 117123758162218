import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import RestartCareer from "../../components/restartCareer/index";

const RestartCareerPage = React.memo((props) => {
  return (
    <>
      <RestartCareer/>
    </>
  );
});

export default withRouter(RestartCareerPage);
