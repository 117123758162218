
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function AreaOfInterest() {

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        // speed: 1000,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <>
            <div class="fancy-feature-ten mt-100 md-mt-70" id="feature">
                <div class="bg-wrapper">
                    <div class="container">
                        <div class="mb-50 justify-content-between align-items-center">
                            <div style={{textAlign:"center"}} class="" data-aos="fade-right" data-aos-duration="1200">
                                <div class=" title-style-six" >
                                    <h2 class="font-montserrat fw-500 fs-50" style={{fontWeight:"600",color:"#281f5d"}}>Actively Hiring For...</h2>
                                </div>
                            </div>
                            {/* <div class="col-lg-5 col-md-6" data-aos="fade-left" data-aos-duration="1200">
                                <p class="sub-text pt-30 pb-30">Commonly used in the graphic, print & publishing industris for previewing visual mockups.</p>
                            </div> */}
                        </div>

                        {/* <div class="row justify-content-center mt-35 md-mt-20"> */}
                            <Slider {...settings}>
                                <div data-aos="fade-up" data-aos-duration="1200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#FFEBDB" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Field sales executive.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Field Sales Executive</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Generate sales using various customer sales methods </p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#E0F8F8" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Brand promoter.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Brand Promoter</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Demonstrate and provide information on various companies products or services.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#F7EDFF" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Field auditor.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Field Auditor</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Travel to company sites, investigate issues, and make risk assessments for organisations.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#FFEBDB" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Data moderator.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Data Moderator</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Evaluate all the user-generated content submitted to an online platform</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                    
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#E0F8F8" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Tele caller.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Tele Caller</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Handle sales and solve queries regarding the product or service over the phone.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#F7EDFF" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Warehouse incharge.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Warehouse Incharge</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Oversee all warehousing and distribution operations.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#FFEBDB" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Instore staff.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Instore Staff</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Welcome and engage with customers as they enter the store.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#E0F8F8" }}><img style={{width:"30px"}} src="assets/app landing/aoi/picking&packing.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Pickers and Packers</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat "> Pack & organise products based on daily orders to meet customer requirements.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#F7EDFF" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Delivery Partner.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Delivery Partner</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Deliver various items to different addresses as assigned.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#FFEBDB" }}><img style={{width:"30px"}} src="assets/app landing/aoi/Field Marketing.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Field Marketing</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Drive brand awareness through on-site, face-to-face interaction with consumers.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#E0F8F8" }}><img style={{width:"30px"}} src="assets/app landing/aoi/telesales.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Telesales Executives</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Persuade customers to purchase companies products & services over a call.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <div style={{margin:"10px"}} class="block-style-fifteen mt-40">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{margin:"auto", background: "#F7EDFF" }}><img style={{width:"30px"}} src="assets/app landing/aoi/event promoter.svg" alt="" /></div>
                                        <h3 style={{textAlign:"center"}} class="font-montserrat ">Event promoters</h3>
                                        <p style={{textAlign:"center"}} class="font-montserrat ">Demonstrate the features of a product to an audience or client.</p>
                                        {/* <a href="#" class="mt-30"><img src="images/icon/69.svg" alt="" /></a> */}
                                    </div>
                                </div>
                            </Slider>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}






