import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function InfluencerMarketing() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Influencer Marketing Service in India
                </title>
                <meta name="description" content="At Taskmo, we highly believe in providing best Influencer Marketing Services that lead to sustainable and organic results. Click here..." />
                <meta name="keywords" content="influencer marketing, micro influencers, macro influencer, influencer marketplace, influencer marketing companies, influencer website, influencer marketing services" />
                <link rel="canonical" href="https://taskmo.com/services/influencer-marketing" />
            </Helmet>

            <div class="fancy-text-block-seven mt-20 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Influencer Marketing
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Influencer Marketing" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18text-align-left">
                                        Influencer Marketing has emerged as a widely accepted trend in today’s digital world. Influencer marketing has turned out to be a widely accepted and trusted method of acquiring customers online. At Taskmo, we strongly believe in providing sustainable influencer marketing services that lead to organic results. Taskmo has specialised in working with brands related to beauty, health and wellness, nutrition, personal care, lifestyle, art and much more. We also work with brands for film integrations and celebrity endorsements.<br /><br />
                                        We help with ideating brand strategies, identifying the right micro-influencers and macro-influencers, crafting compelling content and delivering high-engagement campaigns. We, at Taskmo, provide dedicated end to end support from understanding the brief, scouting for the right influencers from the influencer marketplace, tracking the campaign constantly and reporting to the concerned constantly and working towards the success of the campaign.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">

                                <Find_Taskers_Common subscriber_type="Influencer Marketing" />


                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}>
                                                    <img width="50" src="../../../assets/services/Influence marketing/1.svg" alt="" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Customised Influencer Campaigns.
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                    <img width="50" s src="../../../assets/services/Influence marketing/2.svg" alt="" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Access to Micro and Macro Influncers
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                    <img width="50" src="../../../assets/services/Influence marketing/3.svg" alt="" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Higher Conversions and ROI
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                    <img width="50" src="../../../assets/services/Influence marketing/4.svg" alt="" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Multiple Services
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <OurPartners />



            <div id="feedback" class="pricing-section-two">
                <div class="fancy-hero-four">

                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">10000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Influencers </div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Niche Coverage</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">97</span>
                                                    <span>%</span>
                                                </div>
                                                <div class="bill-cycle"> Conversions & ROI </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial />

            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                Who are micro-influencers and macro-influencers?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                As per industry standards, micro-influencers are those who have an audience size of 1,000 to 1,000,000 and on the other hand, macro-influencers are those who have a larger number of followers anywhere ranging between 100,000 and one million followers. We also collaborate with nano influencers who have close to a thousand followers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Will you help in crafting content for the influencers?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! At Taskmo, we will help you to craft a highly creative piece of content for the influencer at an additional cost.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Do you also work with celebrity influencers?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes, we also work with celebrity influencers and content creators across several popular niches.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


