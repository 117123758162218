import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Campus(props) {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    // console.log("subscriber_city_name",state.subscriber_city_name)
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const navRef4 = React.useRef(null);
    const navRef5 = React.useRef(null);
    const navRef6 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    const onToggleClick4 = (e) => {
        navRef4.current.classList.toggle("show");
    };

    const onToggleClick5 = (e) => {
        navRef5.current.classList.toggle("show");
    };

    const onToggleClick6 = (e) => {
        navRef6.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Bike Driver Onboarding Services | Onboarding || Taskmo
                </title>
                <meta name="description" content="Taskmo - Provides Best Bike Driver Onboarding Services, Driver Onboarding, benefits of bike driver onboarding, click here for more details." />
                <meta name="keywords" content="onboarding service, bike driver onboarding, driver onboarding, cab onboarding, onboarding benefits, successful onboarding" />
                <link rel="canonical" href="https://taskmo.com/services/merchant-onboarding/bike-driver-onboarding" />
            </Helmet>



            <div class="font-montserrat container mt-20">
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9 title-style-eight">
                                <h1 class="font-montserrat fs-50 fw-600 hero-heading">Bike Onboarding Service</h1>
                                <p class="font-montserrat fs-20 fw-500">We assist ride aggregators, onboard bike drivers who use Bike-sharing mobile apps and make sure that their businesses run smoothly and smoothly.</p>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 mb-20 md-mt-30">Apply Now</a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <img src="../../../assets/img/bike.jpg" alt="Taskmo Bike Onboarding Program" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Benefits of Bike Driver Onboarding services</h2>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}><img width="50" src="../../../assets/services/merchant/auto1.png" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Customisable Workflow
                                </h4>
                                <p>Deliver all features you envision for your business</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}><img width="50" src="../../../assets/campus/communicate.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    White-Labelled
                                </h4>
                                <p>
                                    Create a brand for your business so that you remain unique
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}><img width="50" src="../../../assets/campus/create.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    API Integration
                                </h4>
                                <p>
                                    Fully classified and advanced management software for your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">10</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">20</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen mt-180 md-mt-100" id="effect">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Why Taskmo?</h2>
                                <p class="font-montserrat">
                                    Why is Taskmo the best Bike Driver onboarding service provider?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFCE22' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/bikeDriversOnboarding/deployment.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Instant Deployment</h4>
                                        <p>
                                            Deploy resources in all operational regions to get an increased brand presence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#8F6BF6' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/bikeDriversOnboarding/digital-training.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Technical Training</h4>
                                        <p>
                                            Get technically trained enrolled drivers to the intuitive driver app.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF5C5C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/bikeDriversOnboarding/brand-awareness.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Brand Awareness</h4>
                                        <p>
                                            Strategics awareness campaigns at various public places for drivers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#63EAA9' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/bikeDriversOnboarding/access.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Access to 5000+ Pin codes</h4>
                                        <p>
                                            Manage thousands of employees in diverse geographies and monitor the ground reality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 md-mt-30">Apply Now</a>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the other onboarding offered by Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo offers onboarding for various services such as operations management, proctoring, auditing, due diligence, last-mile delivery, new business development, and digital gigs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Is it possible to board a bike driver anywhere and everywhere?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo is currently tied to 750k+ gig workers across 15+ cities including Tier I and Tier II. So it is possible to onboard drivers across India.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                How is Taskmo’s manpower different from others?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                The gig workers don't need to pay anything whatsoever, and they create an account free of cost and then apply for gigs that fit their schedule, location and abilities. The gigs are frequently posted on Taskmo website and mobile app.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick4} class="btn btn-link font-montserrat">
                                                How does Taskmo upskills its gig workers?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef4} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                At Taskmo, We believe in empowering gig workers by providing them with the appropriate tools and an environment where they are able to learn and earn a living.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
