import React from 'react'
import { styles } from "./RetailStoreAuditStyle";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import storeFront from "../../../../assets/images/store-front.svg";
import ethics from "../../../../assets/images/ethics.svg";
import Ellipse from "../../../../assets/images/Ellipse.svg";
import humanResources from "../../../../assets/images/human.svg";
import customerService from "../../../../assets/images/customer-service.svg";
import customerSatisfaction from "../../../../assets/images/customer-satisfaction.svg";
import sellerRegistration from "../../../../assets/images/sellerRegistration.svg";
import introducingSeller from "../../../../assets/images/introducingSeller.svg";
import elearning from "../../../../assets/images/elearning.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OurPartners from '../../ourPartners/OurPartners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Testimonial from '../../testimonial/Testimonial';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import frame from "../../../../assets/images/Frame.png"


const useStyles = makeStyles(styles);

const RetailStoreAudit = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExpanded("panel1")
    }, [])

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Retail Store Audit Service India | Shop Audit || Taskmo
                </title>
                <meta name="description" content="Taskmo - Best Retail Store Audit Service India helps in performing research on your store operations, inventory, customer experience & other improvements..." />
                <meta name="keywords" content="retail store audit, retail shop audit, audit service, retail audit, internal audit, order management, order management system, store order management, store order management, retail order management" />

                <link rel="canonical" href="https://www.taskmo.com/services/retail-store-audit-service" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is a retail store audit checklist?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A retail store audit checklist is a document used to evaluate the performance and compliance of a retail store. It includes store appearance, inventory management, sales performance, customer service, compliance, marketing and merchandising, technology, and financial performance. The checklist enables retailers to ensure that their stores operate efficiently and comply with suitable standards and regulations."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What is a retail store audit?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A retail audit evaluates the efficiency and performance of a single store or a network of stores. It requires gathering data and information on different company issues, including sales, inventory control, marketing, customer service, legal compliance, and financial performance. A retail audit aims to identify improvement areas and make informed decisions about the business. Taskmo conducts retail audits, and the results are used to optimise operations, increase efficiency, and enhance the customer experience."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What are the types of retail audits?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "There are several types of retail audits, each with its specific focus and purpose. Here are some of the most common types of retail audits include Store audits concentrate on the efficiency and effectiveness of specific retail establishments, paying particular attention to product placement, inventory control, and customer service. Compliance audits determine if the store is per various laws and standards, including taxation, labour, and health and safety.Mystery shopping audits require sending fake customers to the business to assess the customer experience, including the level of customer service, the availability of products, and the cost of those products. Inventory audits emphasise the precision of the inventory counts and the effectiveness of the inventory management procedures in the business. Financial audits evaluate the retailer's sales, costs, and profitability. Every kind of retail audit offers insightful information and data that can be utilised to enhance the operation and performance of the retail business."
                            }
                        }, {
                            "@type": "Question",
                            "name": "What is the difference between a retail store audit and an inspection?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "While a retail shop audit and an inspection both include assessing the performance and compliance of a retail business, there are a few important distinctions between the two: Purpose: When compared to a set of predetermined criteria or objectives, the goal of a retail shop audit is to assess the business's overall performance. In contrast, an inspection often focuses on detecting and resolving particular flaws or problems. Scope: An audit of a retail store usually includes a thorough analysis of every facet of the business, whereas an inspection may have a narrower focus, concentrating on specific problems or locations. Methodology: A retail shop audit may require gathering information and data using various techniques, such as interviews, questionnaires, and data analysis, whereas an inspection may require more direct observation and investigation of physical conditions and procedures. Frequency: A retail shop audit might be carried out regularly, such as quarterly or yearly, or inspections could be done on demand or in reaction to a particular problem or complaint. Overall, a retail store audit provides a more holistic assessment of the store's performance and operations, while an inspection focuses on identifying and addressing specific issues or problems."
                            }
                        }, {
                            "@type": "Question",
                            "name": "How can Taskmo help conduct retail store audits for your brand?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Taskmo assists brands in conducting retail store audits efficiently and effectively through customisable audit checklists, experienced auditors, real-time reporting, data analysis, and compliance tracking to provide valuable insights and data that can be used to optimise operations and enhance the customer experience."
                            }
                        }]
                    })}
                </script>

            </Helmet>


            <div style={{ width: "100%", background: "#F4F5F6", paddingBottom: "100px" }} >
                <div style={{ fontFamily: "Montserrat" }} >

                    {/* header card start */}

                    <div className={classes.headerCardStart} >

                        <div className={classes.headerText}>

                            <div>
                                <h1 style={{ color: "#fff", fontFamily: "Montserrat" }} >
                                    Retail Store Audit Services
                                </h1>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Brand loyalty is a thing of the past since customers now favour companies that offer smooth and satisfying customer experiences. Hence, suppliers are relying on effective retail audit strategies to maintain their competitiveness in the current market. Taskmo's best retail store audit service efficiently enables companies to collect data which optimises the health of their operations and goods thereby providing enhanced customer experiences.
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: "#fff", marginTop: "30px", lineHeight: "23.44px", fontSize: "17px"
                                    }}
                                >
                                    Taskmo has successfully completed over 200k+ store audits for more than 25+ renowned brands, including ITC, P&G, Britannia, Treebo etc. Our team of experts streamlines the entire audit process while providing improved accuracy to evaluate store hygiene and help retailers in identifying errors affecting performance.
                                </p>
                            </div>

                            <div style={{ marginTop: "40px", marginBottom: "50px" }} >
                                <Button variant="contained"
                                    onClick={handleClickOpen}
                                    style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                        Connect with our expert
                                    </p>
                                </Button>
                            </div>

                        </div>

                        <div className={classes.headerImage}
                            data-aos="zoom-in" >
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f8b2c7e06c116dae991b1682683264280_store%20audit%20%282%29%201.png" alt='' style={{ width: "85%" }} />
                        </div>
                    </div>

                    {/* header card end */}


                    {/* How Do We Scale Seller Start */}

                    <div style={{ width: "80%", margin: "auto" }} data-aos="zoom-in-up" >

                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "40px" }} >
                                How Do We Scale Retail Store Audit Services?
                            </h2>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }} >

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src='https://taskmotech.s3.ap-south-1.amazonaws.com/bbbe7cd95b11f263ba391682419057943_01.png' alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Leveraging advanced data analytics
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        for optimised operations
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/4c071f2875bbfc1e224d1682419099105_02.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Customised audit checklist for
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        specific business needs
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px" }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d0c150bdf5fe5015bbfe1682419122623_03.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Streamlined  process
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        saving time & money
                                    </p>
                                </div>
                            </div>

                            <div style={{ maxWidth: "max-content", margin: "auto", marginTop: "60px", }} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/fdabb02c30cd451852581682419147973_04.png" alt="" style={{ height: "100px" }} />
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }} >
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        Seasoned auditors with vast
                                    </p>
                                    <p style={{ fontSize: "16px", fontWeight: 600, lineHeight: "23.44px", }} >
                                        experience in retail audits
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* How Do We Scale Seller end */}

                    {/* connect card start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                        <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                            <CardContent style={{ paddingBottom: "0px" }} >

                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                    <div className={classes.connectCard} >
                                        <div className={classes.topText}>
                                            <div style={{ textAlign: "center" }} >
                                                <p className={classes.connectCardText} data-aos="fade-up" >
                                                    Discover what Taskmo can do for you
                                                </p>
                                            </div>
                                            <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                                <Button
                                                    onClick={handleClickOpen}
                                                    variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                    <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                        Connect with our expert
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={classes.connectCardImg} >
                                            <img src={frame} alt="" style={{ maxWidth: "200px" }} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* connect card end */}

                    {/* How We Work? starts */}

                    <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                        <div class="container">
                            <div class="text-center">
                                <h2 class=" font-montserrat fs-50 fw-600" >How We Work?</h2>
                            </div>
                        </div>
                        <div class="bg-wrapper mt-100 md-mt-80">
                            <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                                <img src="../images/shape/200.svg" alt="" />
                            </a>
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a08d0ab47a5dfa1917211682422010677_one.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }} >
                                                        Determining Auditing Goals
                                                    </h4>

                                                    <p>
                                                        Establishing the benchmarks to be measured during the audit and giving each plan a numerical or qualitative value to measure its success.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/72059038107bc90081ea1682422036229_two.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Gather Information Or Data
                                                    </h4>
                                                    <p>
                                                        Gather accurate and trustworthy data that enables brands to effectively determine strengths, fix oversights, and enhance upon shortcomings.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/cef7c8c14c37061a5e6f1682422067044_three.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        Evaluation Of Data
                                                    </h4>
                                                    <p>
                                                        Utilising advanced technology to standardise data representation and identify trends to meet the audit criteria.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/765833e8fa5b810ed6021682422099693_four.png" alt="" style={{ width: "350px" }} />
                                                </div>
                                                <div class="text">
                                                    <h4 style={{ fontFamily: "Montserrat", }}>
                                                        State-Of-The-Art Security
                                                    </h4>
                                                    <p>
                                                        Our retail audit service enables businesses to safeguard their data and guarantee compliance with regulations.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* How We Work? ends */}


                    {/* Expertise Start */}

                    <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                        <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                            <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                                Why Taskmo?
                            </h2>
                        </div>

                        <div style={{ width: "80%", margin: "auto" }} >

                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "70px",
                                flexWrap: "wrap",
                                gap: "10px"
                            }}>
                                <div className={classes.whyTaskmoCards1}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        200k+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Store Audits Conducted
                                    </p>
                                </div>

                                <div className={classes.whyTaskmoCards2}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        10000+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Pincodes Across India
                                    </p>
                                </div>

                                <div className={classes.whyTaskmoCards3}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        25+
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Brands Catered
                                    </p>
                                </div>

                                <div className={classes.whyTaskmoCards3}>
                                    <h2 style={{
                                        color: "#F7D159",
                                        fontSize: "40px",
                                        fontFamily: "Montserrat"
                                    }}>
                                        96%
                                    </h2>
                                    <p style={{
                                        color: "#fff",
                                        lineHeight: "23.44px",
                                        fontSize: "19px",
                                        fontWeight: 400,
                                        marginTop: "15px"
                                    }}>
                                        Productivity Delivered
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Expertise ends */}

                    <OurPartners />

                    {/* use cases start */}

                    <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    Success Stories
                                </h2>
                            </div>

                            <Grid container spacing={3} >

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#dcecff", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/077674b4c1653c3a03d91682422199263_1200px-ITC_Limited_Logo.svg.png" alt="" style={{ width: "80%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers} >
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >7000+</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Successful store audits helped to gather valuable insights leading to enhanced customer experience and satisfaction.
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card style={{ background: "#ffe4e6", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "212px" }} data-aos="zoom-in-up">
                                        <CardContent>
                                            <div className={classes.flipKartCard} >
                                                <div className={classes.successStoriesImageDiv}>
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/a6db0cd201e7e9fda8dc1682422271318_Britannia_Industries_logo.svg.png" alt="" style={{ width: "90%" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900, marginTop: "-20px" }} >90%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Product insights delivered to enhance brand recall value
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: "auto" }} >
                                    <Card style={{ background: "#e8f3ff", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "198px" }} data-aos="zoom-in-up">
                                        <CardContent >
                                            <div className={classes.flipKartCard}>
                                                <div className={classes.successStoriesImageDiv} style={{ marginTop: "30px" }} >
                                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/4e68d0011625b7ee10bf1682422339832_P%26G_logo.png" alt="" style={{ width: "90%", margin: "auto" }} />
                                                </div>

                                                <div className={classes.successStoriesNumbers}>
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >85%</p>
                                                </div>

                                                <div className={classes.successStoriesText}>
                                                    <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                        Accuracy delivered in gathering honest feedback on select products to enhance product quality
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    {/* use cases end */}



                    {/* client testimoidal start */}

                    <Testimonial />

                    {/* client testimoidal end */}

                    {/* contact page start */}

                    <section id="hire-taskers-services">

                        <div class="font-montserrat container mt-80 md-mt-50">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="row align-items-center justify-content-center">
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-9">
                                            <div class="consultation-text pr-xl-5">
                                                <div class="section-heading heading-white mb-30">
                                                    <h2 class="font-montserrat fs-50 fw-600">Leverage Your Business With Us!</h2>
                                                </div>
                                                <p class="fs-18">
                                                    At Taskmo, we work with businesses and enterprises of all sizes by offering services across multiple industries. We help you scale up your business efficiently through our distributed gig workers across the country.</p>
                                                <div class="counter-items row">
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">92</span>

                                                                <span class="suffix">%</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px", fontFamily: "Montserrat" }} class="title font-montserrat">Customer Satisfaction</h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="counter-item mt-40">
                                                            <div class="counter-wrap">
                                                                <span class="counter">2000000</span>
                                                                <span class="suffix">+</span>
                                                            </div>
                                                            <h6 style={{ fontSize: "18px" }} class="title font-montserrat">Tasks Completed</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 wow fadeInUp">
                                            <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                                <Find_Taskers_Common subscriber_type="Contact" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* contact page end */}


                    {/* faq Start */}

                    <div style={{ width: "80%", margin: "auto", marginTop: "70px" }} >
                        <div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                    FAQ’s
                                </h2>
                            </div>

                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is a retail store audit checklist?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            A retail store audit checklist is a document used to evaluate the performance and compliance of a retail store. It includes store appearance, inventory management, sales performance, customer service, compliance, marketing and merchandising, technology, and financial performance. The checklist enables retailers to ensure that their stores operate efficiently and comply with suitable standards and regulations.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is a retail store audit?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                A retail audit evaluates the efficiency and performance of a single store or a network of stores. It requires gathering data and information on different company issues, including sales, inventory control, marketing, customer service, legal compliance, and financial performance. A retail audit aims to identify improvement areas and make informed decisions about the business. Taskmo conducts retail audits, and the results are used to optimise operations, increase efficiency, and enhance the customer experience.
                                            </p>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What are the types of retail audits?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                There are several types of retail audits, each with its specific focus and purpose. Here are some of the most common types of retail audits include
                                            </p>

                                            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        1.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Store audits concentrate on the efficiency and effectiveness of specific retail establishments, paying particular attention to product placement, inventory control, and customer service.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        2.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Compliance audits determine if the store is per various laws and standards, including taxation, labour, and health and safety.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        3.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Mystery shopping audits require sending fake customers to the business to assess the customer experience, including the level of customer service, the availability of products, and the cost of those products.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        4.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Inventory audits emphasise the precision of the inventory counts and the effectiveness of the inventory management procedures in the business.
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        5.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        Financial audits evaluate the retailer's sales, costs, and profitability.
                                                        Every kind of retail audit offers insightful information and data that can be utilised to enhance the operation and performance of the retail business.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >04</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                What is the difference between a retail store audit and an inspection?
                                            </h2>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p>
                                                While a retail shop audit and an inspection both include assessing the performance and compliance of a retail business, there are a few important distinctions between the two:
                                            </p>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        1.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Purpose: </b>
                                                        When compared to a set of predetermined criteria or objectives, the goal of a retail shop audit is to assess the business's overall performance. In contrast, an inspection often focuses on detecting and resolving particular flaws or problems.

                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        2.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Scope: </b>
                                                        An audit of a retail store usually includes a thorough analysis of every facet of the business, whereas an inspection may have a narrower focus, concentrating on specific problems or locations.

                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        3.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Methodology: </b>
                                                        A retail shop audit may require gathering information and data using various techniques, such as interviews, questionnaires, and data analysis, whereas an inspection may require more direct observation and investigation of physical conditions and procedures.

                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }} >
                                                <div>
                                                    <div>
                                                        4.
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Frequency: </b>
                                                        A retail shop audit might be carried out regularly, such as quarterly or yearly, or inspections could be done on demand or in reaction to a particular problem or complaint.

                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "10px" }} >
                                                <p>
                                                    Overall, a retail store audit provides a more holistic assessment of the store's performance and operations, while an inspection focuses on identifying and addressing specific issues or problems.
                                                </p>
                                            </div>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >

                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                            <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >05</p>
                                            <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                How can Taskmo help conduct retail store audits for your brand?
                                            </h2>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Taskmo assists brands in conducting retail store audits efficiently and effectively through customisable audit checklists, experienced auditors, real-time reporting, data analysis, and compliance tracking to provide valuable insights and data that can be used to optimise operations and enhance the customer experience.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </div>
                    </div>

                    {/* faq end */}

                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-aos="zoom-in-up"
                    style={{ zIndex: "100000" }}
                >
                    <DialogContent style={{ margin: "0px", padding: "0px" }} >
                        <div>
                            <Find_Taskers_Common subscriber_type="Contact" />
                        </div>
                    </DialogContent>
                </Dialog>

            </div >
        </>
    )
}

export default RetailStoreAudit