import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from "react-helmet";
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function BackgroundVerification() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Background Verification Services | BGV Companies
                </title>
                <meta name="description" content="Background Verification Services, Background Verification Services, bgv verification, bgv company, background check, background search, accurate backgrounds checks" />
                <meta name="keywords" content="Background Verification Services, Background Verification Services, bgv verification, bgv company, background check, background search, accurate backgrounds checks" />
                <link rel="canonical" href="https://taskmo.com/services/background-verification" />
            </Helmet>


            <div class="pt-100 fancy-text-block-seven mt-60 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Background Verification
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    {/* <h3 class="title font-montserrat fs-50 fw-600">Merchant Onboarding</h3> */}
                                    <p class="mt-20 fs-18">
                                        Human resources are undoubtedly an integral part of every firm. Your company needs to ensure that all your employees, contractors, agents, and freelancers must come from a trusted background. We understand that background verification is an essential part of the recruitment process. Taskmo is a highly reputed background verification service provider in India. We provide background verification services to firms across all sectors. We employ the right individuals and train them digitally to conduct accurate background checks following the standards of your organisation within a quick turnaround time. We are proud to say that Taskmo is the best background verification company in the country and we have performed successful background checks for several popular brands. We have a powerful team of dedicated individuals who help to design a reliable yet customised background verification process.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div id="section" class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <Find_Taskers_Common subscriber_type="Background Verification"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}>
                                                    <img width="50" src="../../../assets/services/Background verification/1.svg" alt="Instant Access to Expert Onboarding Specialists" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Instant Access to Expert Onboarding Specialists </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                    <img width="50" src="../../../assets/services/Background verification/2.svg" alt="Pre-Vetted and Screened On-Ground Experts " />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Pre-Vetted and Screened On-Ground Experts </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                    <img width="50" src="../../../assets/services/Background verification/3.svg" alt="A unique and distinctive onboarding processes." />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A unique and distinctive onboarding processes.</p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                    <img width="50" src="../../../assets/services/Background verification/4.svg" alt="A broad range of industry bound onboarding services" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A broad range of industry bound onboarding services</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                <div class="container">
                    <div class="text-center">
                        <h2 class=" font-montserrat fs-50 fw-600">Our Services</h2>
                    </div>
                </div>
                <div class="bg-wrapper mt-100 md-mt-80">
                    <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                        <img src="../images/shape/200.svg" alt="" />
                    </a>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                            <img width="35" src="../../../assets/services/Background verification/employee.svg" alt="Employee Verification" />
                                        </div>
                                        <div class="text">
                                            <h4>Employee Verification</h4>
                                            <p>A complete process of verifying and validating potential candidate details from their previous organisations. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4F86" }}>
                                            <img width="35" src="../../../assets/services/Background verification/address.svg" alt="Address Verification" />
                                        </div>
                                        <div class="text">
                                            <h4>Address Verification</h4>
                                            <p>A thorough physical check of the address provided by the candidate at the time of employment. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                            <img width="35" src="../../../assets/services/Background verification/education.svg" alt="Education Verification" />
                                        </div>
                                        <div class="text">
                                            <h4>Education Verification</h4>
                                            <p>A verification process to check and validate the professional qualifications of candidates as issued by their respective educational institutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="block-style-twentyFour">
                                    <div class="d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                            <img width="35" src="../../../assets/services/Background verification/professional.svg" alt="Professional Verification" />
                                        </div>
                                        <div class="text">
                                            <h4>Professional Verification</h4>
                                            <p> For certain organisational roles, it almost becomes mandatory for us to conduct specialised professional checks to check the authenticity of the potential candidate. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div id="feedback">
                <OurPartners />
            </div>

            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">100000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Verifications Completed</div>
                                            </div>
                                        </div>

                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">97</span>
                                                    <span>%</span>
                                                </div>
                                                <div class="bill-cycle"> Accurate Reports</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#section" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Testimonial />


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                Can I choose the particular Background Verification service that I want to get done?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes, you are free to choose the right background verification services from our range of services. However, Taskmo will also suggest some accurate background verification services according to your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Will I get a copy of the background verification report?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes. Taskmo is a certified organisation under the MSME Act of India, Taskmo will provide the client companies with all the necessary reports.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Can I avail any custom background verification service from Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! As a leading gig economy service provider, we provide all types of custom background verification services for your organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


