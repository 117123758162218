import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';

export default function BrandActivation() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    BTL Activation Services | Brand Activation | ATL BTL || Taskmo
                </title>
                <meta name="description" content="Taskmo - Best BTL Activation Service provider, BTL Marketing, ATL BTL advertising Agency, Brand activation Services, click to know more..." />
                <meta name="keywords" content="btl activation, services, atl btl, btl activities, atl btl marketing, btl advertising, atl btl activities, brand activation" />
                <link rel="canonical" href="https://taskmo.com/services/btl-activation-services" />
            </Helmet>


            <div class="pt-100 fancy-text-block-seven mt-60 md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        BTL Activation Services
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        With the increase in the number of brands everyday, there is a high demand for brands to create a genuine relationship with their customers. Today, brands that are old and new are increasingly opting for brand activation services to help reach out to their target market.<br /><br />
                                        At Taskmo, with our experiential marketing techniques we ensure that all our brand activation services are directed towards creating an immersive experience for your audience. Through our brand activation services, we not only help to generate brand awareness and create conversions but rather we ensure that your customers create a strong bond with the brand.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Brand Activation"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="title-style-two text-center mt-10md-mb-50">
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}>
                                                    <img width="50" src="../../../assets/services/Activation landing page/1.svg" alt="Instant Access to Activation Experts" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Instant Access to Activation Experts
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                    <img width="50" src="../../../assets/services/Activation landing page/2.svg" alt="Pre -Vetted and Screened On-Ground Taskers" />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    Pre -Vetted and Screened On-Ground Taskers
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                    <img width="50" src="../../../assets/services/Activation landing page/3.svg" alt="A unique, distinctive and customised marketing practices." />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A unique, distinctive and customised marketing practices.
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                    <img width="50" src="../../../assets/services/Activation landing page/4.svg" alt="A broad range of industry brand activation services." />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>
                                                    A broad range of industry brand activation services.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="feedback" class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">75000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Taskers </div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle"> Cities </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OurPartners />

            <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial />

            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the different types of Brand Activation Services offered at Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Our brand activation services include shop activation services, store activation services, retail activation services, residency activation services, doorstep activation services and so many more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Do you offer both BTL Activation and ATL Activation Services?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! As a leading brand activation service provider, we offer services that are related to BTL Marketing, BTL Activation, BTL and ATL Advertising and so many more. Speak to our experts today to know more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


