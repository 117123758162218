import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BusinessFormModal from '../../../BusinessFormModal'


export default function AmazonCaseStudy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Amazon Seller Central | Onboarding | Acquisition || Taskmo
                </title>
                <meta name="description" content="Taskmo - Simplified the Amazon Seller Central - Onboarding and Acquisition process by segmenting the task, into a) Onboarding b) Merchant Listing. Click here..." />
                <meta name="keywords" content="Amazon Seller Central, amazon seller account, seller central amazon india, amazon seller, amazon seller account, amazon best sellers, amazon seller app, seller central amazon india, acquisition" />
                <link rel="canonical" href="https://taskmo.com/case-study/amazon-seller-central" />
            </Helmet>
            {/* <!--====== Portfolio Area Started ======--> */}
            <section class="portfolio-area" style={{ marginTop: "30px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 order-first">
                            <div class="portfolio-thumb">
                                <img src="../../assets/amazon.png" alt="amazon casestudy" />
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-last">
                            <div class="portfolio-info-box wow fadeInUp">
                                <h4 class="font-montserrat fw-700">Project Info</h4>
                                <ul>
                                    <li>
                                        <span class="font-montserrat fw-600">Client</span>
                                        <span class="font-montserrat fw-500">Amazon Seller Central</span>
                                    </li>
                                    <li>
                                        <span class="font-montserrat fw-600">Project Category</span>
                                        <span class="font-montserrat fw-500">Onboarding and Acquisition</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="portfolio-details-content wow fadeInUp">
                                <h2 class="font-montserrat fs-50 fw-600">Amazon Seller Central - Onboarding and Acquisition</h2>
                                <h3 class="font-montserrat fw-600 mt-50">Challenge</h3>
                                <p class="mb-30 fs-18">
                                    Amazon Seller Central, a wing under Amazon is a platform that enables merchants to list their products online. The major challenge they faced was to reach out to the right merchants, explain the benefits of the platform and help them to register as a seller of Amazon successfully.
                                </p>
                                <h3 class="font-montserrat fw-600 sub-title">Solution</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo simplified the process by segmenting the task into a) Onboarding  b) Merchant Listing. The taskers undertook field visits to visit potential merchants and explained the benefits of the platform to them. It helped Amazon to conveniently onboard genuine merchants to their platform.
                                </p>

                                <div>
                                    <div class="col-lg-9">
                                        <div class="feature-lists mt-30">
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">300+  Gigsters</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">5x Faster Onboarding Rate</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">2000+ Working Hours</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">250+ Pincodes</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Portfolio Area End ======--> */}
            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-10 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <BusinessFormModal subscriber_type="Amazon Casestudy Page" Button_Name="Let's Talk" color="#000" background="#fff" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}