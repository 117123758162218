import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import PopularCaseStudy from '../../home/popularCaseStudy/PopularCaseStudy';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import { Link } from "react-router-dom";
import { localRoute } from 'routes/localRoutes';


export default function MerchantOnboarding() {
    const navRef1 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ marginTop: "20px" }}>


            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Taskmo - Best Merchant Onboarding Services In India
                </title>
                <meta name="description" content="Taskmo Known for India's Best Merchant Onboarding Service provider in India, GIG Economy Jobs, Customer Onboarding, process is done in a smooth and efficient manner." />
                <meta name="keywords" content="Best Merchant Onboarding, GIG Economy Jobs, Customer Onboarding, Employee Onboarding, Onboarding" />
                <link rel="canonical" href="https://taskmo.com/services/merchant-onboarding" />
            </Helmet>

            <div class="fancy-text-block-seven md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Merchant / Partner Onboarding
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Merchant Onboarding" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        If you are a payment service provider or a payment aggregator, merchant onboarding plays a key role in contributing to your revenue. With the rise of global small businesses, there has been an increase in the number of micro-merchants.
                                    </p>
                                    <p class="mt-20 fs-18 text-align-left">
                                        At Taskmo, we help payment facilitators by quickly onboarding merchants to their respective payment platforms. We at Taskmo, have a group of trained executives who will ensure that the customer onboarding process is done in a smooth and efficient manner. Our team of experts will understand your requirements for the merchant onboarding process and help in onboarding merchants five times faster, bringing to  you the desired results.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Merchant Onboarding" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="pricing-section-two">
                <div class="fancy-hero-four" data-aos="fade-up" data-aos-duration="1200">
                    <div class="title-style-two text-center md-mb-50" >
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div style={{ border: "none" }} data-aos="fade-up" data-aos-duration="1200" class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/merchant/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Instant Access to Expert Onboarding Specialists </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/merchant/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Pre-vetted and Screened On-Ground Experts </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/merchant/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A unique and distinctive onboarding processes</p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}><img width="50" src="../../../assets/services/merchant/4.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A broad range of industry bound onboarding services</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-twentyOne mt-10 md-mt-80" id="template">
                <div class="container">
                    <div class="font-montserrat title-style-eight  text-center md-mb-10" data-aos="fade-up" data-aos-duration="1200">
                        <h2 class="font-montserrat fs-50">Onboarding Partner</h2>
                        <p style={{ padding: "0px" }} >In addition to merchant onboarding, Taskmo also offers partner onboarding services. Our major partner onboarding services include </p>
                    </div>
                </div>
                <div class="lg-container">
                    <div class="container">
                        <div class="row justify-content-center">


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <Link to={localRoute.autoOnboarding}>
                                    <div class="block-style-twentyOne" >
                                        <img src="../../../assets/services/merchant/Onboading/auto.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white "> Auto-Drivers Onboarding  </span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <Link to={localRoute.cabOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/cab.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white "> Cab Drivers Onboarding </span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <Link to={localRoute.bikeOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/bike onboarding.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white ">Bike Drivers Onboarding </span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <Link to={localRoute.teacherOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/teacher.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white ">Teachers Onboarding</span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <Link to={localRoute.doctorOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/doctor.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white ">Doctors Onboarding </span>
                                        </a>
                                    </div>
                                </Link>
                            </div>



                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <Link to={localRoute.deliveryOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/delivery.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white "> Delivery Executives Onboarding</span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <Link to={localRoute.vendorOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/vendor.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white "> Vendors Onboarding</span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <Link to={localRoute.shopOnboarding}>
                                    <div class="block-style-twentyOne">
                                        <img src="../../../assets/services/merchant/Onboading/shop.png" alt="" class="w-100" />
                                        <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                            <span class="cl-white ">Shop Onboarding</span>
                                        </a>
                                    </div>
                                </Link>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <OurPartners />
            <PopularCaseStudy />

            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">5</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">500000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial />

            <div class="faq-classic pt-10 ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thought? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                Does Taskmo offer partner onboarding services as well?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="show collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! Taskmo can help your firm with onboarding auto drivers, onboarding cab drivers, onboarding delivery executives, onboarding teachers, onboarding doctors and other vendors. We understand your requirements carefully and give you the desired results.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
