import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BusinessFormModal from '../../../BusinessFormModal'
export default function PhonepayCaseStudy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Phonepe | Merchant Onboarding | Penny Drop Transactions || Taskmo
                </title>
                <meta name="description" content="Taskmo - Streamlined the Phonepe Merchant Onboarding Process in Penny Drop Transactions by digitally trained Taskers to acquire merchants. Click to read more..." />
                <meta name="keywords" content="phonepe, phone pe, phonepe business, phonepe download, phonepe app download, phone pe app, phone pe business, phonepe merchant, penny drop, merchant onboarding, payment, transactions" />
                <link rel="canonical" href="https://taskmo.com/case-study/phonepe-penny-drop-transactions" />
            </Helmet>
            <section class="portfolio-area" style={{ marginTop: "30px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 order-first">
                            <div class="portfolio-thumb">
                                <img src="../../assets/case-study/phonepe.png" alt="phonepay casestudy" />
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-last">
                            <div class="portfolio-info-box wow fadeInUp">
                                <h4 class="font-montserrat fw-600">Project Info</h4>
                                <ul>
                                    <li>
                                        <span class="font-montserrat fw-700">Client</span>
                                        <span class="font-montserrat fw-500">Phone Pe</span>
                                    </li>
                                    <li>
                                        <span class="font-montserrat fw-700">Project Category</span>
                                        <span class="font-montserrat fw-500"> Merchant Onboarding </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="font-montserrat portfolio-details-content wow fadeInUp">
                                <h2 class="font-montserrat fs-50 fw-600">Phone Pe - Merchant Onboarding</h2>

                                <h3 class="font-montserrat fw-600 mt-50">ABOUT PhonePe-</h3>
                                <p class="mb-10 mt-20 fs-18">
                                    The need for comfort and ease in everything has increased quite a lot in today's world. Carrying cash is bothersome and why bother when there is a possibility of our payments being done via phone? Thus, India moved towards digital payment and mobile wallets. One of the major exponents in this sector is PhonePe.
                                </p>
                                <p class="mb-10 fs-18">
                                    Today, mobile users prefer to pay online for everything, be it groceries, utility bills, phone bills, or even fund transfers. People trust digital payments for large transactions, removing the physical risk factors and streamlining the fintech industry.
                                </p>
                                <p class="mb-30 fs-18">
                                    PhonePe, the Indian UPI- based app, is one such payment app that has made the lives of millions of Indians simpler. PhonePe is the market leader in the digital payment space. The app allows users to access their app anytime and anywhere. You can easily link your credit and debit cards for payments. It also allows instant transfer of money.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-50">CHALLENGES-</h3>

                                <h3 class="font-montserrat fw-600 mt-20">Scalability</h3>
                                <p class="mb-10 fs-18">
                                    PhonePe wasn't new to the industrychallenges, it had several big competitors. Hence, it needeed help in making people aware of their existence. They wanted our gig workers to help educate people on how PhonePe differs from other digital payment platforms and what benefits it offers them.
                                </p>
                                <p class="mb-30 fs-18">
                                    After the QR code penetration, they wanted to onboard the Kirana stores across Tier 2 and Tier 3 cities to their platform across India. As people in that region weren't much aware of digital payment, so it was a big challenge to educate them to onboard their shops on PhonePe.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-20">Digital Payment Acceptance </h3>
                                <p class="mb-30 fs-18">
                                    PhonePe wanted to encourage merchants to avail the benefits of online payments to reduce the hassle associated with cash payments, such as no change.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-50">SOLUTION-</h3>

                                <h3 class="font-montserrat fw-600 mt-20">Merchant Onboarding across India</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo helped PhonePe in scaling their business by partnering with them in 2021. Taskmo's distributed network of gig workers across India made sure to visit the store and educate merchants about the platform as PhonePe executives. Taskmo made sure to onboard more and more merchants to the platform and scaled their operations.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-20">Live Tracking</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo's advanced tech- stack allowed clear visibility of the day-to-day activities. An online dashboard was offered to track and optimize the operation of the onboarded merchants to ensure they were using the PhonePe platform.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-50">RESULTS-</h3>
                                <p class="mb-30 fs-18">
                                    PhonePe had a significant increase in the number of merchants on its platform. Taskmo was able to onboard over 10000 onboarding across 100+ Pin Codes all over India. The onboard merchants were monitored to see their activity on the PhonePe platform. The merchants were KYC verified and QC Checked to ensure no repeated merchants were onboarded, thus, giving 100% verified leads.
                                </p>

                                {/* <div>
                                    <div class="col-lg-9">
                                        <div class="feature-lists mt-30">
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">300+  Gigsters</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">5x Faster Onboarding Rate</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">2000+ Working Hours</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">250+ Pincodes</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Portfolio Area End ======--> */}



            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-10 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <BusinessFormModal subscriber_type="Phonepay Casestudy Page" Button_Name="Let's Talk" color="#000" background="#fff" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
