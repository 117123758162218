import { GET_CAREER_CATEGORIES, GET_CAREER_CATEGORIES_ERR } from '../../actionTypes/careerType'
import { getJobCategoriesApi } from 'api/Api';
import axios from "axios";

export const getJobCategories = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getJobCategoriesApi}/${payload.filter}/${payload.page}`)
        console.log("GET CAREER CATEGORIES", results)
        let data = results && results.data
        dispatch({
            type: GET_CAREER_CATEGORIES,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_CAREER_CATEGORIES_ERR,
            payload: error && error.response
        });
    }













    
}

