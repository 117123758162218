import React from 'react'

export default function Feature() {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
        navRef1.current.classList.toggle("minusicon");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    return (
        <>
            <div class="font-montserrat mt-130 md-mt-60">
                <div class="container ">
                    <div class="row align-items-center">
                        <div data-aos="fade-up" class="col-lg-6">
                            <div class="mb-35">
                                <h2 class="font-montserrat fw-600 fs-50">
                                    Why Choose Us?
                                </h2>
                            </div>

                            <div id="accordion" class="accordion-style-two pr-5">
                                <div class="card">
                                    <div class="card-header arrow-open" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="fw-600 font-montserrat btn btn-link">
                                                Cost- Efficient
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            <p style={{ fontSize: "18px" }}>By leveraging the skills of distributed gig workers, we can help you significantly lower costs while achieving desired results.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="fw-600 font-montserrat btn btn-link">
                                                Optimize Efficiency
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" ref={navRef2} class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body">
                                            <p style={{ fontSize: "18px" }}>Outsourcing tasks to Taskmo can help you ensure that the task gets done in a prompt and efficient manner and thereby saving time and resources.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="fw-600 font-montserrat btn btn-link">
                                                Increased Flexibility
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="card-body">
                                            <p style={{ fontSize: "18px" }}>Access to a national footprint of taskers to help businesses to get work done easily and quickly without scaling your in-house workforce.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div data-aos="zoom-in-up" class="col-lg-6 col-md-8 m-auto" >
                            <div class="illustration-holder d-flex justify-content-end md-mt-50">
                                <img src="assets/home/why choose us.png" alt="why choose us" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
