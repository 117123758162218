import React from 'react'
import { useEffect } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ThankYou = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ textAlign: "center" }}>
            <div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                    <CheckCircleIcon style={{ color: "#008001", fontSize: "100px" }} />
                </div>

                <h2>Thank you for submitting</h2>
                <h1>Our team will get back to you soon</h1>
            </div>

        </div>
    )
}

export default ThankYou