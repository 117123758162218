import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validationBusiness';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';

const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_campany_name: '',
  subscriber_message: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );


const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function D2C_Button(props) {
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);

  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([])
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);

  const handleClose = () => {
    setOpen(false)
    setErrors(!validation(state));
  }

  const handleChangeNumberLeadInput = (event) => {
    console.log('event', event.target.value)
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));

    if (
      state.subscriber_name !== '' &&
      state.subscriber_email !== '' &&
      !state.subscriber_email.match("gmail") &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.subscriber_campany_name !== '' &&
      state.subscriber_message !== ''
    ) {
      const postData = {
        subscriber_name: state.subscriber_name,
        subscriber_email: state.subscriber_email,
        subscriber_phone: state.subscriber_phone,
        subscriber_campany_name: state.subscriber_campany_name,
        subscriber_message: state.subscriber_message,
        subscriber_type: props.subscriber_type,
        visitor_type: 'hire_tasker'
      };

      createSubscriber(postData)(subscriberDispatch)
      setState(init);
      setOpenNotification(true);
      console.log('Create Subscriber Detail', postData)
    }
  }


  useEffect(() => {
    console.log('subscriberState', subscriberState)
    let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
    if (status == true) {
      console.log("store", store);
      subscriberState.createSubscriber = {
        loading: false,
        error: null,
        data: null,
      }
    }
  }, [subscriberState])


  return (
    <div >
      {props.color ?
        <>
          <a style={{ borderRadius: "10px", color: props.color, background: props.background, cursor: "pointer", }} onClick={handleOpen} class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">{props.Button_Name}</a>
        </> :
        <>
          <a style={{ borderRadius: "10px", color: "white", cursor: "pointer" }} onClick={handleOpen} class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let’s task together!</a>
        </>}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <div style={{ height: 540, overflowY: 'auto', display: 'inline-grid', padding: "0px", position: "absolute" }} class="form-left col-lg-4 col-md-8 wow fadeInUp">
            <Snackbar open={openNotification} onClose={handleCloseNotification} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert severity="success" sx={{ width: '100%' }}>
                Form Successfully Submitted!
              </Alert>
            </Snackbar>
            <div style={{ margin: "0px" }} class="consultation-form-area pl-xl-3 content-mt-md-50">
              <div class="consultation-form">
                <div style={{ display: "flex" }}>
                  <h4 class="pb-10 font-montserrat fs-20 fw-600">Get in touch with our experts!</h4>
                  <CloseIcon style={{ cursor: "pointer", marginLeft: "10%" }} onClick={handleClose} />
                </div>
                <form action="#">
                  <div class="font-montserrat input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Full Name*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                    </Grid>
                    {errors.subscriber_name && <p style={{ color: 'red' }}>{errors.subscriber_name}</p>}
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Work Email*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                    </Grid>
                    {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Phone*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                    </Grid>
                    {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}

                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Company Name*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_campany_name" value={state.subscriber_campany_name} onChange={handleChange} />
                    </Grid>
                    {errors.subscriber_campany_name && <p style={{ color: 'red' }}>{errors.subscriber_campany_name}</p>}
                  </div>
                  <div class="input-field">
                    <Grid xs={12} >
                      <TextField fullWidth label="Describe your requirement*"
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }} variant="outlined" type="text" name="subscriber_message" value={state.subscriber_message} onChange={handleChange} />
                    </Grid>
                    {errors.subscriber_message && <p style={{ color: 'red' }}>{errors.subscriber_message}</p>}
                  </div>
                  <div class="input-field">
                    <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                  </div>
                </form>
              </div>
            
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
