import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import TeacherOnboarding from "../../../../components/services/merchant/onboardingServices/teacherOnboarding/index";

const TeacherOnboardingPage = React.memo((props) => {
  return (
    <>
      <TeacherOnboarding />
    </>
  );
});

export default withRouter(TeacherOnboardingPage);
