import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AutoOnboardingStyle";
import AutoOnboarding from "../../../../components/services/merchant/onboardingServices/autoOnboarding/index";

const AutoOnboardingPage = React.memo((props) => {
  return (
    <>
      <AutoOnboarding />
    </>
  );
});

export default withRouter(AutoOnboardingPage);
