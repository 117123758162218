import React, { useEffect, useState, useContext, } from "react";
import { getAllNews } from "../../../context/actions/pressRelease/getAllNews";

import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from "moment";
import { createNews } from "context/actions/pressRelease/createCareer";
import validation from '../../validation';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const init = {
	subscriber_email: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
	String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);

export default function Newsletter(props) {
	const { newsState, newsDispatch } = useContext(GlobalContext);
	const [filterNewsDetails, setFilterNewsDetails] = React.useState([]);
	const [tabFilter, setTabFilter] = React.useState('active');
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(init);
	const [open, setOpen] = useState(false);
	const [openNotification, setOpenNotification] = useState(false);
	const handleOpenNotification = () => setOpenNotification(true);
	const handleCloseNotification = () => setOpenNotification(false);

	const history = useHistory()

	const handleClose = () => {
		setState(init);
		setOpen(false)
		setErrors(!validation(state));
	}

	const handleOpen = () => {
		setOpen(true)
	}


	useEffect(() => {
		window.scrollTo(0, 0)
		AOS.init({
			duration: 2000
		});
	}, [])

	useEffect(() => {
		let status = tabFilter
		getAllNews({ type: "news_letter", status, page: "all" })(newsDispatch)
	}, [])



	useEffect(() => {
		let status = newsState.getAllNews.status
		console.log('newsstatus', status);
		if (status === true) {
			setFilterNewsDetails(newsState.getAllNews.data.res)
		}
		// SERVER ERROR RESPONSE
		if (status === 500) {
			console.log("Server Error:", newsState.getAllNews)
		}
		// SERVER VALIDATION ERROR RESPONSE  
		if (status === 401) {
			console.log("Validation Error:", newsState.getAllNews)
		}
	}, [newsState])

	console.log("filterNewsDetails", filterNewsDetails);


	useEffect(() => {
		console.log('newsState', newsState)
		let status = newsState.createNews && newsState.createNews.status
		if (status == true) {
			newsState.createNews = {
				loading: false,
				error: null,
				data: null,
			}
		}
	}, [newsState])



	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};


	const handleClick = e => {
		e.preventDefault()
		setErrors(validation(state));
		if (
			state.subscriber_email !== '' &&
			validateEmail(state.subscriber_email)
		) {

			const postData = {
				subscriber_email: state.subscriber_email,
			}

			console.log('Create News Email', postData)
			createNews(postData)(newsDispatch)
			setOpenNotification(true);

			setState(init);
		}
	}



	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					India’s 1st Gig Economy Newsletter || Taskmo
				</title>
				<meta name="description" content="Read our detailed monthly edition taskmo newsletters (gig journals) on gig economy & how we helped in scaling businesses and gig workers in the gig economy" />
				<meta name="keywords" content="Social Gig Platform, Gig Economy, Gig Workers, Gig News,Gig Jobs, Gig topics, Gig updates, Gig Newsletter" />
				<link rel="canonical" href="https://taskmo.com/newsletter" />
			</Helmet>

			<div class="blog-v3" style={{ marginTop: "30px" }}>
				<Snackbar open={openNotification} onClose={handleCloseNotification} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
					<Alert severity="success" sx={{ width: '100%' }}>
						You Successfully Subscribe!
					</Alert>
				</Snackbar>
				<div style={{ cursor: "pointer" }} class="container">
					<img id="img1" src="../../../assets/news/newsletterdesktop.jpeg" style={{ borderRadius: "10px" }} class=" illustration" />
					<img id="img2" src="../../../assets/news/newslettermobile.jpg" style={{ borderRadius: "10px", margin: "auto" }} class=" illustration" />
				</div>

				<div class="fancy-short-banner-three mt-50 md-mt-40">
					<div class="container">
						<div style={{ padding: "25px", background: "#f3edff", borderRadius: "10px" }}>
							<div class="row align-items-center">
								<div class="col-lg-6">
									<div class="title-style-one">
										<h2 class="font-montserrat fw-600" style={{ fontSize: "36px" }}>Subscribe to our newsletter</h2>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-wrapper">
										<form action="#">
											<input
												type="email"
												name="subscriber_email"
												value={state.subscriber_email}
												onChange={handleChange}
												placeholder="Email address" />
											<span style={{ color: 'red' }}>{errors.subscriber_email}</span>

											<button onClick={handleClick}>Subscribe</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style={{ marginTop: "30px" }} class="container">
					<div class="font-montserrat feature-blog-one blog-page-bg">
						<div class="container">
							<div class="row">
								{filterNewsDetails.map(item =>
									<div class="col-lg-4 col-md-6">
										<div class="post-meta">
											<img src={item.news_image} alt={item.news_title} class="image-meta" />
											<div style={{ margin: "10px 0px 0px", padding: "0px" }} class="font-montserrat tag">
												{moment(item.news_date).format('DD-MM-YYYY')}
											</div>
											<h4>
												<a href={item.news_pdf} target="_blank" style={{ cursor: "pointer" }} class="font-montserrat title fw-600">
													{item.news_title}
												</a>
											</h4>
											<a target="_blank" href={item.news_pdf} style={{ cursor: "pointer" }} class="read-more d-flex justify-content-between align-items-center">
												<span>Read More</span>
												<i class="flaticon-right-arrow"></i>
											</a>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

