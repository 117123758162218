import React from 'react'
export default function GrowWithUs() {
    return (
        <div class="leftAlign">
            <div data-aos="zoom-in-up" style={{ textAlign: "center" }} class="pt-50 container font-montserrat">
                <div class="feature-text-block content-r-spacing">
                    <div class="section-heading mb-10">
                        <h2 style={{ lineHeight: "1.1em" }} class=" fs-50 font-montserrat fw-600 fs-all-device">
                            Expand Your Business With The Leading Gig Marketplace
                        </h2>
                    </div>
                </div>
            </div>
            <div data-aos="zoom-in-up" style={{ textAlign: "center" }} class="container font-montserrat">
                <img src="assets/home/world map.png" alt="taskmo world map" />
            </div>
            <div class="container mt-30 font-montserrat">
                <p style={{ textAlign: "center" }}>Taskmo, headquartered in Bangalore, Karnataka has expanded its wings across India and we are now exploring  globally.</p>
            </div>
        </div>
    )
}
