import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row-reverse",
        marginTop: "10px"
    },
    formContainer: {
        width: "60%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            margin: "auto",
            textAlign: "center"
        },
    },
    logoContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "95%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        },
    },
    formMain: {
        minHeight: '100vh',
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            minHeight: '40vh',
            display: "block"
        },
    }
}));

const BusinessPartnerOTP = () => {
    const classes = useStyles();
    const history = useHistory();
    const [text, setText] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("text", text);
        history.push('/bpp')
    }

    useEffect(() => {
        setPhoneNumber(9876543210)
    }, [phoneNumber])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ fontFamily: "Montserrat" }} >

            <div className={classes.logoContainer}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f3ef0a0c9fc2812f05a51684760425590_taskmo-logo.png"
                    alt=""
                    onClick={() => history.push('/business-partner-program')}
                    style={{ maxWidth: "153px", height: "auto", cursor: "pointer" }} />
            </div>

            <Grid container spacing={2} className={classes.root} >

                <Grid item xs={12} sm={6} className={classes.formMain} >

                    <div className={classes.formContainer} >
                        <div style={{ color: "#603890", fontSize: "24px", fontWeight: 700, textAlign: "center" }} >
                            Let’s get you started
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }} >
                            <Button
                                style={{ color: "#606060FF" }}
                                onClick={() => history.push('/business-partner-sign-up')}
                            >
                                <ArrowBackIosIcon /> Back
                            </Button>
                        </div>

                        <form onSubmit={handleSubmit}>

                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    type='number'
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth
                                    value={phoneNumber}
                                    disabled
                                />
                            </div>

                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Enter OTP"
                                    variant="outlined"
                                    fullWidth
                                    value={text}
                                    required
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type='submit'
                                    style={{ background: "#603890", color: "#fff", fontWeight: 700, fontSize: "16px" }}
                                >
                                    SIGN UP
                                </Button>
                            </div>

                        </form>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} style={{
                    minHeight: '100vh',
                    background: `url(${"https://taskmotech.s3.amazonaws.com/493951ab5df4456489721684733788692_Group%208096.png"})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    <div style={{ width: "95%", margin: "auto" }} >
                        <div style={{ fontSize: "40px", fontWeight: 600, color: "#fff", textAlign: "center", lineHeight: "40px" }} >
                            Already have an account
                        </div>

                        <div style={{ fontSize: "20px", fontWeight: 500, color: "#fff", textAlign: "center", lineHeight: "25px", marginTop: "20px" }}>
                            <p>To keep connected with us please</p>
                            <p>login with your personal info</p>
                        </div>

                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }} >
                            <Button
                                variant="contained"
                                type='submit'
                                style={{
                                    background: "#fff",
                                    color: "#603890",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    minWidth: "100px",
                                    minHeight: "40px",
                                    borderRadius: "20px",
                                }}
                                onClick={() => history.push('/business-partner-sign-in')}
                            >
                                SIGN IN
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default BusinessPartnerOTP