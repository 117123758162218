export const styles = (theme) => ({
    jobBanner: {
        width: "100%",
        display: "block",
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    jobBannerMobile: {
        display: "none",
        "@media (max-width: 767px)": {
            width: "100%",
            maxHeight: "500px",
            display: "block",
            marginBottom: "-30px"
        },
    },
})