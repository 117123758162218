import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BusinessFormModal from '../../../BusinessFormModal'
export default function ZomatoCaseStudy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Zomato | User Acquisition | Online Order | Restaurant || Taskmo
                </title>
                <meta name="description" content="Taskmo - Collected data of 25000+ restaurants across the country, Zomato delivery, Zomato Restaurant Partner App, Zomato Business, Online Food Delivery app." />
                <meta name="keywords" content="zomato, zomato order, zomato delivery, zomato app, zomato delivery partner app, zomato restaurant partner, zomato partner app, zomato business, zomato restaurant" />
                <link rel="canonical" href="https://taskmo.com/case-study/zomato-restaurant-partner" />
            </Helmet>
            <section class="portfolio-area" style={{ marginTop: "30px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 order-first">
                            <div class="portfolio-thumb">
                                <img src="../../assets/case-study/zomato.png" alt="Zomato Casestudy" />
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-last">
                            <div class="portfolio-info-box wow fadeInUp">
                                <h4 class="font-montserrat fw-700">Project Info</h4>
                                <ul>
                                    <li>
                                        <span class="font-montserrat fw-600">Client</span>
                                        <span class="font-montserrat fw-500">Zomato</span>
                                    </li>
                                    <li>
                                        <span class="font-montserrat fw-600">Project Category</span>
                                        <span class="font-montserrat fw-500">User Acquisition</span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="portfolio-details-content wow fadeInUp">
                                <h2 class="font-montserrat fs-50 fw-600">Zomato - User Acquisition</h2>
                                <h3 class="font-montserrat fw-600 mt-50">Challenge</h3>
                                <p class="mb-30 fs-18">
                                    Zomato wanted to verify & validate if the aggregated restaurants in a particular region were still functioning post-pandemic. Zomato did not have access to the resources to verify the existing set of restaurant partners within a short deadline of a few months.
                                </p>
                                <h3 class="font-montserrat fw-600 sub-title">Solution</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo's data collection executives worked in over 10+ cities and covered over 10,000+ restaurants in 15 days and physically collected 25000+ restaurants across the country.
                                </p>
                                <div>
                                    <div class="col-lg-9">
                                        <div class="feature-lists mt-30">
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">10+ cities </h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">25,000+ restaurants</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">15 days</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-10 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <BusinessFormModal subscriber_type="Zomato Casestudy Page" Button_Name="Let's Talk" color="#000" background="#fff" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
