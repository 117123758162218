import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ShopOnboarding from "../../../../components/services/merchant/onboardingServices/shopOnboarding/index";

const ShopOnboardingPage = React.memo((props) => {
  return (
    <>
      <ShopOnboarding />
    </>
  );
});

export default withRouter(ShopOnboardingPage);
