import React, { useEffect, useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import { useHistory } from 'react-router-dom';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Lottie from 'react-lottie';
import referalLottieAnimation from '../../assets/referral.json';
import CancelIcon from '@material-ui/icons/Cancel';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import TextField from '@material-ui/core/TextField';
import copy from "copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
    logoContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "95%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        },
    },
    cardHeading: {
        color: "#787878",
        fontSize: "16px",
        fontWeight: 500,
        marginTop: "10px",
    },
    card: {
        background: "#EEE6FF",
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        transition: "background-color 0.3s",
        borderRadius: "20px",
        "&:hover": {
            background: "#6A3996",
            cursor: "pointer",
            color: "#fff",
            "& $cardHeading": {
                color: "#fff"
            }
        }
    },
    profileName: {
        wordBreak: "break-all",
        textAlign: "left",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "20px",
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
        },
    },
    profileEmail: {
        wordBreak: "break-all",
        textAlign: "left",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#787878",
        marginTop: "10px",
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
        },
    },
    active: {
        width: "100px",
        color: "white",
        background: "#368F17",
        margin: "auto",
        padding: "5px",
        borderRadius: "10px"
    },
    inactive: {
        width: "100px",
        color: "white",
        background: "#D51717",
        margin: "auto",
        padding: "5px",
        borderRadius: "10px"
    }
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        width: "100%",
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        maxHeight: 600,
        borderRadius: "10px"
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: "none",
        color: "#888888",
        background: "#F2F2F2",
    },
    body: {
        fontSize: 14,
        fontWeight: 500,
        color: '#252733'
        //border: 'none'
    },
}))(TableCell);

const columns = [

    {
        id: "name",
        label: "Name",
        minwidth: 60,
        align: "left",
        background: "#F4F3F3",
    },
    {
        id: "ReferralID",
        label: "Referral ID",
        minwidth: 60,
        align: "center",
        background: "#F4F3F3",
    },
    {
        id: "profile",
        label: "Designation",
        minwidth: 60,
        align: "center",
        background: "#F4F3F3",
    },

    {
        id: "City",
        label: "City",
        minwidth: 60,
        align: "center",
        background: "#F4F3F3",
    },

    {
        id: "Stage",
        label: "Stage",
        minwidth: 60,
        align: "center",
        background: "#F4F3F3",
    },

    {
        id: "status",
        label: "Status",
        minwidth: 60,
        align: "center",
        background: "#F4F3F3",
    },
]

// stepper start
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
        color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 0,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#602994",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#602994",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 6,
        border: 0,
        margin: "11px -21px 0px -5px",
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#ddd",
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ddd",
    zIndex: 1,
    color: "#000",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: "#602994",
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: "#fff",
        fontWeight: 700
    }),
    ...(ownerState.completed && {
        backgroundColor: "#602994",
        color: "#fff",
        fontWeight: 700
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: 1,
        2: 2,
        3: 3,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Lead Shared', 'Pilot', 'Project live'];


// stepper end

const Bpp = () => {
    const classes = useStyles();
    const classes1 = useStyles1();
    const history = useHistory();

    const [shareopen, setShareOpen] = React.useState(false);
    const [addReferalOpen, setAddReferalOpen] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const initialValues = { name: "", designation: "", company: "", email: "", phoneNumber: "" };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const urlWithReferalCode = "https://taskmo.com/business-partner-program?referalID";
    const uniqueReferalCode = "12345";
    const personReferred = "test user";


    const textTemplate = `Hi,

I hope you are doing well. I wanted to share some exciting news with you – I recently joined Taskmo's Business Partner Program and thought you might be interested in joining too!

Taskmo's Business Partner Program is a referral program that allows you to earn fantastic rewards by referring clients to Taskmo's services. It's a great way to leverage your network and earn some extra perks along the way.

Joining is easy! Follow these steps:

1. Visit the Taskmo website at ${urlWithReferalCode}.
2. On the "Business Partner Program" section, click on "Join Now."
3. Fill out the registration form with your details and unique referral code (${uniqueReferalCode}).
4. Start referring your friends/network to Taskmo and watch your rewards grow!

Don't miss out on this chance to be a part of Taskmo's Business Partner Program. Join today and let's start earning together!

Best regards,
${personReferred}`;

    const encodedTextTemplate = encodeURIComponent(textTemplate);


    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function handleWhatsAppClick(event) {
        if (!isMobileDevice()) {
            event.preventDefault();
            window.open(`https://web.whatsapp.com/send?text=${encodedTextTemplate}`, '_blank');
        }
    }

    function handleLinkedInClick(event) {
        const isDesktop = !isMobileDevice();
        if (isDesktop) {
            event.preventDefault();
            window.open(`https://www.linkedin.com/messaging/compose/?body=${encodeURI(textTemplate)}`, '_blank');
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = (values) => {

        const errors = {};

        if (values.phoneNumber.length < 10) {
            errors.phoneNumber = "Phone Number must contain 10 numbers";
        }

        return errors;
    };



    const handleIconClick = () => {
        copy(textTemplate);
        // Show the tooltip
        setTooltipOpen(true);

        // Hide the tooltip after a short delay
        setTimeout(() => {
            setTooltipOpen(false);
        }, 2000);
    };

    const handleIconMouseEnter = () => {
        setTooltipOpen(false);
    };


    const handleClickShareOpen = () => {
        setShareOpen(true);
    };

    const handleShareClose = () => {
        setShareOpen(false);
    };

    const handleClickAddReferalOpen = () => {
        setAddReferalOpen(true)
    }

    const handleClickAddReferalClose = () => {
        setAddReferalOpen(false)
    }

    const [isCardHovered, setIsCardHovered] = useState(false);

    const [tableData, setTableData] = useState([
        {
            id: 177,
            name: "Nayanika Bedi",
            referalID: "TM1061f",
            referalDate: "24.05.2019",
            designation: "OPS Lead",
            company: "flipkart",
            city: "Bengaluru",
            state: "Karnataka",
            stage: 0,
            status: "pending"
        },
        {
            id: 178,
            name: "Nayanika Bedi",
            referalID: "TM1061f",
            referalDate: "24.05.2019",
            designation: "Business Lead",
            company: "bounce",
            city: "Bengaluru",
            state: "Karnataka",
            stage: 1,
            status: "done"
        },
        {
            id: 179,
            name: "Nayanika Bedi",
            referalID: "TM1061f",
            referalDate: "24.05.2019",
            designation: "OPS Lead",
            company: "ola",
            city: "Bengaluru",
            state: "Karnataka",
            stage: 2,
            status: "pending"
        },
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCardMouseOver = () => {
        setIsCardHovered(true);
    };

    const handleCardMouseOut = () => {
        setIsCardHovered(false);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: referalLottieAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormErrors(validate(formValues))
        if (Object.keys(validate(formValues)).length === 0) {
            console.log("formValues", formValues);
        }
    }
    return (
        <div style={{ fontFamily: "Montserrat", paddingBottom: "100px" }}>
            <div className={classes.logoContainer}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f3ef0a0c9fc2812f05a51684760425590_taskmo-logo.png" alt=""
                    onClick={() => history.push('/business-partner-program')}
                    style={{ maxWidth: "153px", height: "auto", cursor: "pointer" }} />
            </div>

            <div style={{ width: "100%", background: "#F6F7FF", paddingTop: "20px", paddingBottom: "30px" }}>
                <Grid container spacing={3} justifyContent='space-between' style={{ width: "95%", margin: "auto" }}>
                    <Grid item xs={12} md={8}>
                        <div style={{ fontSize: "30px", lineHeight: "40px", fontWeight: 600, color: "#603890" }}>
                            Taskmo Business Partner Program
                        </div>
                        <div style={{ color: "#7C7C7C", fontWeight: 500, fontSize: "20px" }}>
                            Dashboard
                        </div>

                        <Grid container spacing={3} justifyContent='space-between' alignItems='center' style={{ marginTop: "10px" }}>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Card
                                    className={`${classes.card} ${isCardHovered ? classes.cardHovered : ""}`}
                                    onMouseOver={handleCardMouseOver}
                                    onMouseOut={handleCardMouseOut}
                                >
                                    <CardContent>
                                        <div style={{ maxWidth: "40px", background: "#fff", borderRadius: "50%", padding: "5px" }} >
                                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f16123482722cc6e28851684763180905_Frame.svg" alt="" />
                                        </div>

                                        <div className={classes.cardHeading}>
                                            Total Lead
                                        </div>

                                        <div style={{ fontSize: "30px", fontWeight: 700, wordBreak: "break-all", lineHeight: "30px" }} >
                                            139
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Card
                                    className={`${classes.card} ${isCardHovered ? classes.cardHovered : ""}`}
                                    onMouseOver={handleCardMouseOver}
                                    onMouseOut={handleCardMouseOut}
                                >
                                    <CardContent>
                                        <div style={{ maxWidth: "40px", background: "#fff", borderRadius: "50%", padding: "8px" }} >
                                            <img src="https://taskmotech.s3.amazonaws.com/184b7ae804be1b2a5fd41684818622432_get-money%201.svg" alt="" />
                                        </div>

                                        <div className={classes.cardHeading}>
                                            Total Earning
                                        </div>

                                        <div style={{ fontSize: "30px", fontWeight: 700, wordBreak: "break-all", lineHeight: "30px" }} >
                                            ₹46,789
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Card
                                    className={`${classes.card} ${isCardHovered ? classes.cardHovered : ""}`}
                                    onMouseOver={handleCardMouseOver}
                                    onMouseOut={handleCardMouseOut}
                                >
                                    <CardContent>
                                        <div style={{ maxWidth: "40px", background: "#fff", borderRadius: "50%", padding: "8px" }} >
                                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/22d828c75a08405ec9531684818765415_Vector.svg" alt="" style={{ width: "20px", height: "22px" }} />
                                        </div>

                                        <div className={classes.cardHeading}>
                                            Lead Converted
                                        </div>

                                        <div style={{ fontSize: "30px", fontWeight: 700, wordBreak: "break-all", lineHeight: "30px" }} >
                                            110
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card style={{ borderRadius: "20px" }}>
                            <CardContent>

                                <Grid container spacing={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ width: "120px", height: "120px", borderRadius: "50%", overflow: "hidden" }}>
                                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/5333b2d999abd6e3e8bb1684819786912_Ellipse%204.svg" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <div className={classes.profileName}>Charley Mandess</div>
                                        <div className={classes.profileEmail}>charley13mandess@emai.com</div>
                                    </Grid>
                                </Grid>

                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <div style={{
                                        marginTop: "20px",
                                        border: "2px solid #603890",
                                        borderRadius: "20px",
                                        padding: "10px 40px 10px 40px",
                                        textAlign: "center"
                                    }} >
                                        <div style={{
                                            color: "#5C26B4",
                                            fontSize: "22px",
                                            fontWeight: 700,
                                            lineHeight: "24px"
                                        }} >TMREF2073</div>

                                        <div>Referral ID</div>

                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                                    <Button variant="contained"
                                        onClick={handleClickShareOpen}
                                        style={{ fontSize: "16px", fontWeight: 700, padding: "15px 20px 15px 20px", background: "#6A3996", color: "#fff", borderRadius: "30px" }}
                                    >
                                        SHARE  NOW <ShareIcon style={{ marginLeft: "10px" }} />
                                    </Button>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    style={{ width: "100%", overflowX: "auto", display: "inline-grid", marginTop: "30px" }}
                >
                    <Paper className={classes1.root}>
                        <TableContainer className={classes1.container} >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    background: "#F7F8FF",
                                                    color: "#87888e",
                                                    fontWeight: 600
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData?.map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <StyledTableCell align="left" >{row.name}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <div>
                                                        <div>
                                                            <p>{row.referalID}</p>
                                                        </div>

                                                        <div style={{ color: "#87888e" }} >
                                                            on <span >{row.referalDate}</span>
                                                        </div>
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <div>
                                                        <p>{row.designation}</p>
                                                    </div>

                                                    <div style={{ color: "#87888e" }} >
                                                        <p>{row.company}</p>
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <div>
                                                        <p>{row.city}</p>
                                                    </div>

                                                    <div style={{ color: "#87888e" }} >
                                                        <p>{row.state}</p>
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <Stack sx={{ width: '100%' }} spacing={4}>
                                                        <Stepper alternativeLabel activeStep={row.stage} connector={<ColorlibConnector />}>
                                                            {steps.map((label) => (
                                                                <Step style={{
                                                                    fontSize: "10px",
                                                                    paddingLeft: "18px",
                                                                    paddingRight: "1px",
                                                                    fontWeight: 500,
                                                                }} key={label}>
                                                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    </Stack>
                                                </StyledTableCell>

                                                <StyledTableCell align="center" style={{ textAlign: "center" }}>

                                                    <div className={row.status == "done" ? classes.active : classes.inactive} >
                                                        {row.status}
                                                    </div>

                                                </StyledTableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>

            <div style={{ position: "fixed", bottom: "20px", left: "50%", transform: "translateX(-50%)", zIndex: 999 }}>
                <Button
                    onClick={handleClickAddReferalOpen}
                    style={{
                        width: "240px",
                        height: "60px",
                        borderRadius: "20px",
                        background: "#6A3996",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: 700,
                    }}>
                    ADD NEW REFERRAL +
                </Button>
            </div>

            <Dialog
                open={shareopen}
                onClose={handleShareClose}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} >
                        <div
                            style={{
                                zIndex: "2000",
                                cursor: "pointer",
                                color: '#555555',
                                marginRight: "-10px",
                                position: "fixed",
                                marginTop: "-15px"
                            }}
                            onClick={handleShareClose}
                        >
                            <CancelIcon style={{ fontSize: "30px", color: "#555555" }} />
                        </div>
                    </div>
                    <Grid container spacing={2} justifyContent='space-between' alignItems='center' >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ maxWidth: '100%', maxHeight: '100%' }}>
                                    <Lottie options={defaultOptions} isClickToPauseDisabled />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <div>
                                <p style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, lineHeight: "20px" }} >
                                    Refer a Friend, Earn Rewards, and Build Your Business Network with Taskmo’s Business Partner Program
                                </p>
                            </div>
                            <hr />

                            <Grid container spacing={2} justifyContent='center' alignItems='center' style={{ width: "90%", margin: "auto", marginTop: "20px", }}   >
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "center" }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                        <a href={`whatsapp://send?text=${encodedTextTemplate}`} onClick={handleWhatsAppClick} style={{ margin: "auto", }} >
                                            <WhatsAppIcon style={{ fontSize: "40px", color: "#25D366", cursor: "pointer" }} />
                                        </a>
                                        <p>WhatsApp</p>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <a href={`https://www.linkedin.com/messaging/compose/?body=${encodedTextTemplate}`} onClick={handleLinkedInClick} style={{ margin: "auto" }} target="_blank" rel="noopener noreferrer">
                                            <LinkedInIcon style={{ fontSize: "40px", color: "#0077B5", cursor: "pointer" }} />
                                        </a>
                                        <p>LinkedIn</p>
                                    </div>
                                </Grid>


                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <a href={`mailto:?subject=Join Taskmo's Business Partner Program & Earn Exciting Rewards!&body=${encodedTextTemplate}`}>
                                            <img src="https://taskmotech.s3.amazonaws.com/ce1ebd759fd56c0f25c01684911532461_gmail.svg" alt="" style={{ width: "35px", cursor: "pointer", margin: "auto" }} />
                                        </a>
                                        <p>Email</p>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Tooltip title="Link Copied" placement="top" open={tooltipOpen}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                                            onClick={handleIconClick}
                                            onMouseEnter={handleIconMouseEnter}
                                        >
                                            <FileCopyOutlinedIcon style={{ fontSize: '35px', margin: 'auto', cursor: 'pointer' }} />
                                            <p>Copy Link</p>
                                        </div>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Dialog
                open={addReferalOpen}
                onClose={handleClickAddReferalClose}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-aos="fade-up"
            >
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                        <div></div>
                        <div>
                            <h4 style={{ lineHeight: "1.3em" }} class="pb-20 font-montserrat fs-25 fw-600">
                                Add New Referral
                            </h4>
                        </div>

                        <div
                            style={{
                                cursor: "pointer",
                                color: '#555555',
                            }}
                            onClick={handleClickAddReferalClose}
                        >
                            <CancelIcon style={{ fontSize: "30px", color: "#555555" }} />
                        </div>

                    </div>
                    <div>
                        <form onSubmit={handleSubmit} >
                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Full Name"
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    value={formValues?.name}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Designation"
                                    variant="outlined"
                                    fullWidth
                                    name="designation"
                                    value={formValues?.designation}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    label="Company"
                                    variant="outlined"
                                    fullWidth
                                    name="company"
                                    value={formValues?.company}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    type='email'
                                    label="Email address"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={formValues?.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }} >
                                <TextField
                                    id="outlined-basic"
                                    type='number'
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                    name="phoneNumber"
                                    value={formValues?.phoneNumber}
                                    onChange={handleChange}
                                />
                                <div>
                                    <p style={{ color: 'red', fontSize: "10px" }} >{formErrors?.phoneNumber}</p>
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", marginBottom: "15px" }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type='submit'
                                    style={{ background: "#603890", color: "#fff", fontWeight: 700, fontSize: "16px" }}
                                >
                                    SUBMIT
                                </Button>
                            </div>

                        </form>
                    </div>
                </DialogContent>

            </Dialog>

        </div >
    );
};

export default Bpp;
