import { GET_BLOGS_CATEGORIES,GET_BLOGS_CATEGORIES_ERR } from '../../actionTypes/blogType'
import { getBlogCategoriesApi } from 'api/Api';
import axios from "axios";

export const getBlogCategories = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getBlogCategoriesApi}/${payload.filter}/${payload.page}`)
        console.log("GET BLOGS CATEGORIES", results)
        let data = results && results.data
        dispatch({
            type: GET_BLOGS_CATEGORIES,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_BLOGS_CATEGORIES_ERR,
            payload: error && error.response
        });
    }
}

