import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Media() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: false,
        speed: 3000,
        autoplaySpeed: 3500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section class="font-montserrat centerAlign mt-80" style={{ paddingBottom: "100px" }}>
            <div class="section-heading text-center ">
                <h2 class="font-montserrat fw-600 fs-50">Latest Media Features and Awards</h2>
            </div>
            <div class="pt-60">
                <div class="container">
                    <Slider {...settings}>
                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#E8FBFF" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#8BBDC8" }}>The Economic Times</h6>
                                <h4 class="font-montserrat">More women go big on gig during Covid pandemic, better jobs now available</h4>
                                <a target="_blank" href="https://economictimes.indiatimes.com/jobs/more-women-go-big-on-gig-during-pandemic/articleshow/81880122.cms" class="try-btn" style={{ borderColor: "#C0E9F2", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/222.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/223.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#FFEFE7" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#DCAC91" }}>Yourstory</h6>
                                <h4 class="font-montserrat">How an auto-ride inspired two IT professionals to start a jobs platform.</h4>
                                <a target="_blank" href="https://yourstory.com/2017/12/how-an-auto-ride-inspired-two-it-professionals-to-start-a-jobs-platform-for-students/amp" class="try-btn1" style={{ borderColor: "#F5D1BD", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/224.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/225.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#E8FBFF" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#8BBDC8" }}>Silicon India</h6>
                                <h4 class="font-montserrat">FeedMyPockets: The Gig Economy Platform</h4>
                                <a target="_blank" href="https://startup.siliconindia.com/vendor/feedmypockets-the-gig-economy-platform-cid-12114.html" class="try-btn" style={{ borderColor: "#C0E9F2", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/222.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/223.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#FFEFE7" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#DCAC91" }}>Times Of India</h6>
                                <h4 class="font-montserrat">Hiring temps during festive season up 20%</h4>
                                <a target="_blank" href="https://timesofindia.indiatimes.com/business/india-business/hiring-temps-during-festive-season-up-20/articleshow/66156796.cms" class="try-btn1" style={{ borderColor: "#F5D1BD", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/224.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/225.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>




                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#E8FBFF" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#8BBDC8" }}>BW Business World</h6>
                                <h4 class="font-montserrat"> BW Disrupt Unveils 30 Under 30 Super Intra-Entrepreneurs</h4>
                                <a target="_blank" href="http://www.businessworld.in/article/BW-Disrupt-Unveils-30-Under-30-Super-Intra-Entrepreneurs/04-07-2021-395377/" class="try-btn" style={{ borderColor: "#C0E9F2", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/222.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/223.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="block-style-twentySix text-center mb-40" style={{ background: "#FFEFE7" }}>
                                <h6 class="font-montserrat" style={{ fontSize: "22px", color: "#DCAC91" }}>Dropout Dudes</h6>
                                <h4 class="font-montserrat">How FeedMyPockets is already profitable & stepping into Bn$ Industry</h4>
                                <a target="_blank" href="https://dropoutdudes.com/feedmypockets/" class="try-btn1" style={{ borderColor: "#F5D1BD", fontWeight: "500", fontSize: "18px" }}>Read More</a>
                                <img src="images/shape/224.jpg" alt="" class="shapes shape-one" />
                                <img src="images/shape/225.jpg" alt="" class="shapes shape-two" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
}



