import React, { useEffect } from 'react';
import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from "./NewServicesStyle";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom'
import { localRoute } from 'routes/localRoutes';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function Arrow(props) {
    const classes = useStyles();

    let className = props.type === "next" ? classes.nextArrow : classes.prevArrow;
    className += " " + classes.arrow;
    const char = props.type === "next" ? <div
        className={`${classes.arrowButton} ${classes.arrowButtonRight}`}
    >
        <ChevronRightIcon style={{ color: "#673695" }} />
    </div> :
        <div
            className={`${classes.arrowButton} ${classes.arrowButtonLeft}`}
        >
            <ChevronLeftIcon style={{ color: "#673695" }} />
        </div>;
    return (
        <span className={className} onClick={props.onClick}>
            {char}
        </span>
    );
}

const useStyles = makeStyles(styles);

const NewServices = () => {
    const history = useHistory()
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [timer, setTimer] = useState(null);

    const totalTabs = 10;

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        clearInterval(timer);
    };

    const handleArrowClick = (direction) => {
        const maxTabIndex = 10; // set to the maximum tab index
        const newTabIndex =
            direction === 'left'
                ? (selectedTab - 1 + maxTabIndex) % maxTabIndex
                : (selectedTab + 1) % maxTabIndex;
        setSelectedTab(newTabIndex);
        clearInterval(timer);
    };

    useEffect(() => {
        const newTimer = setInterval(() => {
            setSelectedTab((prevSelectedTab) => (prevSelectedTab + 1) % totalTabs);
        }, 2000);
        setTimer(newTimer);
        return () => clearInterval(newTimer);
    }, []);

    return (
        <div>
            <div class="inner-container">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-10 col-md-9 m-auto">
                            <div class="text-center">
                                <h2 class="fs-50 fw-600 font-montserrat">Our Offerings</h2>
                                <p style={{ textAlign: "center" }} class="font-montserrat fs-20 fw-500">India’s Leading Gig Economy Service Provider</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.root}>

                <div style={{ width: "100%", display: "flex", alignItems: "center" }} >
                    <div>
                        <div
                            onClick={() => handleArrowClick('left')}
                            className={`${classes.arrowButton} ${classes.arrowButtonLeft}`}
                        >
                            <ChevronLeftIcon style={{ color: "#673695" }} />
                        </div>
                        {/* {selectedTab > 0 ?
                            <div
                                onClick={() => handleArrowClick('left')}
                                className={`${classes.arrowButton} ${classes.arrowButtonLeft}`}
                            >
                                <ChevronLeftIcon style={{ color: "#673695" }} />
                            </div>
                            :
                            <div className={`${classes.arrowButton} ${classes.arrowButtonLeft}`} style={{ border: "1px solid #000" }} >
                                <ChevronLeftIcon style={{ color: "#000" }} />
                            </div>
                        } */}
                    </div>

                    <div style={{ width: "90%", overflow: "hidden", margin: "auto" }} >
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="off"
                            TabIndicatorProps={{
                                style: { display: 'none' }
                            }}
                        >
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 0 ? classes.merchantActiveTab : classes.inactiveTab} >Merchant Onboarding</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 1 ? classes.activeTab : classes.inactiveTab}>Seller Onboarding</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 2 ? classes.activeTab : classes.inactiveTab}>Partner Onboarding</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 3 ? classes.activeTab : classes.inactiveTab}>Retail Store Audit</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 4 ? classes.activeTab : classes.inactiveTab}>Retail Store Visit</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 5 ? classes.activeTab : classes.inactiveTab}>
                                    Content Moderation</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 6 ? classes.merchantActiveTab : classes.inactiveTab}>Order Taking Service</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 7 ? classes.activeTab : classes.inactiveTab}>POS Marketing</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 8 ? classes.merchantActiveTab : classes.inactiveTab}>Business Loan Service</div>}
                            />
                            <Tab
                                disableRipple
                                className={classes.tabRoot}
                                label={<div className={selectedTab === 9 ? classes.merchantActiveTab : classes.inactiveTab}>Subscription Selling</div>}
                            />
                        </Tabs>
                    </div>

                    <div>

                        <div
                            onClick={() => handleArrowClick('right')}
                            className={`${classes.arrowButton} ${classes.arrowButtonRight}`}
                        >
                            <ChevronRightIcon style={{ color: "#673695" }} />
                        </div>

                        {/* {selectedTab < 8 ?
                            <div
                                onClick={() => handleArrowClick('right')}
                                className={`${classes.arrowButton} ${classes.arrowButtonRight}`}
                            >
                                <ChevronRightIcon style={{ color: "#673695" }} />
                            </div>
                            :
                            <div className={`${classes.arrowButton} ${classes.arrowButtonRight}`} style={{ border: "1px solid black" }}>
                                <ChevronRightIcon style={{ color: "#000" }} />
                            </div>
                        } */}
                    </div>

                </div>
            </div>

            <div className={classes.tabContent}>

                {/* merchant onboarding */}

                {selectedTab === 0 &&

                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push(localRoute.merchantOnboarding)}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }}>
                                Merchant Onboarding
                            </h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }}
                            >
                                Taskmo ensures smooth and legally compliant merchant onboarding solutions that enhance convenience and reduce abandonment, combined with quick processing thereby improving your company's bottom line.  Our fast and frictionless merchant onboarding solutions have helped brands like Amazon, PhonePe, Flipkart, etc to onboard over 1 million merchants across 9000+ pin codes across India.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push(localRoute.merchantOnboarding)}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in" onClick={() => history.push(localRoute.merchantOnboarding)}>
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/945abb2dd9fe341418551682756223693_Merchant%20onboarding%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* seller onboarding */}

                {selectedTab === 1 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/seller-onboarding-service")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >Seller Onboarding</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's effective seller onboarding services establish sustainable long-term relations between sellers and online marketplaces thereby reducing seller churn rate while cutting costs. Our dedicated professionals with in-depth expertise in the onboarding process have enabled renowned brands like Flipkart, Meesho, JioMart, Amazon and others to successfully onboard more than 600k+ sellers pan India.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/seller-onboarding-service")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/06aeb5fe930f1f306ef61682756296435_Seller%20onboarding%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* partner onboarding */}

                {selectedTab === 2 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/partner-onboarding-services")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >
                                Partner Onboarding
                            </h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's well-defined partner onboarding process tackles onboarding challenges and has successfully onboarded over 2 million partners across 10,000+ pin codes for renowned brands such as Rapido, Ola, Zomato, Uber, Swiggy, and Practo, among many more to help them with recovering lost sales opportunities, enhanced partner loyalty and a diminished churn rate.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/partner-onboarding-services")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d70ee6475019652c7bf01683013821123_Partner%20onboarding%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* retail store audit */}

                {selectedTab === 3 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/retail-store-audit-service")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >Retail Store Audits</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Customised retail store audit services offered by Taksko provide retail brands with streamlined, insight-driven management, resulting in increased profitability, reduced costs, and improved employee efficiency, leading to higher customer satisfaction. Our audit experts have completed over 200k+ store audits for over 25+ renowned brands, including ITC, P&G, Britannia, Treebo etc to enable them to increase operational efficiency.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/retail-store-audit-service")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/39d764554738612211841683009786429_Store%20audit%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* retail store visit */}

                {selectedTab === 4 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/retail-store-visits-service")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >Retail Store Visits</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's retail store visit services evaluate the efficiency and effectiveness of retail stores to improve the customer experience, enhance employee performance, and increase sales. Taskmo's streamlined audit process has helped brands like Britannia, Coco Sutra, Bounce, Ola Electric and more across pan India to find and fix problems faster for more conversions.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/retail-store-visits-service")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/074140d904abd70ee30b1683009910067_Store%20Visits%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* content moderation */}

                {selectedTab === 5 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/content-moderation")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >
                                Content Moderation
                            </h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's customised platform-specific content moderation services help you build a flawless online reputation, enhancing your market reputation and increasing your sales.  Our 30Mn+ successful content audits have helped over 25+ brands, including Trell, MX TakaTak etc with more than 96% accuracy to effectively manage their online content
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/content-moderation")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/869472285c13efbb0d341683032355051_content%20Moderation%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* order taking management */}

                {selectedTab === 6 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/outsource-order-taking-service")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >
                                Order-Taking Management
                            </h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo has streamlined the order fulfilment process through its order-taking management system, enabling brands to make better business decisions, reduce costs and prevent backorders and customer complaints. Our right order-taking management strategy has helped brands like JioMart, Meesho and more with 100MN+ Orders in GMV across India.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/outsource-order-taking-service")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/1202fdc82a5c77be073e1683013491567_Ordeer%20taking%20management%20%28our%20offering%29%20%281%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* pos marketing */}

                {selectedTab === 7 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/pos-marketing-services")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >POS Marketing</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                We offer an in-store marketing service to help you build meaningful customer experiences, increase brand awareness, and increase sales without being intrusive. Taskmo has catered to more than 20+ brands, including AmazonPay, PhonePe, Cashe, Payswiff and more by delivering over 3 mn+ successful store visits across India for POS marketing material deployment.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/pos-marketing-services")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2b85448089195a152ebc1683005444577_POS%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* business loan service */}

                {selectedTab === 8 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/selling-business-loans")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >Selling Business Loans</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's business loan services can provide any financial lending company with a competitive edge through its more customer-friendly approach and technical integrations. Our effective selling business loans services have catered to more than 10+ brands, including Cashe, Epay Later and more, efficiently delivering over 25k+ business loans across pan India.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/selling-business-loans")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/402d886c9fc70afaea491682756349436_Business%20Loans%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }

                {/* subscription selling */}

                {selectedTab === 9 &&
                    <Grid container spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                        onClick={() => history.push("/services/sell-subscription")}
                    // onMouseEnter={() => clearInterval(timer)}
                    // onMouseLeave={() => clearInterval(timer)}
                    >
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px" }} >Subscriptions Selling Services</h2>

                            <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                Taskmo's subscription selling service enables your brand to generate predictable recurring revenue from improving customer experience leading to customer loyalty by showcasing value at every stage of the selling process. We have assisted more than 15+ brands, including Vyapar, NowFloats and more across pan India with increased revenue.
                            </p>

                            <Button
                                variant="contained"
                                size='large'
                                style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                onClick={() => history.push("/services/sell-subscription")}
                            >
                                Explore More
                            </Button>

                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                            className={classes.tabImage}  >
                            <div data-aos="zoom-in">
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/bc077c78cbd4d4b94db61682756381520_Selling%20Subscription%20%28our%20offering%29.png" alt="/"
                                    style={{ maxHeight: "500px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>

            <div className={classes.tabSlides} >
                <Slider
                    nextArrow={<Arrow type="next" />}
                    prevArrow={<Arrow type="prev" />}
                    className={classes.slider}
                    autoplay={true}
                    pauseOnHover={true}
                >

                    {/* merchant onboarding */}
                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                Merchant Onboarding
                            </Button>
                        </div>

                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push(localRoute.merchantOnboarding)}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Merchant Onboarding</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo ensures smooth and legally compliant merchant onboarding solutions that enhance convenience and reduce abandonment, combined with quick processing thereby improving your company's bottom line.  Our fast and frictionless merchant onboarding solutions have helped brands like Amazon, PhonePe, Flipkart, etc to onboard over 1 million merchants across 9000+ pin codes across India.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push(localRoute.merchantOnboarding)}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/945abb2dd9fe341418551682756223693_Merchant%20onboarding%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* seller onboarding */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                Seller Onboarding
                            </Button>
                        </div>

                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/seller-onboarding-service")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Seller Onboarding</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's effective seller onboarding services establish sustainable long-term relations between sellers and online marketplaces thereby reducing seller churn rate while cutting costs. Our dedicated professionals with in-depth expertise in the onboarding process have enabled renowned brands like Flipkart, Meesho, JioMart, Amazon and others to successfully onboard more than 600k+ sellers pan India.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/seller-onboarding-service")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/06aeb5fe930f1f306ef61682756296435_Seller%20onboarding%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* partner onboarding */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                Partner Onboarding
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/partner-onboarding-services")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >
                                    Partner Onboarding
                                </h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's well-defined partner onboarding process tackles onboarding challenges and has successfully onboarded over 2 million partners across 10,000+ pin codes for renowned brands such as Rapido, Ola, Zomato, Uber, Swiggy, and Practo, among many more to help them with recovering lost sales opportunities, enhanced partner loyalty and a diminished churn rate.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/partner-onboarding-services")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d70ee6475019652c7bf01683013821123_Partner%20onboarding%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* retail store audit */}
                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                retail store audit
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/retail-store-audit-service")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Retail Store Audits</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Customised retail store audit services offered by Taksko provide retail brands with streamlined, insight-driven management, resulting in increased profitability, reduced costs, and improved employee efficiency, leading to higher customer satisfaction. Our audit experts have completed over 200k+ store audits for over 25+ renowned brands, including ITC, P&G, Britannia, Treebo etc to enable them to increase operational efficiency.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/retail-store-audit-service")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/39d764554738612211841683009786429_Store%20audit%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* retail store visit */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                retail store visit
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/retail-store-visits-service")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Retail Store Visits</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's retail store visit services evaluate the efficiency and effectiveness of retail stores to improve the customer experience, enhance employee performance, and increase sales. Taskmo's streamlined audit process has helped brands like Britannia, Coco Sutra, Bounce, Ola Electric and more across pan India to find and fix problems faster for more conversions.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/retail-store-visits-service")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/074140d904abd70ee30b1683009910067_Store%20Visits%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* content moderation */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                Content Moderation
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/content-moderation")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Content Moderation</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's customised platform-specific content moderation services help you build a flawless online reputation, enhancing your market reputation and increasing your sales.  Our 30Mn+ successful content audits have helped over 25+ brands, including Trell, MX TakaTak etc with more than 96% accuracy to effectively manage their online content
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/content-moderation")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/869472285c13efbb0d341683032355051_content%20Moderation%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>


                    {/* order taking management */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                order taking management
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/outsource-order-taking-service")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-all" }} >
                                    Order-Taking Management
                                </h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo has streamlined the order fulfilment process through its order-taking management system, enabling brands to make better business decisions, reduce costs and prevent backorders and customer complaints. Our right order-taking management strategy has helped brands like JioMart, Meesho and more with 100MN+ Orders in GMV across India.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/outsource-order-taking-service")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/1202fdc82a5c77be073e1683013491567_Ordeer%20taking%20management%20%28our%20offering%29%20%281%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                    </div>


                    {/* pos marketing */}
                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                pos marketing
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/pos-marketing-services")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", marginBottom: "5px", wordBreak: "break-word" }} >
                                    POS Marketing
                                </h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    We offer an in-store marketing service to help you build meaningful customer experiences, increase brand awareness, and increase sales without being intrusive. Taskmo has catered to more than 20+ brands, including AmazonPay, PhonePe, Cashe, Payswiff and more by delivering over 3 mn+ successful store visits across India for POS marketing material deployment.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/pos-marketing-services")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/2b85448089195a152ebc1683005444577_POS%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* business loan service */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                business loan service
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/selling-business-loans")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Selling Business Loans</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's business loan services can provide any financial lending company with a competitive edge through its more customer-friendly approach and technical integrations. Our effective selling business loans services have catered to more than 10+ brands, including Cashe, Epay Later and more, efficiently delivering over 25k+ business loans across pan India.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/selling-business-loans")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/402d886c9fc70afaea491682756349436_Business%20Loans%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* subscription selling */}

                    <div>
                        <div style={{ width: "90%", margin: "auto" }} >
                            <Button variant="contained"
                                fullWidth
                                style={{ background: "#603890", color: "#fff", fontWeight: 600, padding: "10px", borderRadius: "20px", marginBottom: "15px" }}
                            >
                                subscription selling
                            </Button>
                        </div>
                        <Grid container spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "90%", margin: "auto", marginTop: "20px", cursor: "pointer" }}
                            onClick={() => history.push("/services/sell-subscription")}
                        // onMouseEnter={() => clearInterval(timer)}
                        // onMouseLeave={() => clearInterval(timer)}
                        >
                            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tabText} >
                                <h2 style={{ fontFamily: "Montserrat", color: "#000", fontSize: "32px", wordBreak: "break-word" }} >Subscriptions Selling Services</h2>

                                <p style={{ color: "#797979", fontFamily: "Montserrat", lineHeight: "23.44px", marginTop: "10px" }} >
                                    Taskmo's subscription selling service enables your brand to generate predictable recurring revenue from improving customer experience leading to customer loyalty by showcasing value at every stage of the selling process. We have assisted more than 15+ brands, including Vyapar, NowFloats and more across pan India with increased revenue.
                                </p>

                                <Button
                                    variant="contained"
                                    size='large'
                                    style={{ marginTop: "20px", background: "#673695", color: "#fff" }}
                                    onClick={() => history.push("/services/sell-subscription")}
                                >
                                    Explore More
                                </Button>

                            </Grid>

                            <Grid xs={12} sm={12} md={6} lg={6} xl={6}
                                className={classes.tabImage}  >
                                <div data-aos="zoom-in">
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/bc077c78cbd4d4b94db61682756381520_Selling%20Subscription%20%28our%20offering%29.png" alt="/"
                                        style={{ maxHeight: "500px" }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </Slider>
            </div>

        </div>
    );
}

export default NewServices