import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import VendorOnboarding from "../../../../components/services/merchant/onboardingServices/vendorOnboarding/index";

const VendorOnboardingPage = React.memo((props) => {
  return (
    <>
      <VendorOnboarding />
    </>
  );
});

export default withRouter(VendorOnboardingPage);
