import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CareerStyle";
import Career from "../../components/career/index";
const CareerPage = React.memo((props) => {
  return (
    <>
      <Career />
    </>
  );
});

export default withRouter(CareerPage);
