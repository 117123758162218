import React from 'react';
import { styles } from "./MediaKitPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MediaKitImg from "../../assets/images/MediaKitImg.svg";
import taskmoBrandLogos from "../../assets/Taskmo-Brand-Logos.zip";
import pressReleaseDocs from "../../assets/PressRelease.zip";
import taskmoCorporateDetails from "../../assets/Taskmo-Corporate-Details.zip";
import taskmoLeaderShipDetails from "../../assets/Taskmo-Leadership-Photos.zip"
import { Helmet } from "react-helmet";

const useStyles = makeStyles(styles);
const MediaKit = () => {
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    News, Events & Information About Gig Economy || Taskmo
                </title>
                <meta name="description" content="Get breaking news, events, and updated information on Taskmo Media. Read press releases, newsletters, and more..." />
                <meta name="keywords" content="Social Gig Platform, Gig Economy, Gig Workers, Gig News,Gig Jobs, Gig topics, Gig updates, Gig Newsletter" />
                <link rel="canonical" href="https://taskmo.com/mediaKit" />
            </Helmet>

            <div style={{ marginTop: "30px", color: "black", fontFamily: "Montserrat" }} >

                <div>
                    <img src={MediaKitImg} alt="mediaKitImage" />
                </div>

                <div className={classes.info} >
                    <div className={classes.contact} >
                        <h1 style={{ color: "black" }} >Media Contacts</h1>
                        <div style={{ fontSize: "16px", fontWeight: 400 }} >For any media related queries, please contact:</div>
                        <h2>Swati</h2>
                        <div style={{ fontSize: "16px", fontWeight: 400 }}>
                            #33, Mohan chambers, 1st Main Rd, 3rd Phase,
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }}>
                            J. P. Nagar, Bengaluru, Karnataka 560078
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }}>
                            Contact No: 8088841668
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }}>
                            Email: swati.shivkumar@taskmo.com</div>
                    </div>

                    <div className={classes.contact}>
                        <h1 style={{ color: "black" }} >Media Kit</h1>
                        <Button variant="outlined" className={classes.btn}  >
                            <a
                                href={pressReleaseDocs}
                                download
                                style={{ color: "black" }}
                            >
                                Download Press Release
                            </a>
                        </Button>
                        <br />
                        <Button variant="outlined" className={classes.btn}  >
                            <a
                                href={taskmoBrandLogos}
                                download
                                style={{ color: "black" }}
                            >
                                Download Taskmo Brand Logos
                            </a>
                        </Button>
                        <br />
                        <Button variant="outlined" className={classes.btn}  >
                            <a
                                href={taskmoCorporateDetails}
                                download
                                style={{ color: "black" }}
                            >
                                Download Taskmo Corporate Details
                            </a>
                        </Button>
                        <br />
                        <Button variant="outlined" className={classes.btn}  >
                            <a
                                href={taskmoLeaderShipDetails}
                                download
                                style={{ color: "black" }}
                            >
                                Download Taskmo Leadership Photos
                            </a>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(MediaKit);