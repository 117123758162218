import Services from 'components/home/services/Services';
import NewServices from 'pages/services/newServices/NewServices';
import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet';

export default function Services1() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Gig Service Provider | Gig Company | Manpower Services || Taskmo
                </title>
                <meta name="description" content="Taskmo - Best Gig Service provider, Gig Marketplace, Gig Companies, employement, customer service, contract staffing services. To know more click here...." />
                <meta name="keywords" content="Gig service provider, gig company, manpower services, Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
                <link rel="canonical" href="https://taskmo.com/services/financial-products-and-services" />
            </Helmet>
            <div style={{ marginTop: "20px" }} >
                <Services />
                {/* <NewServices /> */}
            </div>
        </div>
    )
}
