import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import TermsConditions from "../../components/termsConditions/index";

const TermsAndConditionsPage = React.memo((props) => {
  return (
    <>
      <TermsConditions />
    </>
  );
});

export default withRouter(TermsAndConditionsPage);
