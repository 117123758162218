import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./HealthCareStyle";
import Healthcare from "../../../components/services/healthcareService/index";
const HealthcarePage = React.memo((props) => {
  return (
    <>
      <Healthcare />
    </>
  );
});
export default withRouter(HealthcarePage);
