import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import CabOnboarding from "../../../../components/services/merchant/onboardingServices/cabOnboarding/index";

const CabOnboardingPage = React.memo((props) => {
  return (
    <>
      <CabOnboarding />
    </>
  );
});

export default withRouter(CabOnboardingPage);
