import React from "react";
import { withRouter } from "react-router-dom";
import "./BrandActivationStyle";
import BrandActivation from "../../../components/services/brandActivation/index";
const BrandActivationPage = React.memo((props) => {
  return (
    <>
      <BrandActivation />
    </>
  );
});
export default withRouter(BrandActivationPage);
