import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./NewsLetterStyle";
import NewsLetter from "../../../components/pressRelease/newsLetter/index";
const NewsLetterPage = React.memo((props) => {
  return (
    <>
      <NewsLetter />
    </>
  );
});
export default withRouter(NewsLetterPage);

