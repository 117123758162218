import { GET_BLOG,GET_BLOG_ERR } from '../../actionTypes/blogType'
import { getBlogApi} from 'api/Api';
import axios from "axios";
export const getBlog = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getBlogApi}/${payload}`)
        console.log("GET BLOGS", results)
        let data = results && results.data
        dispatch({
            type: GET_BLOG,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_BLOG_ERR,
            payload: error && error.response
        });
    }
}
