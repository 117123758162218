import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import OurPartners from '../home/ourPartners/OurPartners';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/GlobalState';
import { createSubscriber } from '../../context/actions/subscriberAction/addSubscriber';
import { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import validation from '../validation';
import Snackbar from '@mui/material/Snackbar';
import { Navigate } from 'react-router';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AreaOfInterest from './AreaOfInterest';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';


const styles = theme => ({
    root: {
        "& $notchedOutline": {
            borderWidth: 0
        },
        "&:hover $notchedOutline": {
            borderWidth: 0
        },
        "&$focused $notchedOutline": {
            borderWidth: 0
        }
    },
    focused: {},
    notchedOutline: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    subscriber_city_name: '',
    age: '',
    qualification: '',
    state: ''
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const names = [
    'Brand Promoter',
    'Data Moderator',
    'Delivery Partner',
    'Event Promoters',
    'Field Activation',
    'Field Auditor',
    'Field Marketing',
    'Field Sales Executive',
    'Instore staff',
    'Part Time Jobs',
    'Picker and Packers',
    'Tele Caller',
    'Telesales',
    'Warehouse Incharge',
];


const cities = [
    'Agra',
    'Ahmedabad',
    'Aligarh',
    'Bangalore',
    'Bhopal',
    'Chandigarh',
    'Chennai',
    'Delhi',
    'Goa',
    'Gorakhpur',
    'Gurgaon',
    'Guwahati',
    'Hyderabad',
    'Indore',
    'Jodhpur',
    'Kolhapur',
    'Kolkata',
    'Kota',
    'Ludhiana',
    'Mumbai',
    'Nagpur',
    'Nashik',
    'Noida',
    'Pune',
    'Surat',
    'Thane',
    'Vadodara'
];

const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
export default function JobsCampaign(props) {
    const { classes } = props;

    const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
    const [states, setState] = useState(init);
    const [store, setStore] = useState([]);
    const [areaOfInterest, setAreaOfInterest] = React.useState([]);
    const [multipleCities, setMultipleCities] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState([])


    console.log("subscriberState", subscriberState)
    console.log("multipleCities", multipleCities)

    const handleChange = (e) => {

        const { name, value } = e.target;
        setState(
            // typeof value === 'string' ? value.split(',') : value,

            { ...states, [name]: value });
    };

    const handleChangeArea = (e) => {
        const {
            target: { value },
        } = e;


        setAreaOfInterest(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleChangeNumberLeadInput = (event) => {
        console.log('event', event.target.value)
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        console.log('onlyNums', onlyNums)
        if (onlyNums.length <= 10) {
            setNumber(onlyNums)
            setState({
                ...states,
                [event.target.name]: onlyNums,
            });
        }
    }



    const handleChangeCity = (e) => {
        const {
            target: { value },
        } = e;


        setMultipleCities(
            typeof value === 'string' ? value.split(',') : value,
        );
    }



    const handleClose = () => {
        setOpen(false);
    };


    const handleClick = e => {
        e.preventDefault()
        setErrors(validation(states));
        if (
            states.subscriber_name !== '' &&
            states.subscriber_email !== '' &&
            validateEmail(states.subscriber_email) &&
            states.subscriber_phone !== '' &&
            states.subscriber_phone.length === 10 &&
            states.subscriber_city_name !== '' &&
            states.age !== '' &&
            states.qualification !== '' &&
            states.state !== ''
        ) {
            const postData = {
                subscriber_name: states.subscriber_name,
                subscriber_email: states.subscriber_email,
                subscriber_phone: states.subscriber_phone,
                city: states.subscriber_city_name,
                age: states.age,
                qualification: states.qualification,
                state: states.state,
                subscriber_type: 'jobs-campaign-page',
                visitor_type: 'job_campaign'
            };

            createSubscriber(postData)(subscriberDispatch)
            console.log('postData', postData)
            setState(init);
            setOpen(true);
        }
    }

    console.log("areaOfInterest", areaOfInterest);
    console.log("states", states);

    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {

            console.log("store", store);
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])



    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        // speed: 1000,
        autoplaySpeed: 1500,
        centerMode: true,

        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // console.log("subscriber_city_name",states.subscriber_city_name)


    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Job Search App | Best Money Earning App || Taskmo
                </title>
                <meta name="description" content="Taskmo - Know for Best Job Search App, Best Money Earning APP, one can find a job that interests them in the field of their choice and perform the task right away!" />
                <meta name="keywords" content="delivery job,packing job, promoters jobs,Field sales executive, Brand promoter, Field auditor, Data moderator, Tele caller, Warehouse incharge, Instore staff, Pickers and packer, Delivery Partner, Field Marketing, Field Activation, Telesales, Event promoter, Part time jobs, Field Data collection,best job search app india, job app, part time jobs apps in india, money earning apps, best earning app, online earning apps, freelance app, job finding apps" />

                <link rel="canonical" href="https://taskmo.com/jobs-campaign" />
            </Helmet>



            <div class="font-montserrat container mt-140 md-mt-100">
                <div class="row justify-content-center">
                    <h2 style={{ width: "80%", margin: "auto", textAlign: "center" }} class="fs-cp-28 fw-600 font-montserrat">Start Gigging with Taskmo!</h2>
                    <div class="col-xl-12 mt-20">

                        <div class="row  al ign-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9">
                                <img width="600" src="assets/home/hirining.jpg" alt="" class="ml-auto bg-round-shape" />
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                    <div class="consultation-form">
                                        <form action="#">
                                            <div class="font-montserrat input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Full Name"
                                                        inputProps={{
                                                            style: {
                                                                height: "20px",
                                                            },
                                                        }} variant="outlined" type="text" name="subscriber_name" value={states.subscriber_name} onChange={handleChange} />
                                                </Grid>
                                                <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                                            </div>

                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Email" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="email" name="subscriber_email" value={states.subscriber_email} onChange={handleChange} />
                                                </Grid>
                                                {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                                            </div>

                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Phone" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="subscriber_phone" value={states.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                                                </Grid>
                                                {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                                            </div>

                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="City" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="subscriber_city_name" value={states.subscriber_city_name} onChange={handleChange} />
                                                </Grid>
                                                {errors.subscriber_city_name && <p style={{ color: 'red' }}>{errors.subscriber_city_name}</p>}
                                            </div>


                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Age" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="age" value={states.age} onChange={handleChange} />
                                                </Grid>
                                                {errors.age && <p style={{ color: 'red' }}>{errors.age}</p>}
                                            </div>


                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Qualification" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="qualification" value={states.qualification} onChange={handleChange} />
                                                </Grid>
                                                {errors.qualification && <p style={{ color: 'red' }}>{errors.qualification}</p>}
                                            </div>

                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="State" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="state" value={states.state} onChange={handleChange} />
                                                </Grid>
                                                {errors.state && <p style={{ color: 'red' }}>{errors.state}</p>}
                                            </div>
                                            <div class="input-field">
                                                <button href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="terms_and_policy" style={{ border: "none" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="font-montserrat nav-link active" data-toggle="tab" href="#opt1">1. Job Description</a>
                                </li>
                                <li class="nav-item">
                                    <a class="font-montserrat nav-link" data-toggle="tab" href="#opt2">2. Responsibilities</a>
                                </li>
                                <li class="nav-item">
                                    <a class="font-montserrat nav-link" data-toggle="tab" href="#opt3">3. Qualifications</a>
                                </li>
                                <li class="nav-item">
                                    <a class="font-montserrat nav-link" data-toggle="tab" href="#opt4">4. Experience</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-8">

                            <div class="tab-content">
                                <div id="opt1" class="tab-pane fade active show">
                                    <h2 class="font-montserrat fs-40 fw-600">Job Description</h2>
                                    <p>The promoters will be placed in the following locations, Apartments, malls, supermarkets and railway stations etc. They will have to identify potential customers for Byju’s (parents of school going kids) and promote Byju’s learning app to them and get them to register for a free counseling session.</p>
                                </div>
                                <div id="opt2" class="tab-pane fade">
                                    <h2 class="font-montserrat fs-40 fw-600">Responsibilities</h2>
                                    <ul>
                                        <li>Attend all training sessions provided by Taskmo and Byjus</li>
                                        <li>Convince customers in the assigned locations</li>
                                    </ul>
                                </div>
                                <div id="opt3" class="tab-pane fade">
                                    <h2 class="font-montserrat fs-40 fw-600">Qualifications</h2>
                                    <ul>
                                        <li>Must have knowledge on how to convince customers and register on Byju's app.</li>
                                    </ul>
                                </div>
                                <div id="opt4" class="tab-pane fade">
                                    <h2 class="font-montserrat fs-40 fw-600">Experience</h2>
                                    <ul><li>1-2 Years</li></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
