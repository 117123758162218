import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CareerDetailsStyle";
import CareerDetails from '../../../components/career/CareerDetails'
const CareerDetailsPage = React.memo((props) => {
  return (
    <>
      <CareerDetails />
    </>
  );
});

export default withRouter(CareerDetailsPage);