import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CaseStudyDetailsStyle";
import CaseStudyDetails from '../../../components/caseStudy/caseStudyDetails'
const CaseStudyDetailsPage = React.memo((props) => {
  return (
    <>
      <CaseStudyDetails />
    </>
  );
});

export default withRouter(CaseStudyDetailsPage);
