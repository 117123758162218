export const styles = (theme) => ({
    headerLogo: {
        width: "100%",
        background: "#fff",
        "@media (max-width: 767px)": {
            marginTop: "-10px"
        },

        "@media (min-width: 768px) and (max-width: 1023px)": {
            marginTop: "-10px"
        },
    }
})