import React from 'react';
import Fab from '@mui/material/Fab';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import Dialog from '@mui/material/Dialog';

const B2BModalChat = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Fab onClick={handleOpen} style={{ zIndex: "100", margin: "0px", top: "auto", right: "20px", bottom: "20px", left: "auto", position: "fixed", cursor: "pointer", border: "2px solid #6A3996", background: "#6A3996" }}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/3385917435bd1ffd61ab1683540397508_writing.svg" alt="" />
            </Fab>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-aos="fade-up"
            >
                <div class="consultation-form-area">
                    <Find_Taskers_Common subscriber_type="pay-per-click-ads" />
                </div>

            </Dialog>

        </div >
    )
}

export default B2BModalChat