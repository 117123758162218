import React from "react";
import { styles } from "./SpamAlertStyle";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

const SpamAlert = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div>
          <img
            src="https://taskmotech.s3.ap-south-1.amazonaws.com/53bfa2c5ca86f96c4e641682335907858_danger.svg"
            alt="danger-icon"
            style={{ width: "20px" }}
          />
        </div>

        <div style={{ textAlign: "center" }}>
          <h5 style={{ color: "#fff" }}>
            #ScamAlert: Beware of fraud groups posting as Taskmo, Ensure not to
            share personal or payment details
          </h5>
        </div>

        <div>
          <Button
            size="small"
            variant="contained"
            onClick={() => history.push("/contact-us")}
            style={{
              background: "#FEBF00",
              color: "#101820FF",
              fontWeight: 600,
            }}
          >
            Contact Us
          </Button>
        </div>

        {/* <div>
                    <img src='https://taskmotech.s3.ap-south-1.amazonaws.com/53bfa2c5ca86f96c4e641682335907858_danger.svg' alt='danger-icon' style={{ width: "20px" }} />
                </div> */}
      </div>
    </div>
  );
};

export default SpamAlert;
