import React, { useEffect, useState, useContext, } from "react";
import { getBlogCategories } from '../../../context/actions/blogAction/getBlogsCategories'
import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Pagination, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { getBlogs } from "../../../context/actions/blogAction/getBlogs";
import { Helmet } from "react-helmet";


export default function Blog(props) {
    const { blogState, blogDispatch } = useContext(GlobalContext);
    const [blogCategoriesDetail, setBlogCategoriesDetail] = useState([])
    // const [blogId, setBlogId] = useState([])
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [paginationCount, setPaginationCount] = useState(1);
    const [totalInActiveRows, setTotalInActiveRows] = useState(0);
    const [totalActiveRows, setTotalActiveRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [BlogList, setBlogList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [tabFilter, setTabFilter] = useState('active');
    const [filterBlogDetails, setFilterBlogDetails] = useState([]);
    const [color, setColor] = useState("")
    // const {blog_id}=useParams()

    const history = useHistory()

    const handleChangePage = (event, newPage) => {
        let count = newPage - 1
        setPage(count);
        console.log('count', count)
        setPaginationCount(newPage)
        let filter = tabFilter
        let page = newPage;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        let filter = tabFilter
        getBlogCategories({ filter, page: "all" })(blogDispatch)
    }, [])

    useEffect(() => {
        console.log('blogCategoriesState1', blogState)
        const status = blogState && blogState.getBlogCategories.status
        if (status == true) {
            setBlogCategoriesDetail(blogState.getBlogCategories.data)
            console.log(setBlogCategoriesDetail(blogState.getBlogCategories.data.res));
        }
    }, [blogState])
    console.log('blogCategoriesDetail', blogCategoriesDetail)


    useEffect(() => {
        let filter = tabFilter
        getBlogs({ filter, page: "all" })(blogDispatch)
    }, [])


    useEffect(() => {
        let status = blogState.getBlogs.status
        if (status === true) {
            console.log("taskerState() ", blogState.getBlogs)
            let limit = '8'
            let total_page = Math.ceil(blogState.getBlogs.data.res.length / limit)
            console.log('total_page', total_page)
            setTotalPage(total_page)
            setPaginationCount(1)
            setPage(0)
            setTotalRows(blogState.getBlogs.data.total_rows)
            setBlogList(blogState.getBlogs.data.res)
            setFilterBlogDetails(blogState.getBlogs.data.res)
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", blogState.getBlogs)
        }
        // SERVER VALIDATION ERROR RESPONSE  
        if (status === 401) {
            console.log("Validation Error:", blogState.getBlogs)
        }

        let update_status = blogState.updateBlog && blogState.updateBlog.data && blogState.updateBlog.status
        if (update_status === true) {
            console.log('getBlog', blogState.updateBlog.data)
            blogState.updateBlog = {
                loading: false,
                error: null,
                data: null,
            }
            let filter = tabFilter
            getBlogs({ filter, page: "all" })(blogDispatch)
        }
    }, [blogState])

    console.log("filterBlogDetails", filterBlogDetails);

    console.log("color", color)
    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    })

    const handleOpenBlog = (category_id) => {
        let count = 0
        setPage(count);
        setFilterBlogDetails([])
        setColor("")
        console.log('category_id', category_id)
        let filtered = BlogList.filter(m => m.blog_category_id == category_id)
        console.log(filtered)
        let limit = '8'
        let total_page = Math.ceil(filtered.length / limit)
        console.log('total_page', total_page)
        setTotalPage(total_page)
        setFilterBlogDetails(filtered)
        setColor("red")
    }
    const OpenBlog = (item) => {
        history.push(`${localRoute.blogDetails1}/${item.blog_slug}`)
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Blog | Gig Onboarding Services || Gig Marketplace
                </title>
                <meta name="description" content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters." />
                <meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
                <link rel="canonical" href="https://taskmo.com/career" />
            </Helmet>

            <div style={{ marginTop: "30px" }} class="blog-v3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="feature-blog-three font-montserrat">
                                {filterBlogDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                    return (
                                        <div style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.blog_slug} class="post-meta">
                                            <img src={item.blog_image} alt={item.blog_title} />
                                            <div class="post">
                                                <h1>
                                                    {item.blog_title}
                                                </h1>
                                                <p>
                                                    {item.meta_description}
                                                </p>
                                                <p class="read-more">
                                                    <span>Continue Reading &nbsp;</span>
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div class="page-pagination-one pt-15">
                                <ul class="d-flex align-items-center">
                                    <Pagination count={totalPage} page={paginationCount} onChange={handleChangePage} onClick={window.scrollTo(0, 0)} color="primary" variant="outlined" onRowsPerPageChange={handleChangeRowsPerPage} />
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-sidebar-one">
                                <div class="sidebar-categories mb-85">
                                    <h4 class="sidebar-title font-montserrat fw-600">Categories</h4>
                                    {blogCategoriesDetail.map(item =>
                                        <ul>
                                            <li>
                                                <a class="font-montserrat" style={{ cursor: "pointer" }} onClick={() => handleOpenBlog(item.category_id)}>{item.category_name}</a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <div class="sidebar-recent-news mb-85">
                                    <h4 class="sidebar-title font-montserrat fw-600">Recent Blogs</h4>
                                    <ul>
                                        <li>
                                            {filterBlogDetails.slice(0, 3).map(item =>
                                                <a style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.blog_slug}>
                                                    <img src={item.blog_image} alt={item.blog_title} class="image-meta" />
                                                    <span class="font-montserrat title" style={{ display: "flex" }}>
                                                        {item.blog_title}
                                                    </span>
                                                </a>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
