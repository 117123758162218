import React from 'react';
import { styles } from "./MediaKitPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom'
import MediaKit from 'components/mediaKit';

const useStyles = makeStyles(styles);
const MediaKitPage = () => {
    const classes = useStyles();
    return (
        <div> <MediaKit /> </div>
    )
}

export default withRouter(MediaKitPage);