import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import OurPartners from '../home/ourPartners/OurPartners';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/GlobalState';
import { createSubscriber } from '../../context/actions/subscriberAction/addSubscriber';
import { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import validation from '../validation';
import Snackbar from '@mui/material/Snackbar';
import { Navigate } from 'react-router';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AreaOfInterest from './AreaOfInterest';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';


const styles = theme => ({
    root: {
        "& $notchedOutline": {
            borderWidth: 0
        },
        "&:hover $notchedOutline": {
            borderWidth: 0
        },
        "&$focused $notchedOutline": {
            borderWidth: 0
        }
    },
    focused: {},
    notchedOutline: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    subscriber_city_name: '',
    area_of_interest: ''
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const names = [
    'Brand Promoter',
    'Data Moderator',
    'Delivery Partner',
    'Event Promoters',
    'Field Activation',
    'Field Auditor',
    'Field Marketing',
    'Field Sales Executive',
    'Instore staff',
    'Part Time Jobs',
    'Pickers and Packer',
    'Tele Caller',
    'Telesales',
    'Warehouse Incharge',
];


const cities = [
    'Agara',
    'Ahemdabad',
    'Aligarh',
    'Bangalore',
    'Bhopal',
    'Chandigarh',
    'Chennai',
    'Delhi',
    'Goa',
    'Gorakhpur',
    'Gurgaon',
    'Guwahati',
    'Hyderabad',
    'Indore',
    'Jodhpur',
    'Kolhapur',
    'Kolkata',
    'Kota',
    'Ludhiana',
    'Mumbai',
    'Nagpur',
    'Nashik',
    'Noida',
    'Pune',
    'Surat',
    'Thane',
    'Vadodara'
];

const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
export default function Campus(props) {
    const { classes } = props;

    const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
    const [state, setState] = useState(init);
    const [store, setStore] = useState([]);
    const [areaOfInterest, setAreaOfInterest] = React.useState([]);
    const [multipleCities, setMultipleCities] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState([])


    console.log("subscriberState", subscriberState)
    console.log("multipleCities", multipleCities)

    const handleChange = (e) => {

        const { name, value } = e.target;
        setState(
            // typeof value === 'string' ? value.split(',') : value,

            { ...state, [name]: value });
    };

    const handleChangeArea = (e) => {
        const {
            target: { value },
        } = e;


        setAreaOfInterest(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleChangeNumberLeadInput = (event) => {
        console.log('event', event.target.value)
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        console.log('onlyNums', onlyNums)
        if (onlyNums.length <= 10) {
            setNumber(onlyNums)
            setState({
                ...state,
                [event.target.name]: onlyNums,
            });
        }
    }



    const handleChangeCity = (e) => {
        const {
            target: { value },
        } = e;


        setMultipleCities(
            typeof value === 'string' ? value.split(',') : value,
        );
    }



    const handleClose = () => {
        setOpen(false);
    };


    const handleClick = e => {
        e.preventDefault()
        setErrors(validation(state));
        if (
            state.subscriber_name !== '' &&
            state.subscriber_email !== '' &&
            validateEmail(state.subscriber_email) &&
            state.subscriber_phone !== '' &&
            state.subscriber_phone.length === 10 &&
            state.subscriber_city_name !== '' &&
            state.area_of_interest !== ''
        ) {
            const postData = {
                subscriber_name: state.subscriber_name,
                subscriber_email: state.subscriber_email,
                subscriber_phone: state.subscriber_phone,
                city: state.subscriber_city_name,
                area_of_interest: state.area_of_interest,
                // area_of_interest: areaOfInterest.toString(),
                subscriber_type: 'campus',
                visitor_type: 'job_seeker'

            };

            createSubscriber(postData)(subscriberDispatch)
            console.log('Create Subscriber Detail', postData)
            setState(init);
            setOpen(true);
            // window.location.href = 'https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US';
        }
    }

    console.log("areaOfInterest", areaOfInterest);
    console.log("state", state);

    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {

            console.log("store", store);
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])



    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        // speed: 1000,
        autoplaySpeed: 1500,
        centerMode: true,

        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // console.log("subscriber_city_name",state.subscriber_city_name)
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const navRef4 = React.useRef(null);
    const navRef5 = React.useRef(null);
    const navRef6 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    const onToggleClick4 = (e) => {
        navRef4.current.classList.toggle("show");
    };

    const onToggleClick5 = (e) => {
        navRef5.current.classList.toggle("show");
    };

    const onToggleClick6 = (e) => {
        navRef6.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Campus Ambassador Program | Part Time Jobs || Taskmo
                </title>
                <meta name="description" content="Join the Taskmo Campus Ambassador Program for college students which offers best part time jobs & gives the opportunity to earn money by completing simple tasks" />
                <meta name="keywords" content="Campus ambassador program, campus ambassador, student ambassador, student ambassador program, part time jobs, part time jobs near me" />
                <link rel="canonical" href="https://taskmo.com/campus-ambassador" />
            </Helmet>



            <div class="font-montserrat container" style={{ marginTop: "30px" }} >
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9 title-style-eight">
                                <h1 class="font-montserrat fs-50 fw-600 hero-heading">Taskmo Campus Ambassador Program</h1>
                                <p class="font-montserrat fs-20 fw-500">Make a mark with your leadership skills, join the Taskmo Campus Ambassador Program. Learn & put your networking skills to best use & build a community with us.</p>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 mb-20 md-mt-30">Apply Now</a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <img src="assets/img/campus ambassador.jpg" alt="Taskmo Campus Ambassador Program" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen mt-180 md-mt-100" id="effect">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Benefits of Becoming Campus Ambassador</h2>
                                <p class="font-montserrat">Aspects of program that you'll enjoy</p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFCE22' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="assets/campus/certificate.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Recognition Certificate</h4>
                                        <p>Make your resume stand out by earning your 'Campus Ambassador' certificate</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#8F6BF6' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="assets/campus/reward.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Rewards and Benefits</h4>
                                        <p>Receive a particular stipend and letter of recommendations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF5C5C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="assets/campus/connect.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Connect Industry Leaders</h4>
                                        <p>Get a glimpse of corporate life as well as a chance to speak with professionals </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#63EAA9' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="assets/campus/upskill.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Expand your horizon</h4>
                                        <p>Get an opportunity to expand your knowledge and grow your ingenuity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-130 md-mt-50 fancy-short-banner-two">
                <div class="container">
                    <div class="content-wrapper">
                        <div class="bg-wrapper d-lg-flex align-items-center justify-content-between">
                            <h2 class="font-montserrat">Are you the one buzzing with energy and brimming with ambition?</h2>
                            <a target="_blank" href="https://bit.ly/3uzrvF8" class="font-montserrat theme-btn-one mt-20 md-mt-30">Apply Now</a>
                        </div>
                    </div>
                </div>
            </div>



            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Responsibilities of a Campus Ambassador</h2>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}><img width="50" src="assets/campus/connect.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Connect
                                </h4>
                                <p>Use your identity and presence on social media to amplify our programs and initiatives</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}><img width="50" src="assets/campus/communicate.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Communicate
                                </h4>
                                <p>Participate, implement and run our latest initiatives and programs on your campus</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}><img width="50" src="assets/campus/create.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Create
                                </h4>
                                <p>Create awareness about Taskmo in your local areas, surroundings, and college to create a difference</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-ten mt-100 md-mt-70" id="feature">
                <div class="bg-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="title-style-eight text-center">
                                    <h2 class="font-montserrat">Application Process</h2>
                                </div>

                            </div>
                        </div>
                        <div class="row justify-content-center mt-35 md-mt-20">
                            <div class="col-lg-2 col-md-4" data-aos="fade-up" data-aos-duration="1200" >
                                <div style={{ display: "grid", height: "220px", padding: "15px", textAlign: "center" }} class="block-style-fifteen mt-40">
                                    <div>
                                        <div class="font-montserrat icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB", margin: "auto" }}>
                                            <img src="assets/campus/resume.png" alt="" />
                                        </div>
                                        <h3 class="font-montserrat">Apply Now</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4" data-aos="fade-up" data-aos-duration="1200">
                                <div style={{ display: "grid", height: "220px", padding: "15px", textAlign: "center" }} class="block-style-fifteen mt-40" >
                                    <div>
                                        <div class="font-montserrat icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB", margin: "auto" }}>
                                            <img src="assets/campus/shortlist.png" alt="" />
                                        </div>
                                        <h3 class="font-montserrat">Get Shortlisted</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4" data-aos="fade-up" data-aos-duration="1200">
                                <div style={{ display: "grid", height: "220px", padding: "15px", textAlign: "center" }} class="block-style-fifteen mt-40" >
                                    <div>
                                        <div class="font-montserrat icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB", margin: "auto" }}>
                                            <img src="assets/campus/interview.png" alt="" />
                                        </div>
                                        <h3 class="font-montserrat">Interview</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div style={{ display: "grid", height: "220px", padding: "15px", textAlign: "center" }} class="block-style-fifteen mt-40" >
                                    <div>
                                        <div class="font-montserrat icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8", margin: "auto" }}>
                                            <img src="assets/campus/offer.png" alt="" />
                                        </div>
                                        <h3 class="font-montserrat">Receive offer letter</h3>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ display: "grid", height: "220px", padding: "15px", textAlign: "center" }} class="block-style-fifteen mt-40" >
                                    <div>
                                        <div class="font-montserrat icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF", margin: "auto" }}>
                                            <img src="assets/campus/welcome.png" alt="" />
                                        </div>
                                        <h3 class="font-montserrat">Welcome to CAP Crew</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 md-mt-30">Apply Now</a>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What is the Taskmo CAP (Campus Ambassador program)?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo Campus Ambassador Program is an excellent opportunity to get involved on university campuses. Under this, you get to expose our tech-driven brand and services on campus, build relationships, identify new opportunities, and more.
                                            </p>
                                            <br />
                                            <h4>
                                                Some core duties of ambassadors would include:
                                            </h4>
                                            <li>getting a thorough understanding of the services the brand intends to market</li>
                                            <li>creating a community of teachers and students employing offline and online strategies
                                                creating and executing effective campaigns</li>
                                            <li>developing marketing strategies and planning marketing campaigns aimed at generating revenue</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                Who can apply to this program?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Students having strong interpersonal skills, leadership qualities, zeal to learn new things, creative marketing thoughts, and proactive in event executions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Is there any fee or registration amount to be paid for the program?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                There is no fee to be paid for joining this Campus Ambassador Program; it’s entirely free!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick4} class="btn btn-link font-montserrat">
                                                What are the benefits of joining this program?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef4} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Stipend, Certificate of appreciation, and letter of recommendation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick5} class="btn btn-link font-montserrat">
                                                What is the main objective of this program?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef5} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                This upskilling programme is designed to improve your resume by teaching you digital skills and, eventually, provide you with financial independence.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick6} class="btn btn-link font-montserrat">
                                                How does this program help me in my career?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef6} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                This program can help you get ample exposure to the industry and help you decide on your future career path.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
