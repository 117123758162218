export const styles = (theme) => ({
    root: {
        width: "90%",
        margin: "auto",
        marginTop: "20px",
        borderTop: "1px solid #949398FF",
        borderBottom: "1px solid #949398FF",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('sm')]: {
            marginTop: "20px",
            width: "90%",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },
    tabContent: {
        width: "90%",
        margin: "auto",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },
    tabSlides: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block",
            width: "70%",
            margin: "auto",
            marginTop: "20px"
        },
    },
    tabRoot: {
        whiteSpace: "nowrap",
        minWidth: "100px",
        [theme.breakpoints.up('md')]: {
            minWidth: "200px",
        },
    },
    arrowButton: {
        background: "#fff",
        padding: "10px",
        borderRadius: "50%",
        border: "1px solid #673695",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    arrowButtonLeft: {
        marginRight: "20px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "0"
        },
    },
    arrowButtonRight: {
        marginLeft: "20px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0"
        },
    },
    merchantActiveTab: {
        backgroundColor: "#f0e0ff",
        color: "#673695",
        padding: "10px",
        borderRadius: "15px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "32px",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('md')]: {
            fontSize: "12px",
            lineHeight: "24px",
            padding: "8px",
            minWidth: "100px",
            borderRadius: "16px",
        },
    },

    activeTab: {
        backgroundColor: "#f0e0ff",
        color: "#673695",
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "15px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "32px",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('md')]: {
            fontSize: "12px",
            lineHeight: "24px",
            padding: "8px",
            minWidth: "100px",
            borderRadius: "16px",
        },
    },
    inactiveTab: {
        backgroundColor: "#fff",
        color: "#000",
        padding: "10px",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "32px",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('md')]: {
            fontSize: "14px",
            lineHeight: "24px",
            padding: "8px",
            minWidth: "100px",
            borderRadius: "16px",
        },
    },
    tabText: {
        textAlign: "left",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
        },
    },
    tabImage: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        },
    },
    arrow: {
        position: "absolute",
        color: "#000000",
        fontSize: "2.5em",
        fontWeight: "bold",
        cursor: "pointer"
    },
    nextArrow: {
        top: 0,
        right: "-45px"
    },
    prevArrow: {
        top: 0,
        left: "-45px"
    }
})