export const styles = (theme) => ({
    offeringContainer: {
        width: "50%",
        "@media (max-width: 600px)": {
            width: "100%",
            marginTop: "20px"
        },
    },

    offeringImages: {
        width: "50%",
        "@media (max-width: 600px)": {
            width: "100%",
        },
    },
    mbBtnView: {
        display: "none",
        padding: "10px",
        marginTop: "30px",
        "@media (max-width: 600px)": {
            display: "block",
        },
    },
    dskBtnView: {
        width: "90%",
        display: "flex",
        justifyContent: "space-around",
        margin: "auto",
        marginTop: "30px",
        flexWrap: "wrap",
        "@media (max-width: 600px)": {
            display: "none",
        },
    },

    dskView: {
        "@media (max-width: 600px)": {
            display: "none",
        },
    }

})