import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Campus(props) {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    // console.log("subscriber_city_name",state.subscriber_city_name)
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);
    const navRef4 = React.useRef(null);
    const navRef5 = React.useRef(null);
    const navRef6 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    const onToggleClick4 = (e) => {
        navRef4.current.classList.toggle("show");
    };

    const onToggleClick5 = (e) => {
        navRef5.current.classList.toggle("show");
    };

    const onToggleClick6 = (e) => {
        navRef6.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Doctor Onboarding Services | Doctor Services || Taskmo
                </title>
                <meta name="description" content="Taskmo - Provides Best Doctor Onboarding Services, doctor service, healthcare service, HealthTech industries," />
                <meta name="keywords" content="Doctor Onboarding Services" />
                <link rel="canonical" href="https://taskmo.com/services/merchant-onboarding/best-doctor-onboarding-services" />
            </Helmet>


            <div class="font-montserrat container mt-20">
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9 title-style-eight">
                                <h1 class="font-montserrat fs-50 fw-600 hero-heading">Doctor Onboarding Service</h1>
                                <p class="font-montserrat fs-20 fw-500">Onboard Doctors for Healthcare and HealthTech industries in a thoughtful, standardised way, so orientation and onboarding can go more smoothly.</p>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 mb-20 md-mt-30">Apply Now</a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <img src="../../../assets/img/doctor.jpg" alt="Doctor Onboarding Service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Benefits of Doctor Onboarding services</h2>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}><img width="50" src="../../../assets/campus/connect.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Due Diligence
                                </h4>
                                <p>
                                    Doctors abided with Due diligence by-law and other verifications.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}><img width="50" src="../../../assets/campus/communicate.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Verification
                                </h4>
                                <p>
                                    Get 100 % assured documents to the standard agreement of the government.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}><img width="50" src="../../../assets/campus/create.svg" alt="" /></div>
                                <h4 class="font-montserrat">
                                    Continue review
                                </h4>
                                <p>
                                    Immediately send in-app surveys on onboarding to identify areas for improvement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">5</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">10</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">60000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen mt-180 md-mt-100" id="effect">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-eight text-center mb-80 md-mb-40">
                                <h2 class="font-montserrat">Why Taskmo?</h2>
                                <p class="font-montserrat">Why is Taskmo the best Doctor-onboarding service provider?</p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FFCE22' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/doctorsOnboarding/data-security.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Data Security</h4>
                                        <p>All sensitive information is stored while maintaining HIPAA requirements .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#8F6BF6' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/doctorsOnboarding/networking.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Networking</h4>
                                        <p>Access to a community of doctors and physicians instantly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#FF5C5C' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/doctorsOnboarding/growth.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Track Growth</h4>
                                        <p>Track the tasks from initial implementation to end execution at a click.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#63EAA9' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src="../../../../assets/services/merchant/doctorsOnboarding/pm-technology.png" alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">PM Technology</h4>
                                        <p>Practice management technology to streamline and simplify the onboarding process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="faq-classic pt-140 md-pt-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                <a target="_blank" href="https://bit.ly/3uzrvF8" class="theme-btn-one mt-20 md-mt-30">Apply Now</a>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What is the due diligence followed by Taskmo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo creates a checklist of due diligence, including by law, contract review, insurance documentation, financial documentation, employee roster, and regulatory compliance documentation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                How does Taskmo ensure timely task execution?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef2} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo identifies responsible staff, and our experts monitor the central access to tie together sequential processes.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick3} class="btn btn-link font-montserrat">
                                                Do Taskmo onboard Doctors for small projects?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef3} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Absolutely! At Taskmo, we ensure that all our giggers perform the given task for the specified projects.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick4} class="btn btn-link font-montserrat">
                                                Does Taskmo provide doctor onboarding across India?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" ref={navRef4} class="collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Yes! Taskmo has access to over 5000+ pin codes and served over 500+ clients across the country. Taskmo has continuously added more cities and pincodes to our existing widespread network.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
