export default {
  createSubscriber: {
    loading: false,
    error: null,
    data: [],
  },
  createImageUrl: {
    loading: false,
    error: null,
    data: [],
  },
};

