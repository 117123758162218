import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import Snackbar from '@mui/material/Snackbar';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import { styles } from "./contentModerationStyle";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(styles);

export default function ContentModeration() {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);
    const navRef3 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }

    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    };

    const onToggleClick3 = (e) => {
        navRef3.current.classList.toggle("show");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        setExpanded("panel1")
    }, [])

    return (
        <>
            <div style={{ marginTop: "20px" }}>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        Best Content Moderation Services | Indian Companies || Taskmo
                    </title>
                    <meta name="description" content="At Taskmo, we provide a wide range of best content moderation services in India, including comment, image, video, social media moderation, and much more." />
                    <meta name="keywords" content="Best content moderation service india, content moderation services in india, content moderation, Image moderation, video moderation, social medaration, content moderator, tik tok moderator, moderator services, moderator, content moderation companies in india" />
                    <link rel="canonical" href="https://taskmo.com/services/content-moderation" />
                </Helmet>

                <div class="fancy-text-block-seven md-mt-40">
                    <div class=" title-style-two text-center">
                        <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                            Content Audit Services
                            <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Content Moderation" />
                        </h1>
                    </div>
                    <div class="bg-wrapper no-bg">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="quote-wrapper ">
                                        <p class="mt-20 fs-18 text-align-left">
                                            The brand image of a business can be easily tarnished by user-generated content that does not adhere to platform rules. Taskmo's content moderation services protect your brand from online bullies and trolls by preventing them from publishing objectionable content.  We offer a variety of content moderation services that are tailored to clients' specific business needs including picture moderation, video moderation, text moderation, etc.
                                        </p>
                                        <br />
                                        <p class="mt-20 fs-18 text-align-left">
                                            Our dedicated team of professionals makes sure that any objectionable internet information is removed without degrading the user experience as a whole. Hence, our unparalleled content moderation services enable clients to calibrate their user-generated content at cost-effective rates.
                                        </p>

                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                    <Find_Taskers_Common subscriber_type="Content Moderation" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div class="pricing-section-two">
                    <div class="fancy-hero-four">
                        <div class="title-style-two text-center mt-10md-mb-50">
                            <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                                How Do We Scale Your Content Audit Services?
                            </h2>
                        </div>
                    </div>
                    <div class="container">
                        <div style={{ border: "none" }} class="pricing-table-area">
                            <div class="clearfix">
                                <div class="tab-content">
                                    <div>
                                        <div style={{ border: "none" }} class="row no-gutters">
                                            <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                                <div class="block-style-twentyTwo">
                                                    <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}>
                                                        <img width="50" src="../../../assets/services/content-moderation/1.svg" alt="" />
                                                    </div>
                                                    <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Ensure perfect accuracy with best-in-class services </p>
                                                </div>
                                            </div>
                                            <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                                <div class="block-style-twentyTwo">
                                                    <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                        <img width="50" src="../../../assets/services/content-moderation/2.svg" alt="" />
                                                    </div>
                                                    <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Customised end-to-end workflow</p>
                                                </div>
                                            </div>
                                            <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                                <div class="block-style-twentyTwo">
                                                    <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                        <img width="50" src="../../../assets/services/content-moderation/3.svg" alt="" />
                                                    </div>
                                                    <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Scalable service with dedicated team experts</p>
                                                </div>
                                            </div>
                                            <div style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                                <div class="block-style-twentyTwo">
                                                    <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                        <img width="50" src="../../../assets/services/content-moderation/4.svg" alt="" />
                                                    </div>
                                                    <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Minimising cost with efficiency</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="fancy-feature-twentyFour pt-90 md-pt-60" id="service">
                    <div class="container">
                        <div class="text-center">
                            <h2 class=" font-montserrat fs-50 fw-600">Our Services</h2>
                        </div>
                    </div>
                    <div class="bg-wrapper mt-100 md-mt-80">
                        <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center">
                            <img src="../images/shape/200.svg" alt="" />
                        </a>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/comment moderation.svg" alt="Comments and Remark Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Comments & Remark Moderation</h4>
                                                <p>Our content moderation experts audit any abusive remarks and any words that might potentially harm your online reputation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4F86" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/text moderation.svg" alt="Text Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Text Moderation</h4>
                                                <p>Our content audit specialists constantly maintain a variety of client-produced material and text-based content, helping to protect your brand's reputation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/image moderation.svg" alt="Picture and Image Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Picture & Image Moderation</h4>
                                                <p>Our content moderators filter, channel, and remove a lot of photographs and images depending on the predetermined criteria, assisting you in staying true to your brand.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#6D64FF" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/video moderation.svg" alt="Video Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Video Moderation</h4>
                                                <p>Our video content moderators vet unwanted video footage that can potentially harm your brand reputation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#393E75" }}>
                                                {/* <img width="35" src="../../../assets/services/content-moderation/social media.svg" alt="Social Media Moderation" /> */}
                                                <VideocamOutlinedIcon style={{ color: "#fff", fontSize: "50px" }} />
                                            </div>
                                            <div class="text">
                                                <h4>Video KYC</h4>
                                                <p>Video-Enabled KYC Verification for Seamless Onboarding with Facial matching, Chatbox support for document sharing & Location tagging.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E22F36" }}>
                                                {/* <img width="35" src="../../../assets/services/content-moderation/Obscenity Moderation.svg" alt="Obscenity Moderation" /> */}
                                                <ViewQuiltIcon style={{ color: "#fff", fontSize: "50px" }} />
                                            </div>
                                            <div class="text">
                                                <h4>Cataloguing</h4>
                                                <p>Well-crafted, well-categorized, & well-structured cataloguing centred on providing a great user experience & faster request fulfillment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E752FF" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/social media.svg" alt="Social Media Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Social Media Moderation</h4>
                                                <p>Our social media content specialists review every widely used social media platform to make sure that your brand presence is managed promptly.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                    <div class="block-style-twentyFour">
                                        <div class="d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#29EEFB" }}>
                                                <img width="35" src="../../../assets/services/content-moderation/Obscenity Moderation.svg" alt="Obscenity Moderation" />
                                            </div>
                                            <div class="text">
                                                <h4>Obscenity Moderation</h4>
                                                <p>Our content censors make sure that there is no obscene content associated with your brand that can offend your target market.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div id="feedback" class="pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">5000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Content Moderators</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Language Support</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">96</span>
                                                    <span>%</span>
                                                </div>
                                                <div class="bill-cycle">Accuracy</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">15</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Minutes AHT <br />(Average Handling Time)</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">100000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Man-hours Completed</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">50000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Content Created/Reviewed</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


                {/* <div class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* connect card start */}

                <div style={{ width: "90%", margin: "auto", marginTop: "70px" }}>
                    <Card style={{ background: "#603890", color: "#fff", borderRadius: "30px" }} >
                        <CardContent>

                            <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", flexWrap: "wrap" }} >
                                <div className={classes.connectCard} >
                                    <div>
                                        <div style={{ textAlign: "center" }} >
                                            <p className={classes.connectCardText} data-aos="fade-up" >
                                                Discover what Taskmo can do for you
                                            </p>
                                        </div>
                                        <div style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }} data-aos="fade-up" >
                                            <Button
                                                onClick={handleClickOpen}
                                                variant="contained" style={{ borderRadius: "15px", background: "#F7D159", color: "#603890" }} >
                                                <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat" }} >
                                                    Connect with our expert
                                                </p>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={classes.connectCardImg} >
                                        <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/495286b33ab9cadebdff1682016514716_gigify+1.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* connect card end */}

                {/* Expertise Start */}

                <div style={{ width: "100%", background: "#6A3996", color: "#fff", marginTop: "50px" }} >

                    <div style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "30px" }} >
                        <h2 style={{ color: "#fff", fontSize: "30px", fontFamily: "Montserrat" }} >
                            Why Taskmo?
                        </h2>
                    </div>

                    <div style={{ width: "80%", margin: "auto" }} >

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "70px",
                            flexWrap: "wrap",
                            gap: "10px"
                        }}>
                            <div style={{
                                border: "5px solid #BA73FA",
                                borderRadius: "30px",
                                boxSizing: "border-box",
                                padding: "35px",
                                textAlign: "center",
                                width: "100%",
                                maxWidth: "220px",
                                minWidth: "200px",
                                margin: "auto",
                                marginBottom: "30px"
                            }}>
                                <h2 style={{
                                    color: "#F7D159",
                                    fontSize: "40px",
                                    fontFamily: "Montserrat"
                                }}>
                                    30Mn+
                                </h2>
                                <p style={{
                                    color: "#fff",
                                    lineHeight: "23.44px",
                                    fontSize: "19px",
                                    fontWeight: 400,
                                    marginTop: "15px"
                                }}>
                                    Successful Content Audits
                                </p>
                            </div>

                            <div style={{
                                border: "5px solid #BA73FA",
                                borderRadius: "30px",
                                boxSizing: "border-box",
                                padding: "35px",
                                textAlign: "center",
                                width: "100%",
                                maxWidth: "200px",
                                minWidth: "200px",
                                margin: "auto",
                                marginBottom: "30px"
                            }}>
                                <h2 style={{
                                    color: "#F7D159",
                                    fontSize: "40px",
                                    fontFamily: "Montserrat"
                                }}>
                                    5000+
                                </h2>
                                <p style={{
                                    color: "#fff",
                                    lineHeight: "23.44px",
                                    fontSize: "19px",
                                    fontWeight: 400,
                                    marginTop: "15px"
                                }}>
                                    Pincodes Across India
                                </p>
                            </div>

                            <div style={{
                                border: "5px solid #BA73FA",
                                borderRadius: "30px",
                                boxSizing: "border-box",
                                padding: "35px",
                                textAlign: "center",
                                width: "100%",
                                maxWidth: "200px",
                                minWidth: "200px",
                                margin: "auto",
                                marginBottom: "30px",
                                minHeight: "196px"
                            }}>
                                <h2 style={{
                                    color: "#F7D159",
                                    fontSize: "40px",
                                    fontFamily: "Montserrat"
                                }}>
                                    25+
                                </h2>
                                <div style={{
                                    color: "#fff",
                                    lineHeight: "23.44px",
                                    fontSize: "19px",
                                    fontWeight: 400,
                                    marginTop: "15px"
                                }}>
                                    <p>
                                        Happy
                                    </p>
                                    <p>
                                        Brands
                                    </p>
                                </div>
                            </div>

                            <div style={{
                                border: "5px solid #BA73FA",
                                borderRadius: "30px",
                                boxSizing: "border-box",
                                padding: "35px",
                                textAlign: "center",
                                width: "100%",
                                maxWidth: "200px",
                                minWidth: "200px",
                                margin: "auto",
                                marginBottom: "30px",
                            }}>
                                <h2 style={{
                                    color: "#F7D159",
                                    fontSize: "40px",
                                    fontFamily: "Montserrat"
                                }}>
                                    96%
                                </h2>
                                <p style={{
                                    color: "#fff",
                                    lineHeight: "23.44px",
                                    fontSize: "19px",
                                    fontWeight: 400,
                                    marginTop: "15px"
                                }}>
                                    Accuracy delivered
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Expertise ends */}

                {/* Success Stories start */}

                <div style={{ width: "90%", margin: "auto", marginTop: "70px" }} >
                    <div>
                        <div style={{ width: "100%", textAlign: "center", marginTop: "50px", marginBottom: "50px" }} >
                            <h2 style={{ fontFamily: "Montserrat", color: "#3C3C3C", fontSize: "50px" }} >
                                Success Stories
                            </h2>
                        </div>

                        <Grid container spacing={3} >

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card style={{ background: "#FFD7D4", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                    <CardContent>
                                        <div className={classes.flipKartCard}>
                                            <div className={classes.successStoriesImageDiv}>
                                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/d9b2e96ba2db5820313c1682507673562_trell-300x169.webp" alt="" style={{ width: "100%" }} />
                                            </div>

                                            <div className={classes.successStoriesNumbers} >
                                                <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >98%+</p>
                                            </div>

                                            <div className={classes.successStoriesText}>
                                                <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                    Quality delivered in content moderation to ensure the platform's safety and uphold the brand's reputation.
                                                </p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card style={{ background: "#FFC8D5", borderRadius: "30px", padding: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }} data-aos="zoom-in-up">
                                    <CardContent>
                                        <div className={classes.flipKartCard}>
                                            <div className={classes.successStoriesImageDiv}>
                                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/fab2a776e6205c42ddfd1682507867890_61055231c32c810004977262.png" alt="" style={{ width: "100%" }} />
                                            </div>

                                            <div className={classes.successStoriesNumbers}>
                                                <p style={{ fontFamily: "Montserrat", fontSize: "30px", fontWeight: 900 }} >48000+</p>
                                            </div>

                                            <div className={classes.successStoriesText}>
                                                <p style={{ color: "#000", lineHeight: "23.44px", fontWeight: 400 }} >
                                                    Content reviewed for audio moderation to ensure that community guidelines were being followed
                                                </p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* Success Stories end */}


                <Testimonial />

                <div class="faq-classic pt-140 md-pt-120">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="title-style-one">
                                    <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                    <h2 class="fs-50 fw-600 font-montserrat">Have any thoughts? Look here.</h2>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div id="accordion" class="md-mt-60">

                                    {/* <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                    What type of content moderation services do you offer?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" ref={navRef1} class="collapse">
                                            <div class="card-body font-montserrat">
                                                <p>
                                                    At Taskmo we offer all types of content moderation services that include
                                                    comments, images, videos, social media and so much more. You can outsource
                                                    your content moderation services of any volume to Taskmo and we’ll help you
                                                    detect content issues such as spam, abuse, defamation, copyright infringements,
                                                    offense, plagiarism, profanity, nudity, pornography, or quality issues.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                    Do you offer a combination or multiple content moderation services?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" ref={navRef2} class="collapse">
                                            <div class="card-body font-montserrat">
                                                <p>
                                                    Yes, Absolutely! Get in touch with our experts to know more details about customised services.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}

                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={expanded === 'panel1' ? <CancelIcon /> : <AddCircleOutlineIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >

                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                                <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >01</p>
                                                <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                    What is content moderation?
                                                </h2>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p>
                                                Content moderation is the process of monitoring and reviewing user-generated content, such as text, images, videos, and audio, on digital platforms to ensure it complies with the brand as well as the platform's policies and guidelines. The purpose of content moderation is to ensure a secure, welcoming, and polite online environment, shield users from objectionable material, and stop abuse, harassment, and discrimination. Taskmo's content moderation service is done by human moderators that have been instructed what content must be removed as inappropriate or offensive, or automatically using disruptive technology for precise content moderation
                                            </p>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={expanded === 'panel2' ? <CancelIcon /> : <AddCircleOutlineIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >

                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                                <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >02</p>
                                                <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                    How does Content Moderation work?
                                                </h2>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p>
                                                Content moderation can be done using a variety of methods, depending on the platform's policies, the type of content being moderated, and the resources available. Taskmo utilises human moderation combined with advanced technology. The goal is to create a safe and inclusive online environment while balancing the need for free expression and open dialogue
                                            </p>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={expanded === 'panel3' ? <CancelIcon /> : <AddCircleOutlineIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >

                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                                                <p style={{ fontSize: "24px", fontWeight: 700, color: "#414141" }} >03</p>
                                                <h2 style={{ fontFamily: "Montserrat", color: "#414141" }} >
                                                    How can content moderation improve online safety?
                                                </h2>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p>
                                                Content moderation plays a crucial role in improving online safety by creating a safe and inclusive environment for users. A few ways in which content moderation can contribute to online safety include removing harmful content, preventing misinformation, protecting vulnerable users, and enforcing community standards. Taskmo’s effective moderation policies and practices ensure a positive user experience.
                                            </p>
                                        </AccordionDetails>
                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-aos="zoom-in-up"
                style={{ zIndex: "100000" }}
            >
                <DialogContent style={{ margin: "0px", padding: "0px" }} >
                    <div>
                        <Find_Taskers_Common subscriber_type="Contact" />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

