import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Testimonial from '../../home/testimonial/index';
import OurPartners from '../../home/ourPartners/index';
import PopularCaseStudy from '../../home/popularCaseStudy/PopularCaseStudy';
import { Helmet } from 'react-helmet';
import Find_Taskers_Common from 'components/Find_Taskers_Common';
import { Link } from "react-router-dom";
import { localRoute } from 'routes/localRoutes';
import CreditCard from '../../../assets/images/credit-card.svg';
import DebitCard from '../../../assets/images/debit-card.svg';
import LoanImg from '../../../assets/images/loan.svg';
import goldLoanImg from '../../../assets/images/rupee.svg';
import accountOpeningImg from '../../../assets/images/account-opening.svg';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';


const FinancialSolutions = () => {
    const navRef1 = React.useRef(null);
    const navRef2 = React.useRef(null);

    const onToggleClick1 = (e) => {
        navRef1.current.classList.toggle("show");
    }
    const onToggleClick2 = (e) => {
        navRef2.current.classList.toggle("show");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ marginTop: "20px" }}>


            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Financial Products and Services | Sell and Earn || Taskmo
                </title>
                <meta name="description" content="Taskmo offers wide range of Financial Products and Services, we provide skilled taskers to sell and earn financial services such as loans, credit cards, insurance etc" />
                <meta name="keywords" content="financial products and services, banking products and services sell financial products, financial products, debit card services, credit card services, account opening services, gold loan services, regular loans, insurance products, financial solutions" />
                <link rel="canonical" href="https://taskmo/services/financial-products-and-services" />
            </Helmet>

            <div class="fancy-text-block-seven md-mt-40">
                <div class=" title-style-two text-center">
                    <h1 style={{ textAlign: "center" }} class="font-montserrat fs-55 fw-600">
                        Financial Products and Services
                        <img style={{ margin: "auto" }} class="underline-image" src="../../../images/shape/line-shape-2.svg" alt="Merchant Onboarding" />
                    </h1>
                </div>
                <div class="bg-wrapper no-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-7 ml-auto" data-aos="fade-up" data-aos-duration="1200">
                                <div class="quote-wrapper ">
                                    <p class="mt-20 fs-18 text-align-left">
                                        Companies who are battling to have a sale with high-rate credit & debit cards, loans, account openings etc can receive financial counselling from Taskmo. We provide our trained professionals to aid companies boost their sales. By utilising the strength of technology and network, we have a comprehensive solution for companies' financial services and increase their sales and earnings.
                                    </p>
                                    <p class="mt-20 fs-18 text-align-left">
                                        At Taskmo, we have a team of skilled executives who will see to it that the procedure is carried out in a quick and effective way. Our team of professionals will be able to comprehend the needs for all the financial solutions and work to boost sales five times faster, giving the required outcomes.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
                                <Find_Taskers_Common subscriber_type="Finance Solutions" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="pricing-section-two">
                <div class="fancy-hero-four" data-aos="fade-up" data-aos-duration="1200">
                    <div class="title-style-two text-center md-mb-50" >
                        <h2 style={{ textAlign: "center" }} class="font-montserrat fs-50 fw-600">
                            Why Choose Us?
                        </h2>
                    </div>
                </div>
                <div style={{ border: "none" }} data-aos="fade-up" data-aos-duration="1200" class="container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div class="clearfix">
                            <div class="tab-content">
                                <div>
                                    <div style={{ border: "none" }} class="row no-gutters">
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FF4A8B" }}><img width="50" src="../../../assets/services/merchant/1.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Instant Access to Financial Product Experts </p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#3FE193" }}><img width="50" src="../../../assets/services/merchant/2.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>Pre-Vetted and Experienced On-Ground Taskers</p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFCF39" }}><img width="50" src="../../../assets/services/merchant/3.svg" alt="" /></div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A Unique and Proven Distribution Strategy</p>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" style={{ border: "none" }} class="col-lg-3 col-sm-6 pr-bg d-flex">
                                            <div class="block-style-twentyTwo">
                                                <div class="icon d-flex align-items-center justify-content-center"
                                                    style={{ background: "#6D64FF" }}
                                                >
                                                    {/* <img width="50" src="../../../assets/services/merchant/4.svg" alt="" /> */}
                                                    <AccountBalanceIcon style={{ color: "#fff", fontSize: "50px" }} />
                                                </div>
                                                <p class="font-montserrat fw-600 mt-20" style={{ lineHeight: "1.7em" }}>A broad range of all Financial product solution</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="fancy-feature-nineteen md-mt-100" id="effect" style={{ marginBottom: "80px" }} >
                <div class="container">
                    <div class="font-montserrat row">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#673695' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src={LoanImg} alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Regular Loans</h4>
                                        <li >
                                            Highly Efficient Promoters
                                        </li>
                                        <li>
                                            Experienced Professionals
                                        </li>
                                        <li>
                                            100% Transparency In Services
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#673695' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src={goldLoanImg} alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Gold Loans</h4>
                                        <li>
                                            Hassle-Free and quick processing
                                        </li>
                                        <li>
                                            Trained Professionals
                                        </li>
                                        <li>
                                            Quick TAT
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#673695' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src={accountOpeningImg} alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Account Opening</h4>
                                        <li>
                                            Unique & Distinctive Process
                                        </li>
                                        <li>
                                            Instant Access To Experts
                                        </li>
                                        <li>
                                            Easy Workflow Management
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#673695' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src={CreditCard} alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Credit Cards</h4>
                                        <li>
                                            Assisting customers in the process
                                        </li>
                                        <li>
                                            Creating Awareness About Benefits
                                        </li>
                                        <li>
                                            Skilled experts to handle any issue
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <div class="block-style-nineteen" style={{ borderLeftColor: '#673695' }}>
                                <div class="d-flex align-items-start">
                                    <img width="90" src={DebitCard} alt="" class="icon" />
                                    <div class="text">
                                        <h4 class="font-montserrat">Debit Cards</h4>
                                        <li>
                                            Exponentially Increase Sales
                                        </li>
                                        <li>
                                            Providing Prompt Assistance
                                        </li>
                                        <li>
                                            Effortless & Efficient Performance
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OurPartners />
            {/* <PopularCaseStudy /> */}

            <div class="mt-40 pricing-section-two">
                <div class="fancy-hero-four">
                    <div class="bg-wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-10 col-lg-11 m-auto">
                                    <h2 class=" font-montserrat fs-50 fw-600">Our Expertise</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" font-montserrat container">
                    <div style={{ border: "none" }} class="pricing-table-area">
                        <div style={{ border: "none" }} class="clearfix">
                            <div class="tab-content">
                                <div class="tab-pane active">
                                    <div class="row no-gutters">
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">5</span>
                                                    <span>x</span>
                                                </div>
                                                <div class="bill-cycle">Faster Onboarding Rate</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">250</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Brands</div>
                                            </div>
                                        </div>
                                        <div style={{ border: "none", lineHeight: "1" }} data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="col-lg-4 col-sm-6 pr-bg d-flex">
                                            <div class="pr-table-wrapper">
                                                <div class="counter-item font-montserrat fs-50 fw-600 price">
                                                    <span class="counter">500000</span>
                                                    <span>+</span>
                                                </div>
                                                <div class="bill-cycle">Onboardings</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-50 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <a style={{ borderRadius: "10px", color: "black", backgroundColor: "white" }} href="#" class="m-auto fw-500 fs-18 font-montserrat theme-btn-nine">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial />

            <div class="faq-classic pt-10 ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-montserrat">Frequently Asked Questions</h6>
                                <h2 class="fs-50 fw-600 font-montserrat">Have any thought? Look here.</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick1} class="btn btn-link font-montserrat">
                                                What are the services under financial solution?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef1} class="show collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo has a wide range of services under Financial Solutions. For instance, our trained executives help organisations in boosting their sales and reaching targets in the sectors of account opening, gold loans, debit & credit cards and regular loans.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="accordion" class="md-mt-60">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button onClick={onToggleClick2} class="btn btn-link font-montserrat">
                                                How Taskmo helps in selling your financial products?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" ref={navRef2} class="show collapse">
                                        <div class="card-body font-montserrat">
                                            <p>
                                                Taskmo with its dynamic workforce supports Fintech companies to acquire new customers using both online and offline channels and set up an appointment for all the required services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialSolutions