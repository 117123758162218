import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import DeliveryOnboarding from "../../../../components/services/merchant/onboardingServices/deliveryOnboarding/index";

const DeliveryExecutiveOnboardingPage = React.memo((props) => {
  return (
    <>
      <DeliveryOnboarding />
    </>
  );
});

export default withRouter(DeliveryExecutiveOnboardingPage);
