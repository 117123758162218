import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BusinessFormModal from '../../../BusinessFormModal'

export default function RapidoCaseStudy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Rapido | Partner Onboarding | Bike Taxi Booking || Taskmo
                </title>
                <meta name="description" content="Taskmo - Rapido Bike Taxi Booking company, Taskmo's digitally trained taskers visited auto drivers and educated them, get rides easily and instantly. Click here" />
                <meta name="keywords" content="rapido, rapido captain, partner onboarding, ride sharing, rideshare apps, bike taxi, rapido bike, rapido bike booking" />
                <link rel="canonical" href="https://taskmo.com/case-study/rapido-bike-taxi-partner-onboarding" />
            </Helmet>
            <section class="portfolio-area" style={{ marginTop: "30px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 order-first">
                            <div class="portfolio-thumb">
                                <img src="../../assets/case-study/rapido.png" alt="rapido casestudy" />
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-last">
                            <div class="portfolio-info-box wow fadeInUp">
                                <h4 class="font-montserrat fw-700">Project Info</h4>
                                <ul>
                                    <li>
                                        <span class="font-montserrat fw-600">Client</span>
                                        <span class="font-montserrat fw-500">Rapido</span>
                                    </li>
                                    <li>
                                        <span class="font-montserrat fw-600">Project Category</span>
                                        <span class="font-montserrat fw-500">Partner Onboarding</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="portfolio-details-content wow fadeInUp">
                                <h2 class="font-montserrat fs-50 fw-600">Rapido - Partner Onboarding </h2>

                                <h3 class="font-montserrat fw-600 mt-50">ABOUT RAPIDO-</h3>
                                <p class="mb-10 mt-10 fs-18">
                                    You must be noticing a lot of bikes and autos on the road. This gradual shift worldwide means that people are accepting alternative modes of transportation apart from owning their private transport or using the public transport.
                                </p>
                                <p class="mb-10 fs-18">
                                    Let's face it, how many of you reach the office on time? How often have you cancelled your solo plans because of a lack of a vehicle? Neither your family or friends are ready to drop you nearby?
                                </p>
                                <p class="mb-30 fs-18">
                                    That's where Rapido comes to the rescue. Rapido is a Bangalore-based Indian online bike taxi aggregator which was founded in the year 2015. It operates in 100+ cities across the country and has more than 15000 registered riders daily. Rapido is used even at night because it allows you to track the location online, and it's safe to go anywhere in the city. It only aims to make one's traveling experience safe and smooth and has a goal to expand and reach more people.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-50">CHALLENGES:</h3>

                                <h3 class="font-montserrat fw-600 mt-30">Scalability issues</h3>

                                <p class="mb-10 fs-18">
                                    In the aggregator industry, your product only works when people know about it. Rapido needed our gig workers to be eligible enough to work and meet the auto drivers across different cities to onboard them to the platform.
                                </p>
                                <p class="mb-30 fs-18">
                                    They wanted to execute the process in real-time, and the onboarding campaigns should be purely based on user actions. However, they faced a significant challenge to make their brand with their existing partners.
                                </p>

                                <h3 class="font-montserrat fw-600 sub-title">Engagement with onboarded drivers</h3>
                                <p class="mb-30 fs-18">
                                    The onboarded auto-drivers weren't accepting the rides, thus, creating a negative impact of Rapido services in the user's mind.
                                </p>

                                <h3 class="font-montserrat fw-600 sub-title">SOLUTION:</h3>

                                <h3 class="font-montserrat fw-600 mt-30">Partner Onboarding across different locations</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo helped Rapido scale its network by teaming with them in 2021. Taskmo's combination of real-time visibility, automated onboarding, and local gig workers offered them a simple and fast onboarding solution. Thus, Rapido was able to onboard more and more auto drivers across various locations.
                                </p>

                                <h3 class="font-montserrat fw-600 mt-30">Ride Conversion</h3>
                                <p class="mb-30 fs-18">
                                    Taskmo helped Rapido with user interaction, and the in-house team contacted all the onboarded auto-drivers to push them to accept the rides. The team retold the advantages of being Rapido partners and how they can utilize them. The process was time-consuming, but the team ensured to get the drivers on the road and track their rides.
                                </p>

                                <h3 class="font-montserrat fw-600 sub-title">RESULTS:</h3>
                                <p class="mb-30 fs-18">
                                    Rapido saw an increase in the number of auto-drivers on their platform. Taskmo was able to onboard more than 1 Lakh auto drivers to the platform across 15+ cities. The onboard drivers were QC verified and were checked under the bylaw. The in-house CX team delivered 45-50% ride conversion; the rides were monitored on the 1st, 5th, and 10th ride model and ensured the drivers didn't stop using Rapido, creating a better user experience and adding to brand reputation amongst the users.
                                </p>

                                {/* <div>
                                    <div class="col-lg-9">
                                        <div class="feature-lists mt-30">
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">20,000+ rides </h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">10+ cities</h4>
                                                </div>
                                            </div>
                                            <div class="simple-icon-box icon-left mb-30 wow fadeInUp">
                                                <div class="icon">
                                                    <i class="flaticon-crop"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="font-montserrat fw-600">500+ Taskers</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div data-aos="fade-up" data-aos-duration="1200" class="fancy-short-banner-four mt-10 mb-50 md-pt-100" >
                <div class="container">
                    <div class="bg-wrapper">
                        <div class="row">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" class="title">
                                    <h2 class="font-montserrat fw-600" style={{ textAlign: "center" }}>Discover what Taskmo can do for you</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150" class="margin-top-btn form-wrapper">
                                <BusinessFormModal subscriber_type="Rapido Casestudy Page" Button_Name="Let's Talk" color="#000" background="#fff" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
