import React, { useEffect } from "react";
import {
  Switch,
  Route,
  withRouter,
  useLocation,
  useHistory
} from "react-router-dom";
import Home from "../pages/home";
import Blog from "../pages/blog/blogMain";
import Header from "../pages/header";
import Footer from "../pages/footer";
import BlogDetails1 from "../pages/blog/blogDetails1";

import CaseStudy from "../pages/caseStudyNew/caseStudy";
import CaseStudyDetails from "../pages/caseStudyNew/caseStudyDetails";

import Contact from "../pages/contact";
import Career from "../pages/career";
import CareerDetails from "../pages/career/careerDetails";
import About from "../pages/about";
import Login from "../pages/login";
import Signup from "../pages/signup";
import AppLanding from "../pages/appLanding";
import D2C from "../pages/d2c";
import Campus from "../pages/campus";
import Services from "../pages/services";
import RestartCareer from "../pages/restartCareer";
import AmazonCaseStudy from "../pages/caseStudy/amazonCaseStudy";
import RapidoCaseStudy from "../pages/caseStudy/rapidoCaseStudy";
import PhonepayCaseStudy from "../pages/caseStudy/phonepayCaseStudy";
import ZomatoCaseStudy from "pages/caseStudy/zomatoCaseStudy";
import MerchantOnboarding from "../pages/services/merchant";
import BackgroundVerification from "../pages/services/backgroundVerification";
import taleSales from "../pages/services/taleSales";
import HealthCare from "../pages/services/healthCare";
import BrandActivation from "../pages/services/brandActivation";
import LastMileDelivery from "../pages/services/lastMileDelivery";
import CustomerAcquisition from "../pages/services/customerAcquisition";
import StockAudits from "../pages/services/stockAudits";
import GeoTagging from "../pages/services/geoTagging";
import PickingPacking from "../pages/services/pickingPacking";
import SurveyCollection from "../pages/services/surveyCollection";
import ProductSampling from "../pages/services/productSampling";
import InfluencerMarketing from "../pages/services/influencerMarketing";
import CustomerSupport from "../pages/services/customerSupport";
import WarehouseService from "../pages/services/warehouseService";
import ContentModeration from "../pages/services/contentModeration";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "pages/TermsConditions";
import NotFound from "../pages/notFound";
import NotFound500 from "../pages/notFound500";
import { localRoute } from "./localRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import PrivacyPolicyApp from "../pages/PrivacyPolicy/PrivacyPolicyApp";
import TermsConditionsApp from "pages/TermsConditions/TermsConditionsApp";
import JobsCampaign from "../pages/jobsCampaign";
import IDCard from "../pages/idCard/IDCard";
import News from "../pages/pressRelease/news";
import NewsLetter from "../pages/pressRelease/newsLetter";
import AutoOnboarding from "pages/services/merchant/autoOnboarding/AutoOnboarding";
import BikeOnboarding from "pages/services/merchant/bikeOnboarding/BikeOnboarding";
import TeacherOnboarding from "pages/services/merchant/teacherOnboarding/TeacherOnboarding";
import CabOnboarding from "pages/services/merchant/cabOnboarding/CabOnboarding";
import doctorOnboarding from "pages/services/merchant/doctorOnboarding";
import deliveryExecutiveOnboarding from "pages/services/merchant/deliveryExecutiveOnboarding";
import vendorOnboarding from "pages/services/merchant/vendorOnboarding";
import shopOnboarding from "pages/services/merchant/shopOnboarding";
import PPC from "pages/ppc";
import MediaKitPage from "pages/MediaKitPage";
import ThankYouPage from "pages/thankyouPage/ThankYouPage";
import FinancialSolutions from "components/services/financialSolutions/FinancialSolutions";
import SpamAlert from "pages/spamAlert/SpamAlert";
import SellerOnboarding from "components/home/services/sellerOnboarding/SellerOnboarding";
import PartnerOnboarding from "components/home/services/partnerOnboarding/PartnerOnboarding";
import RetailStoreAudit from "components/home/services/retailStoreAudit/RetailStoreAudit";
import RetailStoreVisit from "components/home/services/retailStoreVisit/RetailStoreVisit";
import OrderTakingManagement from "components/home/services/orderTakingManagement/OrderTakingManagement";
import PosMarketing from "components/home/services/posMarketing/PosMarketing";
import SellingSubscriptions from "components/home/services/sellingSubscriptions/SellingSubscriptions";
import SellingBusinessLoans from "components/home/services/sellingBusinessLoans/SellingBusinessLoans";
import BusinessPartnerProfile from "pages/businessPartnerProfile/BusinessPartnerProfile";
import BusinessPartnerSignUp from "pages/businessPartnerSignUp/BusinessPartnerSignUp";
import BusinessPartnerSignIn from "pages/businessPartnerSignIn/BusinessPartnerSignIn";
import Bpp from "pages/bpp/Bpp";
import BusinessPartnerOTP from "pages/businessPartnerSignUp/BusinessPartnerOTP";
import Taskam from "pages/taskam/Taskam";

// import BharatPetroleum from "pages/bharatPetroleum/BharatPetroleum";



function RouterComponent() {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, [])
  let pageTitlePath = history.location && history.location.pathname.split('/')

  // console.log("pageTitlePath", pageTitlePath)

  // console.log("checkLocationnnn", location.pathname)



  return (
    <React.Fragment>

      {pageTitlePath && pageTitlePath[1] !== 'privacy-policy-app' && pageTitlePath[1] !== 'id-card' && pageTitlePath[1] !== 'bharat-petroleum' && pageTitlePath && pageTitlePath[1] !== 'b2b-services' && pageTitlePath && pageTitlePath[1] !== 'bpp' && pageTitlePath && pageTitlePath[1] !== 'business-partner-program' && pageTitlePath && pageTitlePath[1] !== 'business-partner-sign-up' && pageTitlePath && pageTitlePath[1] !== 'business-partner-sign-in' && pageTitlePath[1] !== 'business-partner-otp' ? <Header /> : ''}
      <Switch>

        <Route exact path={localRoute.home} component={Home} />
        <Route path={localRoute.blog} component={Blog} />
        <Route path={`${localRoute.blogDetails1}/:blog_id`} component={BlogDetails1} />
        <Route exact path={localRoute.privacyPolicyApp} component={PrivacyPolicyApp} />
        <Route exact path={localRoute.termsConditionsApp} component={TermsConditionsApp} />
        <Route path={localRoute.contact} component={Contact} />
        <Route path={localRoute.career} component={Career} />
        <Route path={`${localRoute.careerDetails}/:portal_job_id`} component={CareerDetails} />
        <Route path={localRoute.careerDetails} component={CareerDetails} />
        <Route path={localRoute.about} component={About} />
        <Route path={localRoute.appLanding} component={AppLanding} />
        <Route path={localRoute.d2c} component={D2C} />
        <Route path={localRoute.jobsCampaign} component={JobsCampaign} />
        <Route path={localRoute.campus} component={Campus} />
        <Route path={localRoute.login} component={Login} />
        <Route path={localRoute.signup} component={Signup} />
        <Route exact path={localRoute.services} component={Services} />
        <Route path={localRoute.restartCareer} component={RestartCareer} />
        <Route path={localRoute.casestudyAmazon} component={AmazonCaseStudy} />
        <Route path={localRoute.casestudyZomato} component={ZomatoCaseStudy} />
        <Route path={localRoute.casestudyRapido} component={RapidoCaseStudy} />
        <Route path={localRoute.casestudyPhonepay} component={PhonepayCaseStudy} />
        <Route path={localRoute.financialSolutions} component={FinancialSolutions} />
        <Route path={localRoute.merchantOnboarding} component={MerchantOnboarding} />
        <Route path={localRoute.backgroundVerification} component={BackgroundVerification} />
        <Route path={localRoute.teleSales} component={taleSales} />
        <Route path={localRoute.healthCare} component={HealthCare} />
        <Route path={localRoute.brandActivation} component={BrandActivation} />
        <Route path={localRoute.lastMileDelivery} component={LastMileDelivery} />
        <Route path={localRoute.customerAcquisition} component={CustomerAcquisition} />
        <Route path={localRoute.stockAudits} component={StockAudits} />
        <Route path={localRoute.geoTagging} component={GeoTagging} />
        <Route path={localRoute.pickingPacking} component={PickingPacking} />
        <Route path={localRoute.surveyCollection} component={SurveyCollection} />
        <Route path={localRoute.productSampling} component={ProductSampling} />
        <Route path={localRoute.influencerMarketing} component={InfluencerMarketing} />
        <Route path={localRoute.customerSupport} component={CustomerSupport} />
        <Route path={localRoute.warehouseService} component={WarehouseService} />
        <Route path={localRoute.contentModeration} component={ContentModeration} />
        <Route path={localRoute.privacyPolicy} component={PrivacyPolicy} />
        <Route path={localRoute.termsConditions} component={TermsConditions} />
        <Route path={localRoute.IDCardPage} component={IDCard} />
        <Route path={localRoute.news} component={News} />
        <Route path={localRoute.newsletter} component={NewsLetter} />
        <Route path={localRoute.mediaKit} component={MediaKitPage} />

        <Route path={localRoute.autoOnboarding} component={AutoOnboarding} />
        <Route path={localRoute.cabOnboarding} component={CabOnboarding} />
        <Route path={localRoute.bikeOnboarding} component={BikeOnboarding} />
        <Route path={localRoute.teacherOnboarding} component={TeacherOnboarding} />

        <Route path={localRoute.doctorOnboarding} component={doctorOnboarding} />
        <Route path={localRoute.deliveryOnboarding} component={deliveryExecutiveOnboarding} />
        <Route path={localRoute.vendorOnboarding} component={vendorOnboarding} />
        <Route path={localRoute.shopOnboarding} component={shopOnboarding} />

        <Route path={localRoute.caseStudy} component={CaseStudy} />
        <Route path={`${localRoute.caseStudyDetails}/:casestudy_id`} component={CaseStudyDetails} />

        <Route path={localRoute.PPC} component={PPC} />
        <Route path={localRoute.thankYou} component={ThankYouPage} />

        <Route exact path="/services/seller-onboarding-service" component={SellerOnboarding} />
        <Route exact path="/services/partner-onboarding-services" component={PartnerOnboarding} />
        <Route exact path="/services/retail-store-audit-service" component={RetailStoreAudit} />
        <Route exact path="/services/retail-store-visits-service" component={RetailStoreVisit} />
        <Route exact path="/services/outsource-order-taking-service" component={OrderTakingManagement} />
        <Route exact path="/services/pos-marketing-services" component={PosMarketing} />
        <Route exact path="/services/selling-business-loans" component={SellingBusinessLoans} />
        <Route exact path="/services/sell-subscription" component={SellingSubscriptions} />


        <Route exact path="/business-partner-program" component={BusinessPartnerProfile} />
        <Route exact path="/business-partner-sign-up" component={BusinessPartnerSignUp} />
        <Route exact path="/business-partner-otp" component={BusinessPartnerOTP} />
        <Route exact path="/business-partner-sign-in" component={BusinessPartnerSignIn} />
        <Route exact path="/bpp" component={Bpp} />
        <Route exact path="/TASKAM" component={Taskam} />

        {/* <Route exact path={localRoute.bharatPetroleum} component={BharatPetroleum} /> */}
        <Route
          path={`${localRoute.notFound}`}
          component={NotFound}
        />
        <Route
          path={`${localRoute.notFound500}`}
          component={NotFound500}
        />

      </Switch>
      {pageTitlePath && pageTitlePath[1] !== 'privacy-policy-app' && pageTitlePath[1] !== 'id-card' && pageTitlePath[1] !== 'bharat-petroleum' && pageTitlePath && pageTitlePath[1] !== 'bpp' && pageTitlePath && pageTitlePath[1] !== 'business-partner-sign-up' && pageTitlePath && pageTitlePath[1] !== 'business-partner-sign-in' && pageTitlePath[1] !== 'business-partner-otp' ? <Footer /> : ''}

    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
