import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import DoctorOnboarding from "../../../../components/services/merchant/onboardingServices/doctorOnboarding";

const DoctorOnboardingPage = React.memo((props) => {
  return (
    <>
      <DoctorOnboarding />
    </>
  );
});

export default withRouter(DoctorOnboardingPage);
