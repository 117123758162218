import { GET_JOBS,GET_JOBS_ERR } from '../../actionTypes/careerType'
import { getJobsApi } from 'api/Api';
import axios from "axios";
export const getJobs = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getJobsApi}/yes/all`)
        console.log("GET JOBS", results)
        let data = results && results.data
        dispatch({
            type: GET_JOBS,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_JOBS_ERR,
            payload: error && error.response
        });
    }
}

