import React, { useEffect, useState, useContext, } from "react";
import { getJobs } from '../../context/actions/careerAction/getCareers'

import { getJobCategories } from '../../context/actions/careerAction/getCareerCategories'
import { GlobalContext } from '../../context/GlobalState'
import { localRoute } from '../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Pagination, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Career(props) {
	const { jobState, jobDispatch } = useContext(GlobalContext);
	const [jobsDetail, setJobsDetail] = useState([])

	const [jobCategoriesDetail, setJobCategoriesDetail] = useState([])
	// const [blogId, setBlogId] = useState([])
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [paginationCount, setPaginationCount] = useState(1);
	const [totalInActiveRows, setTotalInActiveRows] = useState(0);
	const [totalActiveRows, setTotalActiveRows] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [jobList, setJobList] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const [tabFilter, setTabFilter] = useState('active');
	const [filterJobDetails, setFilterJobDetails] = useState([]);
	const [color, setColor] = useState("")
	// const {blog_id}=useParams()

	const history = useHistory()

	const handleBackRestartCareer = () => {
		history.push(localRoute.restartCareer)
	};

	const handleChangePage = (event, newPage) => {
		let count = newPage - 1
		setPage(count);
		console.log('count', count)
		setPaginationCount(newPage)
		let filter = tabFilter
		let page = newPage;
		// getJobs({ filter, page })(jobDispatch)
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		let filter = tabFilter
		getJobCategories({ filter, page: "all" })(jobDispatch)
	}, [])

	useEffect(() => {
		console.log('blogCategoriesState1', jobState)
		const status = jobState && jobState.getJobCategories.status
		if (status == true) {
			setJobCategoriesDetail(jobState.getJobCategories.data)
			console.log("set123", setJobCategoriesDetail(jobState.getJobCategories.data.res));
		}
	}, [jobState])
	console.log('jobCategoriesDetail', jobCategoriesDetail)


	useEffect(() => {
		let filter = tabFilter
		getJobs({ filter, page: "all" })(jobDispatch)
	}, [])


	useEffect(() => {
		let status = jobState.getJobs.status
		if (status === true) {
			console.log("taskerState() ", jobState.getJobs)
			let limit = '8'
			let total_page = Math.ceil(jobState.getJobs.data.res.length / limit)
			console.log('total_page', total_page)
			setTotalPage(total_page)
			setPaginationCount(1)
			setPage(0)
			// setTotalPage(jobState.getJobs.data.total_page)
			// setTotalActiveRows(jobState.getJobs.data.total_active_rows)
			// setTotalInActiveRows(jobState.getJobs.data.total_inactive_rows)
			setTotalRows(jobState.getJobs.data.total_rows)
			setJobList(jobState.getJobs.data.res)

			setFilterJobDetails(jobState.getJobs.data.res)

		}
		// SERVER ERROR RESPONSE
		if (status === 500) {
			console.log("Server Error:", jobState.getJobs)
		}
		// SERVER VALIDATION ERROR RESPONSE  
		if (status === 401) {
			console.log("Validation Error:", jobState.getJobs)
		}

		let update_status = jobState.updateBlog && jobState.updateBlog.data && jobState.updateBlog.status
		if (update_status === true) {
			console.log('getBlog', jobState.updateBlog.data)
			jobState.updateBlog = {
				loading: false,
				error: null,
				data: null,
			}
			let filter = tabFilter
			getJobs({ filter, page: "all" })(jobDispatch)
		}
	}, [jobState])

	console.log("filterJobDetails", filterJobDetails);
	console.log("color", color)


	const openJobCategory = (category_id) => {
		let count = 0
		setPage(count);
		// console.log('count', count)
		setFilterJobDetails([])
		console.log('category_id', category_id)
		let filtered = jobList.filter(m => m.career_category_id == category_id)
		console.log(filtered)
		let limit = '8'
		let total_page = Math.ceil(filtered.length / limit)
		console.log('total_page', total_page)
		setTotalPage(total_page)
		setFilterJobDetails(filtered)
	}
	const OpenJobPage = (item) => {
		history.push(`${localRoute.careerDetails}/${item.portal_job_id}`)
	}


	useEffect(() => {
		AOS.init({
			duration: 2000
		});
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])



	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Careers | Gig Onboarding Services || Gig Marketplace
				</title>
				<meta name="description" content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters." />
				<meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
				<link rel="canonical" href="https://taskmo.com/career" />
			</Helmet>

			<div class="blog-v3" style={{ marginTop: "30px" }} >
				<div onClick={handleBackRestartCareer} style={{ cursor: "pointer" }} class="container">
					<img id="img1" src="../../assets/career/Unemployed.jpg" style={{ borderRadius: "10px" }} class=" illustration" />
					<img id="img2" src="../../assets/career/Unemployed1.jpg" style={{ borderRadius: "10px" }} class=" illustration" />
				</div>
				<div style={{ marginTop: "30px" }} class="container">
					<div class="row">
						<div class="col-lg-4 col-md-12" >
							<div class="blog-sidebar-one">
								<div class="sidebar-categories ">
									<h4 class="sidebar-title font-montserrat fw-600">Categories</h4>
									<div class="sidebar-categories mb-85">
										<div class="nav-tabs">
											<ul class=" career-category ">
												{jobCategoriesDetail.map(item =>
													<li class="nav-item">
														<a class="font-montserrat title nav-link" data-toggle="tab" style={{ border: "none", cursor: "pointer" }} onClick={() => openJobCategory(item.career_category_id)}>{item.career_category_name}</a>
													</li>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-8">
							<div class="tab-content">
								<div class="tab-pane active" id="month">
									<div class="font-montserrat row ">
										{filterJobDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
											return (
												<div class="pt-20 col-lg-6 col-md-6">
													<div style={{ padding: "15px", cursor: "pointer", display: "flex", background: "#F2F2F2" }} onClick={() => OpenJobPage(item)} key={item.portal_job_id} class="pr-table-wrapper">
														<div style={{ width: "80%" }}>
															<div style={{ color: "#471A71" }} class="bill-cycle fw-600 font-montserrat">{item.portal_job_designation}</div>
															<div class="bill-cycle fw-400" style={{ color: "#A6A6A6", fontSize: "16px" }}>{item.portal_job_location}</div>
														</div>
														<div style={{ width: "20%" }}>
															<a style={{ marginTop: "20px" }}>
																<ArrowForwardIcon style={{ color: "#471A71" }} />
															</a>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

