import React, { useEffect, useState, useContext, } from "react";
import { getJob } from '../../context/actions/careerAction/getCareer'
import { GlobalContext } from '../../context/GlobalState'
import parse from 'html-react-parser'
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from "moment";
import Career_Form from "../Career_Form"

import { localRoute } from '../../routes/localRoutes';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function CareerDetails() {
    const { jobState, jobDispatch } = useContext(GlobalContext);
    const [jobsDetail, setJobsDetail] = useState({})
    const { portal_job_id } = useParams()

    const history = useHistory()


    useEffect(() => {
        window.scrollTo(0, 0)
        getJob(portal_job_id)(jobDispatch)
    }, [portal_job_id])

    useEffect(() => {
        console.log('jobstate', jobState)
        let status = jobState && jobState.getJob.status
        if (status == true) {
            setJobsDetail(jobState.getJob.data)
        }
    }, [jobState])

    console.log('jobsDetailz', jobsDetail)


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Careers | Gig Onboarding Services || Gig Marketplace
                </title>
                <meta name="description" content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters." />
                <meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
                <link rel="canonical" href="https://taskmo.com/career-details" />
            </Helmet>


            <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 0px", }}
                class="font-montserrat page-title-area container">
                <ul style={{ width: "265px" }} class="breadcrumb-nav">
                    <li style={{ paddingRight: "4px" }}><a style={{ cursor: "pointer" }} onClick={() => history.push(`${localRoute.career}`)}>Career</a></li>
                    <li><ChevronRightIcon />Career Detail</li>
                </ul>
                <ul class="breadcrumb-nav">

                    <li style={{ cursor: "pointer" }} onClick={() => history.push(`${localRoute.career}`)}><KeyboardBackspaceIcon />Back</li>
                </ul>
            </div>
            <hr style={{ width: "80%", margin: "10px auto" }} />

            <div class="font-montserrat portfolio-details-one ">
                <div class="container ">
                    <div class="main-content mt-75">
                        <div class="row">
                            <div class="col-xl-11 m-auto">
                                <div class="row">
                                    <div class="col-md-4">
                                        <ul class="project-info clearfix pl-xl-5">
                                            <li>
                                                <strong>Date</strong>
                                                <span>
                                                    {(moment(jobsDetail.updated_at).format('DD-MM-YYYY'))}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Job Designation</strong>
                                                <span>
                                                    {jobsDetail.portal_job_designation}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Location</strong>
                                                <span>
                                                    {jobsDetail.portal_job_location}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-8">
                                        <h1 class="font-montserrat fw-600">
                                            {jobsDetail.portal_job_title}
                                        </h1>
                                        <p>
                                            {jobsDetail.portal_job_description && parse(jobsDetail.portal_job_description)}
                                        </p>
                                        <Career_Form subscriber_type="Career Page" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
