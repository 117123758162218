import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./JobsCampaignStyle";
import JobsCampaign from "../../components/jobsCampaign/index";
const JobsCampaignPage = React.memo((props) => {
  return (
    <>
      <JobsCampaign />
    </>
  );
});

export default withRouter(JobsCampaignPage);
