import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./PPCStyle";
import PPC from "../../components/ppc/index";
const PPCPage = React.memo((props) => {
  return (
    <>
      <PPC />
    </>
  );
});

export default withRouter(PPCPage);
