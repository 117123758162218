import React from 'react'
import { styles } from "./AppLandingStyle";
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import OurPartners from '../../components/home/ourPartners/OurPartners';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/GlobalState';
import { createSubscriber } from '../../context/actions/subscriberAction/addSubscriber';
import { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import validation from '../validation';
import Snackbar from '@mui/material/Snackbar';
import { Navigate } from 'react-router';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AreaOfInterest from './AreaOfInterest';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import jobBanner from '../../assets/images/jobBanner.jpg';
import jobBannerMobile from '../../assets/images/jobBannerMobile.png';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    subscriber_city_name: '',
    area_of_interest: ''
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const names = [
    'Brand Promoter',
    'Data Moderator',
    'Delivery Partner',
    'Event Promoters',
    'Field Activation',
    'Field Auditor',
    'Field Marketing',
    'Field Sales Executive',
    'Instore staff',
    'Part Time Jobs',
    'Picker and Packers',
    'Tele Caller',
    'Telesales',
    'Warehouse Incharge',
    'Recruiter'
];


const cities = [
    'Agra',
    'Ahmedabad',
    'Aligarh',
    'Bangalore',
    'Bhopal',
    'Chandigarh',
    'Chennai',
    'Delhi',
    'Goa',
    'Gorakhpur',
    'Gurgaon',
    'Guwahati',
    'Hyderabad',
    'Indore',
    'Jodhpur',
    'Kolhapur',
    'Kolkata',
    'Kota',
    'Ludhiana',
    'Mumbai',
    'Nagpur',
    'Nashik',
    'Noida',
    'Pune',
    'Surat',
    'Thane',
    'Vadodara'
];

const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
export default function About(props) {
    const classStyles = useStyles();
    const { classes } = props;

    const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
    const [state, setState] = useState(init);
    const [store, setStore] = useState([]);
    const [areaOfInterest, setAreaOfInterest] = React.useState([]);
    const [multipleCities, setMultipleCities] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState([]);
    const [openBanner, setOpenBanner] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const checkSessionStorage = sessionStorage.getItem("iAmAnEmployeer");


    const handleClickBannerOpen = () => {
        setOpenBanner(true);
    };

    const handleBannerClose = () => {
        setOpenBanner(false);
    };

    useEffect(() => {
        if (!checkSessionStorage) {
            sessionStorage.setItem("iAmAnEmployeer", "yes");
            setTimeout(() => {
                handleClickBannerOpen()
            }, 2000)
        }
    }, [checkSessionStorage])


    console.log("subscriberState", subscriberState)
    console.log("multipleCities", multipleCities)

    const handleChange = (e) => {

        const { name, value } = e.target;
        setState(
            // typeof value === 'string' ? value.split(',') : value,

            { ...state, [name]: value });
    };

    const handleChangeArea = (e) => {
        const {
            target: { value },
        } = e;


        setAreaOfInterest(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleChangeNumberLeadInput = (event) => {
        console.log('event', event.target.value)
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        console.log('onlyNums', onlyNums)
        if (onlyNums.length <= 10) {
            setNumber(onlyNums)
            setState({
                ...state,
                [event.target.name]: onlyNums,
            });
        }
    }



    const handleChangeCity = (e) => {
        const {
            target: { value },
        } = e;


        setMultipleCities(
            typeof value === 'string' ? value.split(',') : value,
        );
    }



    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {
            console.log("store", store);
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])


    const handleClick = e => {
        e.preventDefault()
        setErrors(validation(state));
        if (
            state.subscriber_name !== '' &&
            state.subscriber_email !== '' &&
            validateEmail(state.subscriber_email) &&
            state.subscriber_phone !== '' &&
            state.subscriber_phone.length === 10 &&
            state.subscriber_city_name !== '' &&
            state.area_of_interest !== ''
        ) {
            const postData = {
                subscriber_name: state.subscriber_name,
                subscriber_email: state.subscriber_email,
                subscriber_phone: state.subscriber_phone,
                city: state.subscriber_city_name,
                area_of_interest: state.area_of_interest,
                // area_of_interest: areaOfInterest.toString(),
                subscriber_type: 'Find-Jobs',
                visitor_type: 'job_seeker'
            };

            createSubscriber(postData)(subscriberDispatch)
            console.log('Create Subscriber Detail', postData)
            setState(init);
            setOpen(true);
            // window.location.href = 'https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US';
        }
    }

    console.log("areaOfInterest", areaOfInterest);
    console.log("state", state);

    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])



    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        // speed: 1000,
        autoplaySpeed: 1500,
        centerMode: true,

        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // console.log("subscriber_city_name",state.subscriber_city_name)


    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Job Search App | Best Money Earning App || Taskmo
                </title>
                <meta name="description" content="Taskmo - Know for Best Job Search App, Best Money Earning APP, one can find a job that interests them in the field of their choice and perform the task right away!" />
                <meta name="keywords" content="delivery job,packing job, promoters jobs,Field sales executive, Brand promoter, Field auditor, Data moderator, Tele caller, Warehouse incharge, Instore staff, Pickers and packer, Delivery Partner, Field Marketing, Field Activation, Telesales, Event promoter, Part time jobs, Field Data collection,best job search app india, job app, part time jobs apps in india, money earning apps, best earning app, online earning apps, freelance app, job finding apps" />
                <link rel="canonical" href="https://taskmo.com/job-search-app" />
            </Helmet>
            <div class="font-montserrat container" style={{ paddingTop: "30px" }}>
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row  align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9">
                                <h1 class="font-montserrat fs-60 fw-600 hero-heading">India’s Largest Gig Discovery Platform.</h1>
                                <p class="mt-15 mb-15 font-montserrat fs-20 fw-500">We are India's Largest distributed network of digital agents aka Gig workers aka Taskers.<br />Start performing tasks that interest you or near you & earn money instantly</p>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" >
                                    <img style={{ marginLeft: "-18px", height: "90px" }} src="assets/img/google_playstore.png" alt="Play store" />
                                </a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                    <div class="consultation-form">
                                        <h3 class="title" style={{ fontSize: "26px" }}>Let’s task together, Join Taskmo</h3>
                                        <form action="#">
                                            <div class="font-montserrat input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Full Name"
                                                        inputProps={{
                                                            style: {
                                                                height: "20px",
                                                            },
                                                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                                                </Grid>
                                                <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                                            </div>
                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Email" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                                                </Grid>
                                                {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                                            </div>
                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Phone" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                                                </Grid>
                                                {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                                            </div>

                                            <div class="input-field">
                                                <FormControl fullWidth>
                                                    <InputLabel id="multiple-city">City</InputLabel>
                                                    <Select
                                                        labelId="multiple-city"
                                                        id="demo-multiple-checkbox"
                                                        label="city"
                                                        value={state.subscriber_city_name}
                                                        name="subscriber_city_name"
                                                        // value={multipleCities}
                                                        // style={{}}
                                                        onChange={handleChange}
                                                        style={{ background: "#f0f4ff", outline: 'none !important' }}
                                                        input={<OutlinedInput classes={classes} label="city" />}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {cities.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors.subscriber_city_name && <p style={{ color: 'red' }}>{errors.subscriber_city_name}</p>}
                                            </div>

                                            <div class="input-field">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-multiple-checkbox-label">Area of Interest</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        label="area of interest"
                                                        value={state.area_of_interest}
                                                        name="area_of_interest"
                                                        // value={multipleCities}
                                                        // style={{}}
                                                        // multiple
                                                        onChange={handleChange}
                                                        style={{ background: "#f0f4ff", outline: 'none !important' }}
                                                        input={<OutlinedInput classes={classes} label="Area of Interest" />}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {names.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors.area_of_interest && <p style={{ color: 'red' }}>{errors.area_of_interest}</p>}
                                            </div>

                                            <div class="input-field">
                                                <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AreaOfInterest />


            <div class="fancy-feature-twentyTwo mt-150 md-mt-50" id="product">
                <div class="container">
                    <div class="title-style-ten mb-40 md-mb-20">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <h2 class="font-montserrat">India’s Best Gig Job App</h2>
                            </div>
                            <div class="col-lg-5">
                                <p class="md-pt-20 font-montserrat">We offer gig jobs across 400+ cities for the next billion internet users</p>
                            </div>
                        </div>
                    </div>

                    <div class="font-montserrat row justify-content-center">
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#FFEBDB" }}>
                                    <img width="50" src="assets/app landing/Zero Hiring Fee.svg" alt="zero hiring fees" />
                                </div>
                                <h4 class="font-montserrat">
                                    Zero Hiring Fee
                                </h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#E0F8F8" }}>
                                    <img width="50" src="assets/app landing/Instant Money Earning.svg" alt="Instant Money Earning" />
                                </div>
                                <h4 class="font-montserrat">
                                    Instant Money Earning
                                </h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <div class="block-style-twentyTwo" onclick="location.href='service-detailsV1.html';">
                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "#F7EDFF" }}>
                                    <img width="50" src="assets/app landing/Work From Anywhere.svg" alt="Work From Anywhere" />
                                </div>
                                <h4 class="font-montserrat">
                                    Work From Anywhere
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fancy-feature-twentyFive mt-50 md-mt-100" id="feature">
                <div class="container">
                    <div class="font-montserrat block-style-twentyFive mt-50 md-mt-120">
                        <div class="row align-items-center">
                            <div class="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last" data-aos="fade-up" data-aos-duration="1200">
                                <img width="600" src="assets/home/why choose us.png" alt="why choose us" class="ml-auto bg-round-shape" />
                            </div>

                            <div class="col-xl-5 col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="text-wrapper order-lg-first">
                                    <h3 class="title font-montserrat fs-50">Why is Taskmo the Best Job Search App?</h3>

                                    <li class="fs-20">Enables building your own Digital Resume</li>
                                    <li class="fs-20">AI-Based Training & Development</li>
                                    <li class="fs-20">100% Insured Taskers </li>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-screen-preview-two mt-100 md-mt-130 pb-100">
                <div class="container">
                    <div class="title-style-eleven text-center mb-80 md-mb-70">
                        <h2 class="font-montserrat fs-50 fw-600">App Overview</h2>
                    </div>
                </div>

                <div class="app-preview-slider-two">
                    <Slider {...settings}>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/1.png" alt="taskmo app overview-1" /></div>
                        </div>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/2.png" alt="taskmo app overview-2" /></div>
                        </div>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/3.png" alt="taskmo app overview-3" /></div>
                        </div>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/4.png" alt="taskmo app overview-4" /></div>
                        </div>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/6.png" alt="taskmo app overview-5" /></div>
                        </div>
                        <div class="item">
                            <div class="img-holder"><img src="assets/app landing/5.png" alt="taskmo app overview-6" /></div>
                        </div>
                    </Slider>
                </div>
            </div>

            <OurPartners />

            <div class="font-montserrat fancy-short-banner-twelve">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
                            <div class="title-style-ten text-center">
                                <h2 class="font-montserrat fs-50">Download Taskmo App Now!</h2>
                                <p class="pt-25 pb-45">India's Best Job Search App</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                        <div class="d-sm-flex align-items-center justify-content-center button-group">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" class="d-flex align-items-center windows-button">
                                <img src="images/icon/playstore.svg" alt="playstore" class="icon" />
                                <div>
                                    <span>Get it on</span>
                                    <strong>Google play</strong>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <img src="images/shape/220.svg" alt="India's Best Job Search App" class="shapes shape-one" />
                <img src="images/shape/221.svg" alt="Job Search App" class="shapes shape-two" />
            </div>

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openBanner}
                onClose={handleBannerClose}
                aria-labelledby="max-width-dialog-title"
                style={{ padding: 0 }}
            >

                <DialogContent style={{ padding: 0 }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} >
                        <div
                            style={{
                                zIndex: "2000",
                                cursor: "pointer",
                                color: '#555555',
                                marginRight: "-10px",
                                position: "fixed",
                                marginTop: "-15px"
                            }}
                            onClick={handleBannerClose}
                        >
                            <CancelIcon style={{ fontSize: "50px", color: "#555555" }} />
                        </div>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.feedmypockets&referrer=utm_source%3Dreferral%26utm_campaign%3D110558">
                            <img src={jobBanner} alt="/" className={classStyles.jobBanner} />
                            <img src={jobBannerMobile} alt="/" className={classStyles.jobBannerMobile} />
                        </a>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
