import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import taskamBg from "../../assets/images/taskamBg.png"
import taskam2 from "../../assets/images/taskam2.png"
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textMain: {
        position: "absolute",
        top: "20%",
        [theme.breakpoints.down('sm')]: {
            top: "5%",
        },
    },
    img: {
        maxHeight: "60vh",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    img1: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            width: "100%",
        },
    },
    para: {
        color: "#fff",
        width: "60%",
        margin: "auto",
        marginTop: "10px",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
        },
    },
    para1: {
        color: "#5F5D5D",
        width: "60%",
        margin: "auto",
        marginTop: "10px",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
        },
    }
}));


const Taskam = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div>
                <Grid container justifyContent="space-between" spacing={3} >
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} >
                        <div style={{ width: "100%", position: "relative" }} >
                            <div style={{ minHeight: "max-content" }} >
                                <img src={taskamBg} alt="/" className={classes.img} />

                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/9b4c041255c0edc8bd981686224602258_Group%205.svg" alt="/" className={classes.img1} style={{ minHeight: "max-content" }} />

                            </div>

                            <div className={classes.textMain} >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <p style={{ color: "#fff", fontSize: "30px", fontWeight: 600 }} >
                                        Welcome to TASKMO
                                    </p>
                                </div>
                                <div className={classes.para}>
                                    At Taskmo, we believe in the power of effective communication and connecting with our audience in meaningful ways. We understand that promotional messages play a crucial role in reaching out to potential customers and sharing valuable information about our products and services
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} data-aos="zoom-in" >
                        <img src="https://taskmotech.s3.amazonaws.com/e658c41b215627a4e7dc1686222382601_Layer%200%201.png" alt="" />
                    </Grid>
                </Grid>
            </div>

            <div>
                <div style={{ color: "#575757", textAlign: "center", fontWeight: 600, fontSize: "40px", lineHeight: "40px", marginTop: "70px" }} >Why Choose Taskmo for Your Promotional Messages?</div>

                <div style={{ width: "90%", margin: "auto", marginTop: "50px" }} >
                    <Grid container spacing={2} justifyContent='space-between'>
                        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/fce47fd1394d0e3de42a1686222801083_guidance%201.png" alt="" />
                                </div>
                                <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                    <p style={{ fontSize: "20px", fontWeight: 700 }} >Expert Guidance</p>
                                    <p style={{ lineHeight: "1.4em" }} >
                                        We understand the latest industry trends &
                                        best practices, and we'll help you tailor your
                                        content to align with your goals.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/0f4511f52935c46101721686222874945_idea%201.svg" alt="" />
                                </div>
                                <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                    <p style={{ fontSize: "20px", fontWeight: 700 }} > Creative Solutions </p>
                                    <p style={{ lineHeight: "1.4em" }} >
                                        We understand that standing out in
                                        a crowded marketplace is essential
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/319a0720c50b6bb39b881686222952147_quality-assurance%201.svg" alt="" />
                                </div>
                                <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                    <p style={{ fontSize: "20px", fontWeight: 700 }} > Compliance Assurance </p>
                                    <p style={{ lineHeight: "1.4em" }} >
                                        We help you navigate the complex landscape and
                                        ensure that you meet all the necessary requirements.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/961c3d4b786ce49111cd1686223033459_statistics%201.svg" alt="" />
                                </div>
                                <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                    <p style={{ fontSize: "20px", fontWeight: 700 }} > Data-Driven Approach </p>
                                    <p style={{ lineHeight: "1.4em" }} >
                                        We leverage data and analytics to
                                        optimize your promotional messages
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div style={{ width: "100%", marginTop: "50px" }} >
                {/* <Grid container spacing={3} justifyContent="space-between" alignItems="center" style={{ width: "100%", border: "2px solid black" }} >

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                        <div>
                            Creating successful promotional messages is an art, and at Taskmo, we're here to support you every step of the way.  Let's work together to craft compelling templates that engage your audience, drive conversions, and achieve your marketing goals. Contact us today to get started on your journey towards creating promotional messages that make a lasting impact!
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                        <div>
                            <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/cbddd6747918ec0b88e71686223557789_Group%204%20%281%29.png" alt="" style={{ border: "2px solid black" }} />
                        </div>
                    </Grid>
                </Grid> */}

                <Grid container justifyContent="space-between" alignItems="center" spacing={3} >
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} >
                        <div style={{ width: "100%" }} >
                            <div className={classes.para1}>
                                Creating successful promotional messages is an art, and at Taskmo, we're here to support you every step of the way.  Let's work together to craft compelling templates that engage your audience, drive conversions, and achieve your marketing goals. Contact us today to get started on your journey towards creating promotional messages that make a lasting impact!
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} data-aos="zoom-in" >
                        <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/cbddd6747918ec0b88e71686223557789_Group%204%20%281%29.png" alt="" />
                    </Grid>
                </Grid>
            </div>

        </div>
    )
}

export default Taskam