export default {
  getNews: {
    loading: false,
    error: null,
    data: [],
  },

  getAllNews: {
    loading: false,
    error: null,
    data: [],
  },

  createNews: {
    loading: false,
    error: null,
    data: [],
  },

};
