import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OurPartners from 'components/home/ourPartners/OurPartners';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minHeight: '80vh',
        background: `url('https://taskmotech.s3.amazonaws.com/9c630ced75e4f079ccbc1684498493212_banner%20copy.jpg') no-repeat center center`,
        backgroundSize: '100% 100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.down('sm')]: {
        //     background: "none",
        // },
    },
    card: {
        textAlign: 'left',
        position: 'absolute',
        width: "30%",
        left: '5%',
        minHeight: "70%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: "auto"
        },
    },
    button: {
        marginTop: theme.spacing(2),
    },
    btnText: {
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Montserrat",
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
        },
    },
    referText: {
        color: '#fff',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: "30px",
            marginTop: "10px"
        },
    },
    ctaImg: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        maxHeight: "150px",

    },

    ctaDesktopText: {
        display: "block",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },

    ctaMobileText: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },
    },

    logoContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "95%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center"
        },
    },
}));

const BusinessPartnerProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ fontFamily: "Montserrat" }} >
            <div className={classes.logoContainer}>
                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/f3ef0a0c9fc2812f05a51684760425590_taskmo-logo.png" alt="" style={{ maxWidth: "153px", height: "auto" }} />
            </div>
            <div className={classes.root} >
                <Card className={classes.card}>
                    <CardContent>
                        <div>

                            <div
                                style={{ fontSize: "30px", color: "#656565", lineHeight: "40px", fontWeight: 700, marginLeft: "10px" }}
                            >
                                Refer a Friend, Earn Rewards, and Build Your Business Network With Taskmo's <span style={{ color: "#603890", }} >Business</span> <span style={{ color: "#603890", }} >Partner Program</span>
                            </div>

                            {/* <div style={{ fontSize: "30px", color: "#656565", lineHeight: "40px", fontWeight: 700 }} >
                                Refer a Friend,
                            </div>

                            <div style={{ fontSize: "30px", color: "#656565", lineHeight: "40px", fontWeight: 700 }}>
                                Earn Rewards, and Build
                            </div>

                            <div style={{ fontSize: "30px", color: "#656565", lineHeight: "40px", fontWeight: 700 }}>
                                Your Business Network
                            </div>

                            <div style={{ fontSize: "30px", color: "#656565", lineHeight: "40px", fontWeight: 700 }}>
                                With Taskmo's <span style={{ color: "#603890", }} >Business</span> </div>

                            <div style={{ fontSize: "30px", color: "#603890", fontWeight: 700, lineHeight: "40px" }}>Partner Program</div> */}

                            {/* <div style={{ display: "flex", justifyContent: "center" }} > */}
                            <Button
                                variant="contained"
                                className={classes.button}
                                size='large'
                                onClick={() => history.push('/business-partner-sign-up')}
                                style={{ color: "#fff", background: "#603890", borderRadius: "20px", marginLeft: "10px" }}>
                                <div className={classes.btnText} > Join Now </div>
                            </Button>
                            {/* </div> */}
                        </div>
                    </CardContent>
                </Card>
            </div>


            <div style={{ color: "#603890", marginTop: "40px", textAlign: "center", fontWeight: 600, fontSize: "40px", lineHeight: "40px" }} >About</div>

            <div style={{ textAlign: "center", width: "95%", margin: "auto", fontSize: "20px", fontWeight: 500, color: "#656565", lineHeight: "30px", marginTop: "10px" }} >
                <b> Empower Your <span style={{ color: "#8759BF" }} >Network</span> and <span style={{ color: "#8759BF" }} > Grow Together</span></b>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%", margin: "auto", marginTop: "30px", flexWrap: "wrap" }} >
                <Grid xs={12} sm={12} md={7} lg={8} xl={8} >
                    <div style={{ width: "90%", margin: "auto", fontSize: "18px", fontWeight: 500, lineHeight: "30px", color: "#656565", textAlign: "center" }} >
                        At Taskmo, we believe in the <b>power of collaboration</b> and <b>growth</b> through strong business partnerships. Our <b>Business Partner Program</b>  is designed to create mutually beneficial relationships by connecting businesses with our innovative workforce solutions, while rewarding those who help us expand our network.
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4} data-aos="zoom-in" style={{ marginTop: "20px" }}  >
                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/81180c17d0f3bf7ec9cc1684565208912_about%20us%202.svg" alt="" style={{ maxHeight: "400px" }} />
                </Grid>
            </div>


            <div style={{ color: "#603890", marginTop: "50px", textAlign: "center", fontWeight: 600, fontSize: "40px", lineHeight: "40px" }} >Benefits of the Program</div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%", margin: "auto", marginTop: "50px", flexWrap: "wrap" }} >

                <Grid xs={12} sm={12} md={5} lg={5} xl={5} data-aos="zoom-in" >
                    <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/eabbf1a18cbe3c9775fd1684565948653_benifits%201.svg" alt="" style={{ maxHeight: "600px" }} />
                </Grid>

                <Grid xs={12} sm={12} md={7} lg={7} xl={7} >

                    <div style={{ width: "90%", margin: "auto", fontSize: "22px", fontWeight: 500, lineHeight: "35px", marginTop: "20px" }} >

                        <Card style={{ background: "#F5F2FF", borderRadius: "20px", border: "2px solid #E5D0FF", boxShadow: "none", marginTop: "20px", }} data-aos="zoom-in-up" >
                            <CardContent>
                                <div style={{ color: "#603890", fontSize: "18px", fontWeight: "500", width: "90%", margin: "auto", lineHeight: "30px" }} >
                                    <b>Attractive incentives:</b> Earn generous rewards for every successful referral, which can include monetary benefits, discounts on Taskmo services, or other exclusive perks.
                                </div>
                            </CardContent>
                        </Card>

                        <Card style={{ background: "#F5F2FF", borderRadius: "20px", border: "2px solid #E5D0FF", boxShadow: "none", marginTop: "20px", }} data-aos="zoom-in-up" >
                            <CardContent>
                                <div style={{ color: "#603890", fontSize: "18px", fontWeight: "500", width: "90%", margin: "auto", lineHeight: "30px" }} >
                                    <b>Strengthen business relationships:</b> Help your network grow by connecting them with Taskmo's powerful workforce solutions, fostering long-lasting business partnerships.
                                </div>
                            </CardContent>
                        </Card>

                        <Card style={{ background: "#F5F2FF", borderRadius: "20px", border: "2px solid #E5D0FF", boxShadow: "none", marginTop: "20px", }} data-aos="zoom-in-up" >
                            <CardContent>
                                <div style={{ color: "#603890", fontSize: "18px", fontWeight: "500", width: "90%", margin: "auto", lineHeight: "30px" }} >
                                    <b>Recognition and appreciation:</b> Stand out as a top-performing referrer and be recognized for your contributions to Taskmo's growing network.
                                </div>
                            </CardContent>
                        </Card>

                    </div>
                </Grid>

            </div>

            <div style={{ color: "#603890", textAlign: "center", fontWeight: 600, fontSize: "40px", lineHeight: "40px", marginTop: "100px" }} >How It Works</div>

            <div style={{ textAlign: "center", width: "95%", margin: "auto", fontSize: "20px", fontWeight: 500, color: "#656565", lineHeight: "30px", marginTop: "10px" }} >
                <b> Joining the Taskmo’s <span style={{ color: "#603890" }}>Business</span>  <span style={{ color: "#603890" }} >Partner Program</span> is simple & seamless. </b>
            </div>

            <div style={{ width: "90%", margin: "auto", marginTop: "50px" }} >
                <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/bc0f8d75a3f58721732b1684568135438_Group%208095.svg" alt="" />
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                <p style={{ fontSize: "20px", fontWeight: 700 }} >Sign Up</p>
                                <p style={{ lineHeight: "1.4em" }} >
                                    Register for the Taskmo’s <b>Business Partner Program </b> by filling out the application form on our website.
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/cfe9f228195a2e6bd41f1684568390525_Group%208094.svg" alt="" />
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                <p style={{ fontSize: "20px", fontWeight: 700 }} > Promote Taskmo</p>
                                <p style={{ lineHeight: "1.4em" }} >
                                    Share information about Taskmo's platform and services with your network, who might benefit from our workforce solutions
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/65816ca3c448bd1c5fad1684569744734_Group%208093.svg" alt="" />
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                <p style={{ fontSize: "20px", fontWeight: 700 }} > Refer Network </p>
                                <p style={{ lineHeight: "1.4em" }} >
                                    When a business in your network expresses interest in Taskmo, submit their contact information through our referral portal.
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <img src="https://taskmotech.s3.ap-south-1.amazonaws.com/e39636e2dedad4cd486a1684569846025_Group%208092.svg" alt="" />
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "16px" }} >
                                <p style={{ fontSize: "20px", fontWeight: 700 }} > Earn Rewards </p>
                                <p style={{ lineHeight: "1.4em" }} >
                                    Once the referred client signs up for Taskmo's services and meets the eligibility criteria, you'll receive your referral reward as per the program terms and conditions.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '50px' }}>
                <Card style={{ background: '#603890', width: '90%', margin: 'auto', borderRadius: '20px' }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item xs={12} sm={10} style={{ textAlign: 'center' }} data-aos="fade-up">
                                <div className={classes.referText}>
                                    Refer a friend today and earn for every successful referral!
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button
                                        size='large'
                                        variant="contained"

                                        style={{ background: '#F7D159', color: '#603890', borderRadius: '20px', fontSize: "16px", fontWeight: 800, }}
                                        onClick={() => history.push('/business-partner-sign-up')}
                                    >
                                        <p className={classes.ctaDesktopText}>
                                            Start Growing your business network now
                                        </p>
                                        <p className={classes.ctaMobileText}>
                                            Join now
                                        </p>
                                    </Button>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up">
                                <img
                                    src="https://taskmotech.s3.ap-south-1.amazonaws.com/9444b9d124ba46b0db811684570641446_refer%20earn%201.svg"
                                    alt=""
                                    className={classes.ctaImg}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <OurPartners />

        </div>
    )
}

export default BusinessPartnerProfile