import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import OurPartners from '../home/ourPartners/OurPartners';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/GlobalState';
import { createSubscriber } from '../../context/actions/subscriberAction/addSubscriber';
import { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import validation from '../validation';
import Snackbar from '@mui/material/Snackbar';
import { Navigate } from 'react-router';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AreaOfInterest from './AreaOfInterest';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import WhyChooseUs from './WhyChooseUs';
import Counter from './Counter';
import BusinessFormModal from '../BusinessFormModal'


const styles = theme => ({
    root: {
        "& $notchedOutline": {
            borderWidth: 0
        },
        "&:hover $notchedOutline": {
            borderWidth: 0
        },
        "&$focused $notchedOutline": {
            borderWidth: 0
        }
    },
    focused: {},
    notchedOutline: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    subscriber_city_name: '',
    area_of_interest: ''
};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
export default function D2C(props) {
    const [open, setOpen] = useState(false);



    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Direct To Consumer App | Best D2C App || Taskmo
                </title>
                <meta name="description" content="Taskmo - Know for Best Job Search App, Best Money Earning APP, one can find a job that interests them in the field of their choice and perform the task right away!" />
                <meta name="keywords" content="delivery job,packing job, promoters jobs,Field sales executive, Brand promoter, Field auditor, Data moderator, Tele caller, Warehouse incharge, Instore staff, Pickers and packer, Delivery Partner, Field Marketing, Field Activation, Telesales, Event promoter, Part time jobs, Field Data collection,best job search app india, job app, part time jobs apps in india, money earning apps, best earning app, online earning apps, freelance app, job finding apps" />
                <link rel="canonical" href="https://taskmo.com/d2c_services" />
            </Helmet>




            <div style={{ marginBottom: "80px", paddingTop: "30px" }} class="hero-banner-three1 hero-banner-three">
                <div class="container">
                    <div class="row" style={{ marginBottom: "0px", paddingTop: "0px" }}>
                        <div class="col-xl-11 col-lg-11 col-md-10 m-auto">
                            <h1 class="hero-heading font-montserrat fw-600" style={{ marginTop: "0px" }} > India’s Largest and Most Trusted Gig Marketplace for D2C Brands </h1>
                        </div>
                        <div style={{ textAlign: "center" }} class="col-xl-9 col-lg-9 m-auto">
                            <p class="mt-40 mb-40 font-montserrat fs-20 fw-500">Taskmo has assisted over 350+ market players in leveraging their businesses and now it's your turn to achieve greater efficiency, productivity, and profitability. </p>
                            <BusinessFormModal subscriber_type="D2C Page" />
                        </div>
                    </div>

                </div>
            </div>

            <OurPartners />
            <Counter />
            <WhyChooseUs />


            <div class="fancy-feature-twentyOne mt-10 md-mt-80" id="template">
                <div class="container">
                    <div class="font-montserrat title-style-eight  text-center md-mb-10" data-aos="fade-up" data-aos-duration="1200">
                        <h2 class="font-montserrat fs-50">Our Services</h2>
                    </div>
                </div>

                <div class="lg-container">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyOne">
                                    <img src="../../../assets/D2C/last mile delivery.png" alt="" class="w-100" />
                                    <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                        <span class="cl-white ">Last Minute Delivery</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyOne">
                                    <img src="../../../assets/D2C/Customer Acquisition.png" alt="" class="w-100" />
                                    <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                        <span class="cl-white ">Customer Acquisition</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyOne">
                                    <img src="../../../assets/D2C/Quality Lead generatio.png" alt="" class="w-100" />
                                    <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                        <span class="cl-white ">Quality Lead generation</span>
                                    </a>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                                <div class="block-style-twentyOne">
                                    <img src="../../../assets/D2C/Network of distribution.png" alt="" class="w-100" />
                                    <a data-fancybox="" class="d-flex align-items-center justify-content-center flex-column video-button">
                                        <span class="cl-white ">Network of distribution</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                            <BusinessFormModal subscriber_type="D2C Page" />
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}
