import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./PrivacyPolicyStyle";
import PrivacyPolicyApp from "../../components/privacyPolicy/privacyPolicyApp";
const PrivacyPolicyAppPage = React.memo((props) => {
  return (
    <>
      <PrivacyPolicyApp />
    </>
  );
});

export default withRouter(PrivacyPolicyAppPage);
