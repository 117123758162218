import React, { useEffect, useState, useContext, } from "react";
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Pagination, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { getAllCasestudy } from "../../../context/actions/casestudyAction/getAllCaseStudy";
import { getCasestudyCategories } from '../../../context/actions/casestudyAction/getCasestudyCategories'
import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import { Helmet } from "react-helmet";


export default function Blog(props) {
    const { casestudyState, casestudyDispatch } = useContext(GlobalContext);

    const [casestudyCategoriesDetail, setCasestudyCategoriesDetail] = useState([])
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [paginationCount, setPaginationCount] = useState(1);
    const [totalInActiveRows, setTotalInActiveRows] = useState(0);
    const [totalActiveRows, setTotalActiveRows] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [CasestudyList, setCasestudyList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [tabFilter, setTabFilter] = useState('active');
    const [filterCasestudyDetails, setFilterCasestudyDetails] = useState([]);
    const [color, setColor] = useState("")

    const history = useHistory()

    const handleChangePage = (event, newPage) => {
        let count = newPage - 1
        setPage(count);
        console.log('count', count)
        setPaginationCount(newPage)
        let filter = tabFilter
        let page = newPage;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        let filter = tabFilter
        getCasestudyCategories({ filter, page: "all" })(casestudyDispatch)
    }, [])

    useEffect(() => {
        console.log('casestudyState', casestudyState)
        const status = casestudyState && casestudyState.getCasestudyCategories.status
        if (status == true) {
            setCasestudyCategoriesDetail(casestudyState.getCasestudyCategories.data)
            console.log(setCasestudyCategoriesDetail(casestudyState.getCasestudyCategories.data.res));
        }
    }, [casestudyState])
    console.log('casestudyCategoriesDetail', casestudyCategoriesDetail)


    useEffect(() => {
        let filter = tabFilter
        getAllCasestudy({ filter, page: "all" })(casestudyDispatch)
    }, [])


    useEffect(() => {
        let status = casestudyState.getAllCasestudy.status
        if (status === true) {
            console.log("taskerState() ", casestudyState.getAllCasestudy)
            let limit = '8'
            let total_page = Math.ceil(casestudyState.getAllCasestudy.data.res.length / limit)
            console.log('total_page', total_page)
            setTotalPage(total_page)
            setPaginationCount(1)
            setPage(0)
            setTotalRows(casestudyState.getAllCasestudy.data.total_rows)
            setCasestudyList(casestudyState.getAllCasestudy.data.res)
            setFilterCasestudyDetails(casestudyState.getAllCasestudy.data.res)
        }
        // SERVER ERROR RESPONSE
        if (status === 500) {
            console.log("Server Error:", casestudyState.getAllCasestudy)
        }
        // SERVER VALIDATION ERROR RESPONSE  
        if (status === 401) {
            console.log("Validation Error:", casestudyState.getAllCasestudy)
        }
    }, [casestudyState])

    console.log("filterCasestudyDetails", filterCasestudyDetails);

    console.log("color", color)
    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    })

    const handleCasestudyCategory = (case_study_category_id) => {
        let count = 0
        setPage(count);
        setFilterCasestudyDetails([])
        setColor("")
        console.log('case_study_category_id', case_study_category_id)
        let filtered = CasestudyList.filter(m => m.case_study_category_id == case_study_category_id)
        console.log('filtered', filtered)
        let limit = '8'
        let total_page = Math.ceil(filtered.length / limit)
        console.log('total_page', total_page)
        setTotalPage(total_page)
        setFilterCasestudyDetails(filtered)
        setColor("red")
    }
    const OpenBlog = (item) => {
        history.push(`${localRoute.caseStudyDetails}/${item.case_study_slug}`)
    }

    console.log("setFilterCasestudyDetails", filterCasestudyDetails);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Casestudy | Gig Onboarding Services || Gig Marketplace
                </title>
                <meta name="description" content="TASKMO is a tech driven on-demand staffing platform that connect companies with pre screened deployable temporary workers, part time workers, manpower, promoters." />
                <meta name="keywords" content="Merchant Onboarding, Background verification, Tele sales, Last mile Delivery, Customer Acquisation, Stock Audits, Geo Tagging, Brand Activation, Picker & Packer, Survey collection, Product Sampling, Influencer marketing, Customer Support, Content Moderation Services, Services offered" />
                <link rel="canonical" href="https://taskmo.com/case-studies" />
            </Helmet>

            <div style={{ marginTop: "30px" }} class="blog-v3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="font-montserrat">

                                <div class="row">

                                    {filterCasestudyDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                        return (

                                            <div class="col-lg-6 col-md-6">
                                                <div class="post-meta" style={{ marginBottom: "20px", padding: "20px", background: "#fff", boxShadow: "0 10px 40px rgb(0 0 0 / 8%)", minHeight: "355px" }}>
                                                    {/* <div class="feature-blog-three font-montserrat"> */}
                                                    <div style={{ cursor: "pointer" }} onClick={() => OpenBlog(item)} key={item.case_study_slug} class="post-meta">
                                                        <img src={item.case_study_image} alt={item.case_study_title} />
                                                        <div class="post">
                                                            <h2>
                                                                {item.case_study_title}
                                                            </h2>

                                                            <p class="read-more">
                                                                <span>Continue Reading &nbsp;</span>
                                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                            </p>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div class="page-pagination-one pt-15">
                                    <ul class="d-flex align-items-center">
                                        <Pagination count={totalPage} page={paginationCount} onChange={handleChangePage} onClick={window.scrollTo(0, 0)} color="primary" variant="outlined" onRowsPerPageChange={handleChangeRowsPerPage} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-sidebar-one">
                                <div class="sidebar-categories mb-85">
                                    <h4 class="sidebar-title font-montserrat fw-600">Categories</h4>
                                    {casestudyCategoriesDetail.map(item =>
                                        <ul>
                                            <li>
                                                <a class="font-montserrat" style={{ cursor: "pointer" }} onClick={() => handleCasestudyCategory(item.case_study_category_id)}>{item.case_study_category_name}</a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
