import React, { useState, useEffect } from "react";
import "./FooterStyle";
import Footer from '../../components/footer/index'
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import ModalChat from "./ModalChat";
import ChatBot from "./ChatBot";

import {
  withRouter,
} from "react-router-dom";
import B2BModalChat from "./B2BModalChat";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FooterPage = React.memo((props) => {
  const location = useLocation()
  console.log("checkLocationnnn", location.pathname)

  return (
    <>

      {location.pathname == "/" ? <ModalChat /> : location.pathname == "/about-us" ? <ModalChat /> :
        location.pathname == "/bpp" ? "" : <B2BModalChat />}

      <Footer />
    </>
  );
});

export default withRouter(FooterPage);
