import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function OurPartners() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: false,
        speed: 3000,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div class="useable-tools-section md-mt-80 mt-40 mb-80">
            <h2 style={{ color: "#281F5D", margin: "0px", textAlign: "center" }} class="mt-20 fs-50 fw-600">Our Clients</h2>
            <p style={{ textAlign: "center", }} class="mb-50 fs-20 font-montserrat fw-500">Our clients range from startups to Fortune 500, listed are just a few !</p>
            <Slider {...settings}>
                <div class="useable-tools-section md-mt-80 mt-40 mb-80">
                    <div class="bubble-one"></div>
                    <div class="bubble-two"></div>
                    <div class="bubble-three"></div>
                    <div class="bubble-four"></div>
                    <div class="bubble-five"></div>
                    <div class="container">

                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-4 col-6" data-aos={"fade-up"} data-aos-duration="1200">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-a">
                                    <a href="#"><img width="200" src="../../../../assets/logo web/airtel.png" alt="airtel logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#FDE5FF" }} class="img-box bx-b">
                                    <a href="#"><img width="250" src="../../../../assets/logo web/byjus.png" alt="byjus logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#FFEDD8" }} class="img-box bx-c">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/amazon pay.png" alt="amazon pay logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ backgroundColor: "#F4FFD1" }} class="img-box bx-d">
                                    <a href="#"><img width="170" src="../../../../assets/logo web/bigbasket.png" alt="bigbasket logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="250">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-e">
                                    <a href="#"><img width="200" src="../../../assets/logo web/bounce.png" alt="bounce logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#E7FFFD" }} class="img-box bx-f">
                                    <a href="#"><img width="100" src="../../../assets/logo web/dunzo.png" alt="dunzo logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div style={{ backgroundColor: "#C7E5FF" }} class="img-box bx-g">
                                    <a href="#"><img width="200" src="../../../../assets/logo web/flipkart.png" alt="flipkart logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#FEFFDE" }} class="img-box bx-h">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/ola.png" alt="ola logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-11 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-i">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/zomato.png" alt="zomato logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="useable-tools-section md-mt-80 mt-40 mb-80">
                    <div class="bubble-one"></div>
                    <div class="bubble-two"></div>
                    <div class="bubble-three"></div>
                    <div class="bubble-four"></div>
                    <div class="bubble-five"></div>
                    <div class="container">

                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-4 col-6" data-aos={"fade-up"} data-aos-duration="1200">
                                <div style={{ backgroundColor: "#FFEBEA" }} class="img-box bx-a">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/1mg.png" alt="1mg logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-c">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/jio.png" alt="jio logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#EDFDFF" }} class="img-box bx-b">
                                    <a href="#"><img width="250" src="../../../../assets/logo web/bharatpe.png" alt="bharatpe logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-d">
                                    <a href="#"><img width="130" src="../../../../assets/logo web/blackbuck.png" alt="blackbuck logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="250">
                                <div style={{ backgroundColor: "#E0FFFD" }} class="img-box bx-e">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/cashify.png" alt="cashify logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#E4E9FF" }} class="img-box bx-f">
                                    <a href="#"><img width="120" src="../../../../assets/logo web/itc.png" alt="itc logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div style={{ backgroundColor: "#FFEEE4" }} class="img-box bx-g">
                                    <a href="#"><img width="140" src="../../../../assets/logo web/medlife.png" alt="medlife logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#FFEEF6" }} class="img-box bx-h">
                                    <a href="#"><img width="100" src="../../../../assets/logo web/meesho.png" alt="meesho logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-11 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ backgroundColor: "#EDF9FF" }} class="img-box bx-i">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/milkbasket.png" alt="milkbasket logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="useable-tools-section md-mt-80 mt-40 mb-80">
                    <div class="bubble-one"></div>
                    <div class="bubble-two"></div>
                    <div class="bubble-three"></div>
                    <div class="bubble-four"></div>
                    <div class="bubble-five"></div>
                    <div class="container">

                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-4 col-6" data-aos={"fade-up"} data-aos-duration="1200">
                                <div style={{ backgroundColor: "#E3FFDB" }} class="img-box bx-a">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/ninjakart.png" alt="ninjakart logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-d">
                                    <a href="#"><img width="130" src="../../../../assets/logo web/pitstop.png" alt="pitshop logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#F4EEFF" }} class="img-box bx-c">
                                    <a href="#"><img width="200" src="../../../../assets/logo web/phonepe.png" alt="phonepe logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">

                                <div style={{ backgroundColor: "#E6F8FF" }} class="img-box bx-b">
                                    <a href="#"><img width="100" src="../../../../assets/logo web/paytm.png" alt="paytm logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="250">
                                <div style={{ backgroundColor: "#FFF4EA" }} class="img-box bx-g">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/tanishq.png" alt="tanishq logo" /></a>
                                </div>

                            </div>
                            <div class="col-xl-4 col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                                <div style={{ backgroundColor: "#FFF1E7" }} class="img-box bx-f">
                                    <a href="#"><img width="130" src="../../../../assets/logo web/swiggy.png" alt="swiggy logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div style={{ backgroundColor: "#E0E8FF" }} class="img-box bx-e">
                                    <a href="#"><img width="120" src="../../../../assets/logo web/porter.png" alt="porter logo" /></a>
                                </div>

                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                                <div style={{ backgroundColor: "#E1E1E1" }} class="img-box bx-h">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/uber.png" alt="uber logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-11 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div style={{ backgroundColor: "#FFF3F3" }} class="img-box bx-i">
                                    <a href="#"><img width="150" src="../../../../assets/logo web/vandilal.png" alt="vadilal logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}



