import React from 'react'
import { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import OurPartners from '../home/ourPartners/OurPartners';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/GlobalState';
import { createSubscriber } from '../../context/actions/subscriberAction/addSubscriber';
import { useContext } from 'react';
import MuiAlert from '@mui/material/Alert';
import validation from '../validation';
import Snackbar from '@mui/material/Snackbar';
import { Navigate } from 'react-router';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import { createImageUrl } from "../../context/actions/subscriberAction/createImageUrl";


const styles = theme => ({
    root: {
        "& $notchedOutline": {
            borderWidth: 0
        },
        "&:hover $notchedOutline": {
            borderWidth: 0
        },
        "&$focused $notchedOutline": {
            borderWidth: 0
        }
    },
    focused: {},
    notchedOutline: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const init = {
    subscriber_name: '',
    subscriber_email: '',
    subscriber_phone: '',
    file_url: '',

};
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




const validateEmail = (email) =>
    String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
export default function RestartCareer(props) {
    const { classes } = props;

    const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
    const [state, setState] = useState(init);
    const [store, setStore] = useState([]);
    const [areaOfInterest, setAreaOfInterest] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState([])
    const [imageUrl, setImageUrl] = React.useState('');
    const [uploadResponse, setUploadResponse] = useState(false);


    const handleChangeNumberLeadInput = (event) => {
        console.log('event', event.target.value)
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        console.log('onlyNums', onlyNums)
        if (onlyNums.length <= 10) {
            setNumber(onlyNums)
            setState({
                ...state,
                [event.target.name]: onlyNums,
            });
        }
    }

    console.log("subscriberState", subscriberState)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleClick = e => {
        e.preventDefault()
        setErrors(validation(state));
        if (
            state.subscriber_name !== '' &&
            state.subscriber_email !== '' &&
            validateEmail(state.subscriber_email) &&
            state.subscriber_phone !== '' &&
            state.subscriber_phone.length === 10 &&
            state.file_url !== '' &&
            areaOfInterest !== ''
        ) {
            const postData = {
                subscriber_name: state.subscriber_name,
                subscriber_email: state.subscriber_email,
                subscriber_phone: state.subscriber_phone,
                file_url: state.file_url,
                subscriber_type: 'Restart Career Page',
                visitor_type: 'job_seeker'
            };
            createSubscriber(postData)(subscriberDispatch)
            console.log('Create Restart Career Detail', postData)
            setState(init);
            setOpen(true);
            setImageUrl("")
            // window.location.href = 'https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US';
        }
    }

    console.log("areaOfInterest", areaOfInterest);

    useEffect(() => {
        console.log('subscriberState', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.status
        if (status == true) {
            console.log("store", store);
            subscriberState.createSubscriber = {
                loading: false,
                error: null,
                data: null,
            }
        }
    }, [subscriberState])

    const handleCapture = ({ target }) => {
        console.log('upload Files')
        const { name, value } = target
        if (value != '') {
            const fileReader = new FileReader();
            const name1 = target.accept.includes('pdf');

            fileReader.readAsDataURL(target.files[0]);

            let formData = new FormData();
            formData.append('file', target.files[0]);
            console.log('file', target.files)
            setImageUrl(target.files[0].name)
            setUploadResponse(false)
            createImageUrl(formData)(subscriberDispatch)
        }
    };


    useEffect(() => {
        console.log('subscriberStatess', subscriberState)
        let status = subscriberState.createSubscriber && subscriberState.createImageUrl.data && subscriberState.createImageUrl.data.status
        if (status == true) {
            setState(
                {
                    ...state,
                    file_url: subscriberState.createImageUrl.data.data.url
                });
            // setImageUrl(subscriberState.createImageUrl.data.data.url)
            setUploadResponse(true)
            console.log("setState1", state.file_url)
        }
    }, [subscriberState])


    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Thank You for Submitting Your Request
                </Alert>
            </Snackbar>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Best Job Search App | Best Money Earning App || Taskmo
                </title>
                <meta name="description" content="Taskmo - Know for Best Job Search App, Best Money Earning APP, one can find a job that interests them in the field of their choice and perform the task right away!" />
                <meta name="keywords" content="best job search app india, job app, part time jobs apps in india, money earning apps, best earning app, online earning apps, freelance app, job finding apps" />
                <link rel="canonical" href="https://taskmo.com/job-search-app" />
            </Helmet>



            <div class="font-montserrat container" style={{ paddingTop: "30px" }} >
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="row  align-items-center justify-content-center">
                            <div data-aos="zoom-in-up" class="col-lg-7 col-md-9">

                                <h1 class="font-montserrat fs-70 fw-600 hero-heading">Restart your career at Taskmo</h1>
                                <p class="mt-15 mb-15 font-montserrat fs-20 fw-500">
                                    <li>
                                        Job security- We offer you a secured job position in your individual niche
                                    </li>
                                    <li>

                                        Progressive work environment-  We offer you a progressive and positive environment to work in
                                    </li>
                                    <li>
                                        A team of talented colleagues-We have the best team of professionals to work with and learn from.
                                    </li>
                                </p>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" >
                                    <img style={{ marginLeft: "-18px", height: "90px" }} src="assets/img/google_playstore.png" alt="Play store" />
                                </a>
                            </div>
                            <div data-aos="zoom-in-up" class="col-lg-5 col-md-8 wow fadeInUp">
                                <div class="consultation-form-area pl-xl-3 content-mt-md-50">
                                    <div class="consultation-form">
                                        <form action="#">
                                            <div class="font-montserrat input-field">

                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Full Name"
                                                        inputProps={{
                                                            style: {
                                                                height: "20px",
                                                            },
                                                        }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
                                                </Grid>
                                                <p style={{ color: 'red' }}>{errors.subscriber_name}</p>
                                            </div>
                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Email" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
                                                </Grid>
                                                {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
                                            </div>
                                            <div class="input-field">
                                                <Grid xs={12} >
                                                    <TextField fullWidth label="Phone" inputProps={{
                                                        style: {
                                                            height: "20px",
                                                        },
                                                    }} type="text" variant="outlined" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
                                                </Grid>
                                                {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}
                                            </div>

                                            <div >
                                                <span>
                                                    <label htmlFor="icon-button-photo" class="mt-10">
                                                        Upload Resume* (Only PDF and Doc File)
                                                        <input
                                                            accept="application/pdf,application/msword"
                                                            type="file"
                                                            id="icon-button-photo"
                                                            // value={state.file_url}
                                                            name="file_url"
                                                            onChange={handleCapture}
                                                            style={{ display: 'none', border: "1px solid #b8b8b8", height: "50px", padding: "9px 15px" }}
                                                            class="inputfile font-montserrat fw-300"
                                                        />
                                                        <Button color="primary" component="span" size="small" variant="contained" style={{ backgroundColor: '#7946A9', marginRight: "5px" }}><UploadIcon />Upload Resume</Button>
                                                        {uploadResponse == true ? <>
                                                            <span style={{ color: "gray" }}>{imageUrl}</span></> : <></>}
                                                    </label>
                                                    {imageUrl ? <p style={{ color: "green" }}>File Uploaded Successfully</p> : ""}
                                                    {errors.file_url && <p style={{ color: 'red' }}>{errors.file_url}</p>}
                                                </span>
                                            </div>



                                            <div class="input-field">
                                                <button href="https://play.google.com/store/apps/details?id=com.feedmypockets&hl=en_IN&gl=US" style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
