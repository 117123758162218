import { GET_ALLNEWS,GET_ALLNEWS_ERR } from '../../actionTypes/newsType'
import { getAllNewsApi } from 'api/Api';
import axios from "axios";
export const getAllNews = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getAllNewsApi}/${payload.type}/${payload.status}/${payload.page}`)
        console.log("Get All News", results)
        let data = results && results.data
        dispatch({
            type: GET_ALLNEWS,
            payload: data || [],
        });
    } catch (error) {
        dispatch({
            type: GET_ALLNEWS_ERR,
            payload: error && error.response
        });
    }
}

