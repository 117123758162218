import React from "react";
import { withRouter } from "react-router-dom";
import "./SignupStyle";
import Signup from "../../components/signUp/index";
const SignupPage = React.memo((props) => {
  return (
    <>
      <Signup />
    </>
  );
});

export default withRouter(SignupPage);
