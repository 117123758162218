export default {
  getCasestudy: {
    loading: false,
    error: null,
    data: [],
  },

  getAllCasestudy: {
    loading: false,
    error: null,
    data: [],
  },

  getCasestudyCategories: {
    loading: false,
    error: null,
    data: [],
  },


};
