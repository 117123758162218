import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validationBusiness';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { localRoute } from 'routes/localRoutes';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const init = {
  subscriber_name: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_campany_name: '',
  subscriber_message: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );


// const validateGmail = (email) =>
// String(email)
//   .match(
//     'gmail'
//   );



const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function Find_Taskers_Common(props) {
  console.log('aaa', props)
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);
  const history = useHistory()
  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([])
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setErrors(false)
    setState(init);
    setOpen(false)
  }

  // if(state.subscriber_email.match("gmail")){
  //   errors.subscriber_email = 'Please enter official email';
  // }

  const handleChangeNumberLeadInput = (event) => {
    console.log('event', event.target.value)
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));

    if (
      state.subscriber_name !== '' &&
      state.subscriber_email !== '' &&
      !state.subscriber_email.match("gmail") &&
      validateEmail(state.subscriber_email) &&
      // validateGmail(!state.subscriber_email) &&   
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.subscriber_campany_name !== '' &&
      state.subscriber_message !== ''
    ) {
      const postData = {
        subscriber_name: state.subscriber_name,
        subscriber_email: state.subscriber_email,
        subscriber_phone: state.subscriber_phone,
        subscriber_campany_name: state.subscriber_campany_name,
        subscriber_message: state.subscriber_message,
        subscriber_type: props.subscriber_type,
        visitor_type: 'hire_tasker'
      };
      createSubscriber(postData)(subscriberDispatch)
      setState(init);
      setOpen(true);
      console.log('Create Subscriber Detail', postData)
    }


  }



  useEffect(() => {
    console.log('subscriberStateCheck', subscriberState.createSubscriber)
    let status = subscriberState.createSubscriber && subscriberState.createSubscriber.data && subscriberState.createSubscriber.data.status
    if (status == true) {
      console.log("store", store);
      subscriberState.createSubscriber = {
        loading: false,
        error: null,
        data: null,
      }
      // window.location.replace('b2b-services/thank-you')
      history.push(`${localRoute.thankYou}`)
    }
  }, [subscriberState])


  return (
    <div >
      <Snackbar open={open} onClose={handleClose} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Form Successfully Submitted!
        </Alert>
      </Snackbar>
      <div class="consultation-form">
        {props.subscriber_type == "pay-per-click-ads" ? <h4 style={{ lineHeight: "1.3em" }} class="pb-20 font-montserrat fs-25 fw-600">Reach out to our expert, who's here for you</h4> : <h4 style={{ lineHeight: "1.3em" }} class="pb-20 font-montserrat fs-25 fw-600">Get in Touch With Our Experts!</h4>}
        {/* <h4 style={{ lineHeight: "1.3em" }} class="pb-20 font-montserrat fs-25 fw-600">Get in Touch With Our Experts!</h4> */}

        <form action="#">
          <div class="font-montserrat input-field">
            <Grid xs={12} >
              <TextField fullWidth label="Full Name*"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }} variant="outlined" type="text" name="subscriber_name" value={state.subscriber_name} onChange={handleChange} />
            </Grid>
            {errors.subscriber_name && <p style={{ color: 'red' }}>{errors.subscriber_name}</p>}
          </div>
          <div class="input-field">
            <Grid xs={12} >
              <TextField fullWidth label="Work Email*"

                inputProps={{
                  style: {
                    height: "20px",
                  },
                }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
            </Grid>
            {errors.subscriber_email && <p style={{ color: 'red' }}>{errors.subscriber_email}</p>}
          </div>
          <div class="input-field">
            <Grid xs={12} >
              <TextField fullWidth label="Phone*"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
            </Grid>
            {errors.subscriber_phone && <p style={{ color: 'red' }}>{errors.subscriber_phone}</p>}

          </div>
          <div class="input-field">
            <Grid xs={12} >
              <TextField fullWidth label="Company Name*"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }} variant="outlined" type="text" name="subscriber_campany_name" value={state.subscriber_campany_name} onChange={handleChange} />
            </Grid>
            {errors.subscriber_campany_name && <p style={{ color: 'red' }}>{errors.subscriber_campany_name}</p>}
          </div>
          {props.subscriber_type != "pay-per-click-ads" &&
            <div class="input-field">
              <Grid xs={12} >
                <TextField fullWidth label="Describe your requirement*"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }} variant="outlined" type="text" name="subscriber_message" value={state.subscriber_message} onChange={handleChange} />
              </Grid>
              {errors.subscriber_message && <p style={{ color: 'red' }}>{errors.subscriber_message}</p>}
            </div>
          }
          {props.subscriber_type == "pay-per-click-ads" &&
            <div class="input-field" >
              <FormControl variant="outlined" fullWidth style={{ background: "#F0F4FF" }} >
                <InputLabel id="demo-simple-select-outlined-label">Select Offerings</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="subscriber_message"
                  value={state.subscriber_message}
                  onChange={handleChange}
                  label="Select Offerings"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 400,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Merchant Onboarding">Merchant Onboarding</MenuItem>
                  <MenuItem value="Partner Onboarding">Partner Onboarding</MenuItem>
                  <MenuItem value="Seller Onboarding">Seller Onboarding</MenuItem>
                  <MenuItem value="Retail Store Audit">Retail Store Audit</MenuItem>
                  <MenuItem value="Retail Store Visit">Retail Store Visit</MenuItem>
                  <MenuItem value="Order Taking Management">Order Taking Management</MenuItem>
                  <MenuItem value="POS Marketing">POS Marketing</MenuItem>
                  <MenuItem value="Content Moderation Service">Content Moderation Service</MenuItem>
                  <MenuItem value="Subscription Selling Service">Subscription Selling Service</MenuItem>
                  <MenuItem value="Selling Business Loans">Selling Business Loans</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
              {errors.subscriber_message && <p style={{ color: 'red' }}>{errors.subscriber_message}</p>}
            </div>
          }
          <div class="input-field">
            <button style={{ fontWeight: "500" }} onClick={handleClick} type="submit" class=" template-btn">Submit <i class="flaticon-right-arrow"></i></button>
          </div>
        </form>

      </div>
    </div>
  );
}
